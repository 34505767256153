import { RootState } from "@redux/store";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../toasts/ErrorToast";

export const ErrorsList = () => {
  const issueDetailsStates = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );
  const { stack_trace } = issueDetailsStates.response.issue_data!;
  const params = useParams();
  const projectid = params.id;

  const [orgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });

  const paddingStyle = "px-4 py-2";

  const commonStyles = `flex h-[40px] justify-center items-center cursor-pointer ${paddingStyle}`;

  const visibleBorderStyle =
    "border-2 border-b-[0] border-[#DFE3F8] decoration-[3px] underline  underline-offset-[11px] text-[#325EBB]";
  const hiddenBorderStyle = "border-[#EFF3FF] text-[#454545]";

  const [isCompleteStack, setIsCompleteStack] = useState<boolean>(false);

  const stackTraceIconStyle = isCompleteStack && "rotate-180";
  const stackContainerStyle =
    !isCompleteStack && "max-h-[195px] overflow-hidden";

  const [isStacktraceActive, setStacktraceIsActive] = useState(true);
  const [isSuggestionActive, setSuggestionIsActive] = useState(false);
  const [issueData, setIssueData] = useState<IssueSummary>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFlickering, setIsFlickering] = useState<boolean>(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!isSuggestionActive) {
      interval = setInterval(() => {
        setIsFlickering((prev) => !prev);
      }, 500);
    } else {
      setIsFlickering(false);
    }
    return () => clearInterval(interval);
  }, [isSuggestionActive]);

  const toggleStacktrace = () => {
    setSuggestionIsActive(false);
    setStacktraceIsActive(true);
  };
  const toggleSuggestion = () => {
    handleClick();
    setStacktraceIsActive(false);
    setSuggestionIsActive(true);
    setCount(count + 1);
  };
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const issueRepo = repository.issues;
  const handleClick = async () => {
    try {
      setIsLoading(true);
      const response = await issueRepo.fetchIssueSummary<IssueSummary>(
        `${issueDetailsStates.response.issue_data?.id}`,
        `${projectid}`,
        `${orgId}`
      );
      if (response) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            ErrorToast({ Message: error.message });
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          setIssueData(response);
        }
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderCodeTags = (text: string) => {
    const regex = /`([^`]+)`/g;
    return text.replace(
      regex,
      '<code class="border border-[#E4E4E4] bg-[#F7F7F7] px-[10px] py-[6px] text-[10px] text-[#D16568] rounded-[3px]">$1</code>'
    );
  };

  return (
    <div className="flex flex-col bg-[#F8F9FC]">
      <div className="flex bg-[#EFF3FF]">
        <div
          onClick={toggleStacktrace}
          className={`${commonStyles} ${
            isStacktraceActive ? visibleBorderStyle : hiddenBorderStyle
          } text-[14px] leading-[23px] tracking-[0.5px] font-[500]`}
        >
          Stacktrace
        </div>
        <div
          onClick={toggleSuggestion}
          className={`${commonStyles} ${
            isSuggestionActive ? visibleBorderStyle : hiddenBorderStyle
          } text-[14px] leading-[23px] tracking-[0.5px] font-[500] gap-[10px]`}
        >
          How to Fix
          <img
            src={
              isSuggestionActive || count > 0
                ? "/icons/LitBulb.svg"
                : isFlickering
                ? "/icons/LitBulb.svg"
                : "/icons/Bulb.svg"
            }
            alt="LoadingIcon"
          />
        </div>
      </div>
      <div className="bg-[#F8F9FC] w-full p-4 border-2 border-[#DFE3F8]">
        {isStacktraceActive ? (
          <div>
            <div className={`flex flex-col ${stackContainerStyle}`}>
              <div className="text-[14px] leading-[24px] text-[#344563] font-fira break-words">
                {stack_trace}
              </div>
            </div>
            <div className="flex flex-col w-full items-center">
              <img
                src="/icons/ExpandStackTrace.svg"
                alt="ExpandDownArrow"
                className={`cursor-pointer ${stackTraceIconStyle}`}
                onClick={() => setIsCompleteStack(!isCompleteStack)}
              />
              <div className="font-medium text-[12px] leading-6 text-[#4F5A69]">
                {isCompleteStack ? "Collapse" : "Expand"}
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="flex w-full h-[50vh] items-center justify-center">
            <img
              className="w-[50px]"
              src="/icons/Loading.svg"
              alt="LoadingIcon"
            />
          </div>
        ) : (
          issueData && (
            <div className="flex flex-col gap-[12px]">
              <h1 className="text-[#172B4D] text-[14px] font-bold leading-[23px]">
                Problems
              </h1>
              <div className="flex flex-col gap-[8px] text-[14px] leading-[24px] text-[#344563]">
                <div>
                  {issueData.issue_summary.map((summary, index) => (
                    <div className="flex justify-start items-start gap-[12px]">
                      <img
                        className="mt-2"
                        src="/icons/ListSuggestion.svg"
                        alt="list-disc"
                      />
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: renderCodeTags(summary),
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
              <h1 className="text-[#172B4D] text-[14px] font-bold leading-[23px]">
                Suggested Solutions
              </h1>
              <div className="flex flex-col gap-[8px] text-[14px] leading-[24px] text-[#344563]">
                <div>
                  {issueData.issue_fix.map((fix, index) => (
                    <div className="flex justify-start items-start gap-[12px]">
                      <img
                        className="mt-2"
                        src="/icons/ListSuggestion.svg"
                        alt="list-disc"
                      />
                      <div
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: renderCodeTags(fix),
                        }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

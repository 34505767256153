import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { RepositoryContext, LoggerContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";

interface props {
  email: string;
}
const MessageEmailVerification = ({ email }: props) => {
  const repository = useContext(RepositoryContext);
  const activationRepo = repository.login;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const [isLoading, setIsLoading] = useState(false);

  const onResendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await activationRepo.resendEmail<props, props>({
        email,
      });
      if (typeof response === "string") {
        if (response === "Account activation email resent successfully.") {
          SuccessToast({
            Message: response,
          });
        }
      } else if (response && typeof response === "object") {
        if ("errors" in response) {
          _.forEach(response.errors, (error) => {
            ErrorToast({ Message: error.message });
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        }
      }
      setIsLoading(false);
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="container h-[100%] flex flex-col justify-center items-center">
      <h1 className="text-[#162C5B] font-bold text-[28px]">
        Verify your email address
      </h1>
      <p className="w-[580px] mt-[58px] text-start text-[18px]">
        We’ve sent a confirmation email to your email account. To use your
        retack.ai account please verify your email by clicking
        <br />
        <span className="text-[18px] font-bold text-[#000000]">
          Confirm Email Address
        </span>{" "}
        on your email.
      </p>
      <div className="bg-[#F3F3F5] flex flex-col gap-[18px] w-[580px] py-[30px] px-[50px] mt-[26px] text-[18px]">
        <div className="flex gap-[10px] ml-[-35px] items-center">
          <img width={25} height={25} src="/icons/JamAlert.svg" alt="alert" />
          <p>Didn’t receive an email ?</p>
        </div>
        <p>
          If you cant find the email in your inbox or spam folder. Please click
          below for the new email.
        </p>

        <button
          onClick={() => onResendEmail()}
          className="underline text-[#0E2390] text-start"
        >
          {isLoading ? (
            <img
              width={22}
              height={22}
              src="/icons/Loading.svg"
              alt="loading"
            />
          ) : (
            "Resend verification link"
          )}
        </button>
      </div>
    </div>
  );
};

export default MessageEmailVerification;

import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { setAuthTokens } from "@feature/authSlice";
import { RepositoryContext, getLocalStorageClient } from "@shared/contexts";
import { RootState } from "@redux/store";

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const authSlice = useSelector(
    (state: RootState) => state.persistedReducer.auth
  );

  const repository = useContext(RepositoryContext);
  const loginRepo = repository.login;

  useEffect(() => {
    const getToken = async () => {
      const localStorageClient = getLocalStorageClient();
      const token = await localStorageClient.getAccessToken();
      dispatch(setAuthTokens(token));
    };
    getToken();
  }, [dispatch, loginRepo]);

  return (
    <>{authSlice.authTokens ? <Outlet /> : <Navigate to="login" replace />}</>
  );
};

export default PrivateRoute;

import {
  setIsEditGitHubLabModalOpen,
  setIsOverlayVisible,
  setIsRemoveGitHubLabModalOpen,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useState, useEffect } from "react";
import { setSourceId } from "@src/redux/feature/sourceSlice";
import CustomToolTip from "../CustomToolTip";

interface GitHubLabSatetProps {
  source_id: number;
}

const GitHubLabStateCard = ({ source_id }: GitHubLabSatetProps) => {
  const dispatch = useDispatch();
  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const role = memberSlice.current_member?.role;

  const defaultSource: SourceData = {
    id: 0,
    name: "",
    description: "",
    is_archived: false,
    created_at: "",
    updated_at: "",
    environments: [],
    integrations: {
      github: {
        id: "",
        is_connected: false,
        expires_at: "",
      },
      gitlab: {
        id: "",
        is_connected: false,
        expires_at: "",
      },
    },
  };

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);
  const [integration, setIntegration] = useState<string>("");
  const [currentSource, setCurrentSource] = useState<SourceData>(defaultSource);

  useEffect(() => {
    const source = _.find(
      sourceSlice.sourceData,
      (source) => source.id === source_id
    );
    setCurrentSource(source!);

    if (source && source.integrations.github.is_connected) {
      setIntegration("Github");
    } else if (source && source.integrations.gitlab.is_connected) {
      setIntegration("Gitlab");
    }
  }, [sourceSlice.sourceData, source_id]);

  const getFormattedDate = (expiresAt: string): string => {
    const expirationDate = new Date(expiresAt);
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return expirationDate.toLocaleDateString("en-GB", options);
  };

  const expirationDate = currentSource.integrations.github.is_connected
    ? currentSource.integrations.github.expires_at
    : currentSource.integrations.gitlab.expires_at;

  const formattedExpirationDate = getFormattedDate(expirationDate);

  const currentDate = new Date();
  const expirationDateObject = new Date(expirationDate);
  const daysDifference = Math.ceil(
    (expirationDateObject.getTime() - currentDate.getTime()) /
      (1000 * 3600 * 24)
  );

  const expirationText =
    daysDifference <= 7 ? `${daysDifference} days` : formattedExpirationDate;

  let warningLevel;
  if (daysDifference > 7) {
    warningLevel = "safe";
  } else if (daysDifference <= 0) {
    warningLevel = "expired";
  } else {
    warningLevel = "unsafe";
  }

  const openRemoveGitHubLabModal = () => {
    dispatch(setIsRemoveGitHubLabModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setSourceId(source_id));
  };

  const openEditGitHubLabModal = () => {
    dispatch(setIsEditGitHubLabModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setSourceId(source_id));
  };

  return (
    <div
      className={`mt-[14px] py-[24px] px-[34px] rounded-[6px] ${
        warningLevel === "expired" ? "bg-[#FF65361A]" : "bg-[#F6F9FF]"
      }`}
    >
      <div className="flex justify-between items-center">
        <div>
          <div className=" flex items-center gap-[30px]">
            <div className="flex items-center gap-[12px]">
              <img
                width={50}
                height={50}
                src={`/icons/${integration}.svg`}
                alt="Integration"
              />
              <h1 className="text-[20px] font-medium text-[#172B4D]">
                {integration}
              </h1>
            </div>
            {warningLevel === "expired" ? (
              <p className=" flex gap-2 px-4 py-1 text-xs font-medium rounded-full bg-opacity-25 bg-[#FF65361A] text-[#ff3636]">
                <img src="/icons/Expired.svg" alt="ExpiredIcon" />
                Expired
              </p>
            ) : (
              <p className="px-[10px] py-[2px] text-[12px] rounded-full bg-opacity-25 bg-[#671FFF] text-[#671FFF]">
                Connected
              </p>
            )}
          </div>
          {warningLevel !== "expired" && (
            <>
              {" "}
              <p className="mt-6 text-[18px] text-[#292C31]">
                This account is now connected to your GitHub account. Start
                tracking and resolving issues effortessly.
              </p>
              <div className="flex gap-[24px] mt-6  text-[14px] font-medium w-fit">
                {role === "Basic" && (
                  <CustomToolTip text="Feature avaiable for Editors and Owners only">
                    <div className="flex justify-start items-center gap-6 cursor-not-allowed w-fit">
                      <button
                        disabled
                        className="flex items-center gap-3 text-[#248900] cursor-not-allowed "
                      >
                        <img
                          width={18}
                          height={18}
                          src="/icons/EditHubLab.svg"
                          alt="edit"
                        />
                        <p>Edit</p>
                      </button>

                      <button
                        disabled
                        className="flex items-center gap-3 text-[#CC0202] cursor-not-allowed "
                      >
                        <img
                          width={18}
                          height={18}
                          src="/icons/MemberDelete.svg"
                          alt="remove"
                        />
                        <p>Remove</p>
                      </button>
                    </div>
                  </CustomToolTip>
                )}
                {role !== "Basic" && (
                  <>
                    <button
                      onClick={openEditGitHubLabModal}
                      className="flex items-center gap-3 text-[#248900] cursor-pointer"
                    >
                      <img
                        width={18}
                        height={18}
                        src="/icons/EditHubLab.svg"
                        alt="edit"
                      />
                      <p>Edit</p>
                    </button>
                    <button
                      onClick={openRemoveGitHubLabModal}
                      className="flex items-center gap-3 text-[#CC0202] cursor-pointer"
                    >
                      <img
                        width={18}
                        height={18}
                        src="/icons/MemberDelete.svg"
                        alt="remove"
                      />
                      <p>Remove</p>
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        {warningLevel !== "expired" && (
          <>
            <div className="h-32">
              {warningLevel == "safe" ? (
                <div className="flex flex-col text-[#001789] w-[200px]">
                  <div className="text-center font-medium text-sm">
                    Expires on
                  </div>
                  <div className="font-medium text-center">
                    {expirationText}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2 items-center">
                  <div className="flex  bg-[#FF65361A] py-3 px-4 rounded-full font-bold text-[#FF6536] text-lg">
                    <div>Expires on </div>
                    <div className="ms-1">{expirationText}</div>
                  </div>
                  {role !== "Basic" && (
                    <a
                      href={`https://www.${integration}.com/`}
                      className="flex justify-center font-medium underline text-[#001789] cursor-pointer"
                    >
                      Update access token
                    </a>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {warningLevel == "expired" && (
        <>
          <div className="flex flex-col gap-7 items-start mt-6 ms-2">
            <p className="text-[18px] text-[#292C31]">
              Please reconnect GitHub Integration as the integration period has
              expired.
            </p>
            {role !== "Basic" && (
              <a
                onClick={openEditGitHubLabModal}
                className="flex justify-center font-medium underline text-[#001789]"
              >
                Renew access token
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default GitHubLabStateCard;

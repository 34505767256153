import CustomToolTip from "@src/components/sharedComponents/CustomToolTip";
import { setLoading } from "@src/redux/feature/appSlice";
import { setProjectData } from "@src/redux/feature/projectSlice";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";

const DemoGeneral = () => {
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const dispatch = useDispatch();
  const [orgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });
  const repository = useContext(RepositoryContext);
  const projectRepo = repository.project;
  const modalHandler = useSelector((state: RootState) => state.modalHandler);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await projectRepo.getProject<ProjectData>(orgId);
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          dispatch(setProjectData(response));
        }
      }
    };
    fetchData()
      .catch(async (error) => {
        showBoundary(error);
        await customLogger.reportError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    customLogger,
    dispatch,
    projectRepo,
    showBoundary,
    modalHandler.isEditProjectModalOpen,
  ]);

  return (
    <div className="p-[25px] md:p-[36px] flex flex-col h-[90vh] justify-between">
      <div>
        <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue">
          Project Information
        </h1>
        <div className="flex flex-col gap-[20px] mt-[44px]">
          <div className="flex">
            <p className="w-[250px] font-medium">Name</p> :
            <p className="ml-[24px]">Demo Project</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Description</p> :
            <p className="ml-[24px] w-[800px]">
              This a Demo Project made to help users to understand how to use
              our software.
            </p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Created Date</p> :
            <p className="ml-[24px]">May 10, 2024</p>
          </div>
        </div>
        <div className="mt-[62px]">
          <CustomToolTip>
            <button
              disabled
              className="bg-[#001789] px-[28px] py-[10px] rounded-[6px] text-[12px] font-bold text-white cursor-not-allowed"
            >
              Edit Project
            </button>
          </CustomToolTip>
        </div>
      </div>

      <div className="border border-[#DCDCDE]">
        <div className="px-[26px] py-[20px]">
          <h1 className="text-[16px] text-[#CD3D25] font-bold">
            Delete this project
          </h1>
        </div>
        <div className="pt-[13px] bg-[#F9EBEB] px-[26px] pb-[18px] border border-[#E3C0C4]">
          <p>
            Deleting this project will permanently remove all associated data
            and cannot be undone.
          </p>
          <CustomToolTip>
            <button
              disabled
              className="p-[10px] bg-[#CD3D25] bg-opacity-[0.50] rounded-[6px] font-medium text-[12px] text-white mt-[18px] cursor-not-allowed"
            >
              Delete Project
            </button>
          </CustomToolTip>
        </div>
      </div>
    </div>
  );
};

export default DemoGeneral;

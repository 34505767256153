import EmailIntegrationModal from "./EmailIntegrationModal";
import SlackIntegrationModal from "./SlackIntegrationModal";
import TeamsIntegrationModal from "./TeamsIntegrationModal";

const IntegrationModal = ({
  closeIntegrationModal,
  integrationName,
}: IntegrationModalProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      {integrationName === "Slack" ? (
        <SlackIntegrationModal
          closeIntegrationModal={closeIntegrationModal}
          integrationName={integrationName}
        />
      ) : integrationName === "Email" ? (
        <EmailIntegrationModal
          closeIntegrationModal={closeIntegrationModal}
          integrationName={integrationName}
        />
      ) : (
        <TeamsIntegrationModal
          closeIntegrationModal={closeIntegrationModal}
          integrationName={integrationName}
        />
      )}
    </div>
  );
};

export default IntegrationModal;

/**
 * Extracts various states from a given Date object, including selected date,
 * hour, minute, AM/PM state, and a formatted date string.
 *
 * Steps in this method:
 * 1. Determine whether the current hour is in the AM or PM.
 * 2. Create an object with different states including:
 *    a. selectedDate: The ISO string representation of the provided date.
 *    b. hour: The string representation of the hour component of the date.
 *    c. minute: The string representation of the minute component of the date.
 *    d. isAM: A boolean indicating whether the time is in the AM.
 *    e. formattedDate: A formatted string representing the date, including day, month, hour, minute, and AM/PM.
 * 3. Return the object containing extracted states.
 *
 * @param {Date} date - The Date object from which to extract states.
 * @returns {DateTimeStates} An object containing various states derived from the provided Date.
 */

import { format } from "date-fns";

/**
 * Extracts various states from a given Date object, including selected date,
 * hour, minute, AM/PM state, and a formatted date string.
 *
 * Steps in this method:
 * 1. Determine whether the current hour is in the AM or PM.
 * 2. Create an object with different states including:
 *    a. selectedDate: The ISO string representation of the provided date.
 *    b. hour: The string representation of the hour component of the date.
 *    c. minute: The string representation of the minute component of the date.
 *    d. isAM: A boolean indicating whether the time is in the AM.
 *    e. formattedDate: A formatted string representing the date, including day, month, hour, minute, and AM/PM.
 * 3. Return the object containing extracted states.
 *
 * @param {Date} date - The Date object from which to extract states.
 * @returns {DateTimeStates} An object containing various states derived from the provided Date.
 */
export const getDateTimeStates = (date: Date): DateTimeStates => {
  // Step 1
  const isAM = date.getHours() < 12;

  // Step 2
  const dateTimeStates: DateTimeStates = {
    // Step 2a
    selectedDate: date.toISOString(),
    // Step 2b
    hour: date.getHours().toString(),
    // Step 2c
    minute: date.getMinutes().toString(),
    // Step 2d
    isAM: isAM,
    // Step 2e
    formattedDate: format(date, "dd MMM hh:mm a"),
    // Step 2f
    normalDate: date,
  };

  // Step 3
  return dateTimeStates;
};

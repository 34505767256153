import { RootState } from "@src/redux/store";
import {
  LoggerContext,
  RepositoryContext,
  projectRepository,
} from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { SuccessToast } from "../toasts/SuccessToast";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../toasts/ErrorToast";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { setProjectData } from "@src/redux/feature/projectSlice";
import { setLoading } from "@src/redux/feature/appSlice";
import { CustomLogger } from "@src/repositories/CustomLoggerRepository";

const EditMemberModal = ({
  memberId,
  closeEditMemberModal,
}: EditMemberModalProps) => {
  const params = useParams();
  const orgId = params.orgId;

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AddMemberFieldData>({
    defaultValues: {
      name: "",
      email: "",
      role: "",
      projects: [],
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isProjectDropdownOpen, setIsProjectDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  const projectSlice = useSelector((state: RootState) => state.projectSlice);

  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const clickedMember = memberSlice.member_data.find(
    (member) => member.id === memberId
  );

  useEffect(() => {
    setValue("name", clickedMember?.name);
    setValue("email", clickedMember?.email ?? "");
    setValue("role", clickedMember?.role ?? "");
    setValue("projects", _.flatMap(clickedMember?.projects, "id") || []);
  }, [
    setValue,
    memberSlice.member_data,
    memberId,
    clickedMember?.name,
    clickedMember?.email,
    clickedMember?.role,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await projectRepository.getProject<ProjectData>(
        parseInt(orgId!)
      );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          dispatch(setProjectData(response));
        }
      }
    };
    fetchData()
      .catch(async (error) => {
        showBoundary(error);
        await customLogger.reportError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [CustomLogger, useDispatch, projectRepository]);

  const name = watch("name");
  const email = watch("email");
  const role = watch("role");
  const projects = watch("projects");
  const selectedOption = watch("role");
  const selectedProjects = watch("projects");

  useEffect(() => {
    const fieldErrors = {
      name: errors.name,
      email: errors.email,
      role: errors.role,
      projects: errors.projects,
    };

    Object.entries(fieldErrors).forEach(([field, error]) => {
      if (error) {
        clearErrors(field as keyof AddMemberFieldData);
      }
    });
  }, [name, email, role, projects]);

  // Filter out the already selected projects and apply search filter
  const availableProjects = projectSlice.projectData
    ?.filter((project) => !selectedProjects.includes(project.id))
    .filter((project) =>
      project.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

  const toggleProjectDropdown = () => {
    setIsProjectDropdownOpen(!isProjectDropdownOpen);
  };

  const handleOptionSelect: (option: string) => void = (option) => {
    setValue("role", option);
    setIsOpen(false);
  };

  const handleProjectDropdownSelect = (option: number) => {
    setValue("projects", [...selectedProjects, option]);
  };

  const removeProject = (projectId: number) => {
    const updatedProjects = selectedProjects.filter(
      (project) => project !== projectId
    );
    setValue("projects", updatedProjects);
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const dispatch = useDispatch();

  const repository = useContext(RepositoryContext);
  const memberRepo = repository.member;

  const capitalizeFirstLetter = (title: string): string => {
    if (!title) return "";
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  const onSubmit = async (data: AddMemberFieldData) => {
    dispatch(setIsLoading(true));
    try {
      const response = await memberRepo.editMember<
        MemberData,
        AddMemberFieldData
      >(parseInt(orgId!), memberId, data);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "email" | "role";
            setError(validField, { message: error.message });
            if (
              validField !== "email" &&
              validField !== "role"
            ) {
              ErrorToast({ Message: error.message });
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeEditMemberModal?.();
          const info: InfoLog = {
            message: "Member details updated.",
          };
          await customLogger.log(info);
          SuccessToast({ Message: "Success! Member has been edited." });
        }
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-[28px] font-[700] text-text_black">
            Edit Member
          </h2>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500]"
              htmlFor="email"
            >
              Email
              <span className="text-red text-sm ml-1">*</span>
            </label>
            <input
              {...register("email", {})}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="email"
              disabled
            />
            {errors.email != null && (
              <small className="block text-red mt-2">
                {errors.email.message}
              </small>
            )}
          </div>
          {clickedMember?.role !== "Owner" && (
            <>
              <div className="mt-2">
                <label className="text-text_gray text-sm font-[500]">
                  Role
                </label>
                <span className="text-red text-sm ml-1">*</span>
                <div className="relative" onMouseLeave={() => setIsOpen(false)}>
                  <button
                    type="button"
                    className="relative cursor-default border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
                    onClick={toggleDropdown}
                  >
                    <span className="flex items-center">
                      <span className="block truncate">{selectedOption}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      {isOpen ? (
                        <img
                          className="w-18 rotate-180"
                          src={`/icons/CustomSelectionArrow.svg`}
                          alt="Roles Dropdown Arrow"
                        />
                      ) : (
                        <img
                          className="w-18"
                          src={`/icons/CustomSelectionArrow.svg`}
                          alt="Roles Dropdown Arrow"
                        />
                      )}
                    </span>
                  </button>
                  {isOpen && (
                    <ul className="absolute z-10 w-full rounded-md bg-white shadow-lg focus:outline-none">
                      {["Basic", "Editor"].map((role) => (
                        <li
                          key={role}
                          className={`cursor-pointer select-none py-2 pr-9 ${
                            selectedOption === role ? "bg-[#F3F4F8]" : ""
                          }`}
                          onClick={() => handleOptionSelect(role)}
                        >
                          <div className="flex items-center">
                            <span className="font-normal ml-3 block truncate">
                              {role}
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="mt-2">
                <label className="text-text_gray text-sm font-[500]">
                  Project
                </label>
                <span className="text-red text-sm ml-1">*</span>
                <div
                  className="relative"
                  onMouseLeave={() => setIsProjectDropdownOpen(false)}
                >
                  <button
                    type="button"
                    className="scrollbar-thin relative cursor-default border border-border_light_gray rounded-[10px] w-full max-h-20 h-fit  py-2 px-3 text-gray-700 focus:outline-none mt-[10px] overflow-y-auto"
                    onClick={toggleProjectDropdown}
                  >
                    <span className="flex justify-start items-center">
                      <div className=" w-[100%] flex justify-start">
                        {selectedProjects && selectedProjects.length > 0 ? (
                          <div className="flex max-w-[90%] flex-wrap gap-2 ">
                            {selectedProjects.map((projectId) => {
                              const project = projectSlice.projectData?.find(
                                (proj) => proj.id === projectId
                              );
                              return (
                                project && (
                                  <span
                                    key={projectId}
                                    className="flex items-center border border-[#4C4C4C] rounded-full px-6 text-[#4C4C4C] gap-4 whitespace-nowrap truncate"
                                  >
                                    <span className="w-10rem truncate">
                                      {capitalizeFirstLetter(project.title)}
                                    </span>
                                    <button
                                      type="button"
                                      className="hover:text-[red] mt-1"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeProject(projectId);
                                      }}
                                    >
                                      &#10005;
                                    </button>
                                  </span>
                                )
                              );
                            })}
                            <input
                              type="text"
                              className="border-0 focus:outline-none w-fit"
                              onChange={(e) => setSearchQuery(e.target.value)}
                              value={searchQuery}
                            />
                          </div>
                        ) : (
                          "Select Project"
                        )}
                      </div>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                      {availableProjects.length > 0 &&
                        (isProjectDropdownOpen ? (
                          <img
                            className="w-18 rotate-180"
                            src="/icons/CustomSelectionArrow.svg"
                            alt="Project Dropdown Arrow"
                          />
                        ) : (
                          <img
                            className="w-18"
                            src="/icons/CustomSelectionArrow.svg"
                            alt="Project Dropdown Arrow"
                          />
                        ))}
                    </span>
                  </button>
                  {isProjectDropdownOpen && (
                    <div className="absolute z-10 w-full rounded-md bg-white shadow-lg focus:outline-none">
                      <ul className="scrollbar-thin w-full h-fit max-h-32 overflow-y-auto focus:outline-none">
                        {availableProjects && availableProjects.length > 0 ? (
                          availableProjects.map((item) => (
                            <li
                              key={item.id}
                              className="cursor-pointer select-none py-2 pr-9 bg-[#F3F4F8]"
                              onClick={() =>
                                handleProjectDropdownSelect(item.id)
                              }
                            >
                              <div className="flex items-center">
                                <span className="font-normal ml-3 block truncate">
                                  {capitalizeFirstLetter(item.title)}
                                </span>
                              </div>
                            </li>
                          ))
                        ) : (
                          <li className="cursor-default select-none py-2 pr-9 bg-[#F3F4F8]">
                            <div className="flex items-center">
                              <span className="font-normal ml-3 block truncate">
                                No matching projects found
                              </span>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          <div className="flex justify-start gap-[20px] mt-[16px]">
            {isLoading ? (
              <button
                disabled
                className="flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              >
                <img
                  width={22}
                  height={22}
                  src="/icons/Loading.svg"
                  alt="loading"
                />
                Updating...
              </button>
            ) : (
              <button
                type="submit"
                className={`${
                  selectedProjects.length === 0
                    ? "bg-button_blue bg-opacity-[0.60]"
                    : "bg-button_blue"
                } w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md`}
                disabled={selectedProjects.length === 0}
              >
                Update
              </button>
            )}
            <button
              type="button"
              onClick={closeEditMemberModal}
              className="border border-button_blue w-[90px] h-[40px] text-nav_blue text-[14px] font-[500] rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditMemberModal;

import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { setMobileNav, setProjectPopUp } from "@feature/appSlice";
import SkeletonLoader from "../loader/SkeletonLoader";

enum ButtonConstants {
  activeBg = "bg-light_nav_blue",
  inactiveBg = "",
  activeFont = "font-[500]",
  inActiveFont = "font-[400]",
}
const SidebarButton = ({
  source,
  name,
  label,
  onClick,
  active,
  link,
}: ButtonProps) => {
  const buttonBgColor = active
    ? ButtonConstants.activeBg
    : ButtonConstants.inactiveBg;

  const buttonFontWeight = active
    ? ButtonConstants.activeFont
    : ButtonConstants.inActiveFont;

  const dispatch = useDispatch();
  const loadingState = useSelector((state: RootState) => state.appSlice);
  const handleCloseMobileNav = () => {
    dispatch(setMobileNav(false));
    dispatch(setProjectPopUp(false));
  };
  return (
    <>
      {loadingState.loading ? (
        <SkeletonLoader />
      ) : (
        <NavLink
          onClick={onClick}
          className="flex text-white hover:text-white font-[500] items-center pb-[4px]"
          to={`${link}`}
        >
          <button
            className={`w-[240px] h-[42px] rounded-md flex gap-2 items-center p-5 ${
              buttonBgColor ? buttonBgColor : "hover:bg-[#102550]"
            } `}
            onClick={handleCloseMobileNav}
          >
            <img width={18} height={18} src={source} alt={name} />
            <p className={`text-sm ${buttonFontWeight} tracking-[0.5px]`}>
              {label}
            </p>
          </button>
        </NavLink>
      )}
    </>
  );
};

export default SidebarButton;

import {
  ChangeEvent,
  KeyboardEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { SuccessToast } from "../../toasts/SuccessToast";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../../toasts/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";

const EditEmailIntegrationModal = ({
  closeEditIntegrationModal,
  integrationName,
}: EditIntegrationModalProps) => {
  const { id } = useParams();
  const projectId = id;
  const {
    handleSubmit,
    setError,
    register,
    formState: { errors },
  } = useForm<EmailIntegrationFieldData>({});

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const integrationRepo = repository.integration;
  const [emailInput, setEmailInput] = useState("");
  const [email, setEmail] = useState<string[]>([]);
  const [integrationData, setIntegrationData] =
    useState<EmailIntegrationFieldData | null>(null);
  const [isEmailListChanged, setIsEmailListChanged] = useState<boolean>(false);

  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const dispatch = useDispatch();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const typedEmail = e.target.value;
    setEmailInput(typedEmail);
    setError("email", {});
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const typedEmail = emailInput.trim();
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (typedEmail !== "" && emailRegex.test(typedEmail)) {
        if (email.includes(typedEmail)) {
          setError("email", {
            message: "This email is already in the list",
          });
        } else {
          setEmail((prevEmails) => [...prevEmails, typedEmail]);
          setEmailInput("");
          setIsEmailListChanged(true);
        }
      } else {
        setError("email", {
          message: "Please enter a valid email address",
        });
      }
    }
  };

  const removeEmail = (indexToRemove: number) => {
    const updatedEmails = email.filter((_, index) => index !== indexToRemove);
    setEmail(updatedEmails);
    setIsEmailListChanged(true);
  };

  const onSubmit = async (data: EmailIntegrationFieldData) => {
    dispatch(setIsLoading(true));
    try {
      const integrationNameLowerCase = integrationName
        ? integrationName.toLowerCase()
        : null;
      const formData = { ...data, email };
      const response = await integrationRepo.editIntegration<
        EmailIntegrationFieldData,
        EmailIntegrationFieldData
      >(`${projectId}`, `${integrationNameLowerCase}/receivers/`, formData);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "email";
            setError(validField, { message: error.message });
            if (validField !== "email") {
              ErrorToast({ Message: error.message });
              closeEditIntegrationModal?.();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeEditIntegrationModal?.();
          const info: InfoLog = {
            message: `${integrationName} has been Updated`,
          };
          await customLogger.log(info);
          SuccessToast({
            Message: `Success ${integrationName} has been Updated`,
          });
        }
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  useEffect(() => {
    setEmail(integrationData?.email || []);
    if (integrationData?.email) {
      register("email", { value: integrationData.email });
    }
  }, [integrationData?.email, register]);

  useEffect(() => {
    const fetchData = async () => {
      const integrationNameLowerCase = integrationName
        ? integrationName.toLowerCase()
        : null;
      const response =
        await integrationRepo.getIntegratedData<EmailIntegrationFieldData>(
          `${projectId}`,
          `${integrationNameLowerCase}/receivers/`
        );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          setIntegrationData(response);
        }
      }
    };
    fetchData().catch(async (error) => {
      showBoundary(error);
      await customLogger.reportError(error);
    });
  }, [integrationRepo, projectId, customLogger, showBoundary, integrationName]);

  return (
    <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-between">
          <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
            <img width={35} height={35} src="/icons/Email.svg" alt="cross" />
            Edit Email
          </h2>
          <button type="button" onClick={closeEditIntegrationModal}>
            <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
          </button>
        </div>
        <div className="mt-2 flex justify-between gap-[72px]">
          <div className="w-full">
            <label
              className="text-text_gray text-sm font-[500] flex items-center"
              htmlFor="port"
            >
              Receiver List
              <span className="text-red text-sm ml-1">*</span>(Add up to 5 email
              addresses)
            </label>
            <div className="border border-border_light_gray rounded-[10px] w-full py-2 px-3 text-gray-700 focus:outline-none mt-[10px]">
              <div className="flex flex-wrap">
                {email.map((emailItem, index) => (
                  <div
                    key={index}
                    className="inline-block bg-gray-200 rounded-full px-2 py-1 mr-2 mb-2"
                  >
                    <span className="flex justify-center items-center border border-[#B3B3B3] rounded-full px-2 text-[#4C4C4C] gap-2">
                      {emailItem}
                      <button
                        type="button"
                        className="hover:text-[red] mt-1"
                        onClick={() => removeEmail(index)}
                      >
                        &#10005;
                      </button>
                    </span>
                  </div>
                ))}
              </div>
              <input
                {...register("email")}
                value={emailInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="w-full h-full bg-transparent focus:outline-none inline-block"
                type="text"
              />
            </div>
            {errors.email && (
              <small className="block text-red mt-2">
                {errors.email.message}
              </small>
            )}
          </div>
        </div>
        <div className="flex gap-[20px]">
          <div className="flex justify-start gap-[20px] mt-[16px]">
            {isLoading ? (
              <button
                disabled
                className="flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              >
                <img
                  width={22}
                  height={22}
                  src="/icons/Loading.svg"
                  alt="loading"
                />
                Saving...
              </button>
            ) : (
              <button
                type="submit"
                className={`bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md ${
                  isEmailListChanged
                    ? "hover:bg-blue-600"
                    : "cursor-not-allowed opacity-50"
                }`}
                disabled={!isEmailListChanged}
              >
                Save Updates
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditEmailIntegrationModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface List {
  organizationList: Organization[];
  currentOrganization: number;
}
const initialState: List = {
  organizationList: [],
  currentOrganization: 0,
};
export const organizationSlice = createSlice({
  name: "organizationSlice",
  initialState,
  reducers: {
    setOrganizationList: (state, action: PayloadAction<Organization[]>) => {
      state.organizationList = action.payload;
      if (state.currentOrganization === 0) {
        state.currentOrganization = action.payload[0].id;
      }
    },
    setCurrentOrganization: (state, action: PayloadAction<Organization>) => {
      state.currentOrganization = action.payload.id;
    },
    resetCurrentOrganization: (state) => {
      state.currentOrganization = 0;
    },
  },
});
export const { setOrganizationList, setCurrentOrganization ,resetCurrentOrganization } =
  organizationSlice.actions;
export default organizationSlice.reducer;

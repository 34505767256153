import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";

const NewOrganization = () => {
  const [newOrganization, setNewOrganization] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AddOrgData>();
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const OrgRepo = repository.organization;

  const handleClick = () => {
    setNewOrganization(true);
  };

  const onSubmit = async (data: AddOrgData) => {
    setIsLoading(true);
    if (!data.website) {
      delete data.website;
    }
    try {
      const response = await OrgRepo.addOrganization<TokenResponse, AddOrgData>(
        data
      );
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.forEach(response.errors, (error) => {
            const validField = error.title as "name" | "website";
            setError(validField, { message: error.message });
            if (validField !== "name" && validField !== "website") {
              ErrorToast({ Message: error.message });
              clearErrors();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          SuccessToast({ Message: "Successfully added your organization" });
          window.location.reload();
        }
      }
      setIsLoading(false);
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };
  return (
    <>
      {newOrganization ? (
        <div className="col-span-3 bg-white h-[100%] pt-[56px] md:pt-0 pb-[5%] md:pb-0">
          <div className="container h-[100%] flex flex-col justify-center items-center">
            <h3 className=" text-[18px] text-[#162C5B] md:text-[28px] font-bold text-center">
              Let's get started!
            </h3>
            <div className="mb-[30px]">
              <p className="w-[239px] text-[14px] text-[#56595F] text-center mt-[26px]">
                Tell us a bit about your organization
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="mb-[42px]">
              <div className="flex flex-col w-full">
                <label className="text-[#42454A] font-[500]">
                  Organization Name
                  <span className="text-red text-sm ml-1">*</span>
                </label>
                <input
                  className={`${
                    errors.name ? "border-[#CC0202]" : "border-[#162C5B]"
                  } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                  placeholder="Truenary"
                  type="text"
                  {...register("name", {
                    required: "organization name field is required",
                  })}
                />
                {errors.name != null && (
                  <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                    <img src="/icons/ErrorAlert.svg" alt="error" />
                    {errors.name.message}
                  </small>
                )}
              </div>
              <div className="mt-5 flex flex-col w-full">
                <div className="flex justify-between">
                  <label className="text-[#42454A] font-[500]">Website</label>
                </div>
                <div className="relative mt-1 md:w-[400px]">
                  <input
                    className={`${
                      errors.website ? "border-[#CC0202]" : "border-[#162C5B]"
                    } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                    type="text"
                    {...register("website")}
                    placeholder="www.websitename.com"
                  />
                  {errors.website != null && (
                    <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                      <img src="/icons/ErrorAlert.svg" alt="error" />
                      {errors.website.message}
                    </small>
                  )}
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  ></button>
                </div>
              </div>
              <button
                type="submit"
                className={`border border-[#325EBB] bg-[#325EBB] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[42px]`}
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="col-span-3 bg-white h-[100%] pt-[56px] md:pt-0 pb-[5%] md:pb-0">
          <div className="container h-[100%] flex flex-col justify-center items-center">
            <h3 className=" text-[18px] text-[#162C5B] md:text-[28px] font-bold text-center">
              New Organization
            </h3>
            <div className="mb-[30px]">
              <p className="w-[239px] md:w-[510px] text-[16px] md:text-[18px] mt-[54px]">
                Hi there! We couldn’t find any organization associated with your
                email.
              </p>
            </div>
            <div className="mb-[42px]">
              <p className="w-[239px] md:w-[510px] text-[16px] md:text-[18px] mt-[10px]">
                If you should be in any organization ask organization admin to
                add you as a member or you can start your own organization
              </p>
            </div>
            <button
              type="button"
              onClick={handleClick}
              className={`border border-[#325EBB] bg-[#325EBB] w-full md:w-[510px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mb-[80px]`}
            >
              New Organization
            </button>
            <div className="border-b w-[510px] border-[#929292] mb-[6px]"></div>
            <p className="text-[#929292]">
              Need help with your account? Click{" "}
              <span>
                <a href="#">here</a>
              </span>{" "}
              to check out our help center
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default NewOrganization;

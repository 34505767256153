import _ from "lodash";
import { useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { SuccessToast } from "../toasts/SuccessToast";
import { ErrorToast } from "../toasts/ErrorToast";
import { useParams } from "react-router-dom";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { setIsAddSourceModalOpen } from "@src/redux/feature/modalHandler";
import { AppDispatch } from "@src/redux/store";
import { useDispatch } from "react-redux";

const AddSourceModal = () => {
  const [inputFields, setInputFields] = useState<string[]>([]);
  const [isNextPressed, setNextPressed] = useState<boolean>(false);
  const [isNextDisabled, setNextDisabled] = useState<boolean>(true);

  const id = useParams();
  const projectId = id.id;

  const dispatch = useDispatch<AppDispatch>();

  const closeAddSourceModal = () => {
    dispatch(setIsAddSourceModalOpen(false));
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const sourceRepo = repository.source;

  const onSubmit = async (data: SourceFieldData) => {
    try {
      if (!data.description) {
        delete data.description;
      }
      const CreateSourceData: CreateSourceData = {
        ...data,
        environment_names: JSON.stringify(data.environment_names),
      };

      const response = await sourceRepo.createSource<
        SourceData,
        CreateSourceData
      >(CreateSourceData, projectId!);

      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "name";
            setError(validField, { message: error.message });
            if (validField === "name") {
              ErrorToast({ Message: error.message });
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeAddSourceModal();
          const info: InfoLog = {
            message: "New source created.",
          };
          await customLogger.log(info);
          SuccessToast({ Message: "Success! Your Source has been created." });
        }
      }
    } catch (error) {
      ErrorToast({ Message: "Failure! Your Source has not been created." });
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm<SourceFieldData>({
    defaultValues: {
      name: "",
      description: "",
      environment_names: [],
    },
  });

  const addEnvInput = () => {
    setNextPressed(true);
    setInputFields(["Default 1"]);
  };

  const addEnvironment = () => {
    // Check if the last index in the inputFields array is not an empty string
    if (inputFields[inputFields.length - 1] !== "") {
      const newInputFields = [...inputFields, ""];
      setInputFields(newInputFields);
    }
  };

  const removeEnvironment = (indexToRemove: number) => {
    setInputFields((prevInputFields) => {
      const newInputFields = prevInputFields.filter(
        (_, index) => index !== indexToRemove
      );
      // Unregister the removed environment using string concatenation
      setValue("environment_names", newInputFields);
      return newInputFields;
    });
  };

  const handleInputChange = () => {
    // Get the input elements by their IDs
    const sourceNameElement = document.getElementById(
      "sourceName"
    ) as HTMLInputElement;
    const sourceName = sourceNameElement?.value ?? "";
    setNextDisabled(!sourceName.trim());
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col align-items-start w-full">
          <h2 className="font-bold text-[#172B4D] text-[28px]">Add Source</h2>
          <div className="flex flex-row font-medium text-[14px]">
            <p>Source{">"} </p>
            <p className="text-[#AAAAAAAA] ml-2 "> AddSource </p>
          </div>
          <a
            href="https://retack.ai/docs/#source"
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-[12px] text-[#1054D9] mt-4 flex gap-[10px]"
          >
            <img src="/icons/learnMore.svg" alt="learn more" /> Learn more
          </a>
          <div className="flex flex-row mt-4 w-full gap-5">
            <div className="flex flex-col w-[100%]">
              <div className="font-medium text-[#172B4D]">Source Name</div>
              <input
                {...register("name", {
                  required: "Name must not be empty",
                })}
                id="sourceName"
                name="name"
                type="text"
                placeholder="Enter Source Name"
                className="w-full px-3 py-4 border border-[#D9DCED] text-[#172B4D] rounded-[10px] mt-4 placeholder-[#AAAAAA] placeholder-[400]"
                onChange={handleInputChange}
              />
              {errors.name && (
                <small className="block text-red mt-2">
                  {errors.name.message}
                </small>
              )}

              <div className="font-medium text-[#172B4D] mt-4">Description</div>
              <textarea
                {...register("description")}
                name="description"
                id="sourceDescription"
                placeholder="Enter Description Name"
                className="w-full h-[15em] px-3 py-4 border border-[#D9DCED] text-[#172B4D] rounded-[10px] mt-4 placeholder-[#AAAAAA] placeholder-[400] resize-none"
                onChange={handleInputChange}
              />

              <div className="flex flex-row gap-3 mt-7">
                {!isNextPressed && (
                  <>
                    <button
                      onClick={addEnvInput}
                      className={`px-9 py-3 ${
                        isNextDisabled ? "bg-[#AAB2DE]" : "bg-[#001789]"
                      }  rounded-[8px] text-white font-bold`}
                      disabled={isNextDisabled}
                    >
                      Next
                    </button>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        closeAddSourceModal();
                      }}
                      className="ml-4 px-8 py-3 border border-[#172B4D] bg-gray-400 text-[#172B4D] font-light rounded-[8px] "
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {isNextPressed && (
          <>
            <div className="mt-8 flex flex-row gap-4">
              <div className="flex flex-col w-[100%]">
                <div className="text-[#172B4D] font-medium">Environment</div>
                {_.map(inputFields, (value, index) => (
                  <div key={index} className="flex flex-row gap-2 relative">
                    <input
                      {...register(`environment_names.${index}`, {
                        required: "Environment name must not be empty",
                      })}
                      className="w-full px-3 py-4 border border-[#D9DCED] rounded-[10px] text-[#172B4D] mt-7"
                      value={value}
                      onChange={(e) => {
                        const newInputFields = [...inputFields];
                        newInputFields[index] = e.target.value;
                        setInputFields(newInputFields);
                      }}
                    />
                    {index !== 0 && (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          removeEnvironment(index);
                        }}
                        className="absolute top-12 right-3  text-[#4d4949]"
                      >
                        <img src="/icons/Cross3.svg" alt="close" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-2 w-full">
              <div className="flex flex-row justify-end">
                {inputFields[inputFields.length - 1] !== "" && (
                  <text
                    onClick={addEnvironment}
                    className="cursor-pointer text-[#172B4D] font-medium"
                  >
                    + Add Environment
                  </text>
                )}
              </div>
              <div className="flex flex-row mt-8">
                <button
                  className="px-8 py-3 bg-[#172B4D] text-white font-bold rounded-[8px]"
                  type="submit"
                >
                  Save
                </button>

                <button
                  className="ml-4 px-8 py-3 border border-[#172B4D] bg-gray-400 text-[#172B4D] font-light rounded-[8px]"
                  onClick={(e) => {
                    e.preventDefault();
                    closeAddSourceModal();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default AddSourceModal;

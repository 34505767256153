import { useContext } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { parseRepositoryResponse } from "@src/actions/parseRepositoryResponse";
import { setDeleteEnvKey } from "@src/redux/feature/environmentSlice";
import { useDispatch } from "react-redux";

const DeleteEnvironmentKeyModal = ({
	id,
	environment_name,
	toggleDeleteEnvironmentKeyOpen
}: DeleteEnvironmentKeyModalProps) => {
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

	const dispatch = useDispatch();

	const repository = useContext(RepositoryContext);
	const sourcesRepo = repository.source;

	const deleteEnvironmentKey = async () => {
		try {
			const response = await sourcesRepo.deleteEnvironmentKey(id);
			await parseRepositoryResponse(response, customLogger, showBoundary);
			dispatch(setDeleteEnvKey());
			toggleDeleteEnvironmentKeyOpen();
		} catch (error) {
			showBoundary(error);
			await customLogger.reportError(error);
		}
	};

  const closeDeleteEnvironmentModal = async () => {
    toggleDeleteEnvironmentKeyOpen();
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-[2] w-[100%] h-[100%] bg-[#040E4140]">
        <div className="w-[349px] md:w-[593px] modal-container bg-white rounded-[12px]">
          <div className="modal-content p-[20px] md:p-[32px] flex flex-col items-center gap-[27px]">
            <div className="w-[54px] h-[54px]">
              <img
                className="w-[100%] h-[100%] object-contain"
                src="/icons/Exclamation.png"
                alt="add"
              />
            </div>
            <h1 className="text-[20px] font-[700] text-[#242731] text-center">
              Are you sure you want to remove a key of "{environment_name}”?
            </h1>
            <p className="text-[16px] text-[#242731] text-center">
              This source will be deleted immediately. You cant undo this
              action.
            </p>
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={deleteEnvironmentKey}
                className="bg-[#FF6370] w-[102px] h-[38px] text-white text-[14px] rounded-md font-bold"
              >
                Yes, Remove
              </button>

              <button
                onClick={closeDeleteEnvironmentModal}
                className="border border-[#001789] w-[105px] h-[38px] text-[#001789] text-[14px] rounded-md font-medium"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteEnvironmentKeyModal;

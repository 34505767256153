import { useContext, useRef } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { SuccessToast } from "../../toasts/SuccessToast";
import _ from "lodash";
import InfoTooltip from "../../buttons/InfoTooltip";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../../toasts/ErrorToast";
import { useDispatch } from "react-redux";

import {
  setIsAddGitHubModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";

const AddGitHubModal = ({ source_id }: AddGitHubLabModalProps) => {
  const params = useParams();
  const projectId = params.id;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<AddGitHubLabFieldData>({});

  const dispatch = useDispatch();

  const closeGitHubModal = () => {
    dispatch(setIsAddGitHubModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const gitHubLabRepo = repository.gitHubLab;

  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
    divRef2: useRef<HTMLDivElement>(null),
    divRef3: useRef<HTMLDivElement>(null),
  };

  const onSubmit = async (data: AddGitHubLabFieldData) => {
    try {
      const response = await gitHubLabRepo.Connect<
        AddGitHubLabFieldData,
        AddGitHubLabFieldData
      >(`${projectId}`, source_id, data);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "access_token" | "clone_url";
            setError(validField, { message: error.message });
            if (validField !== "access_token" && validField !== "clone_url") {
              ErrorToast({ Message: error.message });
              closeGitHubModal?.();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          SuccessToast({
            Message: `Success! Integration has been made`,
          });
          closeGitHubModal?.();

          const info: InfoLog = {
            message: `Success! Integration has been made`,
          };
          await customLogger.log(info);
        }
      }
    } catch (error) {
      showBoundary(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
              <img src="/icons/Github.svg" alt="cross" />
              Github
            </h2>

            <button type="button" onClick={closeGitHubModal}>
              <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
            </button>
          </div>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500] flex items-center"
              htmlFor="access_token"
            >
              Access Token
              <span className="text-red text-sm ml-1">*</span>
              <InfoTooltip
                divRef={divRefs.divRef1}
                messages={["The access token is partially hidden for safety reasons."]}
                bgColor="bg-[grey]"
                textColor="text-[white]"
              />
            </label>
            <input
              {...register("access_token", {
                required: "Channel ID Required",
              })}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
            />
            {errors.access_token && (
              <small className="block text-red mt-2">
                {errors.access_token.message}
              </small>
            )}
          </div>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500] flex items-center"
              htmlFor="clone_url"
            >
              Clone URL
              <span className="text-red text-sm ml-1">*</span>
              <InfoTooltip
                divRef={divRefs.divRef2}
                messages={[
                  "Url of the repository.",
                ]}
                bgColor="bg-[grey]"
                textColor="text-[white]"
              />
            </label>
            <input
              {...register("clone_url", {
                required: "clone_url Required",
              })}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
            />
            {errors.clone_url && (
              <small className="block text-red mt-2">
                {errors.clone_url.message}
              </small>
            )}
          </div>
          <div className="flex gap-[20px]">
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                type="submit"
                className={`bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md ${
                  isValid
                    ? "hover:bg-blue-600"
                    : "cursor-not-allowed opacity-50"
                }`}
                disabled={!isValid}
              >
                Connect
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddGitHubModal;

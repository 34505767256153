import { JsonAPIErrorResp, JsonAPIResp } from "@clients/api/types";

export const parseApiResponse = <T>(
  response: JsonAPIResp<T>
): T | JsonAPIErrorResp | undefined => {
  return "data" in response
    ? response.data
    : "errors" in response
    ? response
    : undefined;
};

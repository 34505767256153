import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { SuccessToast } from "../toasts/SuccessToast";
import { ErrorToast } from "../toasts/ErrorToast";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import { setIsLoading } from "@src/redux/feature/modalHandler";

const DeleteMemberModal = ({
  closeDeleteMemberModal,
  memberId,
}: DeleteMemberModalProps) => {
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const memberRepo = repository.member;
  const dispatch = useDispatch();

  const params = useParams();
  const orgId = params.orgId;

  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const member = memberSlice.member_data.find(
    (member) => member.id === memberId
  );

  const onSubmit = async () => {
    dispatch(setIsLoading(true));
    try {
      const response = await memberRepo.deleteMember(
        memberId,
        parseInt(orgId!)
      );
      if (_.isUndefined(response)) {
        closeDeleteMemberModal?.();
        const info: InfoLog = {
          message: "Member deleted.",
        };
        await customLogger.log(info);
        SuccessToast({ Message: "Successfully Deleted!" });
      } else if ("errors" in response) {
        ErrorToast({ Message: "Error occurred while deleting member!" });
        await customLogger.reportErrorResponse(showBoundary, response);
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-[2] bg-[#040e4104]">
        <div className="min-w-[349px] md:w-[593px] min-h-[349px] md:h-[308px] modal-container bg-white rounded-[12px] z-50 pt-[38px] px-[50px] pb-[50px]">
          <div className="modal-content flex flex-col items-center gap-[22px]">
            <div className="w-[70px] h-[70px] bg-[#FFE8E8] rounded-full flex justify-center items-center">
              <img
                className="w-[46px] h-[46px] object-contain"
                src="/icons/Exclamation.png"
                alt="add"
              />
            </div>
            <h1 className="text-[20px] font-[700] text-[#242731] w-[80%] text-center flex flex-warp justify-center">
              Are you sure you want to delete “{member!.name}"?
            </h1>
            <p className="text-[16px] text-[#242731]">
              This details will be removed immediately.
            </p>
            <div className="flex justify-start gap-[20px] mt-[16px]">
              {isLoading ? (
                <button
                  disabled
                  className="flex items-center gap-2 px-5 bg-[#FF6370] bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
                >
                  <img
                    width={22}
                    height={22}
                    src="/icons/Loading.svg"
                    alt="loading"
                  />
                  Removing...
                </button>
              ) : (
                <button
                  onClick={onSubmit}
                  className="bg-[#FF6370] w-[124px] h-[40px] text-white text-[14px] rounded-[6px] px-[20px] py-[8px] flex justify-center items-center"
                >
                  <span>Yes, Remove</span>
                </button>
              )}
              <button
                onClick={closeDeleteMemberModal}
                className="text-[#001789] w-[87px] h-[40px] text-[14px] border border-[#001789] rounded-[6px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteMemberModal;

import { LoginRepository } from "@repositories/LoginRepository";
import {MemberInvitationRepository} from "@repositories/MemberInvitationRepository";
import { ProfileRepository } from "@repositories/ProfileRepository";
import { ProjectRepository } from "@repositories/ProjectRepository";
import { SourceRepository } from "@repositories/SourceRepository";
import { RepositoryContext } from "@shared/contexts";
import { IssuesRepository } from "@repositories/IssuesRepository";
import { MemberRepository } from "@src/repositories/MemberRepository";
import { IntegrationRepository } from "@src/repositories/IntegrationRepository";
import { GitHubLabRepository } from "@src/repositories/GitHubLabRepository";
import { SubscriptionRepository } from "@src/repositories/SubscriptionRepository";
import { OrganizationRepository } from "@src/repositories/OrganizationRepository";


export declare type RepositoryProviderProps = {
  login: LoginRepository;
  organization : OrganizationRepository;
  memberInvitation: MemberInvitationRepository;
  profile: ProfileRepository;
  project: ProjectRepository;
  source: SourceRepository;
  issues: IssuesRepository;
  member : MemberRepository;
  integration : IntegrationRepository;
  gitHubLab : GitHubLabRepository;
  subscription : SubscriptionRepository;
};
export const RepositoryProvider = ({
  children,
  login,
  organization,
  memberInvitation,
  profile,
  project,
  source,
  issues,
  member,
  integration,
  gitHubLab,
  subscription
}: React.PropsWithChildren<RepositoryProviderProps>) => {
  return (
    <RepositoryContext.Provider
      value={{ login,organization,memberInvitation, profile, project, source, issues, member,integration,gitHubLab,subscription}}
    >
      {children}
    </RepositoryContext.Provider>
  );
};

import _ from "lodash";
import { useEffect, useState } from "react";
import FixIssueStep from "@src/utils/FixIssueSteps.json";
import PRList from "@src/utils/PRList.json";
import { useParams } from "react-router-dom";

const DemoIssueStatusPage = () => {
  const [link, setPRLink] = useState<string>("");
  const processSteps = _.cloneDeep(FixIssueStep);
  const [expandedRows, setExpandedRows] = useState<number[]>([
    processSteps.length - 1,
  ]);
  type PRLinks = Record<string, string>;
  const prLinks: PRLinks = PRList;
  const params = useParams();

  useEffect(() => {
    if (params.statusId) {
      setPRLink(prLinks[params.statusId] || ""); // Set link based on statusId
    }
  }, [params.statusId, prLinks]);

  const handleRowClick = (index: number) => {
    // Toggle the expansion state of the clicked row
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        // If the index is already in the expanded rows, remove it
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        // If the index is not in the expanded rows, add it
        return [...prevExpandedRows, index];
      }
    });
  };

  return (
    <>
      <div className="flex flex-col ms-9 w-[95%]">
        <div className="flex justify-start items-center gap-4 my-8">
          <div className="font-medium">Task Status:</div>
          <div className=" bg-[#0051B0] bg-opacity-[0.10] text-[#0051B0] px-11 py-2 rounded-full">
            Completed
          </div>
        </div>
        <div className="flex flex-col border border-[#D9D9D9] rounded mb-10">
          <div className="flex justify-between items-center p-7">
            <div className="text-lg">Progress Status</div>
            <div className="flex gap-3">
              <div className="flex gap-2">
                <img src="/icons/RunningIcon.svg" alt="RunningIcon" />
                Running
              </div>
              <div className="flex gap-2">
                <img src="/icons/CrossErrorIcon.svg" alt="CrossErrorIcon" />
                Error
              </div>
            </div>
          </div>
          {_.map(processSteps, (step, index) => (
            <>
              <div
                key={index}
                className="flex justify-between items-center mb-2 py-2 px-4 
                  bg-[#d6fdcc]"
                onClick={() => handleRowClick(index)}
              >
                <div className="flex gap-2">
                  <img src="/icons/RunningIcon.svg" alt="status" />
                  <div className="flex">{step[1]}</div>
                </div>
                <div>
                  <img
                    src={`/icons/DownPointer.svg`}
                    alt="DownPointer"
                    className={`transform transition-transform ${
                      expandedRows.includes(index) ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>

              {expandedRows.includes(index) && (
                <div className="flex justify-between ps-[50px] pe-[50px] py-4">
                  {index !== processSteps.length - 1 && <>Step completed</>}
                  {index === processSteps.length - 1 && (
                    <a
                      href={`${link}`}
                      target="_blank"
                      className="text-[#001789]"
                    >
                      Link to Code Fix Pull Request
                    </a>
                  )}
                </div>
              )}
            </>
          ))}
        </div>
        <div className="flex items-center gap-3 border border-[#D9D9D9] rounded mb-10 p-3 text-sm">
          <img
            src={`/icons/SuggestionInfo.svg`}
            alt="SuggestionInfo"
            width={17}
            height={17}
          />
          Suggested fixing are generated by machine learning and algorithms and
          may require human validations.
        </div>
      </div>
    </>
  );
};

export default DemoIssueStatusPage;

import { createSlice } from "@reduxjs/toolkit";

const initialState: SourceSliceProps = {
  source_id: 0,
  environmentData: {
    id: "",
    name: "",
  },
  sourceData: [],
  archivedSourceData: [],
  isDotButtonOpen: false,
};

export const sourceSlice = createSlice({
  name: "SourceSlice",
  initialState,
  reducers: {
    setSourceId: (state, action) => {
      state.source_id = action.payload;
    },
    setSourceData: (state, action) => {
      // Initialize arrays for sourceData and archivedSourceData
      const sourceData: SourceData[] = [];
      const archivedSourceData: SourceData[] = [];

      // Iterate through the incoming data (action.payload)
      action.payload.forEach((source: SourceData) => {
        if (source.is_archived) {
          // Add to archivedSourceData if is_archived is true
          archivedSourceData.push(source);
        } else {
          // Add to sourceData if is_archived is false
          sourceData.push(source);
        }
      });
      // Update state with the separated data
      state.sourceData = sourceData;
      state.archivedSourceData = archivedSourceData;
    },
    setIsDotButtonOpen: (state, action) => {
      state.isDotButtonOpen = action.payload;
    },
    setEnvironmentData: (state, action) => {
      const { id, name } = action.payload;
      state.environmentData.id = id;
      state.environmentData.name = name;
    },
  },
});

export const {
  setSourceId,
  setSourceData,
  setIsDotButtonOpen,
  setEnvironmentData,
} = sourceSlice.actions;
export default sourceSlice.reducer;

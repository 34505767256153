import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { IMemberInvitationRepository } from "./types";
import { parseApiResponse } from "@actions/parseApiResponse";
import { RepositoryURL } from "./constants.enum";

export class MemberInvitationRepository implements IMemberInvitationRepository {
  async getResponse(requestURL: string) {
    const invitationResponse = await this.handleInvitationRequests(requestURL);
    return invitationResponse;
  }
  private _api: IJsonAPI;
  private MEMBER_INVITATION_URL = RepositoryURL.MEMBER_INVITATION_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async memberInvitation<InvitationData>(
    invitationKey: string
  ): Promise<InvitationData | JsonAPIErrorResp | undefined> {
    const invitationUrl = `${this.MEMBER_INVITATION_URL}${invitationKey}/`;
    const headers = new Map<string, string>(); // Replace with actual headers
    headers.set("Content-Type", "application/json");
    const response = await this._api.put<InvitationData, undefined>(
      invitationUrl,
      undefined,
      headers
    );
    return parseApiResponse<InvitationData>(response);
  }

  async handleInvitationRequests(
    requestURL: string
  ): Promise<InvitationData | JsonAPIErrorResp | undefined> {
    requestURL = window.location.href;
    const parsedUrl = new URL(requestURL);
    if (parsedUrl.pathname) {
      const parts = parsedUrl.pathname.split("/").filter(Boolean);
      if (parts.length === 2 && parts[0] === "memberInvitation") {
        const invitationToken = parts[1];
        const decodedToken = invitationToken.slice(2, -1);
        return await this.memberInvitation(decodedToken);
      }
    }
  }
}

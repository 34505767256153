var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AppObserverAPI } from "./api/api";
import { ErrorReportingRepo } from "./repository/ErrorReportingRepo";
// AppObserver: An instance of this class enables any ReactJS + TS project
// to utilize error reporting functionalities.
export class AppObserver {
    // Dependency injection
    constructor(ErrorReportingURL, ErrorReportingApiKey) {
        this._Api = AppObserverAPI.getInstance(ErrorReportingURL);
        // Calling the static "getInstance" method to initialize the private interface field
        this._ErrorReportingRepo = ErrorReportingRepo.getInstance(this._Api, ErrorReportingApiKey);
    }
    // Creating a report method for projects to log errors using AppObserver
    report(error) {
        return __awaiter(this, void 0, void 0, function* () {
            // Calling the "reportError" method from ErrorReportingRepo to report errors to the API
            yield this._ErrorReportingRepo.reportError(error);
        });
    }
}

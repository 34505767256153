import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import SidebarMenu from "@common/sideNav/SidebarMenu";
import SidebarProjectMenu from "./SidebarProjectMenu";
import { useNavigate, useParams } from "react-router-dom";
import SidebarOrganizationMenu from "./SidebarOrganizationMenu";
import { useState } from "react";

const Sidebar = () => {
  const loadingState = useSelector((state: RootState) => state.appSlice);
  const isInsideProject = location.pathname.includes("/projects");
  const params = useParams();
  const id = params.orgId;
  const [orgID] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });
  const orgId = id ? id : orgID;

  const navigate = useNavigate();
  return (
    <section className="bg-nav_blue h-screen flex flex-col">
      <div
        onClick={() => navigate(`/organization/${orgId}`)}
        className="flex items-center w-full h-[9dvh] cursor-pointer box-border"
      >
        <img
          width={125}
          height={125}
          src="/icons/RetackLogo.svg"
          alt="LogoIcon"
          className="mx-10"
        />
      </div>

      <div className="flex flex-col justify-between  h-[91dvh]  ">
        <div className="flex flex-col justify-center gap-2">
          {!loadingState.loading && isInsideProject && <SidebarProjectMenu />}
          <SidebarMenu />
        </div>
        <div className="mb-4">
          <SidebarOrganizationMenu />
        </div>
      </div>
    </section>
  );
};

export default Sidebar;

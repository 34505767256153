export const DemoSourceEnvironmentCheckbox = () => {
  return (
    <div className="flex justify-center items-center w-full cursor-not-allowed ">
      <div className="text-[#4F5A69] mr-2 font-medium text-sm leading-[18px] whitespace-nowrap">
        Sources :
      </div>
      <div className="relative w-full ">
        <div className="flex w-[100%] justify-between h-[35px] items-center cursor-pointer bg-white px-3 py-2 rounded-[8px] border border-[#DDE0EF]">
          <div className="text-[#172B4D] text-[14px] leading-[18px]">All</div>
          <div className="flex justify-center items-center bg-gray rounded-full w-[20px] h-[20px]">
            <img
              className={`w-[18px]`}
              src={`/icons/CustomSelectionArrow.svg`}
              alt="View Environment"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

import { ICustomLogger } from "./types";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import _ from "lodash";
import { getErrorLog } from "@actions/getErrorLog";
import { getErrorLogsFromResponse } from "@actions/getErrorLogsFromResponse";
import { AppObserver } from "app_observer/AppObserver";



const ERROR_API_KEY = import.meta.env.VITE_ERROR_API_KEY;

export class CustomLogger implements ICustomLogger {
  private _api: IJsonAPI;
  private _appObserver: AppObserver;
  private _errorReportingUrl = RepositoryURL.ERROR_REPORTING_URL;

  constructor(API: IJsonAPI, app_observer: AppObserver) {
    this._api = API;
    this._appObserver = app_observer;
  }
  // Logs Error, Info or Warning
  async log(logEntry: LogEntry): Promise<void> {
    const logLevelType =
      "stackTrace" in logEntry
        ? "error"
        : "warningDetails" in logEntry
        ? "warn"
        : "info";

    if (false) {
      console[logLevelType](logEntry);
    } else {
      if (logLevelType === "error") {
        const errorLog: ErrorLog = logEntry as ErrorLog;

        await this._appObserver.report({
          title: errorLog.title,
          stackTrace: errorLog.stackTrace,
        });
      } else {
        const reportErrorHeaders = new Map<string, string>();

        reportErrorHeaders.set("ERROR-API-KEY", ERROR_API_KEY);
        reportErrorHeaders.set("Content-Type", "application/json");

        await this._api.post<LogEntry, unknown>(
          this._errorReportingUrl,
          logEntry,
          reportErrorHeaders,
          false
        );
      }
    }
  }
  // Log multiple errors
  private async logErrors(errorLogs: ErrorLog[]): Promise<void> {
    await Promise.all(
      _.map(errorLogs, async (errorLog: ErrorLog) => {
        await this.log(errorLog);
      })
    );
  }
  // Report single error
  async reportError(error: unknown): Promise<void> {
    const errorLog = getErrorLog(error);
    await this.log(errorLog);
  }
  // Report multiple errors
  async reportErrorResponse(
    showBoundary: (error: unknown) => void,
    response: JsonAPIErrorResp
  ): Promise<void> {
    if ("errors" in response) {
      const errorLogs = getErrorLogsFromResponse(showBoundary, response);
      await this.logErrors(errorLogs);
    }
  }
}

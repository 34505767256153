import { useContext, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { Outlet, useMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { RootState } from "@redux/store";
import Navbar from "@common/header/Navbar";
import Overlay from "@sharedComponents/Overlay";
import Loading from "@sharedComponents/loader/Loading";
import { fetchUserData } from "@actions/fetchUserData";
import _ from "lodash";
import Sidebar from "../common/sideNav/SideBar";

export const Dashboard = () => {
  const isOverlayVisible = useSelector(
    (state: RootState) => state.modalHandler.isOverlayVisible
  );

  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();
  const isDashboard = useMatch("/organization/:orgId");
  const repository = useContext(RepositoryContext);
  const profileRepo = repository.profile;
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const customLogger = useContext(LoggerContext);
  const loadingState = useSelector((state: RootState) => state.appSlice);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserData(profileRepo, showBoundary, dispatch, customLogger);
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    };
    fetchData();
  }, [
    customLogger,
    dispatch,
    profileRepo,
    showBoundary,
    modalHandler.isEditProfileModalOpen,
  ]);

  return (
    <div className="flex bg-bg_gray h-[100vh] w-full">
      <div className="hidden md:block sticky top-0 h-screen w-[300px]">
        <Sidebar />
      </div>
      <div className=" border-b border-[#CBCFE8] absolute w-[100dvw] z-[1] top-[9vh]"></div>
      <div className="relative flex flex-col w-full h-[100vh]">
        <div
          className={`absolute top-0 left-0 bg-[#f2f2f2] h-[250px] w-full ${
            isDashboard ? "" : "z-[-1]"
          }`}
        ></div>
        <Navbar />
        {loadingState.loading ? (
          <Loading />
        ) : (
          <main className="overflow-y-auto">
            <Outlet />
          </main>
        )}
      </div>
      {isOverlayVisible && <Overlay />}
    </div>
  );
};

import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState: AppSliceProps = {
  // loading state
  loading: false,
  // mobile nav state
  mobileNav: false,
  // project list popup
  projectPopUp: false,
};

// Create a slice using createSlice
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setMobileNav: (state, action) => {
      state.mobileNav = action.payload;
    },
    setProjectPopUp: (state, action) => {
      state.projectPopUp = action.payload;
    },
  },
});

// Export the action creator
export const { setLoading, setMobileNav, setProjectPopUp } = appSlice.actions;

// Export the reducer
export default appSlice.reducer;

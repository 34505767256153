import { useContext, useEffect, useRef, useState } from "react";
import InfoTooltip from "../../buttons/InfoTooltip";
import { useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { ErrorToast } from "../../toasts/ErrorToast";
import { SuccessToast } from "../../toasts/SuccessToast";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import { setIsLoading } from "@src/redux/feature/modalHandler";

const EditSlackIntegrationModal = ({
  closeEditIntegrationModal,
  integrationName,
}: EditIntegrationModalProps) => {
  const id = useParams();
  const projectid = id.id;
  const [integrationData, setIntegrationData] =
    useState<AddSlackIntegrationFieldData>();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm<AddSlackIntegrationFieldData>({
    defaultValues: {
      channel_id: "",
      oauth_token: "",
      icon_url: "",
    },
  });

  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
    divRef2: useRef<HTMLDivElement>(null),
    divRef3: useRef<HTMLDivElement>(null),
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);

  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const [url, setUrl] = useState("");
  const [loadingText, setLoadingText] = useState("");
  const [method, setMethod] = useState("");
  const integrationRepo = repository.integration;

  const integrationNameLowerCase = integrationName
    ? integrationName.toLowerCase()
    : null;
  const handleEditTest = () => {
    const saveUrl = `${integrationNameLowerCase}/credential/test/`;
    const method = "integrate";
    setUrl(saveUrl);
    setLoadingText("Sending...");
    setMethod(method);
    handleSubmit((data) => onSubmit(data, saveUrl, method))();
  };

  const handleEditSave = () => {
    const saveUrl = `${integrationNameLowerCase}/credential/`;
    const method = "editIntegration";
    setUrl(saveUrl);
    setMethod(method);
    setLoadingText("Saving...");
    handleSubmit((data) => onSubmit(data, saveUrl, method))();
  };

  const onSubmit = async (
    data: AddSlackIntegrationFieldData,
    url: string,
    method: string
  ) => {
    dispatch(setIsLoading(true));
    try {
      if (!data.icon_url) {
        delete data.icon_url;
      }
      let response;
      if (method === "editIntegration") {
        response = await integrationRepo.editIntegration<
          AddSlackIntegrationFieldData,
          AddSlackIntegrationFieldData
        >(`${projectid}`, url, data);
      } else if (method === "integrate") {
        response = await integrationRepo.integrate<
          AddSlackIntegrationFieldData,
          AddSlackIntegrationFieldData
        >(`${projectid}`, url, data);
      }
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as
              | "channel_id"
              | "oauth_token"
              | "icon_url";
            setError(validField, { message: error.message });
            if (
              validField !== "channel_id" &&
              validField !== "oauth_token" &&
              validField !== "icon_url"
            ) {
              ErrorToast({ Message: error.message });
              closeEditIntegrationModal?.();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          {
            if (url.includes("test")) {
              SuccessToast({
                Message: `Success message sent`,
              });
            } else {
              SuccessToast({
                Message: `Success ${integrationName} Data has been Integrated`,
              });
              closeEditIntegrationModal?.();
            }
            const info: InfoLog = {
              message: `${integrationName} Data has been Integrated`,
            };
            await customLogger.log(info);
          }
        }
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const integrationNameLowerCase = integrationName
        ? integrationName.toLowerCase()
        : null;
      const response =
        await integrationRepo.getIntegratedData<AddSlackIntegrationFieldData>(
          `${projectid}`,
          `${integrationNameLowerCase}/credential/`
        );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          setIntegrationData(response);
        }
      }
    };
    fetchData().catch(async (error) => {
      showBoundary(error);
      await customLogger.reportError(error);
    });
  }, [integrationRepo, projectid, customLogger, showBoundary, integrationName]);

  useEffect(() => {
    setValue("channel_id", integrationData?.channel_id);
    setValue("oauth_token", integrationData?.oauth_token);
    setValue("icon_url", integrationData?.icon_url);
  }, [
    setValue,
    integrationData?.channel_id,
    integrationData?.oauth_token,
    integrationData?.icon_url,
  ]);
  return (
    <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
      <form onSubmit={handleSubmit((data) => onSubmit(data, url, method))}>
        <div className="flex justify-between">
          <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
            <img width={22} height={22} src="/icons/Slack.svg" alt="cross" />
            Edit Slack integration
          </h2>
          <button type="button" onClick={closeEditIntegrationModal}>
            <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
          </button>
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="channel_id"
          >
            Channel ID
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef1}
              messages={["Slack channel to post message"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("channel_id", {
              required: "Channel ID Required",
            })}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.channel_id && (
            <small className="block text-red mt-2">
              {errors.channel_id.message}
            </small>
          )}
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="Oauth_token"
          >
            Oauth_token
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef2}
              messages={[
                "Slack access token link to view slack documentation to generate oauth token",
              ]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("oauth_token", {
              required: "Oauth_token Required",
            })}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.oauth_token && (
            <small className="block text-red mt-2">
              {errors.oauth_token.message}
            </small>
          )}
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="Icon Url"
          >
            Icon Url
            <InfoTooltip
              divRef={divRefs.divRef3}
              messages={["Url for icon image different messages"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("icon_url", {})}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.icon_url && (
            <small className="block text-red mt-2">
              {errors.icon_url.message}
            </small>
          )}
        </div>
        {isLoading ? (
          <button
            disabled
            className="mt-[16px] flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
          >
            <img
              width={22}
              height={22}
              src="/icons/Loading.svg"
              alt="loading"
            />
            {loadingText}
          </button>
        ) : (
          <div className="flex gap-[20px]">
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={handleEditSave}
                type="button"
                className={`bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md ${
                  isValid
                    ? "hover:bg-blue-600"
                    : "cursor-not-allowed opacity-50"
                }`}
                disabled={!isValid}
              >
                Save Changes
              </button>
            </div>
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={handleEditTest}
                type="button"
                className={`bg-[white] w-[160px] h-[40px] text-[#162C5B] text-[14px] font-[700] rounded-md ${
                  isValid ? "bg-blue-600" : "cursor-not-allowed opacity-50"
                }`}
                disabled={!isValid}
              >
                Send Test Event
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditSlackIntegrationModal;

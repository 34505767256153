import NewOrganization from "../login/NewOrganization";

const InitialPage = () => {
  return (
    <section className=" bg-background grid grid-cols-1 md:grid md:grid-cols-5 md:h-screen items-center relative">
      <div className="container md:col-span-2 pt-[25px] md:pt-0 pb-[44px] md:pb-0 ">
        <div className="mt-[10%] md:mt-0 flex flex-col items-center">
          <div className="w-[213px] md:w-[218px] h-[34px] md:h-[65px]">
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/logos/Logo.svg"
              alt="logo"
            />
          </div>
          <p className="text-[16px] md:text-[20px] mt-[18px] md:mt-[22.5px]">
            Unlock Powerful Data Analytics
          </p>
        </div>
        <div className="mt-[10%] md:mt-[55px] flex justify-center">
          <div className="w-[255px] md:w-[387px] h-[180px] md:h-[290px]">
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/logos/Frame.svg"
              alt="Analytics Logo"
            />
          </div>
        </div>
      </div>
      <NewOrganization />
    </section>
  );
};

export default InitialPage;

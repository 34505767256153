import DotsButton from "../buttons/DotsButton";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import _ from "lodash";
import ArchivedGitHubLabStateCard from "./ArchivedGitHubLabStateCard";

/**
 * Renders a card displaying information about a source, including its name, environments, and associated actions.
 *
 * @component
 * @param {Object} props - The props passed to the ArchivedSourceCard component.
 * @param {number} props.source_id - The unique identifier for the source.
 * @param {string} props.source_name - The name of the source.
 * @param {string} props.source_description - The description of the source.
 * @param {Array<Object>} props.environments - An array of environment data associated with the source.
 * @param {Function} props.toggleArchive - A function to toggle the archive status of the source.
 */
const ArchivedSourceCard = ({
  source_id,
  source_name,
  source_description,
  environments,
}: ArchivedSourceCardProps) => {
  const role = useSelector(
    (state: RootState) => state.memberSlice.current_member?.role
  );

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const source = _.find(
    sourceSlice.archivedSourceData,
    (source) => source.id == source_id
  );

  return (
    <div className="w-full rounded-[12px] border border-[#D9DCED] bg-white px-[30px] py-[23px] flex flex-col justify-center mb-[60px] ">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 flex-row items-center justify-center font-[500] text-[#172B4D]">
          <img src="/icons/Device.svg" alt="Device Icon" className="w-[28px]" />
          {source_name}{" "}
          <div className="bg-[#DEE8FF] text-[#162C5B] text-sm rounded-full px-4 py-2">
            Archived
          </div>
        </div>

        {role !== "Basic" && <DotsButton sourceId={source_id} />}
      </div>
      <div className="opacity-50 cursor-not-allowed">
        <p className="mt-2">{source_description}</p>
        <div className="mt-[18px] rounded-[8px] border border-[#D9DCED] overflow-hidden">
          <table className="w-full">
            <thead className="text-left bg-[#F5F6FA] overflow-hidden">
              <tr>
                <th className="p-3 ps-5 text-[#172B4D]">Environment</th>
                <th className="p-3 ps-5 text-[#172B4D] border-s border-[#D9DCED]">
                  Keys
                </th>
              </tr>
            </thead>
            <tbody>
              {_.chain(environments)
                .sortBy(["id"])
                .map((environment, index) => (
                  <tr
                    key={index}
                    className={`pt-4 border-t border-[#D9DCED] h-24 ${
                      !environment.is_active ? "text-[#AAAAAAAA]" : ""
                    }`}
                  >
                    <td className="p-3 align-text-top w-[30%]">
                      <div className="flex flex-row justify-between items-center">
                        <div>{environment.name}</div>
                        {role !== "Basic" && (
                          <div className="relative cursor-not-allowed">
                            <div className="w-[20px] h-[5px]">
                              <img
                                className="w-[3.75px] h-[17px] object-contain"
                                src="/icons/Dots.svg"
                                alt="dots"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {!environment.is_active && (
                        <div className="flex bg-[#DEE8FF] text-[#162C5B] opacity-100 w-20 text-center justify-center items-center rounded-full px-12 py-1">
                          Archived
                        </div>
                      )}
                    </td>

                    <td className="border-s border-[#D9DCED] p-3 align-text-top">
                      {_.map(
                        _.chain(environment.environment_keys)
                          .sortBy(["id"])
                          .filter((keys) => !keys.is_deleted)
                          .value(),
                        (keys, index) => (
                          <div className="flex flex-col" key={index}>
                            <div className="flex flex-row gap-3 items-center">
                              <text className="font-bold flex">
                                <p className="w-[60px]">Key {index + 1}</p>:
                              </text>
                              <p className="w-[270px]">{keys.key}</p>

                              <div className="w-[20px] h-[20px]">
                                <img
                                  className="w-[100%] h-[100%] object-contain"
                                  src="/icons/Copy.svg"
                                  alt="copy"
                                />
                              </div>

                              {role !== "Basic" && (
                                <>
                                  {_.filter(
                                    environment.environment_keys,
                                    (key) => !key.is_deleted
                                  ).length > 1 && (
                                    <img
                                      src="/icons/Delete.svg"
                                      alt="Delete Icon"
                                      className="cursor-not-allowed"
                                    />
                                  )}
                                  {keys.is_active && (
                                    <img
                                      src={
                                        environment.is_active
                                          ? "/icons/ToggleOn.svg"
                                          : "/icons/ToggleOnArchive.svg"
                                      }
                                      alt="ToggleOn Icon"
                                      className={`ms-3 w-[28px] ${
                                        !environment.is_active
                                          ? "cursor-not-allowed"
                                          : "cursor-not-allowed"
                                      }`}
                                    />
                                  )}
                                  {!keys.is_active && (
                                    <img
                                      src={
                                        environment.is_active
                                          ? "/icons/ToggleOff.svg"
                                          : "/icons/ToggleOffArchive.svg"
                                      }
                                      alt="ToggleOff Icon"
                                      className={`ms-3 w-[28px] ${
                                        !environment.is_active
                                          ? "cursor-not-allowed"
                                          : "cursor-not-allowed"
                                      }`}
                                    />
                                  )}{" "}
                                </>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      {role !== "Basic" && (
                        <a
                          className={`cursor-not-allowed font-medium underline text-[#162C5B] ${
                            !environment.is_active ? "text-[#AAAAAAAA] " : ""
                          }`}
                        >
                          Generate more keys
                        </a>
                      )}
                    </td>
                  </tr>
                ))
                .value()}
            </tbody>
          </table>
        </div>

        <div>
          {role !== "Basic" && (
            <div className="flex font-medium mt-2 justify-end w-full text-[#162C5B]">
              <div>
                <div
                  className="inline-block w-auto cursor-not-allowed"
                  style={{ pointerEvents: "none" }}
                >
                  + Add Environments
                </div>
              </div>
            </div>
          )}
          <div>
            {source?.integrations.github.is_connected ||
            source?.integrations.gitlab.is_connected ? (
              <ArchivedGitHubLabStateCard source_id={source_id} />
            ) : (
              <div className="mt-[14px] bg-[#F6F9FF] py-[24px] px-[34px] rounded-[6px]">
                <h1 className="text-[18px] font-bold text-[#162C5B]">
                  Connect with Git Providers
                </h1>
                <p className="mt-4">
                  Lorem ipsum dolor sit amet consectetur. Bibendum ultrices
                  varius arcu mi. Odio ut aliquam amet risus donec sollicitudin
                  ullamcorper
                </p>
                <div className="flex gap-[32px] mt-6 text-[#172B4D] text-[20px] font-medium">
                  <div className="bg-[#EBEDF7] border border-[#E1E5F9] w-[166px] h-[66px] py-[18px] px-[30px] rounded-[5px] flex items-center gap-3">
                    <img src="/icons/Github.svg" alt="github" />
                    <p>GitHub</p>
                  </div>
                  <div className="bg-[#EBEDF7] border border-[#E1E5F9] w-[166px] h-[66px] py-[18px] px-[30px] rounded-[5px] flex items-center gap-3">
                    <img src="/icons/Gitlab.svg" alt="gitlab" />
                    <p>GitLab</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivedSourceCard;

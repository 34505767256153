import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { DateRangePickerContainer } from "@sharedComponents/issuesExplorer/DateRangePickerContainer";
import { SourceEnvironmentCheckbox } from "@src/components/sharedComponents/issuesExplorer/SourceEnvironmentCheckbox";
import { parseRepositoryResponse } from "@actions/parseRepositoryResponse";
import { useErrorBoundary } from "react-error-boundary";
import {
  setErrors,
  setErrorQuery,
  setErrorSourceEnvironment,
  setErrorPageNumber,
} from "@src/redux/feature/errorSlice";
import { setIssueDetailsResponse } from "@feature/issueDetailsSlice";
import Pagination from "@src/components/Pagination";
import { useParams } from "react-router-dom";
import { setIsShowIssueContent } from "@src/redux/feature/modalHandler";
import { IssueContent } from "../issuesExplorer/IssueContent";

export const ViewAllErrorModal = () => {
  const dispatch = useDispatch();

  const params = useParams();
  const projectId = params.id;
  const issueid = params.issueid ? parseInt(params.issueid) : undefined;

  const issuesSlice = useSelector((state: RootState) => state.issuesSlice);
  const issueDetailsSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );
  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const errorSlice = useSelector((state: RootState) => state.errorSlice);
  const isIssueContentOpen = useSelector(
    (state: RootState) => state.modalHandler.isShowIssueContent
  );

  const [query, setQuery] = useState<string>("");

  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);

  const issuesRepo = repository.issues;
  const { showBoundary } = useErrorBoundary();

  const { total_pages, current_page, has_next_page, has_previous_page } =
    errorSlice.meta_data;

  const [sourcesEnvironmentsDropdown, setSourcesEnvironmentsDropdown] =
    useState<Origin[] | null>(null);

  const setPageNumber = (page: number) => {
    dispatch(setErrorPageNumber(page));
  };

  const handleNextPage = () => {
    if (has_next_page) {
      dispatch(setErrorPageNumber(current_page + 1));
    }
  };

  const handlePreviousPage = () => {
    if (has_previous_page) {
      dispatch(setErrorPageNumber(current_page - 1));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const getErrors = await issuesRepo.fetchIssueErrors<ErrorDataResponse>(
        errorSlice.request_params,
        projectId?.toString(),
        issueid
      );
      const getErrorsResponse = await parseRepositoryResponse(
        getErrors,
        customLogger,
        showBoundary
      );

      if (!_.isUndefined(getErrorsResponse)) {
        dispatch(setErrors(getErrorsResponse));
      }

      if (issueDetailsSlice.response.issue_data?.id == null) {
        const issueDetailsResponse =
          await issuesRepo.fetchIssueDetails<IssueDetailsResponse>(
            params.issueid?.toString() || "",
            projectId!,
            { from: "", to: "" }
          );
        const issueDetails = await parseRepositoryResponse(
          issueDetailsResponse,
          customLogger,
          showBoundary
        );
        if (!_.isUndefined(issueDetails)) {
          dispatch(setIssueDetailsResponse(issueDetails));
        }

        const origins = issueDetails?.issue_data?.origin || [];
        setSourcesEnvironmentsDropdown(origins);
      } else {
        setSourcesEnvironmentsDropdown(
          issueDetailsSlice.response.issue_data?.origin || []
        );
      }
    };
    fetchData();
  }, [
    issuesRepo,
    errorSlice.request_params,
    projectSlice.project_id,
    customLogger,
    showBoundary,
    dispatch,
    issuesSlice.issues_response.issue_data,
    projectId,
    issueid,
  ]);

  // Properties for the Checkboxes type component for filtering based on Environments
  const SourceEnvironmentCheckboxProps: SourceEnvironmentCheckboxProps = {
    title: "Source Environment",
    defaultSelection: "All",
    options: sourcesEnvironmentsDropdown,
    isCheckbox: true,
    onSelect: (option: [number, number]) => {
      dispatch(setErrorSourceEnvironment(option));
      dispatch(setErrorPageNumber(1));
    },
  };

  const [clickedId, setClickedId] = useState<number | undefined>(0);

  const handleShowIssueContent = (id: number | undefined) => {
    setClickedId(id);
    dispatch(setIsShowIssueContent(true));
  };

  const handleKeyDown = (e: { key: string }) =>
    e.key === "Enter" && dispatch(setErrorQuery(query));

  return (
    <div>
      <div className="flex flex-col pl-10 pr-4 py-6 h-screen">
        <div className="text-[26px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
          Error
        </div>

        <div className="flex mb-5 h-[35px] bg-white items-center border border-border_gray rounded-[8px] px-3">
          <img src="/icons/Search.svg" alt="SearchIcon" className="w-[14px]" />
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2"
            placeholder="Search"
          />
        </div>
        <div className="flex justify-between gap-[40px]">
          {/* Passing properties to the Checkboxes type component for Source and Environment filter */}
          <SourceEnvironmentCheckbox {...SourceEnvironmentCheckboxProps} />
          <DateRangePickerContainer component="Errors" />
        </div>
        <div className="py-[11px]">
          <div className="overflow-x-auto">
            <table className="w-full text-[#292C31] bg-[#F3F4F8] overflow-y-auto">
              <thead className="text-[14px] text-left ">
                <tr>
                  <th className="font-[700] py-3 px-12">Time</th>
                  <th className="font-[700] py-3 px-12">Source</th>
                  <th className="font-[700] py-3 px-12">Environment</th>
                  <th className="font-[700] py-3 px-12">Title</th>
                </tr>
              </thead>
              <tbody className="text-[12px] cursor-pointer">
                {_.isEmpty(errorSlice.error_list) ? (
                  <tr className="bg-white">
                    <td className="py-3 px-6 text-[#292C31]">
                      No errors have occurred!
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  _.map(errorSlice.error_list, (error, index) => (
                    <tr
                      key={error.id}
                      className={index % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"}
                      onClick={() => handleShowIssueContent(error.id)}
                    >
                      <td className="py-3 px-12 text-[#292C31]">
                        {new Date(error.created_at).toLocaleString("en-US", {
                          month: "short",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: false,
                        })}
                      </td>
                      <td className="py-3 px-12">
                        <div className="bg-[#D7F7EA] px-4 py-1 rounded-[20px] text-[#35D797] truncate text-center inline-block">
                          {error.origin.source}
                        </div>
                      </td>
                      <td className="py-3 px-12">
                        <div className=" px-4 rounded-[20px] border text-center inline-block">
                          {error.origin.environment}
                        </div>
                      </td>
                      <td className="py-3 px-12 max-w-[550px]">
                        <p className="whitespace-nowrap overflow-hidden overflow-ellipsis">
                          {error.title}
                        </p>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-[100%] flex justify-end mt-[1em]">
          <Pagination
            totalPages={total_pages}
            currentPage={current_page}
            onPageChange={setPageNumber}
            hasNextPage={has_next_page}
            hasPreviousPage={has_previous_page}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          />
        </div>
      </div>
      {isIssueContentOpen && <IssueContent clickedId={clickedId} />}
    </div>
  );
};

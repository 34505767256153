export enum RepositoryURL {
  ORGANIZATION_URL = "organizations/",
  EVENTS_URL = "organizations/project/events/",
  LOGIN_URL = "account/",
  MEMBER_INVITATION_URL = "organizations/members/accept-invitation/",
  PROFILE_URL = "account/profile/",
  ORGANIZATION_PROJECT_URL = "organizations/projects/",
  PROJECT_URL = "projects/",
  REFRESH_TOKEN_URL = "account/token/refresh/",
  ERROR_REPORTING_URL = "observe/error-log/",
  ISSUES_URL = "observe/project/",
  MEMBER_URL = "members/",
  SUBSCRIPTION_URL = "subscription/",
}

export enum ErrorTitles {
  BackendError = "Backend Availability Error",
  AuthenticationError = "Authentication Error",
  ValidationError = "Input Validation Error",
  UnknownError = "Unknown Error",
}

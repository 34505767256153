import { useContext, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { RootState } from "@redux/store";
import { RepositoryContext } from "@shared/contexts";
import { LoggerContext } from "@shared/contexts";
import { setProjectData } from "@feature/projectSlice";
import { setLoading, setMobileNav, setProjectPopUp } from "@feature/appSlice";

const DemoSidebarProjectMenu = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [orgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });
  const projectList = useSelector((state: RootState) => state.projectSlice);
  const projectListPopUp = useSelector((state: RootState) => state.appSlice);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const projectRepo = repository.project;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await projectRepo.getProject<ProjectData>(orgId);
        if (response) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
          } else {
            dispatch(setProjectData(response));
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    };
    fetchData();
  }, [customLogger, dispatch, projectRepo, showBoundary]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        dispatch(setProjectPopUp(false));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  const handleProjectRedirect = ({ projectId }: { projectId: number }) => {
    const proId = projectId.toString();
    dispatch(setMobileNav(false));
    dispatch(setProjectPopUp(false));
    if (proId !== id) {
      dispatch(setLoading(true));
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 2000);
    }
  };

  const handleRedirect = () => {
    dispatch(setMobileNav(false));
    dispatch(setProjectPopUp(false));
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 2000);
  };

  const RenderProjectList = () => {
    const demoProject = {
      id: 0, // Unique identifier for the Demo Project
      title: "Demo Project",
      description: "Demo",
      updated_at: new Date().toISOString(),
      created_at: new Date().toISOString(),
    };

    const projects = _.slice(projectList.projectData, 0, 10);
    projects.unshift(demoProject); // Add the Demo Project at the beginning

    const selectedProjectInProjects = _.find(
      projects,
      (project) => project.id.toString() === "0"
    );
    const selectedProjectsInList = _.find(
      projectList.projectData,
      (project) => project.id.toString() === "0"
    );
    if (
      _.isUndefined(selectedProjectInProjects) &&
      !_.isUndefined(selectedProjectsInList)
    ) {
      projects.pop();
      projects.push(projects[0]);
      projects[0] = selectedProjectsInList;
    }
    return _.map(projects, (project, index) => {
      const colors = ["#23ABF7", "#6452E5", "#5CCDC6", "#C095F9"];
      const colorIndex = index % colors.length;
      const projectShortName = project.title[0].toUpperCase();
      const projectTitle = project.title.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      );
      const projectDescription = project.description.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      );

      const projectId = project.id.toString();
      let projectBg = "bg-white hover:bg-[#EBF1FC]";
      let projectColor = "text-[#172B4D]";
      const projectShortNameBg = colors[colorIndex];

      if ("0" === projectId) {
        projectBg = "bg-nav_blue";
        projectColor = "text-white";
      }
      return (
        <NavLink
          to={projectId === "0" ? "" : `/projects/${project.id}`}
          key={project.id}
          onClick={(e) => {
            if (projectId === "0") {
              e.preventDefault(); // Prevent the default action
              window.location.reload(); // Reload the current page
            } else {
              handleProjectRedirect({ projectId: project.id });
            }
          }}
        >
          <div
            className={`flex justify-between items-center ${projectBg} mb-[5px] px-[5px] py-[5px] rounded-[5px]`}
          >
            <div className="flex justify-center items-center gap-[8px] ">
              <div
                className={`w-[30px] h-[30px] flex justify-center items-center rounded-[3px]`}
                style={{ backgroundColor: projectShortNameBg }}
              >
                <p className="text-[15px] text-[#fff]">{projectShortName}</p>
              </div>
              <div>
                <h3
                  className={`${projectColor} text-[14px] font-bold leading-[18px] w-[120px] overflow-hidden text-ellipsis truncate`}
                >
                  {projectTitle}
                </h3>
                <div
                  className={`${projectColor} text-[12px] font-normal leading-[15px] w-[120px] overflow-hidden text-ellipsis truncate`}
                >
                  {projectDescription}
                </div>
              </div>
            </div>
          </div>
        </NavLink>
      );
    });
  };

  const RenderFilteredProjectCard = () => {
    return (
      <div>
        <div className="flex justify-between items-center font-medium text-white leading-6 w-full text">
          <div> Demo Project</div>
          {projectListPopUp.projectPopUp ? (
            <img src="/icons/RoundArrow.svg" alt="DownArrow" />
          ) : (
            <img
              className="rotate-180"
              src="/icons/RoundArrow.svg"
              alt="UpArrow"
            />
          )}
        </div>
      </div>
    );
  };

  const handleProjectPopUpClick = () =>
    dispatch(setProjectPopUp(!projectListPopUp.projectPopUp));

  return (
    <div
      onClick={handleProjectPopUpClick}
      className="relative cursor-pointer border-0 border-[#CBCFE8] border-b-[0.5px] px-5"
    >
      <div
        onClick={handleProjectPopUpClick}
        className="relative cursor-pointer flex flex-col m-5"
      >
        <RenderFilteredProjectCard />
      </div>
      {projectListPopUp.projectPopUp && (
        <div className="w-full flex justify-center">
          <div
            ref={popupRef}
            id="PopupList"
            className="bg-white p-[10px] rounded-[6px] absolute top-[50px] w-[200px] z-1"
          >
            <RenderProjectList />
            <NavLink to={`/organization/${orgId}`} onClick={handleRedirect}>
              <div className="flex justify-center items-center px-[12px] mt-[10px] w-full h-[42px] rounded-[4px] border border-nav_blue border-opacity-50 text-nav_blue ml-1 text-sm text-center font-medium leading-5">
                View All Projects
              </div>
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemoSidebarProjectMenu;

import { AppObserverContext } from "@shared/contexts";
import { AppObserver } from "app_observer/AppObserver";

declare type AppObserverProviderProps = {
  app_observer: AppObserver;
};

export const AppObserverProvider = ({
  children,
  app_observer,
}: React.PropsWithChildren<AppObserverProviderProps>) => {
  return (
    <AppObserverContext.Provider value={app_observer}>
      {children}
    </AppObserverContext.Provider>
  );
};

import { useDispatch, useSelector } from "react-redux";
import {
	setIsEditIntegrationModalOpen,
	setIsIntegrationModalOpen,
	setIsOverlayVisible,
	setIsRemoveIntegrationModalOpen
} from "@src/redux/feature/modalHandler";
import { AppDispatch, RootState } from "@src/redux/store";

const IntegrationCard = ({
	state,
	name,
	buttonName,
	image,
	description,
	onOpenRemoveIntegrationModal,
	onOpenIntegrationModal,
	onOpenEditIntegrationModal
}: IntegrationCardsProps) => {
	const dispatch = useDispatch<AppDispatch>();
	const memberSlice = useSelector((state: RootState) => state.memberSlice);
	const role = memberSlice.current_member?.role;

	const openRemoveIntegrationModal = () => {
		onOpenRemoveIntegrationModal();
		dispatch(setIsRemoveIntegrationModalOpen(true));
		dispatch(setIsOverlayVisible(true));
	};

	const openIntegrationModal = () => {
		onOpenIntegrationModal();
		dispatch(setIsIntegrationModalOpen(true));
		dispatch(setIsOverlayVisible(true));
	};

	const openEditIntegrationModal = () => {
		onOpenEditIntegrationModal();
		dispatch(setIsEditIntegrationModalOpen(true));
		dispatch(setIsOverlayVisible(true));
	};

	return (
		<div className="border border-[#CBCFE8] w-[355px] rounded-[5px] px-[25px] py-[24px]">
			<div className="text-[13px] text-[#A6ADCF] flex justify-end ">
				<p
					className={`px-[10px] py-[2px] rounded-full bg-opacity-25 ${
						state
							? "bg-[#671FFF] text-[#671FFF]"
							: "bg-[#F2BC42] text-[#F28142]"
					}`}
				>
					{state ? "configured" : "Not configured"}
				</p>
			</div>
			<div className="flex items-center mb-[42px] gap-[14px]">
				<img
					className="w-[36px] h-[36px]"
					src={image}
					alt={name}
				/>
				<h1 className="font-bold text-[20px] text-[#000000]">{name}</h1>
			</div>
			<p className="text-[#000000] text-[12px] mb-[45px] leading-4 tracking-[1px]">{description}</p>
			<div className="flex items-center justify-between">
				<button
					onClick={openRemoveIntegrationModal}
					className={`${
						state ? "block" : "hidden"
					} flex items-center gap-[10px] ${
						role === "Basic" ? "opacity-30 cursor-not-allowed" : ""
					}`}
					disabled={role === "Basic"}
				>
					<img
						className="w-[16px] h-[16px]"
						src="/icons/MemberDelete.svg"
						alt="google logo"
					/>
					<p className="text-[#CC0202] font-[500]">Remove</p>
				</button>

				<button
					onClick={
						buttonName === "Edit"
							? openEditIntegrationModal
							: openIntegrationModal
					}
					className={`${
						buttonName === "Edit" ? " w-[106px] text-[#001789] border border-[#001789]" : "bg-[#001789] text-[#FFFFFF]"
					}   text-[14px] rounded-[10px] py-[8px] px-[24px] font-[700] ${
						role === "Basic" ? "opacity-30 cursor-not-allowed" : ""
					}`}
					disabled={role === "Basic"}
				>
					<p>{buttonName}</p>
				</button>
			</div>
		</div>
	);
};

export default IntegrationCard;

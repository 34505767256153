import demoButtonData from "@utils/DemoButtonData.json";
import SidebarButton from "@sharedComponents/buttons/SidebarButton";
import _ from "lodash";

const DemoSidebarMenu = () => {
  const currentURL = window.location.href;
  const urlSegments = currentURL.split("/");
  const projectsIndex = urlSegments.indexOf("project");
  let endpoint = "issues"; // Default value
  if (projectsIndex !== -1 && projectsIndex + 3 < urlSegments.length) {
    endpoint = urlSegments
      .slice(projectsIndex + 3, projectsIndex + 4)
      .join("/");
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="mt-[30px] flex flex-col items-start px-5">
        {_.map(demoButtonData, (button, index) => (
          <SidebarButton
            key={index}
            source={button.source}
            name={button.name}
            label={button.label}
            active={button.label.toLowerCase() === endpoint}
            link={button.link}
          />
        ))}
      </div>
    </div>
  );
};

export default DemoSidebarMenu;

import { parseApiResponse } from "@actions/parseApiResponse";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";

export class GitHubLabRepository {
  private _api: IJsonAPI;

  private ORGANIZATION_PROJECT_URL  = RepositoryURL.ORGANIZATION_PROJECT_URL

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async Connect<GitHubLabData, GitHubLabFieldData>(
    project_id: string,
    source_id: number | null,
    data: GitHubLabFieldData
  ): Promise<GitHubLabData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<GitHubLabData, GitHubLabFieldData>(
      `${this.ORGANIZATION_PROJECT_URL}${project_id}/sources/${source_id}/vcs/credential/`,
      data,
      headers
    );
    return parseApiResponse<GitHubLabData>(response);
  }

  async editGitHubLab<GitHubLabData, GitHubLabFieldData>(
    project_id: string,
    source_id: number,
    vcsId: string,
    data: GitHubLabFieldData
  ): Promise<GitHubLabData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const integration_url = `${this.ORGANIZATION_PROJECT_URL}${project_id}/sources/${source_id}/vcs/${vcsId}/credential/`;
    const response = await this._api.put<GitHubLabData, GitHubLabFieldData>(
      integration_url,
      data,
      headers
    );
    return parseApiResponse<GitHubLabData>(response);
  }

  async removeGitHubLab(project_id: string, source_id: number, vcsId: string) {
    const sources_url = `${this.ORGANIZATION_PROJECT_URL}${project_id}/sources/${source_id}/vcs/${vcsId}/credential/`;
    return await this._api.delete(sources_url);
  }

  async retrieve<GitHubLabData>(
    project_id: string,
    source_id: number,
    vcs_id: string
  ): Promise<GitHubLabData | JsonAPIErrorResp | undefined> {
    const github_url = `${this.ORGANIZATION_PROJECT_URL}${project_id}/sources/${source_id}/vcs/${vcs_id}/credential/`;

    const githubCredential = await this._api.get<GitHubLabData>(github_url);

    return parseApiResponse<GitHubLabData>(githubCredential);
  }
}

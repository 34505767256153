import Projects from "@pages/Projects";
import NotFound from "@pages/NotFound";
import { Application } from "@components/sharedLayout/Application";
import { Dashboard } from "@components/sharedLayout/Dashboard";
import PrivateRoute from "./ProtectedRoutes";
import { CustomErrorBoundary } from "@sharedComponents/errorHandling/CustomErrorBoundary";
import IntegrationPage from "@pages/IntegrationPage";
import { TitleUpdater } from "@sharedComponents/TitleUpdater";
import { IssuesExplorer } from "@pages/IssuesExplorer";
import { DemoIssuesExplorer } from "@pages/DemoIssuesExplorer";
import { DemoApplication } from "@components/sharedLayout/DemoApplication";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import { ViewAllErrorModal } from "@src/components/sharedComponents/modal/ViewAllErrorModal";
import { IssueResolutionPage } from "@src/pages/IssueResolutionPage";
import { DemoIssueResolutionPage } from "@src/pages/DemoIssueResolutionPage";
import AccessManagement from "@pages/AccessManagement";
import MemberInvitationPage from "@src/pages/MemberInvitation";
import Sources from "@src/pages/Sources";
import Usage from "@src/pages/Settings/Usage";
import General from "@src/pages/Settings/General";
import CodeFix from "@src/pages/codeFix/CodeFix";
import IssueStatusPage from "@src/pages/codeFix/IssueStatusPage";
import LoginPage from "@src/pages/login/LoginPage";
import SignUpPage from "@src/pages/login/SignUpPage";
import SignUpVerification from "@src/pages/verification/SignUpVerification";
import ResetForgotPasswordPage from "@src/pages/login/resetPassword/ResetForgotPasswordPage";
import ForgotPasswordPage from "@src/pages/login/resetPassword/ForgotPasswordPage";
import DemoIssueStatusPage from "@src/pages/codeFix/DemoIssueStatusPage";
import DemoSources from "@src/pages/DemoSources";
import DemoIntegrationPage from "@src/pages/DemoIntegrationPage";
import DemoGeneral from "@src/pages/Settings/DemoGeneral";
import { DemoViewAllErrorModal } from "@src/components/sharedComponents/modal/DemoViewAllErrorModal";
import DemoCodeFix from "@src/pages/DemoCodeFix";
import Redirect from "@src/pages/organization/Redirect";
import OrganizationSettings from "@src/pages/Settings/OrganizationSettings";
import AccountSettings from "@src/pages/Settings/AccountSettings";
import RedirectSubs from "@src/pages/subscription/RedirectSubs";
import MainSubs from "@src/pages/subscription/MainSubs";
import Subscription from "@src/pages/subscription/Subscription";

const Router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Navigate to="/organization" />} />
      <Route
        path="activate/:uid/:tokenId"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="verification">
              <SignUpVerification />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        path="reset/:uid/:tokenId"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="verification">
              <ResetForgotPasswordPage />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        path="login"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="Login">
              <LoginPage />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        path="forgotPassword"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="Forgot Password">
              <ForgotPasswordPage />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        path="signUp"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="signUp">
              <SignUpPage />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        path="memberInvitation/:invitation-token/"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="Member Invitation">
              <MemberInvitationPage message="Your Invitation has been granted" />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        path="subscription/checkout/success"
        element={
          <CustomErrorBoundary>
            <TitleUpdater title="Redirect Subscription">
              <RedirectSubs />
            </TitleUpdater>
          </CustomErrorBoundary>
        }
      />
      <Route
        element={
          <CustomErrorBoundary>
            <PrivateRoute />
          </CustomErrorBoundary>
        }
      >
        <Route
          path="/organization"
          element={
            <TitleUpdater title="Redirect">
              <Redirect />
            </TitleUpdater>
          }
        />
        <Route path="/organization/:orgId" element={<Dashboard />}>
          <Route
            index
            element={
              <TitleUpdater title="Dashboard">
                <Projects />
              </TitleUpdater>
            }
          />
          <Route
            path="member management"
            element={
              <TitleUpdater title="Member Management">
                <AccessManagement />
              </TitleUpdater>
            }
          />
          <Route
            path="organization settings"
            element={
              <TitleUpdater title="Organization settings">
                <OrganizationSettings />
              </TitleUpdater>
            }
          />
          <Route
            path="subscription"
            element={
              <TitleUpdater title="Subscription">
                <MainSubs />
              </TitleUpdater>
            }
          ></Route>
          <Route
            path="/organization/:orgId/subscription/plan"
            element={
              <TitleUpdater title="Plan">
                <Subscription />
              </TitleUpdater>
            }
          />
          <Route
            path="account-settings"
            element={
              <TitleUpdater title="Account settings">
                <AccountSettings />
              </TitleUpdater>
            }
          />
          <Route
            path="token usage"
            element={
              <TitleUpdater title="Token Usage">
                <Usage />
              </TitleUpdater>
            }
          />
        </Route>
        <Route path="project/:id/demo" element={<DemoApplication />}>
          <Route
            index
            element={
              <TitleUpdater title="Demo Project">
                <DemoIssuesExplorer />
              </TitleUpdater>
            }
          />
          <Route
            path="sources"
            element={
              <TitleUpdater title="Demo Sources">
                <DemoSources />
              </TitleUpdater>
            }
          />
          <Route
            path="integration"
            element={
              <TitleUpdater title="Demo Integration">
                <DemoIntegrationPage />
              </TitleUpdater>
            }
          />
          <Route
            path="issues/:issueid/errors"
            element={
              <TitleUpdater title="Demo Errors">
                <DemoViewAllErrorModal />
              </TitleUpdater>
            }
          />
          <Route
            path="sources/:sourceid/issues/:issueid/fix"
            element={
              <TitleUpdater title="Demo Issue Fix">
                <DemoIssueResolutionPage />
              </TitleUpdater>
            }
          />
          <Route
            path="settings"
            element={
              <TitleUpdater title="Demo Settings">
                <DemoGeneral />
              </TitleUpdater>
            }
          />

          <Route
            path="codefix"
            element={
              <TitleUpdater title="Demo Codefix">
                <DemoCodeFix />
              </TitleUpdater>
            }
          />

          <Route
            path="codefix/:statusId/issueStatus"
            element={
              <TitleUpdater title="Demo IssueStatus">
                <DemoIssueStatusPage />
              </TitleUpdater>
            }
          />
        </Route>

        <Route path="projects/:id" element={<Application />}>
          <Route
            index
            element={
              <TitleUpdater title="Issues">
                <IssuesExplorer />
              </TitleUpdater>
            }
          />
          <Route
            path="sources"
            element={
              <TitleUpdater title="Sources">
                <Sources />
              </TitleUpdater>
            }
          />

          <Route
            path="integration"
            element={
              <TitleUpdater title="Integration">
                <IntegrationPage />
              </TitleUpdater>
            }
          />
          <Route
            path="issues/:issueid/errors"
            element={
              <TitleUpdater title="Errors">
                <ViewAllErrorModal />
              </TitleUpdater>
            }
          />
          <Route
            path="sources/:sourceid/issues/:issueid/fix"
            element={
              <TitleUpdater title="Issue Fix">
                <IssueResolutionPage />
              </TitleUpdater>
            }
          />
          <Route
            path="members"
            element={
              <TitleUpdater title="Members">
                <AccessManagement />
              </TitleUpdater>
            }
          />
          <Route
            path="settings"
            element={
              <TitleUpdater title="Settings">
                <General />
              </TitleUpdater>
            }
          />
          <Route
            path="codefix"
            element={
              <TitleUpdater title="Codefix">
                <CodeFix />
              </TitleUpdater>
            }
          />
          <Route
            path="codefix/:statusId/issueStatus"
            element={
              <TitleUpdater title="IssueStatus">
                <IssueStatusPage />
              </TitleUpdater>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <TitleUpdater title="404">
              <NotFound />
            </TitleUpdater>
          }
        />
      </Route>
    </Route>
  )
);

export default Router;

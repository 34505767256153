import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Usage = () => {
  const repository = useContext(RepositoryContext);
  const profileRepo = repository.profile;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const params = useParams();
  const orgId = params.orgId!;
  const [usageData, setUsageDate] = useState<UsageData>();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  useEffect(() => {
    dispatch(setIsLoading(true));
    const fetchData = async () => {
      try {
        const response = await profileRepo.getUsage<UsageData>(orgId);
        if (response) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
          } else {
            setUsageDate(response);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };
    fetchData();
  }, [customLogger, profileRepo, showBoundary]);
  const formatted = (count: number) => {
    const result = (count / 1000).toFixed(1) + "k";
    return result.replace(".0k", "k");
  };

  const availableTokensAtSignIn =
    (usageData?.available_ai_token ?? 0) + (usageData?.used_ai_token ?? 0);
  const usedTokens = usageData?.used_ai_token ?? 0;
  const availableTokens = usageData?.available_ai_token ?? 0;

  const calculateWidthTokens = (tokens: number) => {
    const width = (tokens / availableTokensAtSignIn) * 100;
    return width < 0 ? "0%" : width + "%";
  };

  const availableErrorsAtSignIn =
    (usageData?.available_error ?? 0) + (usageData?.used_error ?? 0);
  const usedErrors = usageData?.used_error ?? 0;
  const availableErrors = usageData?.available_error ?? 0;

  const calculateWidthErrors = (tokens: number) => {
    const width = (tokens / availableErrorsAtSignIn) * 100;
    return width < 0 ? "0%" : width + "%";
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-[90vh]">
          <img src="/icons/Loading.svg" alt="loading" />
        </div>
      ) : (
        <div className="p-[25px] md:p-[36px] flex flex-col gap-[46px]">
          <div>
            <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
              Tokens
            </h1>
            <div className="border border-[#D9DCED] px-[32px] py-[24px] mt-[20px] bg-[#F9FBFF]">
              <div className="h-[12px] w-full rounded-full bg-[#CFDBEE] overflow-hidden">
                <div
                  className="h-full bg-[#325EBB]"
                  style={{ width: calculateWidthTokens(usedTokens) }}
                ></div>
              </div>
            </div>
            <div className="border border-t-0 border-[#D9DCED] px-[32px] py-[24px] flex justify-between">
              <div className="flex flex-col gap-[18px]">
                <div className="flex items-center gap-3 text-[16px]">
                  <div className="h-[5px] w-[35px] rounded-full bg-[#325EBB]"></div>
                  <p>Used AI Tokens ({formatted(usedTokens)})</p>
                </div>
                <div className="flex items-center gap-3 text-[16px]">
                  <div className="h-[5px] w-[35px] rounded-full bg-[#CFDBEE]"></div>
                  <p>Available Tokens ({formatted(availableTokens)})</p>
                </div>
              </div>
            </div>
            <div className="text-[12px] px-[32px] py-[12px] border border-t-0 border-[#D9DCED] bg-[#F9FBFF] flex gap-[5px]">
              <img src="/icons/learnMore.svg" alt="learn more" />
              {formatted(usageData?.additional_info.free_signup_token!)} tokens
              available during signing up.
              {" "}{formatted(usageData?.additional_info.monthly_renewal_token!)} tokens
              renewed every month.
            </div>
          </div>
          <div>
            <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
              Errors
            </h1>
            <div className="border border-[#D9DCED] px-[32px] py-[24px] mt-[20px] bg-[#F9FBFF]">
              <div className="h-[12px] w-full rounded-full bg-[#CFDBEE] overflow-hidden">
                <div
                  className="h-full bg-[#325EBB]"
                  style={{ width: calculateWidthErrors(usedErrors) }}
                ></div>
              </div>
            </div>
            <div className="border border-t-0 border-[#D9DCED] px-[32px] py-[24px] flex justify-between">
              <div className="flex flex-col gap-[18px]">
                <div className="flex items-center gap-3 text-[16px]">
                  <div className="h-[5px] w-[35px] rounded-full bg-[#325EBB]"></div>
                  <p>Used Errors ({formatted(usedErrors)})</p>
                </div>
                <div className="flex items-center gap-3 text-[16px]">
                  <div className="h-[5px] w-[35px] rounded-full bg-[#CFDBEE]"></div>
                  <p>Available Errors ({formatted(availableErrors)})</p>
                </div>
              </div>
            </div>
            <div className="text-[12px] px-[32px] py-[12px] border border-t-0 border-[#D9DCED] bg-[#F9FBFF] flex gap-[5px]">
              <img src="/icons/learnMore.svg" alt="learn more" />
              {formatted(usageData?.additional_info.monthly_renewal_error!)} errors renewed every month.
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Usage;

import { IStorageClient } from "@src/clients/storage/types";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { setAuthTokens } from "@src/redux/feature/authSlice";
import {
  LoggerContext,
  RepositoryContext,
  getLocalStorageClient,
} from "@src/shared/contexts";
import _ from "lodash";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

const LoginForm = () => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<LogInData>();
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const LogInRepo = repository.login;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const savedPassword = localStorage.getItem("password");
    const savedEmail = localStorage.getItem("email");
    const savedRememberMe = localStorage.getItem("rememberMe") === "true";
    if (savedRememberMe && savedPassword && savedEmail) {
      setValue("password", savedPassword);
      setValue("email", savedEmail);
      setRememberMe(savedRememberMe);
    }
  }, [setValue]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);
    if (!isChecked) {
      localStorage.removeItem("password");
      localStorage.removeItem("email");
      localStorage.removeItem("rememberMe");
      setValue("password", "");
      setValue("email", "");
    } else {
      localStorage.setItem("rememberMe", "true");
      const password = getValues("password");
      const email = getValues("email");
      if (password && email) {
        localStorage.setItem("password", password);
        localStorage.setItem("email", email);
      }
    }
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setValue("password", newPassword);
    if (rememberMe) {
      localStorage.setItem("password", newPassword);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setValue("email", newEmail);
    if (rememberMe) {
      localStorage.setItem("email", newEmail);
    }
  };

  const onSubmit = async (data: LogInData) => {
    setIsLoading(true);
    try {
      const response = await LogInRepo.emailLogin<TokenResponse, LogInData>(
        data
      );
      const localStorageClient: IStorageClient = getLocalStorageClient();
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.forEach(response.errors, (error) => {
            const validField = error.title as "email" | "password";
            setError(validField, { message: error.message });
            if (validField !== "email" && validField !== "password") {
              ErrorToast({ Message: error.message });
              clearErrors();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          localStorageClient.setAccessToken(response.access_token);
          dispatch(setAuthTokens(response.access_token));
          localStorageClient.setRefreshToken(response.refresh_token);
          navigate("/");
        }
      }
      setIsLoading(false);
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="md:flex justify-center">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center"
      >
        <div className="flex flex-col w-full">
          <label className="text-[#42454A] font-[500]" htmlFor="email">
            Email Address
            <span className="text-red text-sm ml-1">*</span>
          </label>
          <input
            className={`${
              errors.email ? "border-[#CC0202]" : "border-[#162C5B]"
            } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
            type="email"
            {...register("email", {
              required: "The email field is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
            onChange={handleEmailChange}
            placeholder="example@example.com"
          />
          {errors.email != null && (
            <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
              <img src="/icons/ErrorAlert.svg" alt="error" />
              {errors.email.message}
            </small>
          )}
        </div>
        <div className="mt-5 flex flex-col w-full">
          <div className="flex justify-between">
            <label className="text-[#42454A] font-[500]" htmlFor="password">
              Password
              <span className="text-red text-sm ml-1">*</span>
            </label>
          </div>
          <div className="relative mt-1 md:w-[400px]">
            <input
              className={`${
                errors.password ? "border-[#CC0202]" : "border-[#162C5B]"
              } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
              type={passwordVisible ? "text" : "password"}
              {...register("password", {
                required: "The password field is required",
              })}
              onChange={handlePasswordChange}
              placeholder="********"
            />

            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 px-3 flex items-center"
            >
              {passwordVisible ? (
                <img src="/icons/OpenEye.svg" alt="open eye" />
              ) : (
                <img src="/icons/ClosedEye.svg" alt="closed eye" />
              )}
            </button>
          </div>
          {errors.password != null && (
            <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
              <img src="/icons/ErrorAlert.svg" alt="error" />
              {errors.password.message}
            </small>
          )}
          <div className="mt-3 flex justify-between ">
            <div className="flex items-center">
              <input
                id="rememberMe"
                type="checkbox"
                className="h-4 w-4 text-[#162C5B] border-gray-300 rounded focus:ring-[#162C5B]"
                checked={rememberMe}
                onChange={handleRememberMeChange}
              />
              <label
                htmlFor="rememberMe"
                className="ml-2 block text-sm text-gray-900"
              >
                Remember me
              </label>
            </div>
            <NavLink
              to={"/forgotPassword"}
              className="text-[#42454A] text-[14px]"
            >
              Forgot Password?
            </NavLink>
          </div>
        </div>
        {isLoading ? (
          <button
            disabled
            className="flex justify-center items-center border border-[#325EBB] bg-[#325EBB] bg-opacity-[0.80] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[36px] gap-3"
          >
            <img
              width={22}
              height={22}
              src="/icons/Loading.svg"
              alt="loading"
            />
            Logging In ...
          </button>
        ) : (
          <button
            type="submit"
            className={`border border-[#325EBB] bg-[#325EBB] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[36px]`}
          >
            Log In
          </button>
        )}
        <p className="text-center mt-[15px] font-medium">or</p>
      </form>
    </div>
  );
};

export default LoginForm;

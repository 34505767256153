import { useDispatch } from "react-redux";
import SidebarMenu from "../sideNav/SidebarMenu";
import SidebarProjectMenu from "../sideNav/SidebarProjectMenu";
import { setMobileNav, setProjectPopUp } from "@feature/appSlice";

const MobileMenu = () => {
  const dispatch = useDispatch();
  const closeMobileNav = () => {
    dispatch(setMobileNav(false));
    dispatch(setProjectPopUp(false));
  };
  return (
    <div className="fixed top-0 h-[100vh] w-[70%] bg-[#fff] px-[17px] md:hidden">
      <div className="mobile-nav-header flex h-[10vh] border-b border-[#EBEDF4]">
        <p className="text-[#292C31] text-lg font-bold ml-1 mt-12">Dashboard</p>
        <img
          className="absolute top-12 w-[34px] right-[-15px]"
          src="/icons/CloseMobileNav.svg"
          alt="CloseMobileNav"
          onClick={closeMobileNav}
        />
      </div>
      <div className="flex flex-col justify-between h-[90vh]">
        <SidebarMenu />
        <SidebarProjectMenu />
      </div>
      <div
        className="backdrop fixed top-0 right-0 h-[100vh] w-[30%] bg-[#000] bg-opacity-30 z-[-10]"
        onClick={closeMobileNav}
      />
    </div>
  );
};

export default MobileMenu;

import { createSlice } from "@reduxjs/toolkit";
const initialState: ModalHandlerProps = {
  isDotButtonOpen: false,
  isOverlayVisible: false,
  isGoogleConsoleModalOpen: false,
  isEditProfileModalOpen: false,
  isSubscriptionSuccessModalOpen:false,
  isViewOrganizationModalOpen: false,
  isAddOrganizationModalOpen: false,
  isEditOrganizationModalOpen: false,
  isAddSourceModalOpen: false,
  isCreateProjectModalOpen: false,
  isEditProjectModalOpen: false,
  isDeleteProjectModalOpen: false,
  isEditSourceModalOpen: false,
  isDeleteSourceModalOpen: false,
  isAddEnvironmentModalOpen: false,
  isEditEnvironmentModalOpen: false,
  isAddMemberModalOpen: false,
  isEditMemberModalOpen: false,
  isDeleteMemberModalOpen: false,
  isViewAllErrorsOpen: false,
  isShowIssueDetails: false,
  isShowIssueContent: false,
  isIntegrationModalOpen: false,
  isRemoveIntegrationModalOpen: false,
  isEditIntegrationModalOpen: false,
  isProfileDropdownOpen: false,
  isAddGithubModalOpen: false,
  isAddGitlabModalOpen: false,
  isEditGitHubLabModalOpen: false,
  isRemoveGitHubLabModalOpen: false,
  isEnvironmentDotsButtonOpen: false,
  isSourceDotsButtonOpen: false,
  isLoading: false,
  isGitHubLabAndTokenModalOpen: false,
  isMemberProjectsModalOpen: false,
};
export const modalHandler = createSlice({
  name: "modalHandler",
  initialState,
  reducers: {
    setIsGoogleConsoleModalOpen: (state, action) => {
      state.isGoogleConsoleModalOpen = action.payload;
    },
    setIsOverlayVisible: (state, action) => {
      state.isOverlayVisible = action.payload;
      state.isProfileDropdownOpen = false;
    },
    setIsEditProfileModalOpen: (state, action) => {
      state.isEditProfileModalOpen = action.payload;
    },
    setIsSubscriptionSuccessModalOpen: (state, action) => {
      state.isSubscriptionSuccessModalOpen = action.payload;
    },
    setIsViewOrganizationModalOpen: (state, action) => {
      state.isViewOrganizationModalOpen = action.payload;
    },
    setIsAddOrganizationModalOpen: (state, action) => {
      state.isAddOrganizationModalOpen = action.payload;
    },
    setIsEditOrganizationModalOpen: (state, action) => {
      state.isEditOrganizationModalOpen = action.payload;
    },
    setIsAddSourceModalOpen: (state, action) => {
      state.isAddSourceModalOpen = action.payload;
    },
    setIsEditSourceModalOpen: (state, action) => {
      state.isEditSourceModalOpen = action.payload;
    },
    setIsDeleteSourceModalOpen: (state, action) => {
      state.isDeleteSourceModalOpen = action.payload;
    },
    setIsAddEnvironmentModalOpen: (state, action) => {
      state.isAddEnvironmentModalOpen = action.payload;
    },
    setIsEditEnvironmentModalOpen: (state, action) => {
      state.isEditEnvironmentModalOpen = action.payload;
    },
    setIsAddMemberModalOpen: (state, action) => {
      state.isAddMemberModalOpen = action.payload;
    },
    setIsCreateProjectModalOpen: (state, action) => {
      state.isCreateProjectModalOpen = action.payload;
    },
    setIsEditProjectModalOpen: (state, action) => {
      state.isEditProjectModalOpen = action.payload;
    },
    setIsDeleteProjectModalOpen: (state, action) => {
      state.isDeleteProjectModalOpen = action.payload;
    },
    setIsEditMemberModalOpen: (state, action) => {
      state.isEditMemberModalOpen = action.payload;
    },
    setIsDeleteMemberModalOpen: (state, action) => {
      state.isDeleteMemberModalOpen = action.payload;
    },
    setIsViewAllErrorsOpen: (state, action) => {
      state.isViewAllErrorsOpen = action.payload;
    },
    setIsShowIssueDetails: (state, action) => {
      state.isShowIssueDetails = action.payload;
    },
    setIsShowIssueContent: (state, action) => {
      state.isShowIssueContent = action.payload;
    },
    setIsIntegrationModalOpen: (state, action) => {
      state.isIntegrationModalOpen = action.payload;
    },
    setIsRemoveIntegrationModalOpen: (state, action) => {
      state.isRemoveIntegrationModalOpen = action.payload;
    },
    setIsEditIntegrationModalOpen: (state, action) => {
      state.isEditIntegrationModalOpen = action.payload;
    },
    setIsProfileDropdownOpen: (state, action) => {
      state.isProfileDropdownOpen = action.payload;
    },
    setIsAddGitHubModalOpen: (state, action) => {
      state.isAddGithubModalOpen = action.payload;
    },
    setIsAddGitLabModalOpen: (state, action) => {
      state.isAddGitlabModalOpen = action.payload;
    },
    setIsEditGitHubLabModalOpen: (state, action) => {
      state.isEditGitHubLabModalOpen = action.payload;
    },
    setIsRemoveGitHubLabModalOpen: (state, action) => {
      state.isRemoveGitHubLabModalOpen = action.payload;
    },
    setIsEnvironmentDotsButtonOpen: (state, action) => {
      state.isEnvironmentDotsButtonOpen = action.payload;
    },
    setIsSourceDotsButtonOpen: (state, action) => {
      state.isSourceDotsButtonOpen = action.payload;
    },
    setIsGitHubLabAndTokenModalOpen: (state, action) => {
      state.isGitHubLabAndTokenModalOpen = action.payload;
    },
    setIsMemberProjectsModalOpen: (state, action) => {
      state.isMemberProjectsModalOpen = action.payload;
    },
    setAllPopUp: (state, action) => {
      const { isOpen } = action.payload;
      state.isGoogleConsoleModalOpen = isOpen;
      state.isEditProfileModalOpen = isOpen;
      state.isViewOrganizationModalOpen = isOpen;
      state.isAddOrganizationModalOpen = isOpen;
      state.isEditOrganizationModalOpen = isOpen;
      state.isEditSourceModalOpen = isOpen;
      state.isCreateProjectModalOpen = isOpen;
      state.isEditProjectModalOpen = isOpen;
      state.isDeleteProjectModalOpen = isOpen;
      state.isAddMemberModalOpen = isOpen;
      state.isOverlayVisible = isOpen;
      state.isEditMemberModalOpen = isOpen;
      state.isDeleteMemberModalOpen = isOpen;
      state.isViewAllErrorsOpen = isOpen;
      state.isShowIssueDetails = isOpen;
      state.isShowIssueContent = isOpen;
      state.isIntegrationModalOpen = isOpen;
      state.isRemoveIntegrationModalOpen = isOpen;
      state.isEditIntegrationModalOpen = isOpen;
      state.isEditEnvironmentModalOpen = isOpen;
      state.isProfileDropdownOpen = isOpen;
      state.isAddGithubModalOpen = isOpen;
      state.isAddGitlabModalOpen = isOpen;
      state.isEditGitHubLabModalOpen = isOpen;
      state.isRemoveGitHubLabModalOpen = isOpen;
      state.isAddSourceModalOpen = isOpen;
      state.isAddEnvironmentModalOpen = isOpen;
      state.isDeleteSourceModalOpen = isOpen;
      state.isEnvironmentDotsButtonOpen = isOpen;
      state.isSourceDotsButtonOpen = isOpen;
      state.isGitHubLabAndTokenModalOpen = isOpen;
      state.isMemberProjectsModalOpen = isOpen;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});
export const {
  setIsGoogleConsoleModalOpen,
  setIsOverlayVisible,
  setIsEditProfileModalOpen,
  setIsSubscriptionSuccessModalOpen,
  setIsViewOrganizationModalOpen,
  setIsAddOrganizationModalOpen,
  setIsEditOrganizationModalOpen,
  setIsAddSourceModalOpen,
  setIsEditSourceModalOpen,
  setIsDeleteSourceModalOpen,
  setIsAddEnvironmentModalOpen,
  setIsEditEnvironmentModalOpen,
  setIsAddMemberModalOpen,
  setIsEditMemberModalOpen,
  setIsDeleteMemberModalOpen,
  setIsViewAllErrorsOpen,
  setIsCreateProjectModalOpen,
  setIsEditProjectModalOpen,
  setIsDeleteProjectModalOpen,
  setIsShowIssueDetails,
  setIsShowIssueContent,
  setIsIntegrationModalOpen,
  setIsRemoveIntegrationModalOpen,
  setIsEditIntegrationModalOpen,
  setIsProfileDropdownOpen,
  setAllPopUp,
  setIsAddGitHubModalOpen,
  setIsAddGitLabModalOpen,
  setIsEditGitHubLabModalOpen,
  setIsRemoveGitHubLabModalOpen,
  setIsEnvironmentDotsButtonOpen,
  setIsSourceDotsButtonOpen,
  setIsLoading,
  setIsGitHubLabAndTokenModalOpen,
  setIsMemberProjectsModalOpen,
} = modalHandler.actions;
export default modalHandler.reducer;

import { NavLink } from "react-router-dom";

const MessageResetSuccess = () => {
  return (
    <div className="container h-[100%] flex flex-col justify-center items-center">
      <div className="flex flex-col items-center gap-[20px]">
        <img
          width={50}
          height={50}
          src="/images/Tickmark.png"
          alt="Tick Mark"
        />
        <p className="font-bold text-[30px] text-center">Success !!</p>
        <p className="text-[18px] text-center">
        Your password has been reset.
        </p>
        <div className="flex justify-center items-center">
          <button className="text-lg font-bold text-center">
            <NavLink
              to="/login"
              className="text-[#112692] font-medium text-lg md:text-base lg:text-lg"
            >
              Log In
            </NavLink>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageResetSuccess;

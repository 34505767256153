import { useEffect } from "react";

// Component that updates the title of webapp and returns the page component
export const TitleUpdater = ({
  children,
  title,
}: React.PropsWithChildren<TitleUpdaterProps>) => {
  useEffect(() => {
    document.title = `${title} | Retack AI`;
  }, [title]);

  return <>{children}</>;
};

import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { SuccessToast } from "../../toasts/SuccessToast";
import { ErrorToast } from "../../toasts/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  setIsOverlayVisible,
  setIsRemoveGitHubLabModalOpen,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";

const RemoveGitHubLabModal = ({ source_id }: RemoveGitHubLabModalProps) => {
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const gitHubLapRepo = repository.gitHubLab;
  const params = useParams();
  const projectid = params.id;
  const dispatch = useDispatch();

  const [vcsId, setVcsId] = useState<string>("");

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const closeRemoveGitHubLabModal = () => {
    dispatch(setIsRemoveGitHubLabModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  useEffect(() => {
    const source = _.find(
      sourceSlice.sourceData,
      (source) => source.id === source_id
    );
    if (source && source.integrations.github.is_connected) {
      setVcsId(source.integrations.github.id);
    } else if (source && source.integrations.gitlab.is_connected) {
      setVcsId(source.integrations.gitlab.id);
    }
  }, [sourceSlice.sourceData, source_id]);

  const onSubmit = async () => {
    try {
      const response = await gitHubLapRepo.removeGitHubLab(
        `${projectid}`,
        source_id!,
        vcsId
      );
      if (_.isUndefined(response)) {
        closeRemoveGitHubLabModal?.();
        const info: InfoLog = {
          message: "Integrations deleted.",
        };
        await customLogger.log(info);
        SuccessToast({ Message: "Successfully Deleted!" });
      } else if ("errors" in response) {
        ErrorToast({ Message: "Error occurred while removing Integrations!" });
        await customLogger.reportErrorResponse(showBoundary, response);
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-[2] bg-[#040e4104]">
        <div className="min-w-[349px] md:w-[593px] min-h-[349px] md:h-[308px] modal-container bg-white rounded-[12px] z-50 pt-[38px] px-[50px] pb-[50px]">
          <div className="modal-content flex flex-col items-center gap-[22px]">
            <div className="w-[70px] h-[70px] bg-[#FFE8E8] rounded-full flex justify-center items-center">
              <img
                className="w-[46px] h-[46px] object-contain"
                src="/icons/Exclamation.png"
                alt="add"
              />
            </div>
            <h1 className="text-[20px] font-[700] text-[#242731] w-[80%] text-center">
              Are you sure you want to remove “GitHub Integration”?
            </h1>
            <p className="text-[16px] text-[#242731]">
              This integration will be removed immediately.
            </p>
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={onSubmit}
                className="bg-[#FF6370] w-[124px] h-[40px] text-white text-[14px] rounded-[6px] px-[20px] py-[8px] flex justify-center items-center"
              >
                <span>Yes, Remove</span>
              </button>

              <button
                onClick={closeRemoveGitHubLabModal}
                className="text-[#001789] w-[87px] h-[40px] text-[14px] border border-[#001789] rounded-[6px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveGitHubLabModal;

import React, { useEffect, useState } from "react";

interface InfoToolTipProps {
  divRef: React.RefObject<HTMLDivElement>;
  messages: string[];
  bgColor :string;
  textColor :string;
}

const InfoTooltip = ({ divRef, messages,bgColor ,textColor }: InfoToolTipProps) => {
  const [isVisibleState, setIsVisibleState] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsVisibleState(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [divRef]);

  return (
    <div ref={divRef} className="relative">
      <button type="button" onClick={() => setIsVisibleState(!isVisibleState)}>
        <img
          className="w-[14px] h-[14px] ml-[10px] mt-2"
          src="/icons/Question.svg"
          alt="question logo"
        />
      </button>
      <div
        className={`${
          isVisibleState ? "block"  : "hidden"
        } absolute top-0 left-[20px] md:left-[40px] w-[150px] md:w-[210px] ${bgColor} rounded-[12px] px-[16px] py-[12px] z-10 `}
      >
         {messages.map((message, index) => (
          <React.Fragment key={index}>
            <p className={`text-[12px] font-[300] ${textColor}`}>{message}</p>
            {index !== messages.length - 1 && <hr className={`my-[10px] ${textColor}`} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InfoTooltip;

const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center h-[80vh]">
      <img
        className="w-[50x] h-[50px]"
        src="/icons/Loading.svg"
        alt="LoadingIcon"
      />
    </div>
  );
};

export default Loading;

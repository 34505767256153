import { useDispatch, useSelector } from "react-redux";
import {
  setIsMemberProjectsModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import _ from "lodash";

const MemberProjectsModel = () => {
  const dispatch = useDispatch();
  const memberSlice = useSelector((state: RootState) => state.memberSlice);

  const closeMemberProjects = () => {
    dispatch(setIsMemberProjectsModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="flex flex-col gap-6 bg-white rounded-md z-[2]  py-[32px] pl-[32px] pr-[37px]  w-[857px]">
        <h2 className="text-[28px] font-[700] text-[#242731]  ">Project Name</h2>
        <hr className="text-[#D9DCED]" />
        <div className="text-[16px] leading-[20.83px] font-normal">
          Total{" "}
          <b>
            {
              memberSlice.member_data.find(
                (item) => item.id === memberSlice.selected_member_id
              )?.projects.length
            }
          </b>{" "}
          Projects
        </div>
        <div className="scrollbar-thin overflow-y-auto flex flex-col gap-6 max-h-[300px] mb-10">
          {_.map(
            memberSlice.member_data.find(
              (item) => item.id === memberSlice.selected_member_id
            )?.projects,
            (project) => (
              <div className="flex items-center gap-3 text-[12px] font-normal leading-[16.8px]">
                <div className="flex justify-center items-center bg-[#777777]  rounded-[3px] p-[10px] text-[12px]  font-normal text-[#FFFFFF] w-[40px] h-[34.07px]  truncate">
                  {project.title
                    .split(" ")
                    .slice(0, 2)
                    .map((word) => word[0])
                    .join("")
                    .toUpperCase()}
                </div>
                {project.title}
              </div>
            )
          )}
        </div>
        <button
          className="mb-5 w-[109px] h-[38px] border border-[#001789] rounded-[6px] text-[#001789] text-[14px] hover:bg-[#001789] hover:text-white"
          onClick={closeMemberProjects}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default MemberProjectsModel;

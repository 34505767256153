import SignUpForm from "./SignUpForm";

const SignUpPage = () => {
  return (
    <section className=" bg-background grid grid-cols-1 md:grid md:grid-cols-5 md:h-screen items-center relative">
      <div className="container md:col-span-2 pt-[25px] md:pt-0 pb-[44px] md:pb-0 ">
        <div className="flex justify-start md:absolute md:top-[52px]">
          <button className="w-[150px] h-[34px] md:h-[40px] flex items-center gap-2">
            <img
              width={25}
              height={10}
              src="/icons/ArrowLeft.svg"
              alt="arrow"
            />
            <a
              className="text-[14px] font-[500] text-[#162C5B] hover:text-[#162C5B]"
              href="https://retack.ai"
            >
              Go to Home Page
            </a>
          </button>
        </div>
        <div className="mt-[10%] md:mt-0 flex flex-col items-center">
          <div className="w-[213px] md:w-[218px] h-[34px] md:h-[65px]">
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/logos/Logo.svg"
              alt="logo"
            />
          </div>
          <p className="text-[16px] md:text-[20px] mt-[18px] md:mt-[22.5px]">
            Unlock Powerful Data Analytics
          </p>
        </div>
        <div className="mt-[10%] md:mt-[55px] flex justify-center">
          <div className="w-[255px] md:w-[387px] h-[180px] md:h-[290px]">
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/logos/Frame.svg"
              alt="Analytics Logo"
            />
          </div>
        </div>
      </div>
      <SignUpForm />
    </section>
  );
};

export default SignUpPage;

import AddGitHubModal from "./GitHubLabModal/AddGitHubModal";
import EditGitHubLabModal from "./GitHubLabModal/EditGitHubLabModal";
import RemoveGitHubLabModal from "./GitHubLabModal/RemoveGitHubLabModal";
import AddGitLabModal from "./GitHubLabModal/AddGitLabModal";
import AddSourceModal from "./AddSourceModal";
import EditSourceCard from "./EditSourceModal";
import AddEnvironmentModal from "./AddEnvironmentModal";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import EditEnvironmentModal from "./EditEnvironmentModal";
import ArchiveSourceModal from "./ArchiveSourceModal";

const SourceModalOverlayHandler = () => {
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  return (
    <>
      <div>
        {modalHandler.isAddGithubModalOpen && (
          <AddGitHubModal source_id={sourceSlice.source_id} />
        )}
        {modalHandler.isEditGitHubLabModalOpen && (
          <EditGitHubLabModal source_id={sourceSlice.source_id} />
        )}
        {modalHandler.isRemoveGitHubLabModalOpen && (
          <RemoveGitHubLabModal source_id={sourceSlice.source_id} />
        )}
        {modalHandler.isAddGitlabModalOpen && (
          <AddGitLabModal source_id={sourceSlice.source_id} />
        )}
        {modalHandler.isAddSourceModalOpen && <AddSourceModal />}
        {modalHandler.isEditSourceModalOpen && <EditSourceCard />}
        {modalHandler.isAddEnvironmentModalOpen && <AddEnvironmentModal />}
        {modalHandler.isEditEnvironmentModalOpen && <EditEnvironmentModal />}
        {modalHandler.isDeleteSourceModalOpen && <ArchiveSourceModal />}
      </div>
    </>
  );
};

export default SourceModalOverlayHandler;

import { useDispatch, useSelector } from "react-redux";
import { setIsShowIssueContent } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import _ from "lodash";

interface IssueContentProps {
  clickedId: number | undefined;
}

export const IssueContent = ({ clickedId }: IssueContentProps) => {
  const dispatch = useDispatch();
  const errorSlice = useSelector((state: RootState) => state.errorSlice);
  const scrollBarStyle =
    "scrollbar-thumb-rounded-md lg:scrollbar-thin scrollbar-thumb-[#AAB2DE] scrollbar-track-light_gray";
  const errorList = _.find(
    errorSlice.error_list,
    (stacktrace) => stacktrace.id === clickedId
  );
  return (
    <div
      className="absolute z-10 flex top-0 right-0 w-full overflow-hidden h-screen"
      tabIndex={0}
    >
      <div
        data-testid="OverlayShadow"
        className="grow"
        onClick={() => dispatch(setIsShowIssueContent(false))}
      />
      <div className="flex flex-col py-[26px] px-[26px] lg:w-2/3 bg-white border-l border-[#CBCFE8]">
        <div className="flex flex-col justify-center border-b h-[114px] border-border_navbar">
          <div className="flex w-full justify-between items-center ">
            <div className="    font-bold text-[20px] leading-6 text-[#292C31] whitespace-nowrap overflow-hidden overflow-ellipsis">
              Stacktrace
            </div>
            <button>
              <img
                width={20}
                height={20}
                onClick={() => dispatch(setIsShowIssueContent(false))}
                src="/icons/Cross.svg"
                alt="Cross icon"
              />
            </button>
          </div>
          <div className="flex mt-3">
            <div className="min-w-[75px] h-[25px] rounded-[50px] bg-[#D7F7EA] px-3 py-1 flex justify-center items-center mr-4">
              <div className="font-medium text-sm text-[#30C288]">
                {errorList?.origin.source}
              </div>
            </div>
            <div className="min-w-[75px] h-[25px] rounded-[50px] border-[0.5px] border-[#4F5A69] px-3 py-1 flex justify-center items-center">
              <div className="font-medium text-sm text-[#4F5A69]">
                {errorList?.origin.environment}
              </div>
            </div>
          </div>
        </div>
        <div
          className={`bg-[#F5F7F9] mt-6 px-[22px] py-[13px] h-[100%] overflow-y-auto ${scrollBarStyle}`}
        >
          <p className="text-[#344563] text-[14px] leading-5">
            {errorList?.stack_trace}
          </p>
        </div>
      </div>
    </div>
  );
};

import { useDispatch } from "react-redux";
import CustomToolTip from "../CustomToolTip";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchCurrentMember } from "@src/actions/fetchCurrentMember";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useErrorBoundary } from "react-error-boundary";

export const CreateNewProjectCard = ({
  openCreateProjectModal,
}: ProjectCardProps) => {
  const params = useParams();
  const ParamsOrgId = params.orgId;

  const repository = useContext(RepositoryContext);
  const memberRepo = repository.member;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const dispatch = useDispatch();

  const [role, setrole] = useState("");

  useEffect(() => {
    const fetchMemberRole = async () => {
      if (parseInt(ParamsOrgId!) !== 0) {
        try {
          const currentMember = await fetchCurrentMember(
            memberRepo,
            ParamsOrgId,
            showBoundary,
            dispatch,
            customLogger
          );
          setrole(currentMember?.role!);
        } catch (error) {
          showBoundary(error);
          customLogger.reportError(error);
        }
      }
    };
    fetchMemberRole();
  }, [customLogger, dispatch, memberRepo, ParamsOrgId, showBoundary]);

  return (
    <>
      {role === "Basic" ? (
        <CustomToolTip text="Not allowed for Basic User">
          <section className="w-full md:w-[240px] h-[160px] border border-border_gray bg-bg_gray rounded-lg hover:shadow-light z-[1] opacity-50 cursor-not-allowed">
            <div className="flex flex-col w-full h-full items-center justify-center gap-2 ">
              <img
                src="/icons/BluePlus.svg"
                alt="BluePlusIcon"
                className="w-[60px]"
              />
              <h1 className="text-sm text-text_blue font-[500]">
                Create New Project
              </h1>
            </div>
          </section>
        </CustomToolTip>
      ) : (
        <section
          onClick={openCreateProjectModal}
          className="w-full md:w-[240px] h-[160px] border border-border_gray bg-bg_gray rounded-lg hover:shadow-light z-[1]"
        >
          <div className="flex flex-col w-full h-full items-center justify-center gap-2 cursor-pointer">
            <img
              src="/icons/BluePlus.svg"
              alt="BluePlusIcon"
              className="w-[60px]"
            />
            <h1 className="text-sm text-text_blue font-[500]">
              Create New Project
            </h1>
          </div>
        </section>
      )}
    </>
  );
};

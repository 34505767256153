const SkeletonCardLoader = () => {
  return (
    <div className="flex items-center">
      <div className="flex flex-wrap justify-start gap-[28px] md:gap-[24px] 2xl:gap-[50px]">
        <section className="w-[100%] md:w-[240px] h-[160px] px-3 border border-border_gray bg-white py-[16px] flex flex-col items-start justify-center gap-[10px] rounded-[5px] animate-pulse hover:shadow-light">
          <div className="text-text_blue font-[500] w-[80%] h-[16px] bg-[#F3F5F8] rounded-[3px] mb-[8px]" />
          <div className="text-text_dark_gray font-[400] w-[90%] h-[16px] bg-[#F3F5F8] rounded-[3px] mb-[8px]" />
          <div className="grow" />
          <div className="text-text_dark_gray text-[12px] font-[400] w-[70%] h-[12px] bg-[#F3F5F8] rounded-[3px]" />
        </section>
      </div>
    </div>
  );
};

export default SkeletonCardLoader;

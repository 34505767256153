import {
  setIsAddGitHubModalOpen,
  setIsAddGitLabModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { setSourceId } from "@src/redux/feature/sourceSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import GitHubLabStateCard from "./GitHubLabStateCard";
import _ from "lodash";
import CustomToolTip from "../CustomToolTip";

interface GitHubLabCardProps {
  source_id: number;
}

const GitHubLabCard = ({ source_id }: GitHubLabCardProps) => {
  const dispatch = useDispatch();
  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const role = memberSlice.current_member?.role;
  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const openGitHubModal = () => {
    dispatch(setIsAddGitHubModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setSourceId(source_id));
  };

  const openGitLabModal = () => {
    dispatch(setIsAddGitLabModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setSourceId(source_id));
  };

  const source = _.find(
    sourceSlice.sourceData,
    (source) => source.id === source_id
  );

  return (
    <div>
      {source?.integrations.github.is_connected ||
      source?.integrations.gitlab.is_connected ? (
        <GitHubLabStateCard source_id={source_id} />
      ) : (
        <div className="mt-[14px] bg-[#F6F9FF] py-[24px] px-[34px] rounded-[6px]">
          <h1 className="text-[18px] font-bold text-[#162C5B]">
            Connect with Git Providers
          </h1>
          <p className="mt-4">
            Connect your GitHub or GitLab code repository to receive code fix
            merge / pull merge requests from Retack AI.
          </p>
          <div className="flex gap-[32px] mt-6 text-[#172B4D] text-[20px] font-medium">
            {role === "Basic" && (
              <>
                <CustomToolTip text="Feature avaiable for Editors and Owners only">
                  <button
                    disabled
                    className="bg-[#EBEDF7] border border-[#E1E5F9] w-[166px] h-[66px] py-[18px] px-[30px] rounded-[5px] flex items-center gap-3 cursor-not-allowed"
                  >
                    <img src="/icons/Github.svg" alt="github" />
                    <p>GitHub</p>
                  </button>
                </CustomToolTip>
                <CustomToolTip text="Feature avaiable for Editors and Owners only">
                  <button
                    disabled
                    className="bg-[#EBEDF7] border border-[#E1E5F9] w-[166px] h-[66px] py-[18px] px-[30px] rounded-[5px] flex items-center gap-3 cursor-not-allowed"
                  >
                    <img src="/icons/Gitlab.svg" alt="gitlab" />
                    <p>GitLab</p>
                  </button>
                </CustomToolTip>
              </>
            )}
            {role !== "Basic" && (
              <>
                <button
                  onClick={openGitHubModal}
                  className="bg-[#EBEDF7] border border-[#E1E5F9] w-[166px] h-[66px] py-[18px] px-[30px] rounded-[5px] flex items-center gap-3"
                >
                  <img src="/icons/Github.svg" alt="github" />
                  <p>GitHub</p>
                </button>
                <button
                  onClick={openGitLabModal}
                  className="bg-[#EBEDF7] border border-[#E1E5F9] w-[166px] h-[66px] py-[18px] px-[30px] rounded-[5px] flex items-center gap-3"
                >
                  <img src="/icons/Gitlab.svg" alt="gitlab" />
                  <p>GitLab</p>
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GitHubLabCard;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Implementation of the IErrorReportingRepo interface
export class ErrorReportingRepo {
    // Private constructor for singleton pattern
    constructor(api, apiKey) {
        this._Api = api;
        this._ErrorApiKey = apiKey;
    }
    // Singleton pattern: Ensures only one instance is created
    static getInstance(api, apiKey) {
        if (!ErrorReportingRepo.instance) {
            ErrorReportingRepo.instance = new ErrorReportingRepo(api, apiKey);
        }
        return ErrorReportingRepo.instance;
    }
    // Implementation of the reportError method from IErrorReportingRepo interface
    reportError(errorLog) {
        return __awaiter(this, void 0, void 0, function* () {
            // Delegate the error reporting to the logError action
            // Create headers for the error reporting request
            const reportErrorHeaders = new Map();
            reportErrorHeaders.set("ERROR-API-KEY", this._ErrorApiKey);
            reportErrorHeaders.set("Content-Type", "application/json");
            // Call the post method on the provided API to report the error
            yield this._Api.post(reportErrorHeaders, errorLog);
        });
    }
}

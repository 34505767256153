import _ from "lodash";
import { IJsonAPI, JsonAPIError, JsonAPIResp } from "./types";
import { RepositoryURL } from "@repositories/constants.enum";
import { IStorageClient } from "../storage/types";
import { buildQuery } from "@actions/buildQuery";

export class BackendAPI implements IJsonAPI {
  // Access base url from env file
  private _BASE_URL: string;
  // Declare local storage clien
  private localStorageClient: IStorageClient;

  // Define the constructor
  constructor(BaseURL: string, localStorageClient: IStorageClient) {
    this._BASE_URL = BaseURL;
    this.localStorageClient = localStorageClient;
  }

  // Returns the Headers array from key value pairs
  private _buildHeaders(headers: Map<string, string>): Headers {
    const _headers = new Headers();
    Array.from(headers).map(([key, value]) => {
      _headers.append(key, value);
    });
    return _headers;
  }
  // Parse the API response
  private async _parseResponse<T>(response: Response): Promise<T> {
    if (response.status === 204) {
      return undefined as T;
    }
    const responseJson = response.json() as T;
    return responseJson;
  }

  private refreshTokenPromise: Promise<void> | null = null;

  private async refreshToken(): Promise<void> {
    if (this.refreshTokenPromise !== null) {
      return this.refreshTokenPromise;
    }
    this.refreshTokenPromise = (async () => {
      try {
        const refreshToken = await this.localStorageClient.getRefreshToken();
        if (refreshToken) {
          const body = { refresh_token: `token ${refreshToken}` };
          const headers = new Map<string, string>();
          headers.set("Content-Type", "application/json");
          const response = await this.post<TokenResponse, RefreshTokenRequest>(
            RepositoryURL.REFRESH_TOKEN_URL,
            body,
            headers,
            false
          );
          if ("data" in response) {
            await this.localStorageClient.setAccessToken(
              response.data.access_token
            );
          } else {
            throw new Error("Token refresh failed");
          }
        }
      } catch (error) {
        // Handle errors
      } finally {
        this.refreshTokenPromise = null;
      }
    })();

    // Wait for the token refresh promise to resolve or reject
    await this.refreshTokenPromise;
  }

  // Makes an API call with provided request parameters
  private async _request<T, U>(
    path: string,
    headers: Map<string, string>,
    method: string,
    isAuthorized: boolean,
    queryParameters: Map<string, unknown>,
    body?: U
  ): Promise<JsonAPIResp<T>> {
    // Create a Headers array for the request
    const _headers = this._buildHeaders(headers);

    // Add authorization header if necessary
    if (isAuthorized) {
      const accessToken = await this.localStorageClient.getAccessToken();
      _headers.append("Authorization", `Bearer ${accessToken}`);
    }
    // Configure options for the request
    const requestOption: RequestInit = {
      method: method,
      headers: _headers,
    };
    // Add body to request options if it's not undefined
    if (!_.isUndefined(body)) {
      requestOption.body = JSON.stringify(body);
    }

    // Build query parameters from argument map if not empty
    const _query: string = !_.isEqual(queryParameters.size, 0)
      ? buildQuery(queryParameters)
      : "";

    try {
      // Make the request to the API
      const url = _query
        ? `${this._BASE_URL}/${path}?${_query}`
        : `${this._BASE_URL}/${path}`;
      const response = await fetch(url, requestOption);
      if (response.status === 500) {
        const jsonError: JsonAPIError = {
          title: "Fetch",
          status: "500",
          message: "Internal Server Error",
        };
        return {
          errors: [jsonError],
        };
      } else if (response.status === 403) {
        // Refresh the token
        await this.refreshToken();
        const newAccessToken = await this.localStorageClient.getAccessToken();
        // Retry the original request with the new access token
        if (newAccessToken) {
          _headers.set("Authorization", `Bearer ${newAccessToken}`);
          requestOption.headers = _headers;
          const refreshedResponse = await fetch(
            `${this._BASE_URL}/${path}`,
            requestOption
          );
          const refreshTokenResponse = await this._parseResponse<
            JsonAPIResp<T>
          >(refreshedResponse);
          return refreshTokenResponse;
        }
      } else if (response.status === 401) {
        const error: JsonAPIError = {
          title: "Fetch",
          status: "401",
          message: "Your session has expired.",
        };
        await this.localStorageClient.clearTokens();
        await localStorage.removeItem("orgId")
        window.location.replace(`login?error=${encodeURIComponent(error.message)}`);
      }
      const apiResponse = await this._parseResponse<JsonAPIResp<T>>(response);
      return apiResponse;
    } catch (error) {
      const jsonError: JsonAPIError = {
        title: "Fetch",
        status: "Unknown",
        message: "Unresolved error occurred",
      };
      return {
        errors: [jsonError],
      };
    }
  }

  // Implementation of IJsonAPI interface
  get<T>(
    path: string,
    queryParameters: Map<string, unknown> = new Map(),
    headers: Map<string, string> = new Map(),
    isAuthorized: boolean = true
  ): Promise<JsonAPIResp<T>> {
    return this._request(path, headers, "GET", isAuthorized, queryParameters);
  }
  post<T, U>(
    path: string,
    body: U,
    headers: Map<string, string> = new Map(),
    isAuthorized: boolean = true,
    queryParameters: Map<string, unknown> = new Map()
  ): Promise<JsonAPIResp<T>> {
    return this._request<T, U>(
      path,
      headers,
      "POST",
      isAuthorized,
      queryParameters,
      body
    );
  }
  put<T, U>(
    path: string,
    body: U,
    headers: Map<string, string> = new Map(),
    isAuthorized: boolean = true,
    queryParameters: Map<string, unknown> = new Map()
  ): Promise<JsonAPIResp<T>> {
    return this._request<T, U>(
      path,
      headers,
      "PUT",
      isAuthorized,
      queryParameters,
      body
    );
  }
  delete(
    path: string,
    headers: Map<string, string> = new Map(),
    isAuthorized: boolean = true,
    queryParameters: Map<string, unknown> = new Map()
  ): Promise<JsonAPIResp<undefined>> {
    return this._request(
      path,
      headers,
      "DELETE",
      isAuthorized,
      queryParameters
    );
  }
}

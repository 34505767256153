import { SuccessToast } from "@sharedComponents/toasts/SuccessToast";

const CopyButton = ({ text }: CopyButtonProps) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    SuccessToast({ Message: "Copied!" });
  };

  return (
    <button className="w-[20px] h-[20px]" onClick={handleCopy}>
      <img
        className="w-[100%] h-[100%] object-contain"
        src="/icons/Copy.svg"
        alt="copy"
      />
    </button>
  );
};

export default CopyButton;

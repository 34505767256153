import { useState, ReactNode } from "react";

interface CustomToolTipProps {
  children: ReactNode;
  wrap?: "nowrap" | "break";
  width?: string;
  text?: string;
}

const CustomToolTip = ({
  children,
  wrap = "nowrap",
  text = "Not available for demo project",
  width = "w-auto",
}: CustomToolTipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`relative inline-block ${width}`}
    >
      {children}
      {showTooltip && (
        <div
          className={`absolute top-full left-1/2 transform -translate-x-1/2 mt-1 bg-black text-white text-sm p-2 rounded shadow-lg z-50 ${
            wrap === "nowrap" ? "whitespace-nowrap" : "break-words"
          }`}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default CustomToolTip;

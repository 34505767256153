var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class AppObserverAPI {
    // Private constructor for singleton pattern
    constructor(ErrorReportingUrl) {
        this._ErrorReportingUrl = ErrorReportingUrl;
    }
    // Singleton pattern: Ensures only one instance is created
    static getInstance(ErrorReportingUrl) {
        if (!AppObserverAPI.instance) {
            AppObserverAPI.instance = new AppObserverAPI(ErrorReportingUrl);
        }
        return AppObserverAPI.instance;
    }
    // Private method to build Headers from the provided map
    _buildHeaders(headers) {
        const _headers = new Headers();
        if (headers) {
            Array.from(headers).map(([key, value]) => {
                _headers.set(key, value);
            });
        }
        return _headers;
    }
    // Implementation of the post method from IAppObserverApi interface
    post(headers, body) {
        return __awaiter(this, void 0, void 0, function* () {
            // Build headers using the private _buildHeaders method
            const _headers = this._buildHeaders(headers);
            // Construct the request options
            const requestOptions = {
                method: "POST",
                headers: _headers,
                body: body ? JSON.stringify(body) : undefined,
            };
            try {
                // Make the POST request using fetch
                const response = yield fetch(this._ErrorReportingUrl, requestOptions);
                // Parse and return the JSON response
                return yield response.json();
            }
            catch (error) {
                // Handle errors, or simply return without doing anything
                return;
            }
        });
    }
}

import { useParams } from "react-router-dom";
import { useContext } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import { ErrorToast } from "../toasts/ErrorToast";
import _ from "lodash";
import { SuccessToast } from "../toasts/SuccessToast";
import { useErrorBoundary } from "react-error-boundary";
import {
  setIsDeleteSourceModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";

const ArchiveSourceModal = () => {
  const id = useParams();
  const projectId = id.id;

  const dispatch = useDispatch<AppDispatch>();

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const sourcesRepo = repository.source;

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const sourceId = sourceSlice.source_id;

  const findSource = () => {
    let foundSource = _.find(
      sourceSlice.sourceData,
      (source) => source.id === sourceId
    );

    if (!foundSource) {
      foundSource = _.find(
        sourceSlice.archivedSourceData,
        (source) => source.id === sourceId
      );
    }

    return foundSource;
  };

  const source = findSource();

  const closeDeleteSourceModal = () => {
    dispatch(setIsDeleteSourceModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const onSubmit = async () => {
    try {
      const response = await sourcesRepo.deleteSource(projectId!, sourceId);
      if (_.isUndefined(response)) {
        closeDeleteSourceModal();

        const info: InfoLog = {
          message: "Source Archived.",
        };
        await customLogger.log(info);
        dispatch(setIsDeleteSourceModalOpen(false));
        dispatch(setIsOverlayVisible(false));
        SuccessToast({ Message: "Successful!" });
      } else if ("errors" in response) {
        ErrorToast({ Message: "Error occurred!" });

        await customLogger.reportErrorResponse(showBoundary, response);
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-[2] bg-[#040e4104]">
        <div className="min-w-[349px] md:w-[593px] min-h-[349px] md:h-[308px] modal-container bg-white rounded-[12px] z-50 pt-[38px] px-[50px] pb-[50px]">
          <div className="modal-content flex flex-col items-center gap-[22px]">
            <div className="w-[70px] h-[70px] bg-[#FFE8E8] rounded-full flex justify-center items-center">
              <img
                className="w-[46px] h-[46px] object-contain"
                src="/icons/Exclamation.png"
                alt="add"
              />
            </div>
            <h1 className="text-[20px] font-[700] text-[#242731] w-[80%] text-center">
              Are you sure you want to{" "}
              {source?.is_archived ? "unarchive" : "archive"} "{source?.name}"?
            </h1>
            <p className="text-[16px] text-[#242731]">
              This source will be{" "}
              {source?.is_archived ? "unarchived" : "archived"} immediately.
            </p>

            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={onSubmit}
                className="bg-[#FF6370] w-[124px] h-[40px] text-white text-[14px] rounded-[6px] px-[20px] py-[8px] flex justify-center items-center"
              >
                <span>Yes,{source?.is_archived ? "Unarchive" : "Archive"}</span>
              </button>

              <button
                onClick={closeDeleteSourceModal}
                className="text-[#001789] w-[87px] h-[40px] text-[14px] border border-[#001789] rounded-[6px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArchiveSourceModal;

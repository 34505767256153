import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState: AuthSliceProps = {
  authTokens: null,
};

// Create a slice using createSlice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens: (state, action) => {
      state.authTokens = action.payload;
    },
    clearAuthTokens: (state) => {
      state.authTokens = null;
    },
  },
});

// Export the action creator
export const { setAuthTokens, clearAuthTokens } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;

interface SourceEnvironmentUserBadgeProps {
  source: string;
  environment: string;
  user_count?: number;
}

const SourceEnvironmentUserBadge = ({
  source,
  environment,
  user_count,
}: SourceEnvironmentUserBadgeProps) => {
  return (
    <div className="flex mt-2 gap-3">
      <div className="rounded-[50px] bg-[#D7F7EA] px-[13px] py-[3px] flex items-center justify-center ">
        <div className=" text-[#30C288] text-[14px] font-bold leading-[23px]">
          {source}
        </div>
      </div>
      <div className="rounded-[50px] border border-[#4F5A69] px-[13px] py-[3px] flex items-center justify-center">
        <div className="font-normal text-[#4F5A69] text-[14px] leading-[23px]">
          {environment}
        </div>
      </div>
      {user_count === undefined ? null : (
        <div className="flex items-center text-xs font-normal leading-5 text-[#4F5A69] w-[70px] gap-2 whitespace-nowrap">
          <img className="h-[12px]" src="/icons/Users.svg" alt="UsersIcon" />
          {user_count} {user_count === 1 ? 'User' : 'Users'}
        </div>
      )}
    </div>
  );
};

export default SourceEnvironmentUserBadge;

import ArchivedSourceCard from "@src/components/sharedComponents/card/ArchivedSourceCard";
import SourceCard from "@src/components/sharedComponents/card/SourceCard";
import SourceModalOverlayHandler from "@src/components/sharedComponents/modal/SourceModalOverlayHandler ";
import {
  setIsAddSourceModalOpen,
  setIsEnvironmentDotsButtonOpen,
  setIsLoading,
  setIsSourceDotsButtonOpen,
} from "@src/redux/feature/modalHandler";
import { setSourceData } from "@src/redux/feature/sourceSlice";
import { AppDispatch, RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Sources = () => {
  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);
  const environmentSlice = useSelector(
    (state: RootState) => state.environmentSlice
  );
  const [archieveUpdated, setArchieve] = useState<boolean>(false);

  const role = memberSlice.current_member?.role;

  const dispatch = useDispatch<AppDispatch>();

  const openAddSourceModal = () => {
    dispatch(setIsAddSourceModalOpen(true));
  };

  const repository = useContext(RepositoryContext);
  const sourceRepo = repository.source;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const params = useParams();
  const projectid = params.id;

  const handleClick = () => {
    dispatch(setIsEnvironmentDotsButtonOpen(false));
    dispatch(setIsSourceDotsButtonOpen(false));
  };

  document.addEventListener("click", handleClick);

  useEffect(() => {
    const fetchSource = async () => {
      setIsLoading(true);
      try {
        const sourceList = await sourceRepo.getSources<SourceData[]>(
          `${projectid}`
        );
        if (!_.isUndefined(sourceList)) {
          if ("errors" in sourceList) {
            await customLogger.reportErrorResponse(showBoundary, sourceList);
          } else {
            dispatch(setSourceData(sourceList));
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSource();
  }, [
    customLogger,
    dispatch,
    showBoundary,
    modalHandler.isAddSourceModalOpen,
    modalHandler.isDeleteSourceModalOpen,
    modalHandler.isEditSourceModalOpen,
    modalHandler.isAddGithubModalOpen,
    modalHandler.isAddGitlabModalOpen,
    modalHandler.isEditGitHubLabModalOpen,
    modalHandler.isRemoveGitHubLabModalOpen,
    modalHandler.isOverlayVisible,
    modalHandler.isEditEnvironmentModalOpen,
    environmentSlice.currentEnvironmentKey,
    environmentSlice.deleteEnvKey,
    projectid,
    sourceRepo,
    archieveUpdated,
  ]);

  const renderedSourceCards = _.map(sourceSlice.sourceData, (source) => (
    <SourceCard
      key={source.id}
      source_id={source.id}
      source_description={source.description}
      source_name={source.name}
      environments={source.environments}
      toggleArchive={() => setArchieve(!archieveUpdated)}
    />
  ));

  const renderedArchivedSourceCard = _.map(
    sourceSlice.archivedSourceData,
    (source) => (
      <ArchivedSourceCard
        key={source.id}
        source_id={source.id}
        source_description={source.description}
        source_name={source.name}
        environments={source.environments}
        toggleArchive={() => setArchieve(!archieveUpdated)}
      />
    )
  );

  return (
    <>
      {modalHandler.isLoading ? (
        <div className="flex w-full h-[50vh] items-center justify-center">
          <img
            className="w-[50px]"
            src="/icons/Loading.svg"
            alt="LoadingIcon"
          />
        </div>
      ) : (
        <>
          <div className="p-[25px] md:p-[36px] max-h-[90vh] mb-[100px]">
            {!modalHandler.isAddSourceModalOpen && (
              <>
                {(renderedSourceCards.length > 0 ||
                  renderedArchivedSourceCard.length > 0) && (
                  <>
                    <div className="flex items-center justify-between mb-[14px] md:mb-[34px]">
                      <h2 className="text-[20px] text-[#172B4D] md:text-[28px] font-[700] ">
                        Sources
                      </h2>
                      <button
                        onClick={openAddSourceModal}
                        disabled={role === "Basic"}
                        className={`w-[109px] h-[38px] border border-[#001789] rounded-[6px] text-[#001789] text-[14px] hover:bg-[#001789] hover:text-white ${
                          role === "Basic"
                            ? "opacity-50 cursor-not-allowed"
                            : ""
                        }`}
                      >
                        <strong>+</strong> Add New
                      </button>
                    </div>
                    <div className="mb-6 underline">
                      <a
                        href="https://retack.ai/docs/post-error/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Learn to report & send Issues from your application to
                        Retack AI
                      </a>
                    </div>
                  </>
                )}
                <div className="flex flex-col">{renderedSourceCards}</div>
                <div className="flex flex-col">
                  {renderedArchivedSourceCard}
                </div>
                {renderedSourceCards.length === 0 &&
                  renderedArchivedSourceCard.length === 0 && (
                    <div className="flex flex-row items-center">
                      <div className="flex flex-col w-[50%] gap-9">
                        <h1 className="text-[#172B4D] font-[700] text-3xl">
                          We're waiting to receive an error from your
                          application
                        </h1>
                        <p className="w-[70%] text-[14px] tracking-[0.5px] leading-[18px]">
                          Say goodbye to manual debugging and hello to
                          streamlined development processes. Lets get started by
                          adding source
                        </p>

                        <button
                          onClick={openAddSourceModal}
                          disabled={role === "Basic"}
                          className={`w-[109px] h-[38px] border border-[#001789] rounded-[6px] text-white text-[14px] bg-[#001789] ${
                            role === "Basic"
                              ? "opacity-30 cursor-not-allowed"
                              : ""
                          }`}
                        >
                          <strong> Add Source</strong>
                        </button>
                      </div>
                      <div className="flex justify-center items-center">
                        <img
                          src="/images/NoSources.png"
                          alt="no source image"
                          style={{ width: "361px", height: "260px" }}
                        />
                      </div>
                    </div>
                  )}
              </>
            )}
            <SourceModalOverlayHandler />
          </div>
        </>
      )}
    </>
  );
};
export default Sources;

import { useState, useEffect } from "react";
import _ from "lodash";
import { SuccessToast } from "@sharedComponents/toasts/SuccessToast";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import FixIssueStep from "@src/utils/FixIssueSteps.json";
import { useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import { getLocalStorageClient } from "@src/shared/contexts";

interface StepData {
  title: string;
  detail: string;
  success: boolean;
}

export const IssueResolutionPage = () => {
  const SOCKET_URL = import.meta.env.VITE_SOCKET_URL;
  const [processSteps, setProcessSteps] = useState(() =>
    _.cloneDeep(FixIssueStep)
  );
  const [taskStatus, setTaskStatus] = useState<string>("In Queue");
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const issueFixSlice = useSelector((state: RootState) => state.issueFixSlice);
  const issueStatusUuid = issueFixSlice.issue_status_uuid;
  const localStorageClient = getLocalStorageClient();

  useEffect(() => {
    if (issueStatusUuid !== undefined && issueStatusUuid !== "") {
      sessionStorage.setItem("uuid", issueStatusUuid);
    }

    let socket: WebSocket | null = null;
    let uuidTimeout: number | undefined;

    const connectWebSocket = async (uuid: string) => {
      const token = await localStorageClient.getAccessToken();
      socket = new WebSocket(`${SOCKET_URL}${uuid}/token=${token}/`);
      socket.addEventListener("message", (event) => {
        setProcessSteps(_.cloneDeep(FixIssueStep));
        const newData = JSON.parse(event.data).data;
        newData.forEach((stepData: StepData) => {
          const { title, detail, success } = stepData;

          // Find the index of the step with matching title
          const index = processSteps.findIndex(
            (step) =>
              step[1].trim().toLowerCase() === title.trim().toLowerCase()
          );
          if (index !== -1) {
            const updatedSteps = [...processSteps];
            updatedSteps[index][2] = success.toString();
            updatedSteps[index][0] =
              success.toString() === "false" ? "CrossErrorIcon" : "RunningIcon";
            updatedSteps[index][3] = detail;
            setProcessSteps(updatedSteps);
          }
          if (success.toString() === "false") {
            setTaskStatus("Not Fixed");
            socket?.close();
          } else {
            setTaskStatus("Running");
          }

          if (index === processSteps.length - 1) {
            setTaskStatus("Fixed");
            socket?.close();
          }
          socket?.addEventListener("close", () => {
            if (success.toString() === "false") {
              ErrorToast({ Message: `Error in step "${title}"` });
            } else {
              if (index === 5) {
                setExpandedRows([...expandedRows, index]);
                SuccessToast({
                  Message:
                    "Your issue is resolved. Please check the repo for the new PR.",
                });
              }
            }
          });
        });
      });

      return () => {
        socket?.close();
      };
    };

    if (issueStatusUuid !== undefined && issueStatusUuid !== "") {
      uuidTimeout = window.setTimeout(() => {
        connectWebSocket(issueStatusUuid);
      }, 2000); // Delay for 2 seconds
    } else {
      const savedUuid = sessionStorage.getItem("uuid");
      if (savedUuid) {
        uuidTimeout = window.setTimeout(() => {
          connectWebSocket(savedUuid);
        }, 2000); // Delay for 2 seconds
      }
    }

    return () => {
      if (socket) {
        socket.close();
      }
      if (uuidTimeout !== undefined) {
        clearTimeout(uuidTimeout);
      }
    };
  }, [issueStatusUuid]);

  const handleRowClick = (index: number) => {
    // Toggle the expansion state of the clicked row
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        // If the index is already in the expanded rows, remove it
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        // If the index is not in the expanded rows, add it
        return [...prevExpandedRows, index];
      }
    });
  };

  return (
    <>
      <div className="flex flex-col ms-9 w-[95%]">
        <div className="flex justify-start items-center gap-4 my-8">
          <div className="font-medium">Task Status:</div>
          <div className="border border-[#D9D9D9] px-11 py-2 rounded">
            {taskStatus}
          </div>
        </div>
        <div className="flex flex-col border border-[#D9D9D9] rounded mb-10">
          <div className="flex justify-between items-center p-7">
            <div className="text-lg">Progress Status</div>
            <div className="flex gap-3">
              <div className="flex gap-2">
                <img src="/icons/RunningIcon.svg" alt="RunningIcon" />
                Running
              </div>
              <div className="flex gap-2">
                <img src="/icons/CrossErrorIcon.svg" alt="CrossErrorIcon" />
                Error
              </div>
              <div className="flex gap-2">
                <img src="/icons/InQueueIcon.svg" alt="InQueueIcon" />
                In Queue
              </div>
            </div>
          </div>

          {_.map(processSteps, (step, index) => (
            <>
              <div
                key={index}
                className={`flex justify-between items-center mb-2 py-2 px-4 ${
                  step[0] === "RunningIcon"
                    ? "bg-[#d6fdcc]"
                    : step[0] === "CrossErrorIcon"
                    ? "bg-[#FFF1F1]"
                    : "bg-[#F1F5FF]"
                }`}
                onClick={() => handleRowClick(index)}
              >
                <div className="flex gap-2">
                  <img src={`/icons/${step[0]}.svg`} alt="status" />
                  <div className="flex">{step[1]}</div>
                </div>
                <div>
                  <img
                    src={`/icons/DownPointer.svg`}
                    alt="DownPointer"
                    className={`transform transition-transform ${
                      expandedRows.includes(index) ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>

              {expandedRows.includes(index) && (
                <div className="flex justify-between ps-[50px] pe-[50px] py-4">
                  {index !== processSteps.length - 1 && <>{step[3]}</>}
                  {index === processSteps.length - 1 &&
                    step[2] !== "false" &&
                    (step[3] === "queued" ? (
                      step[3]
                    ) : (
                      <a
                        href={`${step[3]}`}
                        target="_blank"
                        className="text-[#001789]"
                      >
                        Link to Code Fix Pull Request
                      </a>
                    ))}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

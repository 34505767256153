import { AnyAction } from "@reduxjs/toolkit";
import { setUserData } from "@feature/profileSlice";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import { ProfileRepository } from "@repositories/ProfileRepository";
import _ from "lodash";
import { Dispatch } from "react";
import { parseRepositoryResponse } from "./parseRepositoryResponse";

export const fetchUserData = async (
  profileRepo: ProfileRepository,
  showBoundary: (error: unknown) => void,
  dispatch: Dispatch<AnyAction>,
  customLogger: CustomLogger
) => {
  const repoResponse = await profileRepo.getProfile<ProfileData>();
  const profile = await parseRepositoryResponse(
    repoResponse,
    customLogger,
    showBoundary
  );
  if (!_.isUndefined(profile)) {
    dispatch(setUserData(profile));
  }
};

import { JsonAPIErrorResp } from "@clients/api/types";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import _ from "lodash";

/**
* Parses the response data or error returned from a repository.
*
* @param {any} response The response data or error returned from a repository.
* @param {CustomLogger} customLogger An instance of CustomLogger used for logging errors.
* @param {Function} showBoundary A callback function used to handle and display errors.
* @returns {Promise<any | undefined>} A promise resolving to the response data or undefined.
*/

export const parseRepositoryResponse = async <T>(
  response: T | JsonAPIErrorResp | undefined,
  customLogger: CustomLogger,
  showBoundary: (error: unknown) => void
): Promise<T | undefined> => {
  if (!_.isUndefined(response)) {
    if (
      typeof response === "object" &&
      !_.isNull(response) &&
      "errors" in response
    ) {
      await customLogger.reportErrorResponse(showBoundary, response);
    } else return response;
  }
};

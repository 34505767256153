import CopyButton from "../buttons/CopyButton";
import DotsButton from "../buttons/DotsButton";
import EnvironmentDotsButton from "../buttons/EnvironmentDotsButton";
import DeleteEnvironmentKeyButton from "../buttons/DeleteEnvironmentKeyButton";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { AppDispatch, RootState } from "@redux/store";
import { setSourceId } from "@feature/sourceSlice";
import { useErrorBoundary } from "react-error-boundary";
import _ from "lodash";
import { setCurrentEnvironmentKey } from "@src/redux/feature/environmentSlice";
import { parseRepositoryResponse } from "@src/actions/parseRepositoryResponse";
import GitHubLabCard from "./GitHubLabCard";
import {
  setIsAddEnvironmentModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";

/**
 * Renders a card displaying information about a source, including its name, environments, and associated actions.
 *
 * @component
 * @param {Object} props - The props passed to the SourceCard component.
 * @param {number} props.source_id - The unique identifier for the source.
 * @param {string} props.source_name - The name of the source.
 * @param {Array<Object>} props.environments - An array of environment data associated with the source.
 * @param {string} props.buttonName - The name of the button.
 * @param {string} props.createdDate - The date when the source was created.
 * @param {string} props.lastUsedDate - The date when the source was last used.
 * @param {Function} props.updateKey - A function to update the key.
 * @param {Function} props.toggleArchive - A function to toggle the archive status of the source.
 * @param {Function} props.setEditSourceModalOpen - A function to set the visibility of the edit source modal.
 * @param {Function} props.setTargetSourceData - A function to set the target source data.
 */
const SourceCard = ({
  source_id,
  source_name,
  source_description,
  environments,
  toggleArchive,
}: SourceCardProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const role = memberSlice.current_member?.role;

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const sourceRepo = repository.source;

  const openAddEnvironmentModal = () => {
    dispatch(setIsAddEnvironmentModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setSourceId(source_id));
  };

  const toggleEnvironmentKey = async (keyid: number, is_active: boolean) => {
    try {
      const environmentKeyArchiveStatus: EnvironmentKeyArchiveStatusProps = {
        is_active: !is_active,
      };
      const response =
        await sourceRepo.toggleEnvironmentKey<EnvironmentKeyData>(
          keyid,
          environmentKeyArchiveStatus
        );
      dispatch(setCurrentEnvironmentKey(response));
      await parseRepositoryResponse(response, customLogger, showBoundary);
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  const generateEnvironmentKey = async (environment_id: number) => {
    try {
      const response =
        await sourceRepo.generateEnvironmentKey<EnvironmentKeyData>(
          environment_id
        );
      await parseRepositoryResponse(response, customLogger, showBoundary);
      dispatch(setCurrentEnvironmentKey(response));
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="w-[100] rounded-[12px] border border-[#D9DCED] bg-white px-[30px] py-[23px] flex flex-col justify-center mb-[60px]">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 flex-row items-end font-[500] text-[#172B4D]">
          <img src="/icons/Device.svg" alt="Device Icon" className="w-[28px]" />
          {source_name}
        </div>

        {role !== "Basic" && <DotsButton sourceId={source_id} />}
      </div>
      <p className="mt-2">{source_description}</p>
      <div className="mt-[18px]  rounded-[8px] border border-[#D9DCED]">
        <table className=" w-full ">
          <thead className="text-left bg-[#F5F6FA]">
            <tr>
              <th className="rounded-tl-[8px] p-3 ps-5 text-[#172B4D]">
                Environments
              </th>
              <th className="rounded-tr-[8px] p-3 ps-5 text-[#172B4D] border-s border-[#D9DCED] ">
                Keys
              </th>
            </tr>
          </thead>
          <tbody>
            {_.chain(environments)
              .sortBy(["id"])
              .map((environment, index) => (
                <tr
                  key={index}
                  className={`pt-4 border-t border-[#D9DCED] h-24 ${
                    !environment.is_active ? "text-[#AAAAAAAA] " : ""
                  }`}
                >
                  <td className="p-3 align-text-top w-[30%]">
                    <div className="flex flex-row justify-between items-center ">
                      <div>{environment.name}</div>
                      {role !== "Basic" && (
                        <div className="relative">
                          <EnvironmentDotsButton
                            id={environment.id}
                            is_active={environment.is_active}
                            environment_name={environment.name}
                            toggleArchive={toggleArchive}
                          />
                        </div>
                      )}
                    </div>
                    {!environment.is_active && (
                      <div className="flex bg-[#DEE8FF] text-[#162C5B] opacity-100 w-20 text-center justify-center items-center rounded-full px-12 py-1">
                        Archived
                      </div>
                    )}
                  </td>

                  <td
                    className={`border-s border-[#D9DCED] p-3 align-text-top`}
                  >
                    {_.map(
                      _.chain(environment.environment_keys)
                        .sortBy(["id"])
                        .filter((keys) => !keys.is_deleted)
                        .value(),
                      (keys, index) => (
                        <div className="flex flex-col" key={index}>
                          <div className="flex flex-row gap-3 items-center">
                            <div className="font-bold flex">
                              <p className="w-[60px]">Key {index + 1}</p> :
                            </div>
                            <p className="w-[270px]">{keys.key}</p>
                            <CopyButton text={keys.key} />
                            {role !== "Basic" && (
                              <>
                                {_.filter(
                                  environment.environment_keys,
                                  (key) => !key.is_deleted
                                ).length > 1 && (
                                  <>
                                    <DeleteEnvironmentKeyButton
                                      id={keys.id}
                                      is_active={environment.is_active}
                                      environment_name={environment.name}
                                    />
                                  </>
                                )}
                                {keys.is_active && (
                                  <img
                                    src={
                                      environment.is_active
                                        ? "/icons/ToggleOn.svg"
                                        : "/icons/ToggleOnArchive.svg"
                                    }
                                    alt="ToggleOn Icon"
                                    className={`ms-3 w-[28px] ${
                                      !environment.is_active
                                        ? "pointer-events-none"
                                        : "cursor-pointer"
                                    }`}
                                    onClick={
                                      environment.is_active
                                        ? () => {
                                            toggleEnvironmentKey(
                                              keys.id,
                                              keys.is_active
                                            ).catch((error) =>
                                              showBoundary(error)
                                            );
                                          }
                                        : undefined
                                    }
                                  />
                                )}
                                {!keys.is_active && (
                                  <img
                                    src={
                                      environment.is_active
                                        ? "/icons/ToggleOff.svg"
                                        : "/icons/ToggleOffArchive.svg"
                                    }
                                    alt="ToggleOn Icon"
                                    className={`ms-3 w-[28px] ${
                                      !environment.is_active
                                        ? "pointer-events-none"
                                        : "cursor-pointer"
                                    }`}
                                    onClick={
                                      environment.is_active
                                        ? () => {
                                            toggleEnvironmentKey(
                                              keys.id,
                                              keys.is_active
                                            ).catch((error) =>
                                              showBoundary(error)
                                            );
                                          }
                                        : undefined
                                    }
                                  />
                                )}{" "}
                              </>
                            )}
                          </div>
                        </div>
                      )
                    )}
                    {role !== "Basic" && (
                      <a
                        className={`cursor-pointer font-medium underline text-[#162C5B] ${
                          !environment.is_active
                            ? "text-[#AAAAAAAA] pointer-events-none"
                            : ""
                        }`}
                        onClick={
                          environment.is_active
                            ? () => {
                                generateEnvironmentKey(environment.id).catch(
                                  (error) => showBoundary(error)
                                );
                              }
                            : undefined
                        }
                      >
                        Generate more keys
                      </a>
                    )}
                  </td>
                </tr>
              ))
              .value()}
          </tbody>
        </table>
      </div>

      <div>
        {role !== "Basic" && (
          <div className="flex font-medium mt-2 justify-end w-full  text-[#162C5B]">
            <div>
              <div
                className="cursor-pointer inline-block w-auto"
                onClick={() => {
                  openAddEnvironmentModal();
                }}
              >
                + Add Environments
              </div>
            </div>
          </div>
        )}

        <GitHubLabCard source_id={source_id} />
      </div>
    </div>
  );
};

export default SourceCard;

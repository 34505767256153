import React from "react";
import { LocalStorageClient } from "@clients/storage/localStorage";
import { LoginRepository } from "@repositories/LoginRepository";
import { MemberInvitationRepository } from "@repositories/MemberInvitationRepository";
import { ProjectRepository } from "@repositories/ProjectRepository";
import { SourceRepository } from "@repositories/SourceRepository";
import { ProfileRepository } from "@repositories/ProfileRepository";
import { RepositoryProviderProps } from "@providers/RepositoryProvider";
import { BackendAPI } from "@clients/api/api";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import { RepositoryURL } from "@repositories/constants.enum";
import { AppObserver } from "app_observer/AppObserver";
import { IssuesRepository } from "@src/repositories/IssuesRepository";
import { IStorageClient } from "@src/clients/storage/types";
import { MemberRepository } from "@src/repositories/MemberRepository";
import { IntegrationRepository } from "@src/repositories/IntegrationRepository";
import { GitHubLabRepository } from "@src/repositories/GitHubLabRepository";
import { SubscriptionRepository } from "@src/repositories/SubscriptionRepository";
import { OrganizationRepository } from "@src/repositories/OrganizationRepository";

const BASE_URL = import.meta.env.VITE_API_URL;
const ERROR_API_KEY = import.meta.env.VITE_ERROR_API_KEY;

const ErrorReportingUrl = RepositoryURL.ERROR_REPORTING_URL;

const localStorageClient = new LocalStorageClient(window.localStorage);
const API = new BackendAPI(BASE_URL, localStorageClient);

export const getLocalStorageClient = (): IStorageClient => {
  return localStorageClient;
};

export const loginRepository = new LoginRepository(API);

export const organizationRepository = new OrganizationRepository(API)

export const memberInvitationRepository = new MemberInvitationRepository(API);

export const projectRepository = new ProjectRepository(API);

export const sourceRepository = new SourceRepository(API);

export const profileRepository = new ProfileRepository(API);

export const issuesRepository = new IssuesRepository(API);

export const memberRepository = new MemberRepository(API);

export const integrationRepository = new IntegrationRepository(API);

export const gitHubLabRepository = new GitHubLabRepository(API);

export const subscriptionRepository = new SubscriptionRepository(API)

const repositories: RepositoryProviderProps = {
  login: loginRepository,
  organization:organizationRepository,
  memberInvitation: memberInvitationRepository,
  project: projectRepository,
  source: sourceRepository,
  profile: profileRepository,
  issues: issuesRepository,
  member: memberRepository,
  integration: integrationRepository,
  gitHubLab: gitHubLabRepository,
  subscription:subscriptionRepository,
};

export const RepositoryContext = React.createContext(repositories);

export const app_observer = new AppObserver(
  `${BASE_URL}/${ErrorReportingUrl}`,
  ERROR_API_KEY
);
export const AppObserverContext = React.createContext(app_observer);

// Context for Custom Logger
export const customLogger = new CustomLogger(API, app_observer);
export const LoggerContext = React.createContext(customLogger);

import { parseApiResponse } from "@actions/parseApiResponse";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import { IMemberRepository } from "./types";

export class MemberRepository implements IMemberRepository {
  private _api: IJsonAPI;

  private MEMBER_URL = RepositoryURL.MEMBER_URL;
  private ORGANIZATION_URL = RepositoryURL.ORGANIZATION_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async getCurrentMember<MemberData>(org_id: string | undefined) {
    const url = `${this.ORGANIZATION_URL}${org_id}/current-member/`;
    const response = await this._api.get<MemberData>(url);
    return parseApiResponse(response);
  }

  async getMember<MemberData>(
    orgId: string | undefined,
    currentPage: number,
    memberQuery: string
  ) {
    const url = memberQuery
      ? `${this.ORGANIZATION_URL}${orgId}/${this.MEMBER_URL}?name=${memberQuery}&page=${currentPage}`
      : `${this.ORGANIZATION_URL}${orgId}/${this.MEMBER_URL}?page=${currentPage}`;
    const response = await this._api.get<MemberData[]>(url);
    return parseApiResponse(response);
  }

  async createMember<MemberData, MemberFieldData>(
    org_id: number,
    data: MemberFieldData
  ): Promise<MemberData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<MemberData, MemberFieldData>(
      `${this.ORGANIZATION_URL}${org_id}/${this.MEMBER_URL}`,
      data,
      headers
    );
    return parseApiResponse<MemberData>(response);
  }

  async resendInvite(memberId: number) {
    return await this._api.put(
      `${this.ORGANIZATION_URL}${this.MEMBER_URL}${memberId}/resend-invitation/`
    );
  }

  async editMember<MemberData, MemberFieldData>(
    org_id: number,
    memberId: number | null,
    data: MemberFieldData
  ) {
    const member_url = `${this.ORGANIZATION_URL}${org_id}/${this.MEMBER_URL}${memberId}/`;
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.put<MemberData, MemberFieldData>(
      member_url,
      data,
      headers
    );
    return parseApiResponse<MemberData>(response);
  }

  async deleteMember(memberId: number | null, orgId: number) {
    return await this._api.delete(
      `${this.ORGANIZATION_URL}${orgId}/${this.MEMBER_URL}${memberId}/`
    );
  }

  async getDemoMember<MemberData>(
    projectId: string | undefined,
    pageNumber: number,
    pageSize: number,
    name: string
  ) {
    const url = name
      ? `demo/member/${projectId}/?name=${name}&page=${pageNumber}&page_size=${pageSize}`
      : `demo/member/${projectId}/?page=${pageNumber}&page_size=${pageSize}`;
    const response = await this._api.get<MemberData[]>(url);
    return parseApiResponse(response);
  }
}

import {
  setIsEditEnvironmentModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { AppDispatch, RootState } from "@src/redux/store";
import { RepositoryContext } from "@src/shared/contexts";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent } from "react";

const EditEnvironmentModal = () => {
  const dispatch = useDispatch<AppDispatch>();
  const repository = useContext(RepositoryContext);
  const { source: sourceRepo } = repository;

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);
  const { environmentData: environmentData } = sourceSlice;

  const [environmentName, setEnvironmentName] = useState<string>(
    environmentData.name
  );

  const handleEnvironmentNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnvironmentName(e.target.value);
  };

  const { handleSubmit, register } = useForm<{ environmentName: string }>({
    defaultValues: {
      environmentName: environmentData.name,
    },
  });

  const closeAddEnvironmentModal = () => {
    dispatch(setIsEditEnvironmentModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const onSubmit = async (data: { environmentName: string }) => {
    await sourceRepo.editEnvironment<EnvironmentData>(
      environmentData.id,
      data.environmentName
    );
    dispatch(setIsEditEnvironmentModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col align-items-start w-full">
            <h2 className="font-bold text-[#172B4D] text-[28px]">
              Edit Environment
            </h2>
            <div className="flex flex-row font-medium text-[14px]">
              <p className="text-[#4F5A69] text-sm">Sources{">"}</p>
              <p className="text-[#AAAAAA] ml-2">Edit Environment</p>
            </div>
            <div className="flex flex-col mt-[26px] w-full gap-[16px]">
              <div>
                <label
                  className="font-medium text-[#172B4D]"
                  htmlFor="environmentName"
                >
                  Environment
                </label>
                <input
                  {...register("environmentName", { required: true })}
                  type="text"
                  placeholder="Enter Environment Name"
                  className="w-full px-3 py-4 border border-[#D9DCED] text-[#172B4D] rounded-[10px] mt-4 placeholder-[#AAAAAA] placeholder-[400] focus:outline-none"
                  onChange={handleEnvironmentNameChange}
                  value={environmentName}
                />
              </div>
            </div>
          </div>
          <div className="mt-2 w-full">
            <div className="flex flex-row mt-8">
              <button
                className="px-8 py-3 bg-[#172B4D] text-white font-bold rounded-[8px]"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={closeAddEnvironmentModal}
                className="ml-4 px-8 py-3 border border-[#172B4D] bg-gray-400 text-[#172B4D] font-light rounded-[8px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditEnvironmentModal;

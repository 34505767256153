import { AnyAction } from "@reduxjs/toolkit";
import { setCurrentMember } from "@feature/memberSlice";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import { MemberRepository } from "@repositories/MemberRepository";
import _ from "lodash";
import { Dispatch } from "react";
import { parseRepositoryResponse } from "./parseRepositoryResponse";

export const fetchCurrentMember = async (
  memberRepo: MemberRepository,
  org_id: string | undefined,
  showBoundary: (error: unknown) => void,
  dispatch: Dispatch<AnyAction>,
  customLogger: CustomLogger
) => {
  const repoResponse = await memberRepo.getCurrentMember<MemberData>(org_id);

  const currentMember = await parseRepositoryResponse(
    repoResponse,
    customLogger,
    showBoundary
  );

  if (!_.isUndefined(currentMember)) {
    dispatch(setCurrentMember(currentMember));
  }
  return currentMember;
};

import { LoggerContext } from "@shared/contexts";
import { CustomLogger } from "@repositories/CustomLoggerRepository";

declare type LoggerProviderProps = {
  customLogger: CustomLogger;
};

export const LoggerProvider = ({
  children,
  customLogger,
}: React.PropsWithChildren<LoggerProviderProps>) => {
  return (
    <LoggerContext.Provider value={customLogger}>
      {children}
    </LoggerContext.Provider>
  );
};

import { NavLink, useParams } from "react-router-dom";

interface GitHubLabAndTokenModalProps {
  closeHandleModal?: () => void;
  lowToken: boolean;
}

const GitHubLabAndTokenModal = ({
  closeHandleModal,
  lowToken,
}: GitHubLabAndTokenModalProps) => {
  const id = useParams();
  const projectid = id.id;
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-[2] bg-[#040e4104]">
        <div className="relative min-w-[349px] md:w-[593px] bg-white rounded-[12px] py-[38px] px-[50px]">
          <button
            onClick={closeHandleModal}
            className="absolute top-4 right-4 items-end"
          >
            <img width={24} src="/icons/Cross.svg" alt="Cross icon" />
          </button>
          <div className="modal-content flex flex-col items-center gap-[22px]">
            <div className="w-[70px] h-[70px] bg-[#FFE8E8] rounded-full flex justify-center items-center">
              <img
                className="w-[46px] h-[46px] object-contain"
                src="/icons/Exclamation.png"
                alt="add"
              />
            </div>
            {lowToken ? (
              <h1 className="text-[20px] font-[700] text-[#242731] w-[80%] text-center">
                Insufficient Token
              </h1>
            ) : (
              <h1 className="text-[20px] font-[700] text-[#242731] w-[80%] text-center">
                Git Providers Integration Required
              </h1>
            )}
            {lowToken ? (
              <p className="text-[16px] text-[#242731] text-center">
                To continue please purchase additional credits or upgrade your
                plan (Minimum 8000 tokens required )
              </p>
            ) : (
              <p className="text-[16px] text-[#242731] text-center">
                Connect your Git Provider to unlock advance AI collaboration and
                code fixes
              </p>
            )}
            {lowToken ? (
              ""
            ) : (
              <NavLink
                onClick={closeHandleModal}
                to={`/projects/${projectid}/sources`}
              >
                Click Here To Integrate Now
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GitHubLabAndTokenModal;

import CopyButton from "../buttons/CopyButton";
import _ from "lodash";
import DemoGitHubLabCard from "./DemoGitHubLabCard";
import CustomToolTip from "../CustomToolTip";

/**
 * Renders a card displaying information about a source, including its name, environments, and associated actions.
 *
 * @component
 * @param {Object} props - The props passed to the SourceCard component.
 * @param {number} props.source_id - The unique identifier for the source.
 * @param {string} props.source_name - The name of the source.
 * @param {Array<Object>} props.environments - An array of environment data associated with the source.
 * @param {string} props.buttonName - The name of the button.
 * @param {string} props.createdDate - The date when the source was created.
 * @param {string} props.lastUsedDate - The date when the source was last used.
 * @param {Function} props.updateKey - A function to update the key.
 * @param {Function} props.toggleArchive - A function to toggle the archive status of the source.
 * @param {Function} props.setEditSourceModalOpen - A function to set the visibility of the edit source modal.
 * @param {Function} props.setTargetSourceData - A function to set the target source data.
 */
const DemoSourceCard = ({
  source_name,
  source_description,
  environments,
}: SourceCardProps) => {
  return (
    <div className="w-[100] rounded-[12px] border border-[#D9DCED] bg-white px-[30px] py-[23px] flex flex-col justify-center mb-[60px]">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 flex-row items-end font-[500] text-[#172B4D]">
          <img src="/icons/Device.svg" alt="Device Icon" className="w-[28px]" />
          {source_name}
        </div>
        <CustomToolTip wrap="break">
          <button
            className="w-[20px] h-[5px] cursor-not-allowed"
            id="menu-button disable"
          >
            <img
              className="w-[3.75px] h-[17px] object-contain"
              src="/icons/Dots.svg"
              alt="dots"
            />
          </button>
        </CustomToolTip>
      </div>
      <p className="mt-2">{source_description}</p>
      <div className="mt-[18px]  rounded-[8px] border border-[#D9DCED]">
        <table className=" w-full ">
          <thead className="text-left bg-[#F5F6FA]">
            <tr>
              <th className="rounded-tl-[8px] p-3 ps-5 text-[#172B4D]">
                Environments
              </th>
              <th className="rounded-tr-[8px] p-3 ps-5 text-[#172B4D] border-s border-[#D9DCED] ">
                Keys
              </th>
            </tr>
          </thead>
          <tbody>
            {_.chain(environments)
              .sortBy(["id"])
              .map((environment, index) => (
                <tr key={index} className="pt-4 border-t border-[#D9DCED] h-24">
                  <td className="p-3 align-text-top w-[30%]">
                    <div className="flex flex-row justify-between items-center ">
                      <div>{environment.name}</div>
                      <CustomToolTip>
                        <button
                          className="w-[20px] h-[5px] cursor-not-allowed"
                          id="menu-button"
                        >
                          <img
                            className="w-[3.75px] h-[17px] object-contain"
                            src="/icons/Dots.svg"
                            alt="dots"
                          />
                        </button>
                      </CustomToolTip>
                    </div>
                  </td>

                  <td
                    className={`border-s border-[#D9DCED] p-3 align-text-top`}
                  >
                    {_.map(
                      _.chain(environment.environment_keys)
                        .sortBy(["id"])
                        .value(),
                      (keys, index) => (
                        <div className="flex flex-col" key={index}>
                          <div className="flex flex-row gap-3 items-center">
                            <text className="font-bold flex">
                              <p className="w-[60px]">Key {index + 1}</p> :
                            </text>
                            <p className="w-[270px]">{keys.key}</p>
                            <CopyButton text={keys.key} />
                            <CustomToolTip>
                              <img
                                src="/icons/Delete.svg"
                                alt="Delete Icon"
                                className="cursor-not-allowed"
                              />
                            </CustomToolTip>
                            <CustomToolTip>
                              <img
                                src="/icons/ToggleOn.svg"
                                alt="ToggleOn Icon"
                                className="ms-3 w-[28px] cursor-not-allowed"
                              />
                            </CustomToolTip>
                          </div>
                        </div>
                      )
                    )}
                    <CustomToolTip>
                      <a className="cursor-not-allowed font-medium underline text-[#162C5B]">
                        Generate more keys
                      </a>
                    </CustomToolTip>
                  </td>
                </tr>
              ))
              .value()}
          </tbody>
        </table>
      </div>

      <div>
        <div className="flex font-medium mt-2 justify-end w-full  text-[#162C5B]">
          <div>
            <CustomToolTip>
              <div className="inline-block w-auto cursor-not-allowed">
                + Add Environments
              </div>
            </CustomToolTip>
          </div>
        </div>

        <DemoGitHubLabCard />
      </div>
    </div>
  );
};

export default DemoSourceCard;

import { useRef } from "react";
import _ from "lodash";
import InfoTooltip from "../../buttons/InfoTooltip";
import CustomToolTip from "../../CustomToolTip";

const DemoSlackIntegrationModal = ({
  closeIntegrationModal,
}: DemoIntegrationModalProps) => {
  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
    divRef2: useRef<HTMLDivElement>(null),
    divRef3: useRef<HTMLDivElement>(null),
  };

  return (
    <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
      <form>
        <div className="flex justify-between">
          <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
            <img width={22} height={22} src="/icons/Slack.svg" alt="cross" />
            Slack
          </h2>
          <button type="button" onClick={closeIntegrationModal}>
            <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
          </button>
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="channel_id"
          >
            Channel ID
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef1}
              messages={["Slack channel to post message"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="Oauth_token"
          >
            Oauth Token
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef2}
              messages={[
                "Slack access token link to view slack documentation to generate oauth token",
              ]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="Icon Url"
          >
            Icon Url
            <InfoTooltip
              divRef={divRefs.divRef3}
              messages={["Url for icon image different messages"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
        </div>

        <div className="flex gap-[20px]">
          <div className="flex justify-start gap-[20px] mt-[16px]">
            <CustomToolTip>
              <button
                type="button"
                className="bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md cursor-not-allowed opacity-50"
                disabled
              >
                Save
              </button>
            </CustomToolTip>
          </div>
          <div className="flex justify-start gap-[20px] mt-[16px]">
            <CustomToolTip>
              <button
                type="button"
                className="bg-[white] w-[160px] h-[40px] text-[#162C5B] text-[14px] font-[700] rounded-md cursor-not-allowed opacity-50"
                disabled
              >
                Send Test Event
              </button>
            </CustomToolTip>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DemoSlackIntegrationModal;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Declare the ApiResponse interface
interface ApiResponse {
  issue_status_uuid: string;
}

// Use the ApiResponse interface as the payload type in the reducer
export const issueFixSlice = createSlice({
  name: "IssueFixSlice",
  initialState: { issue_status_uuid: "" } as ApiResponse, // Set the initial state with an empty string for issue_status_uuid
  reducers: {
    setFixIssueUuid: (state, action: PayloadAction<ApiResponse>) => {
      state.issue_status_uuid = action.payload.issue_status_uuid;
    },
  },
});

export const { setFixIssueUuid } = issueFixSlice.actions;
export default issueFixSlice.reducer;

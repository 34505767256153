import { IssuesRepository } from "@repositories/IssuesRepository";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import _ from "lodash";
import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { setIssuesResponse } from "@feature/issuesSlice";
import { parseRepositoryResponse } from "./parseRepositoryResponse";

export const setDemoIssues = async (
  issuesRepo: IssuesRepository,
  issuesParam: IssuesRequest,
  customLogger: CustomLogger,
  showBoundary: (error: unknown) => void,
  dispatch: Dispatch<AnyAction>
) => {
  const repoResponse = await issuesRepo.fetchDemoIssues<IssuesResponse>(
    issuesParam
  );
  const issues = await parseRepositoryResponse(
    repoResponse,
    customLogger,
    showBoundary
  );
  if (!_.isUndefined(issues)) {
    dispatch(setIssuesResponse(issues));
  }
};

import { useEffect, useRef } from "react";
import { clearAuthTokens } from "@feature/authSlice";
import { SuccessToast } from "@sharedComponents/toasts/SuccessToast";
import { LoggerContext, getLocalStorageClient } from "@src/shared/contexts";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
interface ProfileModalProps {
  ToggleProfileModal: () => void;
}
const ProfileModal = ({ ToggleProfileModal }: ProfileModalProps) => {
  const customLogger = useContext(LoggerContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Reference to the modal element
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // Function to handle clicks outside the modal
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        ToggleProfileModal();
      }
    };
    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ToggleProfileModal]);
  const handleLogout = async () => {
    const local = getLocalStorageClient();
    await local.clearTokens();
    localStorage.removeItem("orgId")
    dispatch(clearAuthTokens());
    SuccessToast({ Message: "Logged Out successfully!" });
    const info: InfoLog = {
      message: "User logged out.",
    };
    await customLogger.log(info);
    ToggleProfileModal();
    navigate("/login");
  };
  return (
    <div
      ref={modalRef}
      className="absolute right-[60px] top-[60px] z-[999] bg-white rounded-[4px] shadow-lg"
    >
      <button
        onClick={handleLogout}
        className="flex justify-start items-center px-[22px] py-[16px] w-[150px]"
      >
        <img
          className="mr-2"
          height={18}
          width={18}
          src="/icons/SignOut.svg"
          alt="edit"
        ></img>
        Log Out
      </button>
    </div>
  );
};
export default ProfileModal;

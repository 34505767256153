import { useRef } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import InfoTooltip from "../../buttons/InfoTooltip";
import CustomToolTip from "../../CustomToolTip";

const DemoTeamsIntegrationModal = ({
  closeIntegrationModal,
}: DemoIntegrationModalProps) => {
  const {
    register,
    formState: { errors },
  } = useForm<AddTeamsIntegrationFieldData>({});

  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
    divRef2: useRef<HTMLDivElement>(null),
    divRef3: useRef<HTMLDivElement>(null),
  };

  return (
    <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
      <form>
        <div className="flex justify-between">
          <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
            <img width={22} height={22} src="/icons/Teams.svg" alt="cross" />
            Microsoft Teams
          </h2>
          <button type="button" onClick={closeIntegrationModal}>
            <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
          </button>
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="webhook_url"
          >
            Webhook URL
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef1}
              messages={["Teams channel to post message"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("webhook_url", {
              required: "webhook_url Required",
            })}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.webhook_url && (
            <small className="block text-red mt-2">
              {errors.webhook_url.message}
            </small>
          )}
        </div>
        <div className="flex gap-[20px]">
          <div className="flex justify-start gap-[20px] mt-[16px]">
            <CustomToolTip>
              <button
                type="button"
                className="bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md cursor-not-allowed opacity-50"
                disabled
              >
                Save
              </button>
            </CustomToolTip>
          </div>
          <div className="flex justify-start gap-[20px] mt-[16px]">
            <CustomToolTip>
              <button
                type="button"
                className="bg-[white] w-[160px] h-[40px] text-[#162C5B] text-[14px] font-[700] rounded-md cursor-not-allowed opacity-50"
                disabled
              >
                Send Test Event
              </button>
            </CustomToolTip>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DemoTeamsIntegrationModal;

import { createSlice } from "@reduxjs/toolkit";

const initialState: MemberSliceProps = {
  meta_data: {
    total_pages: 0,
    total_items: 0,
    current_page: 0,
    has_previous_page: false,
    has_next_page: false,
  },
  member_data: [],
  current_member: null,
  selected_member_id: null,
};

export const memberSlice = createSlice({
  name: "memberSlice",
  initialState,
  reducers: {
    setMemberData: (state, action) => {
      state.member_data = action.payload.member_data;
    },
    setMetaData: (state, action) => {
      state.meta_data = action.payload.meta_data;
    },
    setCurrentMember: (state, action) => {
      state.current_member = action.payload;
    },
    setSelectedMemberId: (state, action) => {
      state.selected_member_id = action.payload;
    },
  },
});

export const {
  setMemberData,
  setMetaData,
  setCurrentMember,
  setSelectedMemberId,
} = memberSlice.actions;
export default memberSlice.reducer;

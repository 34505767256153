import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getDateTimeStates } from "@actions/getDateTimeStates";
import { format, subMinutes } from "date-fns";
import _ from "lodash";

// Generate DateTimeStates for the start and end dates
const startDateStates: DateTimeStates = getDateTimeStates(
  subMinutes(new Date(), 15)
);
const endDateStates: DateTimeStates = getDateTimeStates(new Date());

// Create an initial date range state
const initialDateRange: DateRangePickerStates = {
  startDateStates: startDateStates,
  endDateStates: endDateStates,
  combinedDateRange: `${startDateStates.formattedDate} to ${endDateStates.formattedDate}`,
  showDateRangePicker: false,
  rangePickerViewIcon: "DownArrow",
};

// Define the initial state for the Issues feature
const initialState: IssueDetailsStates = {
  dateRangeStates: initialDateRange,
  request: {
    q: "",
    s: [],
    e: [],
    sort_by: "earliest",
    id: null,
    timeRange: {
      from: subMinutes(new Date(), 15).toISOString(),
      to: new Date().toISOString(),
    },
  },
  response: {
    meta: {
      prev_page_number: null,
      current_page_number: 1,
      next_page_number: null,
      page_count: 0,
      total_count: 0,
      page_size: 10,
    },
    issue_data: null,
    errors_frequencies: [],
  },
  isErrorsTabOpen: false,
  isResponseLoading: false,
  issueStatus: null,
};

export const issueDetailsSlice = createSlice({
  name: "issueDetailsSlice",
  initialState,
  reducers: {
    /**
     * Sets or updates the request parameters for fetching Issue Details
     * @param state Represents the current state of Issue details slice.
     * @param action Represents the json object with updated request parameters.
     */
    setIssueDetailsRequest: (
      state,
      action: PayloadAction<IssueDetailsRequest>
    ) => {
      state.request = action.payload;
    },
    /**
     * Adds or updates the time range, formatted date, visibility, and icon in the DateRangePicker component.
     * Updates all states based on the current values in the slice.
     *
     * @param state Represents the current state of the slice.
     */
    setIssueDetailsRangePickerStates: (state) => {
      // Extract selected dates in ISO string format
      const startDateISOString =
        state.dateRangeStates.startDateStates.selectedDate;

      const endDateISOString = state.dateRangeStates.endDateStates.selectedDate;

      // Set TimeRange of current state with start and end date from selected dates
      state.request.timeRange = {
        from: startDateISOString,
        to: endDateISOString,
      };

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
      state.dateRangeStates.showDateRangePicker = false;
      state.dateRangeStates.rangePickerViewIcon = "DownArrow";
      state.isResponseLoading = true;
    },

    /**
     * Updates the time range and formatted date for Issue Details DateRangePicker component.
     * Updates all states based on the payload parameter.
     *
     * @param state Represents the current state of the slice.
     * @param action Represent the object of type TimeRange and contains the new date range.
     */
    setIssueDetailsTimeRange: (state, action: PayloadAction<TimeRange>) => {
      // Set TimeRange of current state with start and end date from selected dates
      state.request.timeRange = action.payload;
      state.dateRangeStates.startDateStates.selectedDate = action.payload.from;
      state.dateRangeStates.endDateStates.selectedDate = action.payload.to;

      // Set formatted start and end dates
      const formattedStartDate = format(
        new Date(action.payload.from),
        "dd MMM hh:mm a"
      );
      const formattedEndDate = format(
        new Date(action.payload.to),
        "dd MMM hh:mm a"
      );

      state.dateRangeStates.startDateStates.formattedDate = formattedStartDate;
      state.dateRangeStates.endDateStates.formattedDate = formattedEndDate;
      state.dateRangeStates.combinedDateRange = `${formattedStartDate} to ${formattedEndDate}`;
    },

    /**
     * Sets the Details response states for a specific issue in the state.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the properties of Issue details response.
     */
    setIssueDetailsResponse: (
      state,
      action: PayloadAction<IssueDetailsResponse>
    ) => {
        state.response = action.payload;
        state.isResponseLoading = false;
    },

    setIssueStatus: (state, action: PayloadAction<issueStatus>) => {
      state.issueStatus = action.payload;
    },

    /**
     * Sets the start date states for a specific component in the state.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the properties of start date states.
     */
    setIssueDetailsStartDateStates: (
      state,
      action: PayloadAction<DateTimeStates>
    ) => {
      // Update the start date states for the specified component
      state.dateRangeStates.startDateStates = action.payload;
    },

    /**
     * Sets the end date states for a specific component in the state.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the properties of end date states.
     */
    setIssueDetailsEndDateStates: (
      state,
      action: PayloadAction<DateTimeStates>
    ) => {
      // Update the end date states for the specified component
      state.dateRangeStates.endDateStates = action.payload;
    },

    /**
     * Sets the visibility of the date range picker and related properties for a specific component.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the component name and showDateRangePicker boolean.
     */
    setShowIssueDetailsRangePicker: (state, action: PayloadAction<boolean>) => {
      // Update the visibility of the date range picker
      state.dateRangeStates.showDateRangePicker = action.payload;

      // Update the rangePickerViewIcon based on the visibility
      state.dateRangeStates.rangePickerViewIcon = action.payload
        ? "UpArrow"
        : "DownArrow";
    },

    /**
     * Shows or hides the date picker for a specific date type (from or to).
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the component name and dateType (from or to).
     */
    /**
     * Inverts the state in IssueDetailsSlice representing current visibility of Errors tab.
     * @param state Represents current state of Issue Details slice.
     * @param action Represents the new display mode for Errors tab in Issue Details page.
     */
  },
});

export const {
  setIssueDetailsRequest,
  setIssueDetailsTimeRange,
  setIssueDetailsRangePickerStates,
  setIssueDetailsResponse,
  setIssueDetailsStartDateStates,
  setIssueDetailsEndDateStates,
  setShowIssueDetailsRangePicker,
  setIssueStatus,
} = issueDetailsSlice.actions;

export default issueDetailsSlice.reducer;

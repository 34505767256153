import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ErrorToast } from "@sharedComponents/toasts/ErrorToast";
import { getLocalStorageClient } from "@shared/contexts";
import GoogleLoginButton from "../../components/sharedComponents/buttons/GoogleLoginButton";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorResponse, setErrorResponse] = useState<string>();
  const [errorToastShown, setErrorToastShown] = useState(false);
 
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get("error");
    if (error) {
      setErrorResponse(decodeURIComponent(error));
      if (errorResponse && !errorToastShown) {
        ErrorToast({ Message: errorResponse });
        setErrorToastShown(true);
      }
    }
  }, [location.search, errorResponse, errorToastShown]);

  useEffect(() => {
    const getToken = async () => {
      const localStorageClient = getLocalStorageClient();
      const token = await localStorageClient.getAccessToken();
      if (token !== null) {
        navigate(`/organization`);
      }
    };
    getToken();
  }, [navigate]);

  useEffect(() => {
    const clearBrowserCache = async () => {
      if ("caches" in window) {
        try {
          const cacheNames = await caches.keys();
          const deletePromises = cacheNames.map((cacheName) =>
            caches.delete(cacheName)
          );
          await Promise.all(deletePromises);
          console.log("All cache entries have been cleared.");
        } catch (error) {
          console.error("Failed to clear cache:", error);
        }
      } else {
        console.warn("Caches API is not available in this browser.");
      }
    };

    clearBrowserCache();
  }, []);

  return (
    <section className=" bg-background grid grid-cols-1 md:grid md:grid-cols-5 md:h-screen items-center relative">
      <div className="container md:col-span-2 pt-[25px] md:pt-0 pb-[44px] md:pb-0 ">
        <div className="flex justify-start md:absolute md:top-[52px]">
          <button className="w-[150px] h-[34px] md:h-[40px] flex items-center gap-2">
            <img
              width={25}
              height={10}
              src="/icons/ArrowLeft.svg"
              alt="arrow"
            />
            <a
              className="text-[14px] font-[500] text-[#162C5B] hover:text-[#162C5B]"
              href="https://retack.ai"
            >
              Go to Home Page
            </a>
          </button>
        </div>
        <div className="mt-[10%] md:mt-0 flex flex-col items-center">
          <div className="w-[213px] md:w-[218px] h-[34px] md:h-[65px]">
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/logos/Logo.svg"
              alt="logo"
            />
          </div>
          <p className="text-[16px] md:text-[20px] mt-[18px] md:mt-[22.5px]">
            Unlock Powerful Data Analytics
          </p>
        </div>
        <div className="mt-[10%] md:mt-[55px] flex justify-center">
          <div className="w-[255px] md:w-[387px] h-[180px] md:h-[290px]">
            <img
              className="w-[100%] h-[100%] object-contain"
              src="/logos/Frame.svg"
              alt="Analytics Logo"
            />
          </div>
        </div>
      </div>
      <div className="col-span-3 bg-white h-[100%] pt-[56px]  md:pt-0 pb-[5%] md:pb-0">
        <div className="container h-[100%] flex flex-col justify-center">
          <h3 className=" text-[18px] text-[#162C5B] md:text-[28px] font-bold text-center">
            Let's get started !
          </h3>
          <div className="flex justify-center text-center mb-[36px]">
            <p className="w-[239px] md:w-[403px] text-[14px] md:text-[16px] mt-[10px]">
              Please log in to continue
            </p>
          </div>
          <LoginForm />
          <GoogleLoginButton />
          <div className="text-center mt-[30px] text-[#a3a3a3] text-[12px] md:text-[14px]">
            <p className="text-[16px] text-[#56595F] mb-[16px]">
              Don’t have an account?{" "}
              <span>
                <NavLink
                  className={"text-[#0E2390] underline underline-offset-2"}
                  to={"/signUp"}
                >
                  Sign Up
                </NavLink>
              </span>
            </p>
            <a
              href="https://retack.ai/terms&service"
              className="text-[#a3a3a3] text-[12px] md:text-[14px] mr-[8px]"
            >
              Terms and Conditions
            </a>
            <span>|</span>
            <a
              href="https://retack.ai/privacyPolicy"
              className="text-[#a3a3a3] text-[12px] md:text-[14px] ml-[8px]"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;

import { RootState } from "@redux/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { NavLink, useParams } from "react-router-dom";
import { DemoErrorsList } from "./DemoErrorsList";
import { BarDiagram } from "./BarDiagram";
import { useEffect } from "react";
import {
  setIsViewAllErrorsOpen,
  setIsShowIssueDetails,
} from "@src/redux/feature/modalHandler";
import { setIssueDetailsTimeRange } from "@feature/issueDetailsSlice";
import {
  setErrorIssuesId,
  resetErrorParams,
} from "@src/redux/feature/errorSlice";
import { resetIssueSourceEnvironmentParams } from "@src/redux/feature/issuesSlice";
import SourceEnvironmentUserBadge from "../SourceEnvironmentBadge";

export const DemoIssueDetails = () => {
  const dispatch = useDispatch();
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const issueDetailsSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );
  const Pid = useParams();
  const projectid = Pid.id;
  const issueDetailsData = issueDetailsSlice.response.issue_data;
  const errors_frequencies = issueDetailsSlice.response.errors_frequencies;

  const issuesSlice = useSelector((state: RootState) => state.issuesSlice);
  const timeRange = issuesSlice.request_params.timeRange;

  const ViewAllErrorModal = async () => {
    dispatch(resetIssueSourceEnvironmentParams());
    dispatch(resetErrorParams());
    dispatch(setIsViewAllErrorsOpen(true));
    dispatch(setErrorIssuesId(issueDetailsData!.id!));
  };

  useEffect(() => {
    dispatch(setIssueDetailsTimeRange(timeRange));
  }, [dispatch, timeRange]);

  const scrollBarStyle =
    "scrollbar-thumb-rounded-md lg:scrollbar-thin scrollbar-thumb-[#AAB2DE] scrollbar-track-light_gray";

  const barGraphProps: BarGraphProps = {
    frequency_data: errors_frequencies,
    max_bar_height: 100,
    containerHeight: 120,
    x_axis_label_count: 7,
    y_axis_label_count: 4,
    bars_container_width: 1200,
  };

  return (
    modalHandler.isShowIssueDetails && (
      <div
        className="absolute flex top-0 right-0 w-full overflow-hidden h-screen z-[10]"
        tabIndex={0}
      >
        <div
          data-testid="OverlayShadow"
          className="grow"
          onClick={() => dispatch(setIsShowIssueDetails(false))}
        />
        <div className="flex flex-col w-[95%] lg:w-2/3 bg-white border-l border-[#CBCFE8]">
          <div className="flex flex-col justify-center p-[26px] pb-[21px]">
            <div className="flex w-full justify-between items-center ">
              <div className="flex justify-center items-center gap-[10px]">
                <div className="max-w-[450px] font-bold text-[20px] leading-6 text-[#292C31] whitespace-nowrap overflow-hidden overflow-ellipsis ">
                  {issueDetailsData!.title}
                </div>
                <div
                  className={`text-[#FFFFFF] bg-[#B5763D] rounded-[3px] px-[10px] text-[10px] font-bold`}
                >
                  COMPLETED
                </div>
              </div>
              <div className="flex flex-row gap-4 ">
                <NavLink
                  to={`/project/${projectid}/demo/sources/${issueDetailsSlice?.response.issue_data?.origin[0].source_id}/issues/${issueDetailsSlice.response.issue_data?.id}/fix`}
                >
                  <button
                    className={`bg-color-gradient text-[#ffffff] font-semibold py-[10px] px-[20px] rounded rounded-[20px] flex justify-center items-center gap-[8px]`}
                  >
                    <img
                      width={22}
                      height={22}
                      src="/icons/AiPowered.svg"
                      alt="ai powered"
                    ></img>

                    <p>Try Another Fix</p>
                  </button>
                </NavLink>
                <button>
                  <img
                    onClick={() => dispatch(setIsShowIssueDetails(false))}
                    src="/icons/Cross.svg"
                    alt="Cross icon"
                    width={20}
                    height={20}
                  />
                </button>
              </div>
            </div>
            <div className="text-[12px] text-[#172B4D] flex gap-1">
              <p>
                This issue is 10 days old affecting 100 users with 10 errors.{" "}
                <span className="text-[#454545]">Last seen 10 days ago.</span>
              </p>
            </div>
            <SourceEnvironmentUserBadge
              source={issueDetailsData!.origin[0].source}
              environment={issueDetailsData!.origin[0].environment}
            />
          </div>
          <div className="border-b border-[#CBCFE8]"></div>
          <div
            className={`flex flex-col w-full py-5 px-[26px] max-h-[80vh] overflow-x-hidden overflow-y-scroll ${scrollBarStyle} `}
          >
            {issueDetailsSlice.isResponseLoading ? (
              <div className="flex items-center justify-center w-full min-h-[50vh]">
                <img
                  src="/icons/Loading.svg"
                  alt="LoadingIcon"
                  className="w-[40px]"
                />
              </div>
            ) : (
              <div className="flex flex-col">
                <DemoErrorsList />
              </div>
            )}
            <div className="flex items-center gap-3 border border-[#D9D9D9] rounded mt-2 p-2 text-sm">
              <img
                src={`/icons/SuggestionInfo.svg`}
                alt="SuggestionInfo"
                width={17}
                height={17}
              />
              Suggested fixing are generated by machine learning and algorithms
              and may require human validations.
            </div>
            <div className="flex justify-end mb-[25px] mt-[22px] h-[35px] text-text_dark_blue text-[14px] leading-5 font-medium">
              <button className="underline text-[#162C5B]">
                <NavLink
                  onClick={() => {
                    ViewAllErrorModal();
                  }}
                  to={`/project/${projectid}/demo/issues/${issueDetailsSlice.response.issue_data?.id}/errors`}
                >
                  <p className="text-[#162C5B] w-full mr-5">
                    View all 10 errors
                  </p>
                </NavLink>
              </button>
            </div>
            {!_.isEmpty(errors_frequencies) && (
              <>
                <div className="max-h-[200px]  mb-10 ps-3 w-full">
                  <BarDiagram {...barGraphProps} />
                </div>
                <div className="border border-[#D9DCED] flex justify-start items-center gap-3 mb-3 p-[10px] rounded-[5px] ">
                  <img
                    className="h-[15px]"
                    src="/icons/Clock.svg"
                    alt="Clock"
                  />{" "}
                  <div className="leading-[18px] text-[14px] font-normal">
                    {" "}
                    All times are in Coordinated Universal Time (UTC){" "}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

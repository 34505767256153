import EditOrganizationModal from "@src/components/sharedComponents/modal/EditOrganizationModal";
import {
  setIsEditOrganizationModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const OrganizationSettings = () => {
  const params = useParams();
  const orgId = params.orgId!;
  const dispatch = useDispatch();
  const organizationList = useSelector(
    (state: RootState) => state.organizationSlice.organizationList
  );
  const currentOrganization = organizationList.find(
    (org) => org.id === parseInt(orgId)
  );

  const openEditOrganizationModal = () => {
    dispatch(setIsEditOrganizationModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeEditOrganizationModal = () => {
    dispatch(setIsEditOrganizationModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };
  const isEditOrganizationModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isEditOrganizationModalOpen
  );

  return (
    <div className="p-[25px] md:p-[36px] flex flex-col h-[90vh] justify-between xl:w-full xl:w-[1075px] md:w-[732px] w-full">
      <div>
        <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue">
          Organizational Settings
        </h1>
        <div className="flex flex-col gap-[20px] mt-[44px] text-[#172B4D]">
          <div className="flex">
            <p className="w-[250px] font-medium">Name</p> :
            <p className="ml-[24px]">{currentOrganization?.name}</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Website</p> :
            <p className="ml-[24px]">{currentOrganization?.website}</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Created Date</p> :
            <p className="ml-[24px]">
              {new Date(currentOrganization?.created_at!).toLocaleDateString(
                "en-US",
                {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                }
              )}
            </p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Organization Admin</p> :
            <p className="ml-[24px]">{currentOrganization?.owner_email}</p>
          </div>
        </div>
        <div className="mt-[62px]">
          <button
            onClick={openEditOrganizationModal}
            className="border border-[#001789] px-[28px] py-[10px] rounded-[6px] text-[12px] font-bold text-[#001789]"
          >
            Edit Organizational Settings
          </button>
        </div>
      </div>

      <div className="border border-[#DCDCDE]">
        <div className="px-[26px] py-[20px]">
          <h1 className="text-[16px] text-[#CD3D25] font-bold">
            Remove Organization
          </h1>
        </div>
        <div className="pt-[13px] bg-[#F9EBEB] px-[26px] pb-[18px] border border-[#E3C0C4]">
          <p>
            Deleting this organization will permanently remove it along with all
            associated data, and this action cannot be undone.
          </p>

          <button
            disabled
            className="p-[10px] bg-[#CD3D25] rounded-[6px] font-medium text-[12px] text-white mt-[18px] cursor-not-allowed"
          >
            Remove Organization
          </button>
        </div>
      </div>
      {isEditOrganizationModalOpen && (
        <EditOrganizationModal
          closeEditOrganizationModal={closeEditOrganizationModal}
        />
      )}
    </div>
  );
};

export default OrganizationSettings;

type SubscriptionTitle = "Starter" | "Startup" | "Enterprise";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorToast } from "../toasts/ErrorToast";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";

type CreateSessionData = { price_id: string };

interface SubscriptionCardProps {
  title: SubscriptionTitle;
  price?: number
  period: string;
  billingPeriod: string;
  currentPlan: string | undefined;
  feature: Feature | undefined;
  price_detail: PriceDetail | undefined;
  current_price_id: string | undefined;
}

interface Redirect_Url {
  redirect_url: string;
}

const SubscriptionCard = ({
  title,
  price,
  period,
  billingPeriod,
  currentPlan,
  feature,
  price_detail,
  current_price_id,
}: SubscriptionCardProps) => {
  const formatted = (count: number) => {
    const result = (count / 1000).toFixed(1) + "k";
    return result.replace(".0k", "k");
  };

  const displayPrice = typeof price === 'number' ? (price * 0.01).toFixed(2) : price;

  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const params = useParams();
  const orgId = params.orgId;
  const repository = useContext(RepositoryContext);
  const subscriptionRepo = repository.subscription;
  const dispatch = useDispatch();

  const downgradeFreePlan = async (current_plan: string | undefined) => {
    if (current_plan?.toLowerCase()==="startup"){
        const errorMessage = "Downgrade to the free plan is not available as you are currently on the Startup plan.";
        ErrorToast({ Message: errorMessage});
    }
  }
  const createSession = async (price_id: string) => {
    dispatch(setIsLoading(true));
    const data = {
      price_id: `${price_id}`,
    };
    try {
      const response = await subscriptionRepo.createSession<
        Redirect_Url,
        CreateSessionData
      >(orgId, data);

      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            console.log(error);
          });
          const errorMessage = response.errors[0]?.message || "An unknown error occurred."; // error message
          ErrorToast({ Message: errorMessage});
        } else {
          window.open(response.redirect_url, "_blank");
        }
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      ErrorToast({ Message: "Failure! Your Session has not been created." });
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  const renderButton = () => {
    if (title === "Starter") {
      return currentPlan === "Starter" ? (
        <button className="bg-[#325EBB] bg-opacity-[0.20] text-[#325EBB] text-[14px] py-[12px] w-[200px] rounded-full font-bold">
          CURRENT PLAN
        </button>
      ) : (
        <button onClick={() => downgradeFreePlan(currentPlan)} className="bg-[#325EBB] text-[#FFFFFF] text-[14px] py-[12px] w-[200px] rounded-full font-bold">
          DOWNGRADE TO FREE
        </button>
      );
    }

    if (title === "Startup") {
      return current_price_id === price_detail?.id ? (
        <button className="bg-[#325EBB] bg-opacity-[0.20] text-[#325EBB] text-[14px] py-[12px] w-[200px] rounded-full font-bold">
          CURRENT PLAN
        </button>
      ) : isLoading ? (
        <div className="flex justify-center items-center w-full">
          <button
            disabled
            className="flex justify-center gap-2 px-5 bg-[#325EBB] text-[#FFFFFF] text-[14px] py-[12px] w-[200px] rounded-full font-bold"
          >
            <img
              width={22}
              height={22}
              src="/icons/Loading.svg"
              alt="loading"
            />
            Processing...
          </button>
        </div>
      ) : (
        <button
          onClick={() => createSession(price_detail?.id!)}
          className="bg-[#325EBB] text-[#FFFFFF] text-[14px] py-[12px] w-[200px] rounded-full font-bold"
        >
          UPGRADE
        </button>
      );
    }

    if (title === "Enterprise") {
      return currentPlan === "Enterprise" ? (
        <button className="bg-[#325EBB] bg-opacity-[0.20] text-[#325EBB] text-[14px] py-[12px] w-[200px] rounded-full font-bold">
          CURRENT PLAN
        </button>
      ) : (
        <a href="https://retack.ai/#contact" target="_blank" rel="noopener noreferrer">
        <button className="bg-[#325EBB] text-[#FFFFFF] text-[14px] py-[12px] w-[200px] rounded-full font-bold">
          CONTACT US 
        </button>
        </a>
      );
    }

    return null;
  };

  return (
    <div
      className={`border border-[#ECF3FF]  ${
        title === "Startup" ? "bg-[#ECF3FF]" : "bg-[#F7FAFF]"
      } w-[350px] text-center py-[20px] md:py-[20px] px-[30px]`}
    >
      <h1 className={`text-[20px] text-[#325EBB] font-medium`}>{title}</h1>
      <p className="text-[14px] h-[20px] text-[#000000] my-[8px]">
        {title === "Starter"
          ? "For individuals and idea explorers"
          : title === "Startup"
          ? "For startup teams building great products"
          : "For corporate & large organizations"}
      </p>
      <div className="mb-2 h-[60px]">
        {title === "Starter" || title === "Startup" ? (
          <div>
            <h2 className="text-[20px]">
              <span className="text-[30px] font-bold">${displayPrice}</span>
              <span className="text-[24px]">/</span>
              {period}
            </h2>
            <p className="text-[12px] font-medium mt-[-5px]">
              Billed {billingPeriod}
            </p>
          </div>
        ) : (
          <h1 className="text-[30px] font-bold">Custom</h1>
        )}
      </div>
      <div className="my-5">{renderButton()}</div>
      <div className="flex flex-col h-[260px] gap-[10px] text-[14px] text-start leading-[21px] tracking-[0.5px] ml-[25px]">
        <div className="flex gap-[12px]">
          <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />
          {title === "Enterprise" ? (
            <>
              <p>Unlimited custom features</p>
            </>
          ) : (
            <p>Unlimited projects</p>
          )}
        </div>
        <div className="flex gap-[12px]">
          {title === "Starter" ? (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />
              Maximum {feature?.max_member_limit} members
            </>
          ) : title === "Startup" ? (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />
              Unlimited members
            </>
          ) : null}
        </div>
        {title === "Startup" ? (
          <div className="flex gap-[12px]">
            <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />{" "}
            Unlimited issue email alerts
          </div>
        ) : null}
        <div className="flex gap-[12px]">
          {title === "Enterprise" ? null : (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />
              <p>{formatted(feature?.no_of_error!)} monthly errors</p>
            </>
          )}
        </div>
        <div className="flex gap-[12px]">
          {title === "Enterprise" ? null : (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />
              {feature?.data_retention_period} days Data Retention
            </>
          )}
        </div>
        <div className="flex gap-[12px]">
          {title === "Enterprise" ? null : (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />{" "}
              50k AI tokens on new signups
            </>
          )}
        </div>
        <div className="flex gap-[12px]">
          {title === "Enterprise" ? null : title === "Startup" ? (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />{" "}
              {formatted(feature?.openai_token_limit!)} free AI tokens every month
            </>
          ) : (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />{" "}
              {formatted(feature?.openai_token_limit!)} free AI tokens every month
            </>
          )}
        </div>
        <div className="flex gap-[12px]">
          {title === "Enterprise" ? null : (
            <>
              <img width={12} height={12} src="/icons/BlueTick.svg" alt="tick" />{" "}
              Slack,Teams and Email Integration
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;

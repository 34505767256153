import IntegrationCard from "@src/components/sharedComponents/card/IntegrationCard";
import RemoveIntegrationModal from "@src/components/sharedComponents/modal/integrationModal/RemoveIntegrationModal";
import IntegrationModal from "@src/components/sharedComponents/modal/integrationModal/IntegrationModal";
import {
  setIsEditIntegrationModalOpen,
  setIsIntegrationModalOpen,
  setIsOverlayVisible,
  setIsRemoveIntegrationModalOpen,
} from "@src/redux/feature/modalHandler";
import { AppDispatch, RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useErrorBoundary } from "react-error-boundary";
import EditIntegrationModal from "@src/components/sharedComponents/modal/integrationModal/EditIntegrationModal";

const IntegrationPage = () => {
  const repository = useContext(RepositoryContext);
  const integrationRepo = repository.integration;
  const id = useParams();
  const projectid = id.id;
  const [integrationData, setIntegrationData] = useState<IntegratedData>();
  const [selectedIntegrationName, setSelectedIntegrationName] = useState<
    string | null
  >(null);

  const dispatch = useDispatch<AppDispatch>();

  const modalHandler = useSelector((state: RootState) => state.modalHandler);

  const closeIntegrationModal = () => {
    dispatch(setIsIntegrationModalOpen(false));
    dispatch(setIsOverlayVisible(false));
    setSelectedIntegrationName(null);
  };

  const closeRemoveIntegrationModal = () => {
    dispatch(setIsRemoveIntegrationModalOpen(false));
    dispatch(setIsOverlayVisible(false));
    setSelectedIntegrationName(null);
  };

  const closeEditIntegrationModal = () => {
    dispatch(setIsEditIntegrationModalOpen(false));
    dispatch(setIsOverlayVisible(false));
    setSelectedIntegrationName(null);
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  useEffect(() => {
    const fetchData = async () => {
      const response =
        await integrationRepo.getIntegrationStatus<IntegratedData>(
          `${projectid}`
        );
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          setIntegrationData(response);
        }
      }
    };
    fetchData().catch(async (error) => {
      showBoundary(error);
      await customLogger.reportError(error);
    });
  }, [
    integrationRepo,
    projectid,
    modalHandler.isIntegrationModalOpen,
    modalHandler.isRemoveIntegrationModalOpen,
    customLogger,
    showBoundary,
  ]);

  const svgMapping: Record<string, string> = {
    slack: "Slack.svg",
    mail_receivers: "Email.svg",
    ms_teams: "Teams.svg",
  };

  const filteredIntegrationArray = _.filter(
    Object.entries(integrationData || {})
  );

  const integrationArray = _.map(filteredIntegrationArray, ([name, state]) => ({
    name:
      name.toLowerCase() === "mail_receivers"
        ? "Email"
        : name.toLowerCase() === "ms_teams"
        ? "Microsoft Teams"
        : name.charAt(0).toUpperCase() + name.slice(1).toLowerCase(),
    state,
    buttonName: state ? "Edit" : "Configure",
    image: `/icons/${svgMapping[name]}`,
    link: name.toLowerCase(),
    description:
      name === "slack"
        ? "Connect your slack account to receive slack notification when new issue arises."
        : name === "mail_receivers"
        ? "Connect your email to receive email notification when new issue arises."
        : "Connect your team for collaboration and error resolution when issue arises.",
  }));

  return (
    <div className="p-[25px] md:p-[36px]">
      <div className="flex items-center justify-between mb-5">
        <h1 className="text-[20px] md:text-[30px] font-[700] text-text_dark_blue">
          Integration
        </h1>
      </div>
      <p className="mb-[14px] md:mb-[34px]">Get notified whenever new issue arrises </p>
      <div className="flex gap-[30px] flex-wrap">
        {_.map(integrationArray, (integration) => (
          <IntegrationCard
            key={integration.name}
            state={integration.state}
            name={integration.name}
            buttonName={integration.buttonName}
            image={integration.image}
            description={integration.description}
            onOpenIntegrationModal={() =>
              setSelectedIntegrationName(integration.name)
            }
            onOpenRemoveIntegrationModal={() =>
              setSelectedIntegrationName(integration.name)
            }
            onOpenEditIntegrationModal={() =>
              setSelectedIntegrationName(integration.name)
            }
          />
        ))}
      </div>
      {modalHandler.isIntegrationModalOpen && (
        <IntegrationModal
          key={selectedIntegrationName}
          closeIntegrationModal={closeIntegrationModal}
          integrationName={selectedIntegrationName}
        />
      )}
      {modalHandler.isRemoveIntegrationModalOpen && (
        <RemoveIntegrationModal
          key={selectedIntegrationName}
          closeRemoveIntegrationModal={closeRemoveIntegrationModal}
          integrationName={selectedIntegrationName}
        />
      )}
      {modalHandler.isEditIntegrationModalOpen && (
        <EditIntegrationModal
          key={selectedIntegrationName}
          closeEditIntegrationModal={closeEditIntegrationModal}
          integrationName={selectedIntegrationName}
        />
      )}
    </div>
  );
};

export default IntegrationPage;

import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import { parseApiResponse } from "@actions/parseApiResponse";
import { IIssuesRepository } from "./types";
import _ from "lodash";

export class IssuesRepository implements IIssuesRepository {
  private _api: IJsonAPI;
  private ISSUES_URL: string = RepositoryURL.ISSUES_URL;

  constructor(api: IJsonAPI) {
    this._api = api;
  }
  async fetchIssues<IssuesResponse>(params: IssuesRequest, project_id: string) {
    const queryParameters: Map<string, unknown> = new Map();
    const { s, e, timeRange, page } = params;

    _.map(params, (value, key) => {
      if (_.isEqual(typeof value, "string")) {
        queryParameters.set(key, value);
      }
    });
    if (!_.isEmpty(s)) {
      queryParameters.set("s", s);
    }
    if (!_.isEmpty(e)) {
      queryParameters.set("e", e);
    }

    queryParameters.set("page", page.toString());
    queryParameters.set("from", timeRange.from);
    queryParameters.set("to", timeRange.to);

    const response = await this._api.get<IssuesResponse>(
      `${this.ISSUES_URL}${project_id}/issues/`,
      queryParameters
    );
    return parseApiResponse<IssuesResponse>(response);
  }

  async fetchIssueDetails<IssueDetailsResponse>(
    issue_id: string,
    project_id: string,
    timeRange: TimeRange
  ) {
    const queryParameters: Map<string, unknown> = new Map();
    queryParameters.set("from", timeRange.from);
    queryParameters.set("to", timeRange.to);

    const response = await this._api.get<IssueDetailsResponse>(
      `${this.ISSUES_URL}${project_id}/issues/${issue_id}/`,
      queryParameters
    );
    return parseApiResponse<IssueDetailsResponse>(response);
  }

  async fetchIssueStatus<issueStatus>(issue_id: string, project_id: string) {
    const response = await this._api.get<issueStatus>(
      `${this.ISSUES_URL}${project_id}/issue-status/${issue_id}`
    );
    return parseApiResponse<issueStatus>(response);
  }

  async fetchIssueSummary<IssueSummary>(
    issue_id: string,
    project_id: string,
    orgId: string
  ) {
    const response = await this._api.get<IssueSummary>(
      `observe/organization/${orgId}/project/${project_id}/issues/${issue_id}/summary/`
    );
    return parseApiResponse<IssueSummary>(response);
  }

  async fetchIssueErrors<IssueErrors>(
    params: IssueErrorsRequest,
    project_id?: string,
    issue_id?: number
  ) {
    const queryParameters: Map<string, unknown> = new Map();
    const { s, e, timeRange, page } = params;

    _.map(params, (value, key) => {
      if (_.isEqual(typeof value, "string")) {
        queryParameters.set(key, value);
      }
    });
    if (!_.isEmpty(s)) {
      queryParameters.set("s", s);
    }
    if (!_.isEmpty(e)) {
      queryParameters.set("e", e);
    }
    queryParameters.set("from", timeRange.from);
    queryParameters.set("to", timeRange.to);
    if (_.isNumber(page)) {
      queryParameters.set("page", page.toString());
    }

    const response = await this._api.get<IssueErrors>(
      `${this.ISSUES_URL}${project_id}/issue/${issue_id}/errors`,
      queryParameters
    );
    return parseApiResponse<IssueErrors>(response);
  }

  async fixIssue(
    orgId: string,
    project_id: string,
    source_id: string,
    issue_id: string
  ): Promise<JsonAPIErrorResp> {
    const fixIssueUrl = `observe/organization/${orgId}/project/${project_id}/sources/${source_id}/fix/issues/${issue_id}/`;
    const response = await this._api.post(fixIssueUrl);
    return parseApiResponse(response) as JsonAPIErrorResp;
  }

  async getCodeFix<CodeFixData>(
    project_id: string,
    pageNumber?: number,
    pageSize?: number,
    search?: string
  ): Promise<CodeFixData | JsonAPIErrorResp | undefined> {
    const url =
      `${this.ISSUES_URL}${project_id}/issue-fix/?` +
      (search ? `search_query=${search}&` : "") +
      (pageNumber !== undefined ? `page=${pageNumber}&` : "") +
      (pageSize !== undefined ? `page_size=${pageSize}&` : "");

    const cleanedUrl = url.endsWith("&")
      ? url.slice(0, -1)
      : url.endsWith("?")
      ? url.slice(0, -1)
      : url;

    const CodeFix = await this._api.get<CodeFixData>(cleanedUrl);

    return parseApiResponse<CodeFixData>(CodeFix);
  }

  async fetchDemoIssues<IssuesResponse>(params: IssuesRequest) {
    const queryParameters: Map<string, unknown> = new Map();
    const { s, e, timeRange, page } = params;

    _.map(params, (value, key) => {
      if (_.isEqual(typeof value, "string")) {
        queryParameters.set(key, value);
      }
    });
    if (!_.isEmpty(s)) {
      queryParameters.set("s", s);
    }
    if (!_.isEmpty(e)) {
      queryParameters.set("e", e);
    }
    queryParameters.set("page", page.toString());
    queryParameters.set("from", timeRange.from);
    queryParameters.set("to", timeRange.to);

    const response = await this._api.get<IssuesResponse>(
      `demo/${this.ISSUES_URL}issues/`,
      queryParameters
    );
    return parseApiResponse<IssuesResponse>(response);
  }

  async fetchDemoIssueDetails<IssueDetailsResponse>(issue_id: string) {
    const response = await this._api.get<IssueDetailsResponse>(
      `demo/${this.ISSUES_URL}issues/${issue_id}/`
    );
    return parseApiResponse<IssueDetailsResponse>(response);
  }

  async fetchDemoIssueErrors<IssueErrors>(
    params: IssueErrorsRequest,
    project_id?: string,
    issue_id?: number
  ) {
    const queryParameters: Map<string, unknown> = new Map();
    const { s, e, timeRange, page } = params;

    _.map(params, (value, key) => {
      if (_.isEqual(typeof value, "string")) {
        queryParameters.set(key, value);
      }
    });
    if (!_.isEmpty(s)) {
      queryParameters.set("s", s);
    }
    if (!_.isEmpty(e)) {
      queryParameters.set("e", e);
    }
    queryParameters.set("from", timeRange.from);
    queryParameters.set("to", timeRange.to);
    if (_.isNumber(page)) {
      queryParameters.set("page", page.toString());
    }

    const response = await this._api.get<IssueErrors>(
      `demo/${this.ISSUES_URL}${project_id}/issue/${issue_id}/demo-errors`,
      queryParameters
    );
    return parseApiResponse<IssueErrors>(response);
  }

  async fetchDemoIssueSummary<IssueSummary>(issue_id: string) {
    const response = await this._api.get<IssueSummary>(
      `demo/${this.ISSUES_URL}issues/${issue_id}/summary/`
    );
    return parseApiResponse<IssueSummary>(response);
  }

  async getDemoCodeFix<CodeFixData>(
    project_id: string
  ): Promise<CodeFixData | JsonAPIErrorResp | undefined> {
    const CodeFix_url = `demo/${this.ISSUES_URL}${project_id}/issue-fix/`;
    const CodeFix = await this._api.get<CodeFixData>(CodeFix_url);
    return parseApiResponse<CodeFixData>(CodeFix);
  }
}

import { JsonAPIError } from "@clients/api/types";
import { ErrorTitles } from "./constants.enum";

export class CustomError extends Error {
  public title: string;
  public status: string;

  constructor(title: string, status: string, message: string) {
    super(message);
    this.title = title;
    this.status = status;
  }
}
export class BackendError extends CustomError {
  constructor(error: JsonAPIError) {
    super(
      ErrorTitles.BackendError,
      error.status,
      `${error.title}: ${error.message}`
    );
  }
}
export class AuthenticationError extends CustomError {
  constructor(error: JsonAPIError) {
    super(
      ErrorTitles.AuthenticationError,
      error.status,
      `${error.title}: ${error.message}`
    );
  }
}
export class ValidationError extends CustomError {
  constructor(error: JsonAPIError) {
    super(
      ErrorTitles.ValidationError,
      error.status,
      `${error.title}: ${error.message}`
    );
  }
}

import { parseRepositoryResponse } from "@src/actions/parseRepositoryResponse";
import Pagination from "@src/components/Pagination";
import SourceEnvironmentUserBadge from "@src/components/sharedComponents/SourceEnvironmentBadge";
import { IssueDetails } from "@src/components/sharedComponents/issuesExplorer/IssueDetails";
import {
  setIssueDetailsRequest,
  setIssueDetailsResponse,
  setIssueStatus,
} from "@src/redux/feature/issueDetailsSlice";
import {
  setIsLoading,
  setIsShowIssueDetails,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _, { debounce } from "lodash";
import { useContext, useEffect, useState, useCallback } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

const CodeFix = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const projectid = params.id;
  const repository = useContext(RepositoryContext);
  const codeFixRepo = repository.issues;
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [codeFixData, setCodeFixData] = useState<CodeFixData>();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const issueDetailsSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );

  const id = issueDetailsSlice.request.id;
  const timeRange = issueDetailsSlice.request.timeRange;
  const issuesRepo = repository.issues;
  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const { total_pages, total_items, has_next_page, has_previous_page } =
    codeFixData?.meta_data || {};

  const handleNextPage = () => {
    if (has_next_page) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (has_previous_page) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleShowIssueDetails = async (index: number | undefined) => {
    if (!_.isUndefined(index)) {
      dispatch(
        setIssueDetailsRequest({ ...issueDetailsSlice.request, id: index })
      );

      const issueDetailsResponse =
        await issuesRepo.fetchIssueDetails<IssueDetailsResponse>(
          index.toString(),
          projectSlice.project_id!.toString(),
          timeRange
        );

      const issueDetails = await parseRepositoryResponse(
        issueDetailsResponse,
        customLogger,
        showBoundary
      );

      if (!_.isUndefined(issueDetails)) {
        dispatch(setIssueDetailsResponse(issueDetails));
      }

      const issueStatusResponse =
        await issuesRepo.fetchIssueStatus<issueStatus>(
          index.toString(),
          projectSlice.project_id!.toString()
        );

      const issueStatus = await parseRepositoryResponse(
        issueStatusResponse,
        customLogger,
        showBoundary
      );

      if (!_.isUndefined(issueStatus)) {
        dispatch(setIssueStatus(issueStatus));
        dispatch(setIsShowIssueDetails(true));
      }
    }
  };

  const capitalizeFirstLetter = (string: string) => {
    if (string.length === 0) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const getStatusBgColor = (status: string) => {
    switch (status.toLowerCase()) {
      case "failed":
        return "bg-[#ED4337] bg-opacity-[0.10] text-[#ED4337]";
      case "running":
        return "bg-[#0EB000] bg-opacity-[0.10] text-[#0EB000]";
      case "in queue":
        return "bg-[#2E00B0] bg-opacity-[0.10] text-[#2E00B0]";
      case "completed":
        return "bg-[#0051B0] bg-opacity-[0.10] text-[#0051B0]";
      default:
        return "bg-[#F7F7F7]";
    }
  };

  const formatTotalTokens = (totalTokens?: number): string => {
    if (totalTokens === undefined) return "0";

    if (totalTokens >= 1000) {
      const formatted = totalTokens / 1000;
      return formatted.toFixed(1) + " k";
    }

    return totalTokens.toString();
  };

  const formatDuration = (duration?: number): string => {
    if (duration === undefined) return "00:00:00";

    const totalSeconds = Math.round(duration);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const pad = (num: number) => String(num).padStart(2, "0");

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  const fetchCodeFix = useCallback(
    async (query: string) => {
      dispatch(setIsLoading(true));
      try {
        const codeFixList = await codeFixRepo.getCodeFix<CodeFixData>(
          `${projectid}`,
          currentPage,
          itemsPerPage,
          query
        );
        if (!_.isUndefined(codeFixList)) {
          if ("errors" in codeFixList) {
            await customLogger.reportErrorResponse(showBoundary, codeFixList);
          } else {
            setCodeFixData(codeFixList);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [codeFixRepo, currentPage, customLogger, dispatch, projectid, showBoundary]
  );

  const debouncedFetchCodeFix = useCallback(
    debounce((query: string) => fetchCodeFix(query), 500),
    [fetchCodeFix]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchQuery(value);
    debouncedFetchCodeFix(value);
  };

  useEffect(() => {
    const handleDisplayIssueDetails = async () => {
      if (!_.isNull(id) && !_.isNull(projectSlice.project_id)) {
        const issueDetailsResponse =
          await issuesRepo.fetchIssueDetails<IssueDetailsResponse>(
            id.toString(),
            projectSlice.project_id.toString(),
            timeRange
          );

        const issueDetails = await parseRepositoryResponse(
          issueDetailsResponse,
          customLogger,
          showBoundary
        );
        if (!_.isUndefined(issueDetails)) {
          dispatch(setIssueDetailsResponse(issueDetails));
        }
      }
    };
    handleDisplayIssueDetails();
  }, [
    customLogger,
    dispatch,
    id,
    issuesRepo,
    projectSlice.project_id,
    showBoundary,
    timeRange,
  ]);

  useEffect(() => {
    fetchCodeFix(searchQuery);
  }, [fetchCodeFix, currentPage]);

  return (
    <>
      <div>
        <div className="p-[25px] md:p-[36px]">
          <div className="flex flex-col">
            <div className="flex justify-between">
              <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
                Code Fix
              </h1>
            </div>
            <div className="flex mb-5 h-[50px] bg-white items-center border border-border_gray rounded-[8px] px-3">
              <img
                src="/icons/Search.svg"
                alt="SearchIcon"
                className="w-[14px]"
              />
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2"
                placeholder="Search"
              />
            </div>
          </div>
          {isLoading ? (
            <div className="flex w-full h-[50vh] items-center justify-center">
              <img
                className="w-[50px]"
                src="/icons/Loading.svg"
                alt="LoadingIcon"
              />
            </div>
          ) : (
            <>
              <div className="py-[11px]">
                <div className="overflow-x-auto">
                  <table className="w-full text-[#292C31] bg-[#F3F4F8]  overflow-y-auto">
                    <thead className="text-[14px] text-left ">
                      <tr>
                        <th className="font-[700] py-3 px-6">Issue Title</th>
                        <th className="font-[700] py-3 px-6">Fix Status</th>
                        <th className="font-[700] py-3 px-6">Date</th>
                        <th className="font-[700] py-3 px-6">Started By</th>
                        <th className="font-[700] py-3 px-6">Token Used</th>
                      </tr>
                    </thead>
                    <tbody className="text-[12px]">
                      {_.isEmpty(codeFixData) ? (
                        <tr className="bg-white">
                          <td className="py-3 px-6 text-[#292C31]">
                            No issues have occurred!
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      ) : (
                        codeFixData?.ai_fix_data?.map((item, index) => (
                          <tr
                            key={item.id}
                            className={`${
                              index % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"
                            }`}
                          >
                            <td className="py-3 px-6">
                              <div
                                onClick={() =>
                                  handleShowIssueDetails(item.issue.id)
                                }
                                className="max-w-[350px] font-bold text-[14px] leading-[23px] text-[#292C31] whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer"
                              >
                                {item.issue.title}
                              </div>
                              <SourceEnvironmentUserBadge
                                source={item.issue.origin[0].source}
                                environment={item.issue.origin[0].environment}
                              />
                            </td>
                            <td className="py-3 px-6">
                              <NavLink to={`${item.id}/issueStatus`}>
                                <div
                                  className={`${getStatusBgColor(
                                    item.status
                                  )} cursor-pointer px-[25px] py-[10px] rounded-full text-center max-w-[130px]`}
                                >
                                  {capitalizeFirstLetter(item.status)}
                                </div>
                              </NavLink>
                            </td>
                            <td className="py-3 px-6">
                              <NavLink to={`${item.id}/issueStatus`}>
                                <div className="cursor-pointer flex flex-col text-[#292C31] leading-[23px] tracking-[0.5px] gap-2 text-[14px]">
                                  {new Date(item.created_at).toLocaleDateString(
                                    "en-US",
                                    {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    }
                                  )}
                                  <div className="flex items-center text-xs font-normal leading-5 mr-[36px] gap-2">
                                    <img
                                      className="h-[12px]"
                                      src="/icons/Clock.svg"
                                      alt="Clock"
                                    />
                                    <div>{formatDuration(item.duration)}</div>
                                  </div>
                                </div>
                              </NavLink>
                            </td>
                            <td className="py-3 px-6">
                              <NavLink to={`${item.id}/issueStatus`}>
                                <div className="cursor-pointer mb-6 leading-[23px] text-[#292C31] tracking-[0.5px] text-[14px]">
                                  {typeof item.user_account !== "string"
                                    ? item.user_account.display_name
                                    : item.user_account}
                                </div>
                              </NavLink>
                            </td>
                            <td className="py-3 px-6">
                              <NavLink to={`${item.id}/issueStatus`}>
                                <div className="cursor-pointer mb-6 leading-[23px] text-[#292C31] tracking-[0.5px] text-[14px] flex gap-[10px]">
                                  <img src="/icons/Token.svg" alt="token" />
                                  <div>
                                    {typeof item.openai_responses !== "string"
                                      ? formatTotalTokens(
                                          item.openai_responses.total_tokens
                                        )
                                      : item.openai_responses}
                                  </div>
                                </div>
                              </NavLink>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-between items-center mt-[20px]">
                  <p className="text-[12px]">Total {total_items} issues</p>
                  <Pagination
                    totalPages={total_pages}
                    currentPage={currentPage}
                    onPageChange={setCurrentPage}
                    hasNextPage={has_next_page}
                    hasPreviousPage={has_previous_page}
                    onNextPage={handleNextPage}
                    onPreviousPage={handlePreviousPage}
                  />
                </div>
              </div>
            </>
          )}
          <IssueDetails />
        </div>
      </div>
    </>
  );
};

export default CodeFix;

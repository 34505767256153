import GoogleLoginButton from "@src/components/sharedComponents/buttons/GoogleLoginButton";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import MessageEmailVerification from "../verification/MessageEmailVerification";

const SignUpForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpData>();

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const signUpRepo = repository.login;

  const onSubmit = async (data: SignUpData) => {
    setIsLoading(true);
    try {
      setEmail(data.email);
      const response = await signUpRepo.signUp<SignUpData, SignUpData>(data);
      if (typeof response === "string") {
        if (response === "New user created") {
          SuccessToast({
            Message: "please check your email for verification",
          });
          setSuccess(true);
        }
      } else if (response && typeof response === "object") {
        if ("errors" in response) {
          _.forEach(response.errors, (error) => {
            const validField = error.title as
              | "first_name"
              | "last_name"
              | "email"
              | "password";
            setError(validField, { message: error.message });
            if (
              validField !== "first_name" &&
              validField !== "last_name" &&
              validField !== "email" &&
              validField !== "password"
            ) {
              ErrorToast({ Message: error.message });
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        }
      }
      setIsLoading(false);
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="col-span-3 bg-white h-[100%] pt-[56px]  md:pt-0 pb-[5%] md:pb-0">
      {success ? (
        <MessageEmailVerification email={email} />
      ) : (
        <div className="container h-[100%] flex flex-col justify-center">
          <h3 className=" text-[18px] text-[#162C5B] md:text-[28px] font-bold text-center">
            Let's get started !
          </h3>
          <div className="flex justify-center text-center mb-[36px]">
            <p className="w-[239px] md:w-[403px] text-[14px] md:text-[16px] mt-[10px]">
              To explore our AI features, please Sign Up
            </p>
          </div>
          <div className="md:flex justify-center">
            <form
              className="flex flex-col justify-center items-center"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col w-full">
                <label className="text-[#42454A] font-[500]">
                  First Name
                  <span className="text-red text-sm ml-1">*</span>
                </label>
                <input
                  className={`${
                    errors.first_name ? "border-[#CC0202]" : "border-[#162C5B]"
                  } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                  placeholder="First Name"
                  type="text"
                  {...register("first_name", {
                    required: "The first name field is required",
                  })}
                />
                {errors.first_name != null && (
                  <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                    <img src="/icons/ErrorAlert.svg" alt="error" />
                    {errors.first_name.message}
                  </small>
                )}
              </div>
              <div className="mt-5 flex flex-col w-full">
                <label className="text-[#42454A] font-[500]">
                  Last Name
                  <span className="text-red text-sm ml-1">*</span>
                </label>
                <input
                  className={`${
                    errors.last_name ? "border-[#CC0202]" : "border-[#162C5B]"
                  } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                  placeholder="Last Name"
                  type="text"
                  {...register("last_name", {
                    required: "The last name field is required",
                  })}
                />
                {errors.last_name != null && (
                  <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                    <img src="/icons/ErrorAlert.svg" alt="error" />
                    {errors.last_name.message}
                  </small>
                )}
              </div>
              <div className="mt-5 flex flex-col w-full">
                <label className="text-[#42454A] font-[500]">
                  Email Address
                  <span className="text-red text-sm ml-1">*</span>
                </label>
                <input
                  className={`${
                    errors.email ? "border-[#CC0202]" : "border-[#162C5B]"
                  } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                  type="email"
                  {...register("email", {
                    required: "The email field is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                  placeholder="example@example.com"
                />
                {errors.email != null && (
                  <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                    <img src="/icons/ErrorAlert.svg" alt="error" />
                    {errors.email.message}
                  </small>
                )}
              </div>
              <div className="mt-5 flex flex-col w-full">
                <label className="text-[#42454A] font-[500]">
                  Password
                  <span className="text-red text-sm ml-1">*</span>
                </label>
                <div className="relative mt-1 md:w-[400px]">
                  <input
                    id="password"
                    className={`${
                      errors.password ? "border-[#CC0202]" : "border-[#162C5B]"
                    } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                    type={passwordVisible ? "text" : "password"}
                    {...register("password", {
                      required: "The password field is required",
                    })}
                    placeholder="********"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 px-3 flex items-center"
                  >
                    {passwordVisible ? (
                      <img src="/icons/OpenEye.svg" alt="open eye" />
                    ) : (
                      <img src="/icons/ClosedEye.svg" alt="closed eye" />
                    )}
                  </button>
                </div>
                {errors.password != null && (
                  <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                    <img src="/icons/ErrorAlert.svg" alt="error" />
                    {errors.password.message}
                  </small>
                )}
              </div>
              {isLoading ? (
                <button
                  disabled
                  className="flex justify-center items-center border border-[#325EBB] bg-[#325EBB] bg-opacity-[0.80] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[36px] gap-3"
                >
                  <img
                    width={22}
                    height={22}
                    src="/icons/Loading.svg"
                    alt="loading"
                  />
                  Signing Up ...
                </button>
              ) : (
                <button className="border border-[#325EBB] bg-[#325EBB] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[46px]">
                  Sign Up
                </button>
              )}
              <p className="text-center mt-[15px] font-medium">or</p>
            </form>
          </div>
          <GoogleLoginButton />
          <div className="text-center mt-[30px] text-[#a3a3a3] text-[12px] md:text-[14px]">
            <p className="text-[16px] text-[#56595F] mb-[16px]">
              Already have an account?{" "}
              <span>
                <NavLink
                  className={"text-[#0E2390] underline underline-offset-2"}
                  to={"login"}
                >
                  Log In
                </NavLink>
              </span>
            </p>
            <a
              href="https://retack.ai/terms&service"
              className="text-[#a3a3a3] text-[12px] md:text-[14px] mr-[8px]"
            >
              Terms and Conditions
            </a>
            <span>|</span>
            <a
              href="https://retack.ai/privacyPolicy"
              className="text-[#a3a3a3] text-[12px] md:text-[14px] ml-[8px]"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignUpForm;

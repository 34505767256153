import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import _ from "lodash";
import { setBarChartIssuesTimeRange } from "@src/redux/feature/issuesSlice";
import { useDispatch } from "react-redux";
import { ErrorToast } from "../toasts/ErrorToast";

interface FrequencyData {
  interval: string;
  frequency: string;
}

interface BarGraphProps {
  frequency_data: FrequencyData[];
  x_axis_label_count: number;
  y_axis_label_count: number;
}

export const BarDiagram = (props: BarGraphProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const margin = { top: 20, right: 0, bottom: 30, left: 30 };
  const brushRef = useRef<d3.BrushBehavior<unknown> | null>(null);
  const dispatch = useDispatch();

  const { frequency_data, x_axis_label_count, y_axis_label_count } = props;

  const [timestampList, setTimestampList] = useState<string[]>([]);
  const [containerWidth, setContainerWidth] = useState<number>(600);
  const [containerHeight, setContainerHeight] = useState<number>(300);
  const [modifiedFrequencyData, setModifiedFrequencyData] = useState<
    FrequencyData[]
  >([]);

  const parseDate = d3.timeParse("%Y-%m-%d %H:%M:%S");

  const validateIntervals = (differences: number[]) => {
    const minDifference = Math.min(...differences);
    if (minDifference < 1) {
      throw new Error("Intervals smaller than 1 millisecond are not allowed.");
    }
  };

  useEffect(() => {
    if (frequency_data.length > 0) {
      const sortedData = [...frequency_data].sort((a, b) => {
        const dateA = parseDate(a.interval.split(" to ")[0]);
        const dateB = parseDate(b.interval.split(" to ")[0]);
        return dateA!.getTime() - dateB!.getTime();
      });

      const intervals = sortedData.map(
        (d) => parseDate(d.interval.split(" to ")[0])!
      );

      const differences = intervals
        .slice(1)
        .map((date, index) => date.getTime() - intervals[index].getTime());

      const avgDifference = _.mean(differences);
      const lastIntervalDate = intervals[intervals.length - 1];
      const newIntervalDate = new Date(
        lastIntervalDate.getTime() + avgDifference
      );

      const newFrequencyData = [
        ...sortedData,
        {
          interval: d3.timeFormat("%Y-%m-%d %H:%M:%S")(newIntervalDate),
          frequency: "0",
        },
      ];

      setModifiedFrequencyData(newFrequencyData);
      setTimestampList(newFrequencyData.map((d) => d.interval));
    }
  }, [frequency_data]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const width = container.getBoundingClientRect().width || 600;
        const height = container.getBoundingClientRect().height || 300;
        setContainerWidth(width);
        setContainerHeight(height);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(timestampList) || !containerWidth) return;

    const width = containerWidth - margin.left - margin.right;
    const height = containerHeight - margin.top - margin.bottom;

    const frequencyList = _.map(modifiedFrequencyData, (data) =>
      Number(data.frequency)
    );
    const maxCount = Math.max(10, d3.max(frequencyList) || 0);

    const yScale = d3
      .scaleLinear()
      .domain([0, maxCount])
      .range([height, 0])
      .nice();

    const xScale = d3
      .scaleUtc()
      .domain(
        d3.extent(
          modifiedFrequencyData,
          (d) => parseDate(d.interval.split(" to ")[0])!
        ) as [Date, Date]
      )
      .range([0, width]);

    const yTicks = Array.from(
      new Set(
        d3
          .range(0, maxCount + 2, maxCount / (y_axis_label_count - 1))
          .map((d) => Math.round(d))
      )
    ).filter((d) => d !== 0);

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${containerWidth} ${containerHeight}`)
      .attr("width", "100%")
      .attr("height", "100%")
      .style("overflow", "visible")
      .attr("position", "relative");

    svg.selectAll("*").remove();

    const graph = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    graph
      .append("g")
      .selectAll("line")
      .data(yTicks)
      .enter()
      .append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "#D9DCED")
      .attr("stroke-width", "1");

    const brushX = d3
      .brushX()
      .extent([
        [0, 0],
        [width, height],
      ])
      .on("end", handleBrushEnd);

    brushRef.current = brushX;
    graph.append("g").attr("class", "brush").call(brushX);

    const tooltip = d3
      .select(containerRef.current)
      .append("div")
      .style("position", "absolute")
      .style("background-color", "white")
      .style("border", "1px solid #ccc")
      .style("padding", "5px")
      .style("border-radius", "3px")
      .style("box-shadow", "0 0 10px rgba(0, 0, 0, 0.1)")
      .style("pointer-events", "none")
      .style("display", "none");

    graph
      .append("g")
      .selectAll("rect")
      .data(modifiedFrequencyData)
      .enter()
      .append("rect")
      .attr("x", (d) => xScale(parseDate(d.interval.split(" to ")[0])!)!)
      .attr("y", (d) => yScale(Number(d.frequency)))
      .attr("width", width / modifiedFrequencyData.length - 1)
      .attr("height", (d) => height - yScale(Number(d.frequency)))
      .attr("fill", "#ED4337CC")
      .on("mouseover", function (_, d) {
        tooltip
          .style("display", "block")
          .style("background-color", "black")
          .style("font-size", "14px")
          .style("padding", "8px 16px")
          .style("color", "white")
          .style("border-radius", "6px")
          .html(`Frequency: ${d.frequency}<br>Interval: ${d.interval}`);
      })
      .on("mousemove", function (event) {
        const tooltipWidth = tooltip.node()!.offsetWidth;
        const spaceRight = window.innerWidth - event.pageX;
        if (spaceRight < tooltipWidth + 20) {
          tooltip
            .style("left", event.pageX - tooltipWidth - 10 + "px")
            .style("top", event.pageY - 10 + "px");
        } else {
          tooltip
            .style("left", event.pageX + 10 + "px")
            .style("top", event.pageY - 10 + "px");
        }
      })
      .on("mouseout", function () {
        tooltip.style("display", "none");
      });

    const firstInterval = parseDate(
      modifiedFrequencyData[0].interval.split(" to ")[0]
    );
    const lastInterval = parseDate(
      modifiedFrequencyData[modifiedFrequencyData.length - 1].interval.split(
        " to "
      )[0]
    );

    const [minDate, maxDate] = xScale.domain();
    const tickInterval =
      (maxDate.getTime() - minDate.getTime()) / (x_axis_label_count - 1);
    const tickValues = d3
      .range(x_axis_label_count)
      .map((i) => new Date(minDate.getTime() + i * tickInterval));

    if (firstInterval && lastInterval) {
      const totalRange = lastInterval.getTime() - firstInterval.getTime();
      const isLessThanOrEqualToOneDay = totalRange <= 24 * 60 * 60 * 1000;
      graph
        .append("g")
        .attr("transform", `translate(0,${height})`)
        .style("font-size", "10px")
        .style("font-weight", 600)
        .call(
          d3
            .axisBottom(xScale)
            .tickValues(tickValues)
            .tickFormat((d) => {
              const formatWithTime = d3.timeFormat("%H:%M:%S.%L");
              const formatWithoutTime = d3.timeFormat("%d %b");
              return isLessThanOrEqualToOneDay
                ? formatWithTime(d as Date)
                : formatWithoutTime(d as Date);
            })
            .tickSizeOuter(0)
            .tickSizeInner(15)
            .tickPadding(15)
        )
        .selectAll(".tick text")
        .style("font-size", "12px")
        .style("font-weight", "400");
    }

    graph
      .selectAll(".tick")
      .filter((_, i, nodes) => i === 0 || i === nodes.length - 1)
      .remove();

    graph
      .append("g")
      .style("font-size", "12px")
      .style("font-weight", "400")
      .call(
        d3
          .axisLeft(yScale)
          .tickSize(0)
          .tickFormat((d) => {
            const num = Number(d);
            if (num >= 1e9) {
              return num / 1e9 + "B";
            } else if (num >= 1e6) {
              return num / 1e6 + "M";
            } else if (num >= 1e3) {
              return num / 1e3 + "K";
            }
            return num.toString();
          })
          .tickValues(yTicks.map(Number))
          .tickSizeOuter(0)
          .tickPadding(15)
      )
      .call((g) => g.select(".domain").remove())
      .selectAll(".tick text")
      .style("font-size", "12px")
      .style("font-weight", "400")
      .style("fill", "#172B4D");

    function handleBrushEnd(event: any) {
      const selection = event.selection;
      if (selection === null) return;
      const [x0, x1] = selection.map(xScale.invert);

      const brushedIntervals = modifiedFrequencyData.filter((d) => {
        const date = parseDate(d.interval.split(" to ")[0]);
        return date && date >= x0 && date <= x1;
      });

      const brushedIntervalsWithBars = brushedIntervals.filter(
        (d) => Number(d.frequency) > 0
      );

      const isoDateFormat = d3.timeFormat("%Y-%m-%dT%H:%M:%S.%LZ");

      if (brushedIntervalsWithBars.length > 0) {
        const brushedDates = brushedIntervals.map(
          (d) => parseDate(d.interval.split(" to ")[0])!
        );

        const differences = brushedDates
          .slice(1)
          .map((date, index) => date.getTime() - brushedDates[index].getTime());

        try {
          validateIntervals(differences);

          dispatch(
            setBarChartIssuesTimeRange({
              from: isoDateFormat(
                parseDate(brushedIntervals[0].interval.split(" to ")[0])!
              ),
              to: isoDateFormat(
                parseDate(
                  brushedIntervals[brushedIntervals.length - 1].interval.split(
                    " to "
                  )[0]
                )!
              ),
            })
          );
        } catch (error) {
          ErrorToast({ Message: (error as Error).message });
          return;
        }
      } else {
        ErrorToast({ Message: "Selected Intervals does not contain issues" });
      }
    }
  }, [
    timestampList,
    modifiedFrequencyData,
    containerWidth,
    containerHeight,
    x_axis_label_count,
    y_axis_label_count,
  ]);

  return (
    <div ref={containerRef} className="w-[100%] h-[100%]">
      <svg ref={svgRef} />
    </div>
  );
};

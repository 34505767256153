import _ from "lodash";
import { useState, useContext } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorToast } from "@sharedComponents/toasts/ErrorToast";
import ArchiveEnvironmentModal from "../modal/ArchiveEnvironmentModal";
import { setEnvironmentData } from "@src/redux/feature/sourceSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@src/redux/store";
import {
  setIsEnvironmentDotsButtonOpen,
  setIsEditEnvironmentModalOpen,
  setIsSourceDotsButtonOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";

const EnvironmentDotsButton = (buttonProps: ArchiveButtonProps) => {
  const { id, is_active, environment_name, toggleArchive } = buttonProps;

  const dispatch = useDispatch<AppDispatch>();

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const { environmentData: environmentData } = sourceSlice;

  const stringId = id.toString();
  const [isArchiveEnvironmentOpen, setArchiveEnvironmentOpen] =
    useState<boolean>(false);

  const repository = useContext(RepositoryContext);
  const sourceRepo = repository.source;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const initialEnvironmentData = {
    id: id,
    name: environment_name,
  };

  const handleButtonClick = () => {
    dispatch(
      setIsEnvironmentDotsButtonOpen(
        environmentData.id == stringId
          ? !modalHandler.isEnvironmentDotsButtonOpen
          : true
      )
    );
    dispatch(setEnvironmentData(initialEnvironmentData));
    dispatch(setIsSourceDotsButtonOpen(false));
  };

  const toggleArchiveEnvironmentOpen = () => {
    setArchiveEnvironmentOpen(!isArchiveEnvironmentOpen);
  };

  const toggleDropDown = () => {
    setIsEnvironmentDotsButtonOpen(!modalHandler.isEnvironmentDotsButtonOpen);
  };

  const archiveEnvironment = async (id: number, isActive: boolean) => {
    try {
      const environmentArchiveStatus: EnvironmentArchiveStatusProps = {
        is_active: isActive,
      };
      const response = await sourceRepo.archiveEnvironment<EnvironmentData>(
        id,
        environmentArchiveStatus
      );

      if (!_.isUndefined(response)) {
        toggleArchive();
        dispatch(setIsEnvironmentDotsButtonOpen(false));
        if ("errors" in response) {
          ErrorToast({ Message: "Error occurred while archiving!" });
          await customLogger.reportErrorResponse(showBoundary, response);
        }
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  const editEnvironment = async () => {
    dispatch(setIsEditEnvironmentModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  return (
    <div>
      <button
        className="w-[20px] h-[5px]"
        id="menu-button"
        onClick={(event) => {
          handleButtonClick();
          event.stopPropagation();
        }}
      >
        <img
          className="w-[3.75px] h-[17px] object-contain"
          src="/icons/Dots.svg"
          alt="dots"
        />
      </button>
      {modalHandler.isEnvironmentDotsButtonOpen &&
        environmentData.id == stringId && (
          <>
            <div
              className={`absolute right-6 top-[-0.5rem] px-[16px] py-[14px] w-[152px]  rounded-md bg-white shadow-light`}
            >
              {is_active ? (
                <>
                  {" "}
                  <button
                    className="text-[#454545] w-[100%] text-[14px] flex gap-[5px]"
                    onClick={() => {
                      if (is_active) {
                        editEnvironment();
                      }
                    }}
                  >
                    <div className="w-[18px] h-[18px]">
                      <img
                        className="w-[100%] h-[100%] object-contain"
                        src="/icons/Edit.svg"
                        alt="dots"
                      />
                    </div>
                    Edit
                  </button>
                  <hr className="text-[#E5E7F2] my-[15px]" />
                </>
              ) : (
                ""
              )}

              <button
                className="text-[#454545] w-[100%] text-[14px] flex gap-[5px] z-10"
                onClick={() => {
                  is_active
                    ? toggleArchiveEnvironmentOpen()
                    : archiveEnvironment(id, true);
                }}
              >
                <div className="w-[18px] h-[18px]">
                  <img
                    className="w-[100%] h-[100%] object-contain"
                    src="/icons/Archive.svg"
                    alt="ArchiveIcon"
                  />
                </div>
                {is_active ? <>Archive</> : <>Unarchive</>}
              </button>
            </div>
          </>
        )}

      {isArchiveEnvironmentOpen && (
        <ArchiveEnvironmentModal
          id={id}
          is_active={is_active}
          environment_name={environment_name}
          toggleArchive={toggleArchive}
          toggleDropDown={toggleDropDown}
          toggleArchiveEnvironmentOpen={toggleArchiveEnvironmentOpen}
        />
      )}
    </div>
  );
};

export default EnvironmentDotsButton;

import { IJsonAPI } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import { parseApiResponse } from "@actions/parseApiResponse";

export class OrganizationRepository {
  private _api: IJsonAPI;

  private organization_URL = RepositoryURL.ORGANIZATION_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async isOrganizationMember<organizationData>() {
    const headers = new Map<string, string>();
    const organizationResponse = await this._api.get<organizationData>(
      `${this.organization_URL}members/is-organization-member/`,
      headers
    );
    return parseApiResponse<organizationData>(organizationResponse);
  }

  async addOrganization<addOrganizationResponse, organizationData>(
    data: organizationData
  ) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<
      addOrganizationResponse,
      organizationData
    >(`${this.organization_URL}`, data, headers);

    return parseApiResponse<addOrganizationResponse>(response);
  }

  async editOrganization<OrganizationData, AddOrgData>(
    orgId: string,
    data: AddOrgData
  ) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const response = await this._api.put<OrganizationData, AddOrgData>(
      `${this.organization_URL}${orgId}/`,
      data,
      headers
    );
    return parseApiResponse<OrganizationData>(response);
  }

  async getOrganization<organizationData>() {
    const headers = new Map<string, string>();
    const organizationResponse = await this._api.get<organizationData>(
      `${this.organization_URL}`,
      headers
    );
    return parseApiResponse<organizationData>(organizationResponse);
  }
}

import { IJsonAPI } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import { IProfileRepository } from "./types";
import { parseApiResponse } from "@actions/parseApiResponse";

export class ProfileRepository implements IProfileRepository {
  private _api: IJsonAPI;

  private PROFILE_URL = RepositoryURL.PROFILE_URL;
  private LOGIN_URL = RepositoryURL.LOGIN_URL;
  private ORGANIZATION_URL = RepositoryURL.ORGANIZATION_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async getProfile<ProfileData>() {
    const profileResponse = await this._api.get<ProfileData>(this.PROFILE_URL);
    return parseApiResponse<ProfileData>(profileResponse);
  }

  async getUsage<UsageData>(orgId: string | number) {
    const UsageResponse = await this._api.get<UsageData>(
      `${this.ORGANIZATION_URL}${orgId}/organization-feature/`
    );
    return parseApiResponse<UsageData>(UsageResponse);
  }

  async editProfile<ViewProfileData, ProfileData>(data: ProfileData) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.put<ViewProfileData, ProfileData>(
      `${this.LOGIN_URL}settings/profile/`,
      data,
      headers
    );
    return parseApiResponse<ViewProfileData>(response);
  }
}

import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import _ from "lodash";
import projectButtonData from "@utils/ButtonData.json";
import organizationButtonData from "@utils/OrganizationButtonData.json";
import SidebarButton from "@sharedComponents/buttons/SidebarButton";

interface ButtonData {
  source: string;
  name: string;
  label: string;
  link: string;
}

const SidebarMenu = () => {
  const params = useParams();
  const id = params.orgId;
  const [orgID] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });
  const orgId = id ? id : orgID;
  const currentURL = window.location.href;
  const urlSegments = currentURL.split("/");
  const projectsIndex = urlSegments.indexOf("projects");
  const organizationsIndex = urlSegments.indexOf("organization");
  let endpoint = "issues";

  if (projectsIndex !== -1 && projectsIndex + 2 < urlSegments.length) {
    endpoint = urlSegments.slice(projectsIndex + 2, projectsIndex + 3).join("/");
  } else if (
    organizationsIndex !== -1 &&
    organizationsIndex + 2 < urlSegments.length
  ) {
    endpoint = urlSegments
      .slice(organizationsIndex + 2, organizationsIndex + 3)
      .join("/");
  } else if (organizationsIndex !== -1) {
    endpoint = "projects";
  }

  endpoint = decodeURIComponent(endpoint);

  const location = useLocation();
  const isProject = location.pathname.includes("/projects");

  const [organizationData, setOrganizationData] = useState({
    ...organizationButtonData,
    menu: organizationButtonData.menu as ButtonData[]
  });

  useEffect(() => {
    const appendOrgIdToLinks = (
      data: ButtonData[],
      baseLink: string,
      idToAppend: string
    ): ButtonData[] => {
      return _.map(data, (item) => {
        let newLink = item.link;
        if (item.link.startsWith(baseLink) && !item.link.endsWith(idToAppend)) {
          newLink = `${baseLink}/${idToAppend}`;
        }
        return { ...item, link: newLink };
      });
    };

    const baseLink = "/organization"; // Base link to check for appending
    const idToAppend = String(orgId); // Convert orgId to string

    const updatedMenu = appendOrgIdToLinks(
      organizationData.menu,
      baseLink,
      idToAppend
    );

    if (!_.isEqual(updatedMenu, organizationData.menu)) {
      setOrganizationData((prevData) => ({ ...prevData, menu: updatedMenu }));
    }
  }, [orgId, orgID]); 

  return (
    <div>
      {isProject ? (
        <div className="flex flex-col">
          <div className="flex flex-col items-start px-5 py-3 gap-4">
            <div>
              {_.map(projectButtonData, (button, index) => (
                <SidebarButton
                  key={index}
                  source={button.source}
                  name={button.name}
                  label={button.label}
                  active={button.label.toLowerCase() === endpoint}
                  link={button.link}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-8 mt-4">
          <div className="flex flex-col items-start px-5 py-3 gap-4">
            <div>
              {_.map(organizationData.menu, (button, index) => (
                <SidebarButton
                  key={index}
                  source={button.source}
                  name={button.name}
                  label={button.label}
                  active={button.label.toLowerCase() === endpoint}
                  link={button.link}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SidebarMenu;

import EditProfileModal from "@src/components/sharedComponents/modal/EditProfileModal";
import {
  setIsEditProfileModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { useDispatch, useSelector } from "react-redux";

const AccountSettings = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.profileSlice.user);
  const isEditProfileModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isEditProfileModalOpen
  );
  const openEditProfileModal = () => {
    dispatch(setIsEditProfileModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeEditProfileModal = () => {
    dispatch(setIsEditProfileModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  return (
    <div className="p-[25px] md:p-[36px] flex flex-col h-[90vh] justify-between xl:w-full md:w-[732px] w-full">
      <div>
        <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue">
          Account Settings
        </h1>
        <div className="flex flex-col gap-[20px] mt-[44px] text-[#172B4D]">
          <div className="flex">
            <p className="w-[250px] font-medium">First Name</p> :
            <p className="ml-[24px]">{userData?.first_name}</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Last Name </p> :
            <p className="ml-[24px]">{userData?.last_name}</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Email Address</p> :
            <p className="ml-[24px]">{userData?.email}</p>
          </div>
        </div>
        <div className="mt-[62px]">
          <button
            onClick={openEditProfileModal}
            className="border border-[#001789] px-[28px] py-[10px] rounded-[6px] text-[12px] font-bold text-[#001789]"
          >
            Edit Acccount Settings
          </button>
        </div>
      </div>

      <div className="border border-[#DCDCDE]">
        <div className="px-[26px] py-[20px]">
          <h1 className="text-[16px] text-[#CD3D25] font-bold">
            Delete Account
          </h1>
        </div>
        <div className="pt-[13px] bg-[#F9EBEB] px-[26px] pb-[18px] border border-[#E3C0C4]">
          <p>
            Deleting this account will permanently remove it along with all
            associated data, and this action cannot be undone.
          </p>

          <button
            disabled
            className="p-[10px] bg-[#CD3D25] rounded-[6px] font-medium text-[12px] text-white mt-[18px] cursor-not-allowed"
          >
            Delete Account
          </button>
        </div>
      </div>
      {isEditProfileModalOpen && (
        <EditProfileModal closeEditProfileModal={closeEditProfileModal} />
      )}
    </div>
  );
};

export default AccountSettings;

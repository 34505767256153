import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getDateTimeStates } from "@actions/getDateTimeStates";
import { subWeeks } from "date-fns";
import _ from "lodash";

function formatDate(isoString: string): string {
  const date = new Date(isoString);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };

  // Format the date parts
  const formatter = new Intl.DateTimeFormat("en-US", options);
  const parts = formatter.formatToParts(date);

  // Extract the parts and transform them as needed
  const day = parts.find((part) => part.type === "day")?.value;
  const month = parts.find((part) => part.type === "month")?.value;
  const hour = parts.find((part) => part.type === "hour")?.value;
  const minute = parts.find((part) => part.type === "minute")?.value;
  let period = parts.find((part) => part.type === "dayPeriod")?.value;

  // Convert period to uppercase
  period = period?.toUpperCase();

  // Combine the parts into the desired format
  return `${day} ${month} ${hour}:${minute} ${period}`;
}

// Generate DateTimeStates for the start and end dates
const startDateStates: DateTimeStates = getDateTimeStates(
  subWeeks(new Date(), 1)
);
const endDateStates: DateTimeStates = getDateTimeStates(new Date());

// Create an initial date range state
const initialDateRange: DateRangePickerStates = {
  startDateStates: startDateStates,
  endDateStates: endDateStates,
  combinedDateRange: `${startDateStates.formattedDate} to ${endDateStates.formattedDate}`,
  showDateRangePicker: false,
  rangePickerViewIcon: "DownArrow",
};

// Define the initial state for the Issues feature
const initialState: IssuesSliceProps = {
  dateRangeStates: initialDateRange,
  request_params: {
    q: "",
    page: 1,
    s: [],
    e: [],
    sort_by: "Total Count",
    timeRange: {
      from: subWeeks(new Date(), 1).toISOString(),
      to: new Date().toISOString(),
    },
  },
  issues_response: {
    meta_data: {
      total_pages: 0,
      total_items: 0,
      current_page: 0,
      has_next_page: false,
      has_previous_page: false,
    },
    issue_data: [],
    errors_frequencies: [],
  },
  sourceData: [],
};

export const issuesSlice = createSlice({
  name: "issuesSlice",
  initialState,
  reducers: {
    /**
     * Sets the query parameter in the state to the provided string.
     *
     * @param state Represents the current state of the slice.
     * @param action The string to be set as the query parameter.
     */
    setIssuesQuery: (state, action: PayloadAction<string>) => {
      state.request_params.q = action.payload;
    },

    /**
     * Sets the page number parameter in the state to the provided string.
     *
     * @param state Represents the current state of the slice.
     * @param action The string to be set as the page number parameter.
     */
    setIssuesPageNumber: (state, action: PayloadAction<number>) => {
      state.request_params.page = action.payload;
    },

    /**
     * Sets the sort by parameter in the state to the provided string.
     *
     * @param state Represents the current state of the slice.
     * @param action The string to be set as the sort by parameter.
     */
    setIssuesSortBy: (state, action: PayloadAction<string>) => {
      state.request_params.sort_by = action.payload;
    },

    /**
     * Adds or updates the time range, formatted date, visibility, and icon in the DateRangePicker component.
     * Updates all states based on the current values in the slice.
     *
     * @param state Represents the current state of the slice.
     */
    setIssuesTimeRange: (state) => {
      // Extract selected dates in ISO string format
      const startDateISOString =
        state.dateRangeStates.startDateStates.selectedDate;

      const endDateISOString = state.dateRangeStates.endDateStates.selectedDate;

      // Create a TimeRange object with start and end dates
      state.request_params.timeRange = {
        from: startDateISOString,
        to: endDateISOString,
      };

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
      state.dateRangeStates.rangePickerViewIcon = "DownArrow";
    },

    resetdateRangeStates: (state) => {
      state.dateRangeStates = initialDateRange;
    },

    resetIssueParams: (state) => {
      state.request_params.q = "";
      state.request_params.page = 1;
      state.request_params.s = [];
      state.request_params.e = [];
      state.request_params.sort_by = "Total Count";
      state.request_params.timeRange = {
        from: subWeeks(new Date(), 1).toISOString(),
        to: new Date().toISOString(),
      };
    },

    resetIssueSourceEnvironmentParams: (state) => {
      state.request_params.s = [];
      state.request_params.e = [];
    },

    /**
     * Adds or removes a source from the list of issues s in the state.
     * If the source is not already present, it adds it; otherwise, it removes it.
     *
     * @param state Represents the current state of the slice.
     * @param action The source to be added or removed from the list.
     */
    addIssuesSource: (state, action: PayloadAction<number>) => {
      // Check if the payload source is not already in the s array
      if (!_.includes(state.request_params.s, action.payload)) {
        // Add the payload source to the s array using spread syntax
        state.request_params.s = [...state.request_params.s, action.payload];
      } else {
        // Remove the payload source from the s array
        const differenceList = _.difference(state.request_params.s, [
          action.payload,
        ]);
        state.request_params.s = differenceList;
      }
    },

    /**
     * Adds or removes an environment from the list of issues e in the state.
     * If the environment is not already present, it adds it; otherwise, it removes it.
     *
     * @param state Represents the current state of the slice.
     * @param action The environment to be added or removed from the list.
     */
    addIssuesEnvironment: (state, action: PayloadAction<number>) => {
      // Check if the payload environment is not already in the e array
      if (!_.includes(state.request_params.e, action.payload)) {
        // Add the payload environment to the e array using spread syntax
        state.request_params.e = [...state.request_params.e, action.payload];
      } else {
        // Remove the payload environment from the e array
        const differenceList = _.difference(state.request_params.e, [
          action.payload,
        ]);
        state.request_params.e = differenceList;
      }
    },
    setIssuesSourceEnvironment: (
      state,
      action: PayloadAction<[number, number]>
    ) => {
      const [s, e] = action.payload;

      // Check if the payload environment is not already in the s array
      if (!state.request_params.s.includes(s)) {
        // Add the second number (s) to the s array
        state.request_params.s = [...state.request_params.s, s];
      } else {
        // Remove the second number (s) from the s array
        state.request_params.s = state.request_params.s.filter(
          (item) => item !== s
        );
      }

      // Check if the payload environment is not already in the e array
      if (!state.request_params.e.includes(e)) {
        // Add the first number (e) to the e array
        state.request_params.e = [...state.request_params.e, e];
      } else {
        // Remove the first number (e) from the e array
        state.request_params.e = state.request_params.e.filter(
          (item) => item !== e
        );
      }
    },
    /**
     * Sets the issues response based on the provided payload.
     * If the current page number is 1, it replaces the existing issues response; otherwise, it appends to the existing issues data.
     *
     * @param state Represents the current state of the slice.
     * @param action The payload containing the issues response data.
     */
    setIssuesResponse: (state, action: PayloadAction<IssuesResponse>) => {
      state.issues_response = action.payload;
    },

    /**
     * Sets the environment data in the state.
     *
     * @param state Represents the current state of the slice.
     * @param action The payload containing the environment data.
     */
    setSourceData: (state, action: PayloadAction<SourceData[]>) => {
      // Update the environmentData property in the state
      state.sourceData = action.payload;
    },

    /**
     * Sets the start date states for a specific component in the state.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the properties of start date states.
     */
    setIssuesStartDateStates: (
      state,
      action: PayloadAction<DateTimeStates>
    ) => {
      // Update the start date states for the specified component
      state.dateRangeStates.startDateStates = action.payload;

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
    },

    /**
     * Sets the end date states for a specific component in the state.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the properties of end date states.
     */
    setIssuesEndDateStates: (state, action: PayloadAction<DateTimeStates>) => {
      // Update the end date states for the specified component
      state.dateRangeStates.endDateStates = action.payload;

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
    },

    /**
     * Sets the visibility of the date range picker and related properties for a specific component.
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the component name and showDateRangePicker boolean.
     */
    setShowIssuesRangePicker: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      if (typeof action.payload === "undefined") {
        // Toggle the visibility of the date range picker
        state.dateRangeStates.showDateRangePicker =
          !state.dateRangeStates.showDateRangePicker;
      } else {
        state.dateRangeStates.showDateRangePicker = action.payload;
      }

      // Update the rangePickerViewIcon based on the visibility
      state.dateRangeStates.rangePickerViewIcon = state.dateRangeStates
        .showDateRangePicker
        ? "UpArrow"
        : "DownArrow";
    },

    /**
     * Shows or hides the date picker for a specific date type (from or to).
     *
     * @param state Represents the current state of the slice.
     * @param action PayloadAction containing the component name and dateType (from or to).
     */
    setBarChartIssuesTimeRange: (
      state,
      action: PayloadAction<{ from: string; to: string }>
    ) => {
      const { from, to } = action.payload;

      // Set raw ISO dates in request_params
      state.request_params.timeRange.from = from;
      state.request_params.timeRange.to = to;

      // Format dates for dateRangeStates
      const formattedFrom = formatDate(from);
      const formattedTo = formatDate(to);

      // Update dateRangeStates with formatted dates

      state.dateRangeStates.startDateStates.selectedDate = formattedFrom;
      state.dateRangeStates.startDateStates.formattedDate = formattedFrom;
      state.dateRangeStates.startDateStates.normalDate = new Date(from);
      state.dateRangeStates.endDateStates.selectedDate = formattedTo;
      state.dateRangeStates.endDateStates.formattedDate = formattedTo;
      state.dateRangeStates.endDateStates.normalDate = new Date(to);
      state.dateRangeStates.combinedDateRange = `${formattedFrom} to ${formattedTo}`;
    },
  },
});

export const {
  setIssuesQuery,
  addIssuesEnvironment,
  addIssuesSource,
  setIssuesTimeRange,
  setIssuesResponse,
  setIssuesSortBy,
  setIssuesPageNumber,
  setSourceData,
  setIssuesEndDateStates,
  setShowIssuesRangePicker,
  setIssuesStartDateStates,
  resetdateRangeStates,
  resetIssueParams,
  resetIssueSourceEnvironmentParams,
  setIssuesSourceEnvironment,
  setBarChartIssuesTimeRange,
} = issuesSlice.actions;

export default issuesSlice.reducer;

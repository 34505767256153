import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import MessageResetSuccess from "@src/pages/verification/MessageResetSuccess";
import { RepositoryContext, LoggerContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";

const ResetForgotPasswordForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const repository = useContext(RepositoryContext);
  const activationRepo = repository.login;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetForgotPassword>();

  const [verificationStatus, setVerificationStatus] = useState<
    "pending" | "success" | "failed"
  >("pending");

  useEffect(() => {
    const verify = async (uid: string, token: string) => {
      const data: VerificationData = { uid, token, type: "reset" };
      try {
        const response = await activationRepo.Verify<
          VerificationData,
          VerificationData
        >(data);
        if (_.isString(response)) {
          setVerificationStatus("success");
        } else if (_.isObject(response)) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
            setVerificationStatus("failed");
          } else {
            setVerificationStatus("success");
          }
        } else {
          setVerificationStatus("failed");
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
        setVerificationStatus("failed");
      }
    };

    const parsedUrl = new URL(window.location.href);
    if (parsedUrl.pathname) {
      const parts = parsedUrl.pathname.split("/").filter(Boolean);
      if (parts.length === 3 && parts[0] === "reset") {
        const uid = parts[1];
        const tokenId = parts[2];
        verify(uid, tokenId);
      }
    } else {
      setVerificationStatus("failed");
    }
  }, [activationRepo, customLogger, showBoundary]);

  if (verificationStatus === "pending") {
    return (
      <div className="h-screen flex justify-center items-center">
        <img width={120} height={120} src="/icons/Loading.svg" alt="loading" />
      </div>
    );
  }

  if (verificationStatus === "failed") {
    return (
      <div className="flex flex-col justify-center items-center gap-[20px]">
        <img
          width={50}
          height={50}
          src="/images/exception.png"
          alt="Exception"
        />
        <p className="font-bold text-[30px] text-center">
          Sorry, your token has expired
        </p>
        <p className="text-[18px] text-center">
          Please request email for password reset again
        </p>
        <div className="flex justify-center items-center">
          <button className="text-lg font-bold text-center">
            <NavLink
              to="/login"
              className="text-[#112692] font-medium text-lg md:text-base lg:text-lg"
            >
              Resend email for password reset
            </NavLink>
          </button>
        </div>
      </div>
    );
  }

  const onSubmit = async (data: ResetForgotPassword) => {
    if (!verificationStatus) {
      return;
    }
    const parsedUrl = new URL(window.location.href);
    const parts = parsedUrl.pathname.split("/").filter(Boolean);
    if (parts.length === 3 && parts[0] === "reset") {
      const uid = parts[1];
      const token = parts[2];
      const requestData = { ...data, uid, token, type: "reset" };
      try {
        const response = await activationRepo.resetForgotPassword<
          ResetForgotPassword,
          ResetForgotPassword
        >(requestData);
        if (response && typeof response === "object") {
          if ("errors" in response) {
            _.forEach(response.errors, (error) => {
              const validField = error.title as "password" | "confirm_password";
              setError(validField, { message: error.message });
              if (
                validField !== "confirm_password" &&
                validField !== "password"
              ) {
                ErrorToast({ Message: error.message });
              }
            });
            await customLogger.reportErrorResponse(showBoundary, response);
          }
        } else {
          if (typeof response === "string") {
            SuccessToast({
              Message: "Password has been reset successfully",
            });
            setResetSuccess(true);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    }
  };

  return (
    <>
      {resetSuccess ? (
        <MessageResetSuccess />
      ) : (
        <form
          className="flex flex-col justify-center items-center w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className=" text-[18px] text-[#162C5B] md:text-[28px] font-bold text-center">
            Password
          </h3>
          <div className="flex justify-center text-center mb-[36px]">
            <p className="w-[239px] md:w-[450px] text-[14px] md:text-[16px] mt-[10px]">
              Please enter a new password to get started
            </p>
          </div>
          <div className="mt-5 flex flex-col w-full md:w-[400px]">
            <label className="text-[#42454A] font-[500]">New Password</label>
            <div className="relative mt-1">
              <input
                id="newPassword"
                className={`${
                  errors.password ? "border-[#CC0202]" : "border-[#162C5B]"
                } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                type={passwordVisible ? "text" : "password"}
                {...register("password", { required: "Password is required" })}
              />
              <div className="absolute inset-y-0 right-0 px-3 flex items-center">
                <button type="button" onClick={togglePasswordVisibility}>
                  {passwordVisible ? (
                    <img src="/icons/OpenEye.svg" alt="open eye" />
                  ) : (
                    <img src="/icons/ClosedEye.svg" alt="closed eye" />
                  )}
                </button>
              </div>
            </div>
            {errors.password != null && (
              <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                <img src="/icons/ErrorAlert.svg" alt="error" />
                {errors.password.message}
              </small>
            )}
          </div>
          <div className="mt-5 flex flex-col w-full md:w-[400px]">
            <label className="text-[#42454A] font-[500]">
              Confirm Password
            </label>
            <div className="relative mt-1">
              <input
                id="confirmPassword"
                className={`${
                  errors.confirm_password
                    ? "border-[#CC0202]"
                    : "border-[#162C5B]"
                } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
                type={confirmPasswordVisible ? "text" : "password"}
                {...register("confirm_password", {
                  required: "Confirm Password is required",
                })}
              />
              <div className="absolute inset-y-0 right-0 px-3 flex items-center">
                <button type="button" onClick={toggleConfirmPasswordVisibility}>
                  {confirmPasswordVisible ? (
                    <img src="/icons/OpenEye.svg" alt="open eye" />
                  ) : (
                    <img src="/icons/ClosedEye.svg" alt="closed eye" />
                  )}
                </button>
              </div>
            </div>
            {errors.confirm_password != null && (
              <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
                <img src="/icons/ErrorAlert.svg" alt="error" />
                {errors.confirm_password.message}
              </small>
            )}
          </div>
          <button
            type="submit"
            className="border border-[#325EBB] bg-[#325EBB] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[46px]"
          >
            Reset Password
          </button>
          <div className="text-center mt-[30px] text-[#a3a3a3] text-[12px] md:text-[14px]">
            <p className="text-[16px] text-[#56595F] mb-[16px]">
              Don’t have an account?{" "}
              <span>
                <NavLink
                  className={"text-[#0E2390] underline underline-offset-2"}
                  to={"/signUp"}
                >
                  Sign Up
                </NavLink>
              </span>
            </p>
            <a
              href="https://retack.ai/terms&service"
              className="text-[#a3a3a3] text-[12px] md:text-[14px] mr-[8px]"
            >
              Terms and Conditions
            </a>
            <span>|</span>
            <a
              href="https://retack.ai/privacyPolicy"
              className="text-[#a3a3a3] text-[12px] md:text-[14px] ml-[8px]"
            >
              Privacy Policy
            </a>
          </div>
        </form>
      )}
    </>
  );
};

export default ResetForgotPasswordForm;

import { parseRepositoryResponse } from "@src/actions/parseRepositoryResponse";
import { setDemoIssues } from "@src/actions/setDemoIssues";
import { setIssuesPageNumber } from "@feature/issuesSlice";
import Pagination from "@src/components/Pagination";
import { DemoIssueDetails } from "@src/components/sharedComponents/issuesExplorer/DemoIssueDetails";
import {
  setIssueDetailsResponse,
} from "@src/redux/feature/issueDetailsSlice";
import { setIsShowIssueDetails } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SourceEnvironmentUserBadge from "@src/components/sharedComponents/SourceEnvironmentBadge";

const DemoCodeFix = () => {
  const dispatch = useDispatch();
  const issuesSlice = useSelector((state: RootState) => state.issuesSlice);
  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const issues_data = issuesSlice.issues_response.issue_data;

  const repository = useContext(RepositoryContext);
  const issuesRepo = repository.issues;
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const { total_pages, current_page, has_next_page, has_previous_page } =
    issuesSlice.issues_response.meta_data;

  const handleNextPage = () => {
    if (has_next_page) {
      dispatch(setIssuesPageNumber(current_page + 1));
    }
  };

  const handlePreviousPage = () => {
    if (has_previous_page) {
      dispatch(setIssuesPageNumber(current_page - 1));
    }
  };

  const setPageNumber = (page: number) => {
    dispatch(setIssuesPageNumber(page));
  };

  const handleShowIssueDetails = async (id: number | undefined) => {
    if (!_.isNull(id) && !_.isNull(projectSlice.project_id)) {
      const issueDetailsResponse =
        await issuesRepo.fetchDemoIssueDetails<IssueDetailsResponse>(
          id!.toString()
        );

      const issueDetails = await parseRepositoryResponse(
        issueDetailsResponse,
        customLogger,
        showBoundary
      );

      if (!_.isUndefined(issueDetails)) {
        dispatch(setIssueDetailsResponse(issueDetails));
      }
      dispatch(setIsShowIssueDetails(true));
    }
  };

  useEffect(() => {
    if (!_.isNull(projectSlice.project_id)) {
      setDemoIssues(
        issuesRepo,
        issuesSlice.request_params,
        customLogger,
        showBoundary,
        dispatch
      ).catch(async (error) => {
        showBoundary(error);
        await customLogger.reportError(error);
      });
    }
  }, [
    projectSlice.project_id,
    customLogger,
    issuesRepo,
    issuesSlice.request_params,
    dispatch,
    showBoundary,
  ]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full h-[50vh] items-center justify-center">
          <img
            className="w-[50px]"
            src="/icons/Loading.svg"
            alt="LoadingIcon"
          />
        </div>
      ) : (
        <div>
          <div className="p-[25px] md:p-[36px]">
            <div className="flex flex-col">
              <div className="flex justify-between">
                <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
                  Code Fix
                </h1>
              </div>
              <div className="flex mb-5 h-[50px] bg-white items-center border border-border_gray rounded-[8px] px-3">
                <img
                  src="/icons/Search.svg"
                  alt="SearchIcon"
                  className="w-[14px]"
                />
                <input
                  type="text"
                  className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2"
                  placeholder="Search"
                />
              </div>
            </div>
            <div className="py-[11px]">
              <div className="overflow-x-auto">
                <table className="w-full text-[#292C31] bg-[#F3F4F8]  overflow-y-auto">
                  <thead className="text-[14px] text-left ">
                    <tr>
                      <th className="font-[700] py-3 px-6">Issue Title</th>
                      <th className="font-[700] py-3 px-6">Fix Status</th>
                      <th className="font-[700] py-3 px-6">Date</th>
                      <th className="font-[700] py-3 px-6">Started By</th>
                      <th className="font-[700] py-3 px-6">Token Used</th>
                    </tr>
                  </thead>
                  <tbody className="text-[12px]">
                    {issues_data.map((item, index) => (
                      <tr
                        key={item.id}
                        className={`${
                          index % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"
                        }`}
                      >
                        <td className="py-3 px-6">
                          <div
                            onClick={() => handleShowIssueDetails(item.id)}
                            className=" max-w-[350px] font-bold text-[14px] leading-[23px] text-[#292C31] whitespace-nowrap overflow-hidden overflow-ellipsis cursor-pointer"
                          >
                            {item.title}
                          </div>
                          <SourceEnvironmentUserBadge
                            source={item.origin[0].source}
                            environment={item.origin[0].environment}
                          />
                        </td>
                        <td className="py-3 px-6">
                          <NavLink to={`${item.id}/issueStatus`}>
                            <div className="bg-[#0051B0] bg-opacity-[0.10] text-[#0051B0] cursor-pointer px-[25px] py-[10px] rounded-full text-center max-w-[130px]">
                              Completed
                            </div>
                          </NavLink>
                        </td>
                        <td className="py-3 px-6">
                          <NavLink to={`${item.id}/issueStatus`}>
                            <div className="cursor-pointer flex flex-col text-[#292C31] leading-[23px] tracking-[0.5px] gap-2 text-[14px]">
                              20 May 2023
                              <div className="flex items-center text-xs font-normal leading-5 mr-[36px] gap-2">
                                <img
                                  className="h-[12px]"
                                  src="/icons/Clock.svg"
                                  alt="Clock"
                                />
                                <div> 00:00:20</div>
                              </div>
                            </div>
                          </NavLink>
                        </td>
                        <td className="py-3 px-6">
                          <NavLink to={`$${item.id}/issueStatus`}>
                            <div className="cursor-pointer mb-6 leading-[23px] text-[#292C31] tracking-[0.5px] text-[14px]">
                              Demo User
                            </div>
                          </NavLink>
                        </td>
                        <td className="py-3 px-6">
                          <NavLink to={`${item.id}/issueStatus`}>
                            <div className="cursor-pointer mb-6 leading-[23px] text-[#292C31] tracking-[0.5px] text-[14px] flex gap-[10px]">
                              <img src="/icons/Token.svg" alt="token" />
                              <div>2.3k</div>
                            </div>
                          </NavLink>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="flex justify-between items-center mt-[20px]">
                <p className="text-[12px]">Total {total_pages} pages</p>
                <Pagination
                  totalPages={total_pages}
                  currentPage={current_page}
                  onPageChange={setPageNumber}
                  hasNextPage={has_next_page}
                  hasPreviousPage={has_previous_page}
                  onNextPage={handleNextPage}
                  onPreviousPage={handlePreviousPage}
                />
              </div>
            </div>
            <DemoIssueDetails />
          </div>
        </div>
      )}
    </>
  );
};

export default DemoCodeFix;

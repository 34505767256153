import EditEmailIntegrationModal from "./EditEmailIntegrationModal";
import EditSlackIntegrationModal from "./EditSlackIntegrationModal";
import EditTeamsIntegrationModal from "./EditTeamsIntegrationModal";

const EditIntegrationModal = ({
  closeEditIntegrationModal,
  integrationName,
}: EditIntegrationModalProps) => {

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      {integrationName === "Slack" ? (
        <EditSlackIntegrationModal
          closeEditIntegrationModal={closeEditIntegrationModal}
          integrationName={integrationName}
        />
      ) : integrationName === "Microsoft Teams" ? (
        <EditTeamsIntegrationModal
          closeEditIntegrationModal={closeEditIntegrationModal}
          integrationName={integrationName}
        />
      ) : (
        <EditEmailIntegrationModal
          closeEditIntegrationModal={closeEditIntegrationModal}
          integrationName={integrationName}
        />
      )}
    </div>
  );
};

export default EditIntegrationModal;

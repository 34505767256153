import DemoGitHubLabStateCard from "./DemoGitHubLabStateCard";
import _ from "lodash";

const DemoGitHubLabCard = () => {
  return (
    <div>
      <DemoGitHubLabStateCard />
    </div>
  );
};

export default DemoGitHubLabCard;

import { useState } from "react";
import DeleteEnvironmentKeyModal from "../modal/DeleteEnvironmentKeyModal";

const DeleteEnvironmentKeyButton = ({
	id,
	is_active,
	environment_name
}: DeleteEnvironmentKeyButtonProps) => {
  const [isDeleteEnvironmentKeyOpen, setDeleteEnvironmentKeyOpen] =
    useState<boolean>(false);

  const toggleDeleteEnvironmentKeyOpen = () => {
    setDeleteEnvironmentKeyOpen(!isDeleteEnvironmentKeyOpen);
  };

  return (
    <>
      <img
        src="/icons/Delete.svg"
        alt="Delete Icon"
        className={`cursor-pointer ${
          !is_active ? "pointer-events-none" : "cursor-pointer"
        }`}
        onClick={() => {
          if (is_active) toggleDeleteEnvironmentKeyOpen();
        }}
      />

			{isDeleteEnvironmentKeyOpen && (
				<DeleteEnvironmentKeyModal
					id={id}
					environment_name={environment_name}
					toggleDeleteEnvironmentKeyOpen={toggleDeleteEnvironmentKeyOpen}
				/>
			)}
		</>
	);
};

export default DeleteEnvironmentKeyButton;

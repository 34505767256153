import React, { useContext, useState, useEffect } from "react";
import { RepositoryContext } from "@src/shared/contexts";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import _ from "lodash";

// test
interface MemberInvitationPageProps {
  message: string;
}

const MemberInvitationPage: React.FC<MemberInvitationPageProps> = () => {
  const repository = useContext(RepositoryContext);
  const memberInvitationRepo = repository.memberInvitation;
  const [isValidInvitation, setIsValidInvitation] = useState<boolean | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const SITE_URL = import.meta.env.VITE_WEB_URL;
  //Todo
  // Add loading state
  // set loading to true before call fetch data and set it to false after it resolve.
  // check if loading is true and invitation is null then show loading icon.

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const requestURL = window.location.href;
        const response = await memberInvitationRepo.getResponse(requestURL);
        if (response && typeof response === "object") {
          if ("errors" in response) {
            ErrorToast({ Message: response.errors[0].message });
            setIsValidInvitation(false);
          } else {
            setIsValidInvitation(true);
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [memberInvitationRepo]);

  return (
    <>
      {isLoading && isValidInvitation === null && <div>Loading...</div>}

      {!isLoading && isValidInvitation !== null && (
        <>
          {isValidInvitation ? (
            <div className="flex justify-center">
              <div
                className="w-full md:w-[40%] border border-[#D9D9D9] mt-12"
                id="divIndex"
              >
                <div className="h-[104px] md:h-15 flex items-center bg-[#172b4d] text-white px-8">
                  <img src="/images/Group.png" alt="Logo" className="h-10" />
                </div>
                <div className="flex justify-center items-center mt-6">
                  <img src="/images/Tickmark.png" alt="Tick Mark" />
                </div>
                <div className="flex justify-center items-center mt-8">
                  <p className="font-bold text-3xl text-center">
                    Congratulation !!
                  </p>
                </div>
                <div className="flex justify-center items-center mt-3">
                  <p className="text-lg text-center mt-5">You are onboard.</p>
                </div>
                <div className="flex justify-center items-center mt-3 mb-2">
                  <p className="text-lg font-bold text-center mt-5">
                    <a
                      href={SITE_URL}
                      className="text-[#112692] font-medium text-lg md:text-base lg:text-lg"
                    >
                      Get Started
                    </a>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex justify-center">
              <div className="w-full md:w-[40%] border border-[#D9D9D9] mt-12">
                <div className="h-[104px] md:h-15 flex items-center bg-[#172b4d] text-white px-8">
                  <img src="/images/Group.png" alt="Logo" className="h-10" />
                </div>
                <div className="flex justify-center items-center mt-6">
                  <img src="/images/exception.png" alt="Exception" />
                </div>
                <div className="flex justify-center items-center mt-8">
                  <p className="font-bold text-3xl text-center">Oops !!</p>
                </div>
                <div className="flex justify-center items-center mt-3">
                  <p className="text-lg text-center mt-5">
                    Looks like the invitation link is invalid.
                  </p>
                </div>
                <div className="flex justify-center items-center mt-3 mb-2">
                  <p className="text-lg font-bold text-center mt-5">
                    <a
                      href={SITE_URL}
                      className="text-[#112692] font-medium text-lg md:text-base lg:text-lg"
                    >
                      Try Log In
                    </a>
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MemberInvitationPage;

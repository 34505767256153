import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { clearAuthTokens } from "@src/redux/feature/authSlice";
import { getLocalStorageClient, customLogger } from "@src/shared/contexts";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import {
  resetCurrentOrganization,
  setCurrentOrganization,
} from "@src/redux/feature/organizationSlice";
import {
  setIsAddOrganizationModalOpen,
  setIsOverlayVisible,
  setIsViewOrganizationModalOpen,
} from "@src/redux/feature/modalHandler";
import CustomToolTip from "@src/components/sharedComponents/CustomToolTip";

const SidebarOrganizationMenu = () => {
  const [popup, setPopup] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const paramsOrgId = params.orgId;

  const [orgId, setOrgId] = useState(0);

  useEffect(() => {
    if (paramsOrgId !== undefined) {
      setOrgId(parseInt(paramsOrgId));
    } else {
      const storedOrgId = localStorage.getItem("orgId");
      if (storedOrgId) {
        setOrgId(parseInt(storedOrgId));
      }
    }
  }, [paramsOrgId]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const local = getLocalStorageClient();
    await local.clearTokens();
    localStorage.removeItem("orgId");
    dispatch(clearAuthTokens());
    dispatch(resetCurrentOrganization());
    SuccessToast({ Message: "Logged Out successfully!" });
    const info: InfoLog = {
      message: "User logged out.",
    };
    await customLogger.log(info);
    navigate("/login");
  };

  const organizationList = useSelector(
    (state: RootState) => state.organizationSlice.organizationList
  );
  const userData = useSelector((state: RootState) => state.profileSlice.user);

  const togglePopup = () => {
    setPopup((prevPopup) => !prevPopup);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setPopup(false);
    }
  };

  useEffect(() => {
    if (popup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popup]);

  const processName = (name: string) => {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
    const capitalizedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { initials, capitalizedName };
  };

  const handleCurrentOrganization = (id: number) => {
    const currentOrganization = organizationList.find(
      (org) => org.id === parseInt(id.toString())
    );
    localStorage.setItem("orgId", id.toString());

    dispatch(setCurrentOrganization(currentOrganization!));
  };

  const openViewOrganizationModal = () => {
    dispatch(setIsViewOrganizationModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const openAddOrganizationModal = () => {
    dispatch(setIsAddOrganizationModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const renderOrganizationMenu = () => {
    return (
      <div className="absolute bottom-[100px] flex flex-col w-[90%] px-[20px] py-[10px] bg-[#354361] gap-5 rounded-[10px]">
        <div className="flex flex-col gap-[4px]">
          <div className="flex justify-between">
            <button
              onClick={handleLogout}
              className="hover:shadow-md hover:bg-[black] p-2 rounded-[5px]"
            >
              <img
                className="w-[16px] h-[16px]"
                src="/icons/LogOut.svg"
                alt="logout"
              />
            </button>
            <NavLink
              to={`/organization/${localStorage.getItem(
                "orgId"
              )}/account-settings`}
              className="hover:shadow-md hover:bg-[black] p-2 rounded-[5px]"
            >
              <img
                className="w-[20px] h-[20px]"
                src="/icons/AccountSettings.svg"
                alt="account settings"
              />
            </NavLink>
          </div>
          <div className="border-t border-[#FFFFFF]"></div>
        </div>

        {organizationList.slice(0, 4).map((item) => {
          const { initials, capitalizedName } = processName(item.name);
          return (
            <NavLink
              key={item.id}
              className="flex flex-col gap-5 font-medium"
              to={`/organization/${item.id}`}
              onClick={() => handleCurrentOrganization(item.id)}
            >
              <div className="flex items-center gap-[10px]">
                <div className="bg-[black] rounded-[5px] h-[30px] w-[30px] flex justify-center items-center text-[#FFFFFF] text-[10px] font-[300]">
                  {initials}
                </div>
                <p className="text-[14px] font-medium text-[#EBEBEB] w-[180px] truncate">
                  {capitalizedName}
                </p>
              </div>
            </NavLink>
          );
        })}

        <div className="flex flex-col gap-5 font-medium">
          {!organizationList.some((item) => item.owner_id === userData?.id) ? (
            <button
              onClick={openAddOrganizationModal}
              className="flex items-center gap-5"
            >
              <img
                className="mt-[6px] w-[15px] h-[15px]"
                src="/icons/Addition.svg"
                alt="add organization"
              />
              <p className="text-[14px] text-[#EBEBEB] mt-1">
                Add Organization
              </p>
            </button>
          ) : (
            <CustomToolTip text="Organization ownership limit reached">
              <button className="flex items-center gap-5 cursor-not-allowed">
                <img
                  className="mt-[6px] w-[15px] h-[15px] opacity-25"
                  src="/icons/Addition.svg"
                  alt="add organization"
                />
                <p className="text-[14px] text-[#EBEBEB] mt-1 opacity-25">
                  Add Organization
                </p>
              </button>
            </CustomToolTip>
          )}
          <button
            onClick={() => {
              openViewOrganizationModal();
              togglePopup();
            }}
            className="text-start"
          >
            <p className="text-[14px] text-[#EBEBEB] underline underline-offset-[3px]">
              View All Organizations
            </p>
          </button>
        </div>
      </div>
    );
  };

  const currentOrganization = organizationList.find((org) => org.id === orgId);

  const { capitalizedName } = processName(currentOrganization?.name || "");

  const getUserInitials = (firstName: string, lastName: string) => {
    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();
    return `${firstInitial}${lastInitial}`;
  };

  const userInitials = getUserInitials(
    userData?.first_name || "",
    userData?.last_name || ""
  );

  return (
    <div
      ref={menuRef}
      className="flex flex-col justify-center px-[4px] mb-[-3px] items-center gap-1"
    >
      {popup && renderOrganizationMenu()}
      <div
        className="flex relative items-center justify-center w-[90%] h-[75px] px-[20px] bg-[#354361] cursor-pointer rounded-[10px]"
        onClick={togglePopup}
      >
        <div className="flex justify-center items-start w-full gap-1">
          <div className="bg-[black] rounded-full min-h-[40px] min-w-[40px] flex justify-center items-center text-[#FFFFFF] font-medium">
            {userInitials}
          </div>
          <div className="font-Poppins text-start ms-4 w-[65%] ">
            <div className="flex justify-between">
              <p className="font-medium text-[#FFFFFF] truncate">
                {userData?.first_name} {userData?.last_name}
              </p>
              {popup ? (
                <img
                  className="rotate-180"
                  src="/icons/RoundArrow.svg"
                  alt="UpArrow"
                />
              ) : (
                <img src="/icons/RoundArrow.svg" alt="DownArrow" />
              )}
            </div>
            <p className="text-[12px] text-[#9D9D9D] font-[300] w-[110px] truncate">
              {capitalizedName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarOrganizationMenu;

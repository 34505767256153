/**
 * Builds a query string from a Map of parameters. Parameters with empty or undefined values are excluded.
 *
 * Steps in this method:
 * 1. Initialize an empty array to store query parameters.
 * 2. Iterate through each key-value pair in the provided Map.
 *    a. Serialize the value to a string, using JSON.stringify for objects.
 *    b. If the serialized value is not empty, add the key-value pair to the array.
 * 3. Join the array of query parameters into a single string using "&" as a separator.
 *
 * @param {Map<string, unknown>} params - Map of parameters to be included in the query string.
 * @returns {string} Query string built from the provided parameters.
 */

import _ from "lodash";
/**
 * Builds a query string from a Map of parameters. Parameters with empty or undefined values are excluded.
 *
 * Steps in this method:
 * 1. Initialize an empty array to store query parameters.
 * 2. Iterate through each key-value pair in the provided Map.
 *    a. Serialize the value to a string, using JSON.stringify for objects.
 *    b. If the serialized value is not empty, add the key-value pair to the array.
 * 3. Join the array of query parameters into a single string using "&" as a separator.
 *
 * @param {Map<string, unknown>} params - Map of parameters to be included in the query string.
 * @returns {string} Query string built from the provided parameters.
 */
export const buildQuery = (params: Map<string, unknown>): string => {
  // Step 1
  const queryParams: string[] = [];

  // Step 2
  Array.from(params).map(([key, value]) => {
    // Step 2a
    const serializedValue =
      typeof value === "object" ? JSON.stringify(value) : value;

    // Step 2b
    if (!_.isEmpty(serializedValue)) {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(
          serializedValue as string
        )}`
      );
    }
  });

  // Step 3
  return queryParams.join("&");
};

import { RootState } from "@redux/store";
import {
  setIssueDetailsRequest,
  setIssueDetailsResponse,
  setIssueStatus,
} from "@src/redux/feature/issueDetailsSlice";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import SourceEnvironmentBadge from "../SourceEnvironmentBadge";
import { setIsShowIssueDetails } from "@src/redux/feature/modalHandler";
import { useContext } from "react";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { parseRepositoryResponse } from "@src/actions/parseRepositoryResponse";
import { useErrorBoundary } from "react-error-boundary";

/**
 * IssuesList component displays the data received after fetching Issues List.
 * The issue_data and meta_data properties of the response are used to render this table.
 *
 * Steps in this component:
 * 1. Retrieve issues data and next page number from Redux store.
 * 2. Initialize state for loading more issues.
 * 3. Access the repository context for issues and initialize Redux dispatch.
 * 4. Utilize the useErrorBoundary hook for error handling.
 * 5. Define a method (handlePageChange) to load next entries in the IssuesList.
 * 6. Use useRef to create an IntersectionObserver to trigger loading more issues when last row enters viewport.
 * 7. Create a callback function (lastIssueRef) to handle the intersection of the last row.
 * 8. Render the IssuesList with IssueBlock components and loading indicator.
 */

export const IssuesList = () => {
  // Step 1
  const issuesSlice = useSelector((state: RootState) => state.issuesSlice);
  const issueDetailSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );
  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const issues_data = issuesSlice.issues_response.issue_data;

  const customLogger = useContext(LoggerContext);
  const { showBoundary } = useErrorBoundary();

  const timeRange = issueDetailSlice.request.timeRange;

  const repository = useContext(RepositoryContext);
  const issuesRepo = repository.issues;

  const dispatch = useDispatch();

  const issueDetailsSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );

  const handleShowIssueDetails = async (index: number | undefined) => {
    if (!_.isUndefined(index)) {
      dispatch(
        setIssueDetailsRequest({ ...issueDetailsSlice.request, id: index })
      );

      const issueDetailsResponse =
        await issuesRepo.fetchIssueDetails<IssueDetailsResponse>(
          index.toString(),
          projectSlice.project_id!.toString(),
          timeRange
        );

      const issueDetails = await parseRepositoryResponse(
        issueDetailsResponse,
        customLogger,
        showBoundary
      );

      if (!_.isUndefined(issueDetails)) {
        dispatch(setIssueDetailsResponse(issueDetails));
      }

      const issueStatusResponse =
        await issuesRepo.fetchIssueStatus<issueStatus>(
          index.toString(),
          projectSlice.project_id!.toString()
        );

      const issueStatus = await parseRepositoryResponse(
        issueStatusResponse,
        customLogger,
        showBoundary
      );

      if (!_.isUndefined(issueStatus)) {
        dispatch(setIssueStatus(issueStatus));
        dispatch(setIsShowIssueDetails(true));
      }
    }
  };

  return (
    <tbody className="text-[12px]">
      {_.isEmpty(issues_data) ? (
        <tr className="bg-white">
          <td className="py-3 px-6 text-[#292C31]">No issues have occurred!</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      ) : (
        _.map(issues_data, (issue, index) => (
          <tr
            onClick={() => handleShowIssueDetails(issue.id)}
            key={issue.id}
            className={`${
              index % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"
            } cursor-pointer rounded border-t-[2px] border-[#D9DCED]`}
          >
            <td className="py-3 px-6">
              <div className="max-w-[800px]">
                <div className="w-[400px] font-bold text-sm tracking-[0.5px] leading-5 text-text_dark_blue whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {issue.title}
                </div>
                <div className="text-xs w-[400px] font-normal leading-5 text-text_dark_blue truncate whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {issue.stack_trace}
                </div>
                <div className="flex gap-2">
                  <SourceEnvironmentBadge
                    source={issue.origin[0].source}
                    environment={issue.origin[0].environment}
                    user_count={issue.user_count}
                  />
                </div>
              </div>
            </td>
            <td className="align-text-top text-center pt-3">
              <div className="flex justify-center">
                {issue.latest_occurrence}
              </div>
            </td>
            <td className="align-text-top text-center pt-3">
              {" "}
              <div>{issue.initial_occurrence}</div>
            </td>
            <td className="align-text-top text-center pt-3">
              <div className="flex justify-center items-center gap-1">
                <img
                  className="h-[12px]"
                  src="/icons/ErrorCount.svg"
                  alt="ErrorCount"
                />
                {issue.error_count}
              </div>
            </td>
          </tr>
        ))
      )}
    </tbody>
  );
};

import { RootState } from "@redux/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { ErrorsList } from "./ErrorsList";
import { BarDiagram } from "./BarDiagram";
import { useContext, useEffect, useState } from "react";
import {
  setIsViewAllErrorsOpen,
  setIsShowIssueDetails,
  setIsGitHubLabAndTokenModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { setIssueDetailsTimeRange } from "@feature/issueDetailsSlice";
import {
  setErrorIssuesId,
  resetErrorParams,
} from "@src/redux/feature/errorSlice";
import { resetIssueSourceEnvironmentParams } from "@src/redux/feature/issuesSlice";
import GitHubLabAndTokenModal from "../modal/GitHubLabAndTokenModal";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorToast } from "../toasts/ErrorToast";
import { setFixIssueUuid } from "@src/redux/feature/fixIssueSlice";
import SourceEnvironmentUserBadge from "../SourceEnvironmentBadge";

export const IssueDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const issueDetailsSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );
  const Pid = useParams();
  const projectid = Pid.id;
  const id = issueDetailsSlice.request.id;
  const issueDetailsData = issueDetailsSlice.response.issue_data;
  const errors_frequencies = issueDetailsSlice.response.errors_frequencies;
  const status =
    issueDetailsSlice.issueStatus?.status == ""
      ? "null"
      : issueDetailsSlice.issueStatus?.status;

  const [orgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });

  const issuesSlice = useSelector((state: RootState) => state.issuesSlice);
  const timeRange = issuesSlice.request_params.timeRange;
  const isIntegrated = useSelector((state: RootState) =>
    state.sourceSlice.sourceData.some(
      (s) =>
        s.id == issueDetailsData?.origin[0].source_id &&
        (s.integrations.github.is_connected ||
          s.integrations.gitlab.is_connected)
    )
  );

  const repository = useContext(RepositoryContext);
  const profileRepo = repository.profile;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const [usageData, setUsageData] = useState<UsageData>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await profileRepo.getUsage<UsageData>(orgId);
        if (response) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
          } else {
            setUsageData(response);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    };
    fetchData();
  }, [customLogger, profileRepo, projectid, showBoundary]);

  const issuesRepo = repository.issues;

  const params = useParams();
  const projectId = params.id;

  const handleFixIssue = async () => {
    sessionStorage.removeItem("uuid");
    try {
      if ((usageData?.available_ai_token ?? 0) < 8000 || !isIntegrated) {
        openHandleModal();
      } else {
        const response = await issuesRepo.fixIssue(
          orgId.toString(),
          projectId!,
          `${issueDetailsSlice?.response.issue_data?.origin[0].source_id}`,
          `${issueDetailsSlice.response.issue_data?.id}`
        );
        if (response) {
          if ("errors" in response) {
            _.map(response.errors, (error) => {
              ErrorToast({ Message: error.message });
            });
          } else {
            dispatch(setFixIssueUuid(response));
            navigate(
              `/projects/${projectid}/sources/${issueDetailsSlice?.response.issue_data?.origin[0].source_id}/issues/${issueDetailsSlice.response.issue_data?.id}/fix`
            );
          }
        }
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  const ViewAllErrorModal = async () => {
    dispatch(setErrorIssuesId(issueDetailsData!.id!));
    dispatch(resetIssueSourceEnvironmentParams());
    dispatch(resetErrorParams());
    dispatch(setIsViewAllErrorsOpen(true));
    dispatch(setErrorIssuesId(issueDetailsData!.id!));
  };

  useEffect(() => {
    dispatch(setIssueDetailsTimeRange(timeRange));
  }, [dispatch, timeRange]);

  const scrollBarStyle =
    "scrollbar-thumb-rounded-md lg:scrollbar-thin scrollbar-thumb-[#AAB2DE] scrollbar-track-light_gray";

  const barGraphProps: BarGraphProps = {
    frequency_data: errors_frequencies,
    x_axis_label_count: 7,
    y_axis_label_count: 4,
    bars_container_width: 900,
  };

  type Status = "failed" | "running" | "in queue" | "completed" | "null";

  const statusStyles: Record<
    Status,
    { textColor: string; bgColor: string; label: string }
  > = {
    failed: {
      textColor: "text-[#FFFFFF]",
      bgColor: "bg-[#ED4337]",
      label: "FAILED",
    },
    running: {
      textColor: "text-[#FFFFFF]",
      bgColor: "bg-[#0EB000]",
      label: "RUNNING",
    },
    "in queue": {
      textColor: "text-[#FFFFFF]",
      bgColor: "bg-[#2E00B0]",
      label: "QUEUED",
    },
    completed: {
      textColor: "text-[#FFFFFF]",
      bgColor: "bg-[#B5763D]",
      label: "COMPLETED",
    },
    null: {
      textColor: "",
      bgColor: "",
      label: "",
    },
  };

  const renderStatusContent = (status: Status) => {
    const currentStatus = statusStyles[status];

    if (currentStatus) {
      return (
        <div>
          <div
            className={`${currentStatus.textColor} ${currentStatus.bgColor} rounded-[3px] px-[10px] text-[10px] font-bold`}
          >
            {currentStatus.label}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <p className="flex items-center text-[16px] text-[#172B4D] h-24 font-medium">
            Let Retack.Ai attempt to fix this issue
          </p>
        </div>
      );
    }
  };

  const content = renderStatusContent(status as Status);

  const openHandleModal = () => {
    dispatch(setIsGitHubLabAndTokenModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeHandleModal = () => {
    dispatch(setIsGitHubLabAndTokenModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const getAgeMessage = (timeDifference: number) => {
    const differenceInSeconds = Math.floor(timeDifference / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    const remainingHours = differenceInHours % 24;
    const remainingMinutes = differenceInMinutes % 60;

    if (differenceInDays > 0) {
      return `about ${differenceInDays} day${
        differenceInDays !== 1 ? "s" : ""
      } ${
        remainingHours > 0
          ? `${remainingHours} hr${remainingHours !== 1 ? "s" : ""}`
          : ""
      } `;
    } else if (differenceInHours > 0) {
      return `about ${differenceInHours} hour${
        differenceInHours !== 1 ? "s" : ""
      } ${
        remainingMinutes > 0
          ? `${remainingMinutes} min${remainingMinutes !== 1 ? "s" : ""}`
          : ""
      } `;
    } else if (differenceInMinutes > 0) {
      return `about ${differenceInMinutes} minute${
        differenceInMinutes !== 1 ? "s" : ""
      } `;
    } else {
      return `about ${differenceInSeconds} second${
        differenceInSeconds !== 1 ? "s" : ""
      } old`;
    }
  };

  const createdAt = issueDetailsSlice?.response.issue_data?.created_at || "N/A";
  const updatedAt = issueDetailsSlice?.response.issue_data?.updated_at || "N/A";

  if (updatedAt === "N/A") return null;

  const currentTime = new Date().getTime();

  const timeDifferenceCreatedAt = Math.abs(
    currentTime - new Date(createdAt).getTime()
  );
  const timeDifferenceUpdatedAt = Math.abs(
    currentTime - new Date(updatedAt).getTime()
  );

  const ageMessageCreatedAt = getAgeMessage(timeDifferenceCreatedAt);
  const ageMessageUpdatedAt = getAgeMessage(timeDifferenceUpdatedAt);

  const message = `This issue is ${ageMessageCreatedAt} old, affecting ${
    issueDetailsData?.user_count
  } ${issueDetailsData?.user_count! > 1 ? "users" : "user"} with ${
    issueDetailsData?.error_count
  } ${issueDetailsData?.error_count! > 1 ? "errors. " : "error. "}`;
  const lastSeenMessage = ` Last seen ${ageMessageUpdatedAt} ago`;

  return (
    !_.isNull(issueDetailsData) &&
    modalHandler.isShowIssueDetails &&
    !_.isNull(id) && (
      <div
        className="absolute flex top-0 right-0 w-full overflow-hidden h-screen "
        tabIndex={0}
      >
        <div
          data-testid="OverlayShadow"
          className="grow"
          onClick={() => dispatch(setIsShowIssueDetails(false))}
        />
        <div className="flex flex-col w-[95%] lg:w-2/3 bg-white border-l border-[#CBCFE8]">
          <div className="flex flex-col justify-center p-[26px] pb-[21px]">
            <div className="flex w-full justify-between items-center ">
              <div className="flex justify-center items-center gap-[10px]">
                <div className="max-w-[450px] font-bold text-[20px] leading-6 text-[#292C31] whitespace-nowrap overflow-hidden overflow-ellipsis ">
                  {issueDetailsData.title}
                </div>
                {content}
              </div>
              <div className="flex flex-row gap-4 ">
                <button
                  onClick={handleFixIssue}
                  disabled={status === "running" || status === "in queue"}
                  className={`${
                    status === "running" || status === "in queue"
                      ? "bg-disabled-gradient cursor-not-allowed"
                      : "bg-color-gradient"
                  } text-[#ffffff] font-semibold py-[10px] px-[20px] rounded-[20px] flex justify-center items-center gap-[8px]`}
                >
                  <img
                    width={22}
                    height={22}
                    src="/icons/AiPowered.svg"
                    alt="ai powered"
                  ></img>
                  {status === "failed" || status === "completed" ? (
                    <p>Try Another Fixing</p>
                  ) : (
                    <p>Fix This Issue</p>
                  )}
                </button>
                <button>
                  <img
                    onClick={() => dispatch(setIsShowIssueDetails(false))}
                    src="/icons/Cross.svg"
                    alt="Cross icon"
                    width={20}
                    height={20}
                  />
                </button>
              </div>
            </div>
            <div className="text-[12px] text-[#172B4D] flex gap-1">
              <p>
                {message}
                <span className="text-[#454545]">{lastSeenMessage}</span>
              </p>
            </div>
            <SourceEnvironmentUserBadge
              source={issueDetailsData.origin[0].source}
              environment={issueDetailsData.origin[0].environment}
            />
          </div>
          <div className="border-b border-[#CBCFE8]"></div>
          <div
            className={`flex flex-col w-full py-5 px-[26px] max-h-[80vh] overflow-x-hidden overflow-y-scroll ${scrollBarStyle} `}
          >
            {issueDetailsSlice.isResponseLoading ? (
              <div className="flex items-center justify-center w-full min-h-[50vh]">
                <img
                  src="/icons/Loading.svg"
                  alt="LoadingIcon"
                  className="w-[40px]"
                />
              </div>
            ) : (
              <div className="flex flex-col">
                <ErrorsList />
              </div>
            )}
            <div className="flex items-center gap-3 border border-[#D9D9D9] rounded mt-2 p-2 text-sm">
              <img
                src={`/icons/SuggestionInfo.svg`}
                alt="SuggestionInfo"
                width={17}
                height={17}
              />
              Suggested fixing are generated by machine learning and algorithms
              and may require human validations.
            </div>
            <div className="flex justify-end mb-[25px] mt-[22px] h-[35px] text-text_dark_blue text-[14px] leading-5 font-medium">
              <button className="underline text-[#162C5B]">
                <NavLink
                  onClick={() => {
                    ViewAllErrorModal();
                  }}
                  to={`/projects/${projectid}/issues/${issueDetailsSlice.response.issue_data?.id}/errors`}
                >
                  <p className="text-[#162C5B] w-full mr-5">
                    View all {issueDetailsData.error_count} errors
                  </p>
                </NavLink>
              </button>
            </div>
            {!_.isEmpty(errors_frequencies) && (
              <>
                <div className="max-h-[200px]  mb-10 ps-3 w-full">
                  <BarDiagram {...barGraphProps} />
                </div>
                <div className="border border-[#D9DCED] flex justify-start items-center gap-3 mb-3 p-[10px] rounded-[5px] ">
                  <img
                    className="h-[15px]"
                    src="/icons/Clock.svg"
                    alt="Clock"
                  />{" "}
                  <div className="leading-[18px] text-[14px] font-normal">
                    {" "}
                    All times are in Coordinated Universal Time (UTC){" "}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {modalHandler.isGitHubLabAndTokenModalOpen && (
          <GitHubLabAndTokenModal
            closeHandleModal={closeHandleModal}
            lowToken={(usageData?.available_ai_token ?? 0) < 8000}
          />
        )}
      </div>
    )
  );
};

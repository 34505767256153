import { FallbackProps } from "react-error-boundary";
import NotFound from "@pages/NotFound";

enum refreshBtnProps {
	active = "bg-black text-gray",
	hover = "hover:bg-gray hover:text-black"
}
export const FallbackComponent = (props: FallbackProps) => {
	// Handle logging errors using fallbackProps
	const { error, resetErrorBoundary } = props;
	const errorMessage = error.message;
	const componentStack = error.stack;
	const errorStatus = error.status;

	return (
		<>
			{errorStatus === "404" ? (
				<NotFound resetErrorBoundary={resetErrorBoundary} />
			) : (
				<div className="flex justify-center items-center flex-col w-screen h-screen">
					<div className="text-red text-2xl font-[500] mb-3">
						An error occurred!
					</div>
					<p>Message: {errorMessage}</p>
					<button>View Detail</button>
					<div className="w-full h-[75vh] flex-col px-5 overflow-scroll">
						<pre>Component stack: {componentStack}</pre>
					</div>
					<button
						onClick={() => resetErrorBoundary()}
						className={`${refreshBtnProps.active} ${refreshBtnProps.hover} p-1 min-w-[125px] rounded-full text-lg font-bold`}
					>
						Refresh
					</button>
				</div>
			)}
		</>
	);
};

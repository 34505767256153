import DemoEmailIntegrationModal from "./DemoEmailIntegrationModal";
import DemoSlackIntegrationModal from "./DemoSlackIntegrationModal";
import DemoTeamsIntegrationModal from "./DemoTeamsIntegrationModal";

const DemoIntegrationModal = ({
  closeIntegrationModal,
  integrationName,
}: IntegrationModalProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      {integrationName === "Slack" ? (
        <DemoSlackIntegrationModal
          closeIntegrationModal={closeIntegrationModal}
        />
      ) : integrationName === "Email" ? (
        <DemoEmailIntegrationModal
          closeIntegrationModal={closeIntegrationModal}
        />
      ) : (
        <DemoTeamsIntegrationModal
          closeIntegrationModal={closeIntegrationModal}
        />
      )}
    </div>
  );
};

export default DemoIntegrationModal;

import _ from "lodash";
import CustomToolTip from "../CustomToolTip";

const DemoGitHubLabStateCard = () => {
  return (
    <div className="mt-[14px] py-[24px] px-[34px] rounded-[6px] bg-[#F6F9FF]">
      <div className="flex justify-between items-center">
        <div>
          <div className=" flex items-center gap-[30px]">
            <div className="flex items-center gap-[12px]">
              <img
                width={50}
                height={50}
                src={`/icons/Github.svg`}
                alt="Integration"
              />
              <h1 className="text-[20px] font-medium text-[#172B4D]">Github</h1>
            </div>
            <p className="px-[10px] py-[2px] text-[12px] rounded-full bg-opacity-25 bg-[#671FFF] text-[#671FFF]">
              Connected
            </p>
          </div>{" "}
          <p className="mt-6 text-[18px] text-[#292C31]">
            This account is now connected to your GitHub account. Start tracking
            and resolving issues effortessly.
          </p>
          <div className="flex gap-[24px] mt-6  text-[14px] font-medium">
            <CustomToolTip>
              <button className="flex items-center gap-3 text-[#248900] cursor-not-allowed">
                <img
                  width={18}
                  height={18}
                  src="/icons/EditHubLab.svg"
                  alt="edit"
                />
                <p>Edit</p>
              </button>
            </CustomToolTip>
            <CustomToolTip>
              <button className="flex items-center gap-3 text-[#CC0202] cursor-not-allowed">
                <img
                  width={18}
                  height={18}
                  src="/icons/MemberDelete.svg"
                  alt="remove"
                />
                <p>Remove</p>
              </button>
            </CustomToolTip>
          </div>
        </div>
        <div className="h-32">
          <div className="flex flex-col text-[#001789] ">
            <div className="text-center font-medium text-sm">Expires on</div>
            <div className="font-medium">10 May, 2035</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoGitHubLabStateCard;

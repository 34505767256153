import _ from "lodash";
import DemoDateRangePicker from "../issuesExplorer/DemoDateRangePicker";
import { DemoSourceEnvironmentCheckbox } from "../issuesExplorer/DemoSourceEnvironmentCheckbox";

export const DemoViewAllErrorModal = () => {
  // Define an array to store JSX elements
  const rows = [];

  // Loop to generate table rows
  for (let index = 0; index < 10; index++) {
    rows.push(
      <tr key={index} className={index % 2 === 0 ? "bg-white" : "bg-[#FAFAFA]"}>
        <td className="py-3 px-12 text-[#292C31]"> 29 May 2023</td>
        <td className="py-3 px-12">
          <div className="bg-[#D7F7EA] px-4 py-1 rounded-[20px] text-[#35D797] truncate text-center inline">
            Demo Source
          </div>
        </td>
        <td className="py-3 px-12">
          <div className="px-4 rounded-[20px] border text-center inline">
            Demo Environment
          </div>
        </td>
        <td className="py-3 px-12 max-w-[550px]">
          <p className="whitespace-nowrap overflow-hidden overflow-ellipsis inline">
            Demo Error
          </p>
        </td>
      </tr>
    );
  }

  return (
    <div>
      <div className="flex flex-col pl-10 pr-4 py-6 h-[90vh] overflow-y-scroll">
        <div className="text-[26px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
          Error
        </div>

        <div className="flex mb-5 h-[35px] bg-white items-center border border-border_gray rounded-[8px] px-3">
          <img src="/icons/Search.svg" alt="SearchIcon" className="w-[14px]" />
          <input
            type="text"
            className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2"
            placeholder="Search"
          />
        </div>
        <div className="flex justify-between gap-[40px]">
          {/* Passing properties to the Checkboxes type component for Source and Environment filter */}
          <DemoSourceEnvironmentCheckbox />
          <DemoDateRangePicker />
        </div>
        <div className="py-[11px]">
          <div className="overflow-x-auto">
            <table className="w-full text-[#292C31] bg-[#F3F4F8] overflow-y-auto">
              <thead className="text-[14px] text-left ">
                <tr>
                  <th className="font-[700] py-3 px-12">Time</th>
                  <th className="font-[700] py-3 px-12">Source</th>
                  <th className="font-[700] py-3 px-12">Environment</th>
                  <th className="font-[700] py-3 px-12">Title</th>
                </tr>
              </thead>
              <tbody className="text-[12px] cursor-pointer">
                {/* Render the rows array */}
                {rows}
              </tbody>
            </table>
          </div>
        </div>
        <div className="w-[100%] flex justify-end mt-[1em]">
          <div className="flex text-gray-700">
            <div className="h-8 w-8 mr-1 flex justify-center items-center rounded-[4px] cursor-pointer opacity-50 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#162C5B"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-left w-4 h-4"
              >
                <polyline points="15 18 9 12 15 6" />
              </svg>
            </div>
            <div className="flex h-8 justify-center items-center font-medium gap-[8px] text-[#162C5B] border-2 w-8 rounded-[4px]">
              1
            </div>
            <div className="h-8 w-8 ml-1 flex justify-center items-center rounded-[4px] cursor-pointer opacity-50 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#162C5B"
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-right w-4 h-4"
              >
                <polyline points="9 18 15 12 9 6" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "@feature/appSlice";

export const AppCard = ({
  appName,
  appInformation,
  editedTime,
  projectId,
}: AppCardProps) => {
  const appTitle = appName.replace(/\b\w/g, (match) => match.toUpperCase());
  const appDescription = appInformation.replace(/\b\w/g, (match) =>
    match.toUpperCase()
  );

  const dispatch = useDispatch();


  const handleOpenProject = () => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 1000);
  };
  return (
    <NavLink
      className="w-full md:w-[240px] h-[160px] border border-border_gray bg-white rounded-lg hover:shadow-light z-[1]"
      to={`/projects/${projectId}`}
      onClick={handleOpenProject}
    >
      <section className="flex flex-col w-full h-full gap-1 cursor-pointer p-3">
        <h1 className="text-sm text-text_blue font-[500] truncate">
          {appTitle}
        </h1>
        <div className="text-text_dark_gray text-xs font-[400] flex-wrap max-h-[60px] overflow-hidden line-clamp-3">
          {appDescription}
        </div>
        <div className="grow" />
        <p className="text-text_dark_gray text-[12px] font-[400]">
          Last edited at {editedTime}
        </p>
      </section>
    </NavLink>
  );
};
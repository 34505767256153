import _ from "lodash";
import { useState, useContext, ChangeEvent } from "react";
import { useForm } from "react-hook-form";
import { SuccessToast } from "../toasts/SuccessToast";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import { ErrorToast } from "../toasts/ErrorToast";
import { useParams } from "react-router-dom";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import {
  setIsEditSourceModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";

const EditSourceCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const sourceRepo = repository.source;

  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const params = useParams();
  const projectId = params.id;
  const sourceId = sourceSlice.source_id;

  const source = _.find(
    sourceSlice.sourceData,
    (source) => source.id === sourceId
  );

  const [sourceName, setSourceName] = useState<string>(source?.name!);
  const [sourceDescription, setSourceDescription] = useState<string>(
    source?.description!
  );

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<EditSourceData>({
    defaultValues: {
      name: sourceName,
      description: sourceDescription,
    },
  });

  const handleSourceNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSourceName(e.target.value);
    clearErrors();
  };

  const handleSourceDescriptionChange = (description: string) => {
    setSourceDescription(description);
    clearErrors();
  };

  const closeEditSourceModal = () => {
    dispatch(setIsEditSourceModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const onSubmit = async (data: EditSourceData) => {
    try {
     
      const EditSourceData: EditSourceData = {
        ...data,
      };
      const response = await sourceRepo.editSource<EditSourceData>(
        projectId,
        sourceId,
        EditSourceData
      );

      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "name";
            setError(validField, { message: error.message });
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeEditSourceModal?.();
          const info: InfoLog = {
            message: "New source created.",
          };
          await customLogger.log(info);
          SuccessToast({ Message: "Success! Your Source has been updated." });
        }
      }
    } catch (error) {
      ErrorToast({ Message: "Failure! Your Source has not been updated." });
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col align-items-start w-full">
            <h2 className="font-bold text-[#172B4D] text-[28px]">
              Edit Source
            </h2>
            <div className="flex flex-row font-medium text-[14px]">
              <p className="text-[#4F5A69] text-sm">Sources{">"} </p>
              <p className="text-[#AAAAAAAA] ml-2 "> Edit sources</p>
            </div>

            {/* Display input for Source Name */}
            <div className="flex flex-col mt-[26px] w-full gap-[16px]">
              <div className="mt-2">
                <label
                  className="font-medium text-[#172B4D]"
                  htmlFor="description"
                >
                  Source Name
                </label>
                <input
                  {...register("name", { required: "source name is required" })}
                  type="text"
                  placeholder="Enter Source Name"
                  className="w-full px-3 py-4 border border-[#D9DCED] text-[#172B4D] rounded-[10px] mt-4 placeholder-[#AAAAAA] placeholder-[400] focus:outline-none"
                  value={sourceName}
                  onChange={handleSourceNameChange}
                />
                {errors.name != null && (
                  <small className="block text-error mt-2">
                    {errors.name.message}
                  </small>
                )}
              </div>
              <div className="mt-2">
                <label
                  className="font-medium text-[#172B4D]"
                  htmlFor="description"
                >
                  Description
                </label>
                <textarea
                  {...register("description", {})}
                  placeholder="Edit Description"
                  className="w-full px-3 py-4 border border-[#D9DCED] text-[#172B4D] rounded-[10px] mt-4 placeholder-[#AAAAAA] placeholder-[400] focus:outline-none"
                  value={sourceDescription}
                  onChange={(e) =>
                    handleSourceDescriptionChange(e.target.value)
                  }
                  rows={5}
                />
              </div>
            </div>
          </div>
          <div className="mt-2 w-full">
            <div className="flex flex-row mt-8">
              <button
                className="px-8 py-3 bg-[#172B4D] text-white font-bold rounded-[8px]"
                type="submit"
              >
                Save
              </button>

              <button
                className="ml-4 px-8 py-3 border border-[#172B4D] bg-gray-400 text-[#172B4D] font-light rounded-[8px]"
                onClick={closeEditSourceModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSourceCard;

import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import Navbar from "@common/header/Navbar";
import Sidebar from "@common/sideNav/SideBar";
import Overlay from "@sharedComponents/Overlay";
import Loading from "@sharedComponents/loader/Loading";
import { useContext, useEffect } from "react";
import { fetchUserData } from "@actions/fetchUserData";
import { RepositoryContext, LoggerContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { setProjectId } from "@feature/projectSlice";

export const Application = () => {
  const id = useParams();
  const projectId = id.id;
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();
  const repository = useContext(RepositoryContext);
  const profileRepo = repository.profile;
  const memberRepo = repository.member;
  const customLogger = useContext(LoggerContext);

  const loadingState = useSelector((state: RootState) => state.appSlice);
  const organizationSlice = useSelector(
    (state: RootState) => state.organizationSlice
  );

  useEffect(() => {
    try {
      fetchUserData(profileRepo, showBoundary, dispatch, customLogger);
    } catch (error) {
      showBoundary(error);
      customLogger.reportError(error);
    }
  }, [
    customLogger,
    dispatch,
    memberRepo,
    profileRepo,
    projectId,
    organizationSlice.currentOrganization,
    showBoundary,
  ]);

  useEffect(() => {
    dispatch(setProjectId(projectId));
  }, [dispatch, projectId]);
  const isOverlayVisible = useSelector(
    (state: RootState) => state.modalHandler.isOverlayVisible
  );

  return (
    <>
      <div className="flex bg-bg_gray h-[100vh] w-full">
        <div className="hidden md:block sticky top-0 h-screen w-[300px]">
          <Sidebar />
        </div>
        <div className="border-b border-[#CBCFE8] absolute w-[100dvw] z-1 top-[9vh]"></div>
        <div className="flex flex-col w-full h-[100vh]">
          <Navbar />
          {loadingState.loading ? (
            <Loading />
          ) : (
            <main className="overflow-y-auto">
              <Outlet />
            </main>
          )}
        </div>
      </div>
      {isOverlayVisible && <Overlay />}
    </>
  );
};

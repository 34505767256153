import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ErrorToast } from "../toasts/ErrorToast";
import { SuccessToast } from "../toasts/SuccessToast";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";

const AddOrganizationModal = ({
  closeAddOrganizationModal,
}: AddOrganizationModalProps) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<AddOrgData>();

  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  const dispatch = useDispatch();
  const repository = useContext(RepositoryContext);
  const OrgRepo = repository.organization;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const onSubmit = async (data: AddOrgData) => {
    dispatch(setIsLoading(true));
    if (!data.website) {
      delete data.website;
    }
    try {
      const response = await OrgRepo.addOrganization<AddOrgData, AddOrgData>(
        data
      );
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.forEach(response.errors, (error) => {
            const validField = error.title as "name" | "website";
            setError(validField, { message: error.message });
            if (validField !== "name" && validField !== "website") {
              ErrorToast({ Message: error.message });
              clearErrors();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          SuccessToast({ Message: "Successfully added your organization" });
          window.location.reload();
        }
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-[28px] font-[700] text-text_black">
            Add Organization{" "}
          </h2>
          <div className="mt-2">
            <label className="text-text_gray text-sm font-[500]" htmlFor="name">
              Organization Name
              <span className="text-red text-sm ml-1">*</span>
            </label>
            <input
              {...register("name", {
                required: "Organization Name is Required",
              })}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
              placeholder="ABC Corporations Pvt. Ltd."
            />
            {errors.name != null && (
              <small className="block text-red mt-2">
                {errors.name.message}
              </small>
            )}
          </div>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500]"
              htmlFor="website"
            >
              Website
            </label>
            <input
              {...register("website")}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
              placeholder="www.websitename.com"
            />
            {errors.website != null && (
              <small className="block text-red mt-2">
                {errors.website.message}
              </small>
            )}
          </div>
          <div className="flex justify-start gap-[20px] mt-[28px]">
            {isLoading ? (
              <button
                disabled
                className="flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              >
                <img
                  width={22}
                  height={22}
                  src="/icons/Loading.svg"
                  alt="loading"
                />
                Creating...
              </button>
            ) : (
              <button
                type="submit"
                className="bg-button_blue w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              >
                Save and Proceed
              </button>
            )}
            <button
              type="button"
              onClick={closeAddOrganizationModal}
              className="border border-button_blue w-[90px] h-[40px] text-nav_blue text-[14px] font-[500] rounded-md"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrganizationModal;

import { ChangeEvent, KeyboardEvent, useState } from "react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import CustomToolTip from "../../CustomToolTip";

const DemoEmailIntegrationModal = ({
  closeIntegrationModal,
}: DemoIntegrationModalProps) => {
  const {
    setError,
    register,
    formState: { errors },
  } = useForm<EmailIntegrationFieldData>({});

  const [emailInput, setEmailInput] = useState("");
  const [email, setEmail] = useState<string[]>([]);
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const typedEmail = e.target.value;
    setEmailInput(typedEmail);
    setError("email", {});
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();

      const typedEmail = emailInput;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (typedEmail !== "" && emailRegex.test(typedEmail)) {
        if (email.includes(typedEmail)) {
          setError("email", {
            message: "This email is already in the list",
          });
        } else {
          setEmail((prevEmails) => [...prevEmails, typedEmail]);
          setEmailInput("");
        }
      } else {
        setError("email", {
          message: "Please enter a valid email address",
        });
      }
    }
  };

  const removeEmail = (indexToRemove: number) => {
    const updatedEmails = email.filter((_, index) => index !== indexToRemove);
    setEmail(updatedEmails);
  };

  return (
    <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
      <form>
        <div className="flex justify-between">
          <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
            <img width={35} height={35} src="/icons/Email.svg" alt="cross" />
            Email
          </h2>
          <button type="button" onClick={closeIntegrationModal}>
            <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
          </button>
        </div>
        <div className="mt-2 flex justify-between gap-[72px]">
          <div className="w-full">
            <label
              className="text-text_gray text-sm font-[500] flex items-center"
              htmlFor="port"
            >
              Receiver List
              <span className="text-red text-sm ml-1">*</span>(Add up to 5 email
              addresses)
            </label>
            <div className="border border-border_light_gray rounded-[10px] w-full py-2 px-3 text-gray-700 focus:outline-none mt-[10px]">
              <div className="flex flex-wrap">
                {email.map((emailItem, index) => (
                  <div
                    key={index}
                    className="inline-block bg-gray-200 rounded-full px-2 py-1 mr-2 mb-2"
                  >
                    <span className="flex justify-center items-center border border-[#B3B3B3] rounded-full px-2 text-[#4C4C4C] gap-2">
                      {emailItem}
                      <button
                        type="button"
                        className="hover:text-[red] mt-1"
                        onClick={() => removeEmail(index)}
                      >
                        &#10005;
                      </button>
                    </span>
                  </div>
                ))}
              </div>
              <input
                {...register("email")}
                value={emailInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="w-full h-full bg-transparent focus:outline-none inline-block"
                type="text"
              />
            </div>
            {errors.email && (
              <small className="block text-red mt-2">
                {errors.email.message}
              </small>
            )}
          </div>
        </div>
        <div className="flex gap-[20px]">
          <div className="flex justify-start gap-[20px] mt-[16px]">
            <CustomToolTip>
              <button
                className="bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md cursor-not-allowed opacity-50"
                disabled
              >
                Save
              </button>
            </CustomToolTip>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DemoEmailIntegrationModal;

import DeleteProjectModal from "@src/components/sharedComponents/modal/projectModal/DeleteProjectModal";
import EditProjectModal from "@src/components/sharedComponents/modal/projectModal/EditProjectModal";
import { setLoading } from "@src/redux/feature/appSlice";
import {
  setIsDeleteProjectModalOpen,
  setIsEditProjectModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { setProjectData } from "@src/redux/feature/projectSlice";
import { RootState } from "@src/redux/store";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";

const General = () => {
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const dispatch = useDispatch();

  const repository = useContext(RepositoryContext);
  const projectRepo = repository.project;
  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const [orgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });

  const role = memberSlice.current_member?.role;

  const openEditProjectModal = () => {
    dispatch(setIsEditProjectModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeEditProjectModal = () => {
    dispatch(setIsEditProjectModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const openDeleteProjectModal = () => {
    dispatch(setIsDeleteProjectModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeDeleteProjectModal = () => {
    dispatch(setIsDeleteProjectModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await projectRepo.getProject<ProjectData>(orgId);
      if (response) {
        if ("errors" in response) {
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          dispatch(setProjectData(response));
        }
      }
    };
    fetchData()
      .catch(async (error) => {
        showBoundary(error);
        await customLogger.reportError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    customLogger,
    dispatch,
    projectRepo,
    showBoundary,
    modalHandler.isEditProjectModalOpen,
  ]);

  const PID = Number(projectSlice.project_id);
  const currentProject = projectSlice.projectData.find(
    (project) => project.id === PID
  );

  return (
    <div className="p-[25px] md:p-[36px] flex flex-col h-[90vh] justify-between">
      <div>
        <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue">
          Project Information
        </h1>
        <div className="flex flex-col gap-[20px] mt-[44px]">
          <div className="flex">
            <p className="w-[250px] font-medium">Name</p> :
            <p className="ml-[24px]">{currentProject?.title}</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Description</p> :
            <p className="ml-[24px] w-[800px]">{currentProject?.description}</p>
          </div>
          <div className="flex">
            <p className="w-[250px] font-medium">Created Date</p> :
            <p className="ml-[24px]">
              {currentProject?.created_at &&
                new Date(currentProject.created_at).toLocaleDateString(
                  "en-US",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  }
                )}
            </p>
          </div>
        </div>
        <div className="mt-[62px]">
          <button
            onClick={openEditProjectModal}
            className="bg-[#001789] px-[28px] py-[10px] rounded-[6px] text-[12px] font-bold text-white"
          >
            Edit Project
          </button>
        </div>
      </div>

      <div className="border border-[#DCDCDE]">
        <div className="px-[26px] py-[20px]">
          <h1 className="text-[16px] text-[#CD3D25] font-bold">
            Delete this project
          </h1>
        </div>
        <div className="pt-[13px] bg-[#F9EBEB] px-[26px] pb-[18px] border border-[#E3C0C4]">
          <p>
            Deleting this project will permanently remove all associated data
            and cannot be undone.
          </p>
          {role !== "Owner" ? (
            <button
              disabled
              className="p-[10px] bg-[#CD3D25] bg-opacity-[0.50] rounded-[6px] font-medium text-[12px] text-white mt-[18px] cursor-not-allowed"
            >
              Delete Project
            </button>
          ) : (
            <button
              onClick={openDeleteProjectModal}
              className="p-[10px] bg-[#CD3D25] rounded-[6px] font-medium text-[12px] text-white mt-[18px]"
            >
              Delete Project
            </button>
          )}
        </div>
      </div>

      {modalHandler.isEditProjectModalOpen && (
        <EditProjectModal closeEditProjectModal={closeEditProjectModal} />
      )}
      {modalHandler.isDeleteProjectModalOpen && (
        <DeleteProjectModal closeDeleteProjectModal={closeDeleteProjectModal} />
      )}
    </div>
  );
};

export default General;

import { useState } from "react";
import { setCurrentOrganization } from "@src/redux/feature/organizationSlice";
import { RootState } from "@src/redux/store";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

interface Props {
  closeViewOrganizationModal?: () => void;
}
const ViewOrganizationModal = ({ closeViewOrganizationModal }: Props) => {
  const dispatch = useDispatch();
  const orgId = localStorage.getItem("orgId");
  const organizationList = useSelector(
    (state: RootState) => state.organizationSlice.organizationList
  );

  const [searchQuery, setSearchQuery] = useState("");

  const processName = (name: string) => {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase()).join("");
    const capitalizedName = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return { initials, capitalizedName };
  };

  const handleCurrentOrganization = (id: number) => {
    const currentOrganization = organizationList.find(
      (org) => org.id === parseInt(id.toString())
    );
    dispatch(setCurrentOrganization(currentOrganization!));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredOrganizations = organizationList.filter((organization) =>
    organization.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <h2 className="text-[18px] font-[700] text-[#000000]">
          View all organization
        </h2>
        <div className="flex my-3 h-[38px] bg-white items-center border border-border_gray rounded-[8px] px-3">
          <img src="/icons/Search.svg" alt="SearchIcon" className="w-[14px]" />
          <input
            type="text"
            className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2 text-[14px]"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <h2 className="text-[14px] font-[400] text-[#000000] mb-5">
          Total{" "}
          <span className="font-[700]">{filteredOrganizations.length}</span>{" "}
          Organizations
        </h2>
        <div className="flex flex-col gap-5 mb-[40px]">
          {filteredOrganizations.map((item) => {
            const { initials, capitalizedName } = processName(item.name);
            return (
              <NavLink
                key={item.id}
                className="flex flex-col gap-5 font-medium"
                to={`/organization/${item.id}`}
                onClick={() => handleCurrentOrganization(item.id)}
              >
                <div className="flex items-center gap-[10px]">
                  <div className="bg-[#777777] rounded-[3px] h-[35px] w-[40px] flex justify-center items-center text-[#FFFFFF]">
                    {initials}
                  </div>
                  <p className="text-[12px] text-[#000000] w-[150px] truncate">
                    {capitalizedName}
                  </p>
                  {parseInt(orgId!) === item.id ? (
                    ""
                  ) : (
                    <img src="/icons/Change.svg" alt="change" />
                  )}
                </div>
              </NavLink>
            );
          })}
        </div>
        <button
          className="border border-[#001789] py-[10px] px-[40px] rounded-[6px] text-[#001789] text-[14px]"
          onClick={closeViewOrganizationModal}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewOrganizationModal;

import { useState, useEffect, useContext } from "react";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import { useErrorBoundary } from "react-error-boundary";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";

const SignUpVerification = () => {
  const repository = useContext(RepositoryContext);
  const activationRepo = repository.login;
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState<
    "pending" | "success" | "failed"
  >("pending");

  useEffect(() => {
    const verify = async (uid: string, token: string) => {
      const data: VerificationData = { uid, token, type: "activation" };
      try {
        const response = await activationRepo.Verify<
          VerificationData,
          VerificationData
        >(data);
        if (_.isString(response)) {
          setVerificationStatus("success");
        } else if (_.isObject(response)) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
            setVerificationStatus("failed");
          } else {
            setVerificationStatus("success");
          }
        } else {
          setVerificationStatus("failed");
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
        setVerificationStatus("failed");
      }
    };

    const parsedUrl = new URL(window.location.href);
    if (parsedUrl.pathname) {
      const parts = parsedUrl.pathname.split("/").filter(Boolean);
      if (parts.length === 3 && parts[0] === "activate") {
        const uid = parts[1];
        const tokenId = parts[2];
        verify(uid, tokenId);
      } else {
        setVerificationStatus("failed");
      }
    } else {
      setVerificationStatus("failed");
    }
  }, [activationRepo, customLogger, showBoundary]);
  if (verificationStatus === "pending") {
    return (
      <div className="h-screen flex justify-center items-center">
        <img width={120} height={120} src="/icons/Loading.svg" alt="loading" />
      </div>
    );
  }

  const onResend = async () => {
    setIsLoading(true);
    const parsedUrl = new URL(window.location.href);
    if (parsedUrl.pathname) {
      const parts = parsedUrl.pathname.split("/").filter(Boolean);
      if (parts.length === 3 && parts[0] === "activate") {
        const uid = parts[1];
        try {
          const response = await activationRepo.resendVerificationEmail<string>(
            uid
          );
          if (typeof response === "string") {
            if (response === "Password reset successfully.") {
              SuccessToast({
                Message: "Email re-sent Successfully",
              });
            }
          } else if (response && typeof response === "object") {
            if ("errors" in response) {
              _.forEach(response.errors, (error) => {
                ErrorToast({ Message: error.message });
              });
              await customLogger.reportErrorResponse(showBoundary, response);
            }
          }
          setIsLoading(false);
        } catch (error) {
          showBoundary(error);
          await customLogger.reportError(error);
        }
      }
    }
  };

  return (
    <div>
      <section className=" bg-background grid grid-cols-1 md:grid md:grid-cols-5 md:h-screen items-center relative">
        <div className="container md:col-span-2 pt-[25px] md:pt-0 pb-[44px] md:pb-0 ">
          <div className="flex justify-start md:absolute md:top-[52px]">
            <button className="w-[150px] h-[34px] md:h-[40px] flex items-center gap-2">
              <img
                width={25}
                height={10}
                src="/icons/ArrowLeft.svg"
                alt="arrow"
              />
              <a
                className="text-[14px] font-[500] text-[#162C5B] hover:text-[#162C5B]"
                href="https://retack.ai"
              >
                Go to Home Page
              </a>
            </button>
          </div>
          <div className="mt-[10%] md:mt-0 flex flex-col items-center">
            <div className="w-[213px] md:w-[218px] h-[34px] md:h-[65px]">
              <img
                className="w-[100%] h-[100%] object-contain"
                src="/logos/Logo.svg"
                alt="logo"
              />
            </div>
            <p className="text-[16px] md:text-[20px] mt-[18px] md:mt-[22.5px]">
              Unlock Powerful Data Analytics
            </p>
          </div>
          <div className="mt-[10%] md:mt-[55px] flex justify-center">
            <div className="w-[255px] md:w-[387px] h-[180px] md:h-[290px]">
              <img
                className="w-[100%] h-[100%] object-contain"
                src="/logos/Frame.svg"
                alt="Analytics Logo"
              />
            </div>
          </div>
        </div>
        <div className="col-span-3 bg-white h-[100%] pt-[56px]  md:pt-0 pb-[5%] md:pb-0">
          <div className="flex justify-center items-center h-screen">
            <div className="flex flex-col justify-center items-center gap-[20px] py-[20px]">
              {verificationStatus === "success" && (
                <>
                  <img
                    width={50}
                    height={50}
                    src="/images/Tickmark.png"
                    alt="Tick Mark"
                  />
                  <p className="font-bold text-[30px] text-center">
                    Success !!
                  </p>
                  <p className="text-[18px] text-center">
                    Your email has been verified. Your account is now active.
                  </p>
                  <div className="flex justify-center items-center">
                    <button className="text-lg font-bold text-center">
                      <NavLink
                        to="/login"
                        className="text-[#112692] font-medium text-lg md:text-base lg:text-lg"
                      >
                        Get Started
                      </NavLink>
                    </button>
                  </div>
                </>
              )}
              {verificationStatus === "failed" && (
                <>
                  <img
                    width={50}
                    height={50}
                    src="/images/exception.png"
                    alt="Exception"
                  />
                  <p className="font-bold text-[30px] text-center">
                    Sorry, your token has expired
                  </p>
                  <p className="text-[18px] text-center">
                    Looks like your token has already expired. Please click the
                    link below to resend verification link.
                  </p>
                  <div className="flex justify-center items-center">
                    <button
                      onClick={() => onResend()}
                      className="text-lg font-bold text-center"
                    >
                      <div className="text-[#112692] font-medium text-lg md:text-base lg:text-lg">
                        {isLoading ? (
                          <img
                            width={22}
                            height={22}
                            src="/icons/Loading.svg"
                            alt="loading"
                          />
                        ) : (
                          "Resend verification link"
                        )}
                      </div>
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUpVerification;

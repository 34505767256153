import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useState, useRef, useEffect } from "react";
import _ from "lodash";
import {
  setIssuesEndDateStates,
  setIssuesStartDateStates,
  setIssuesTimeRange,
  setShowIssuesRangePicker,
} from "@feature/issuesSlice";
import {
  setErrorEndDateStates,
  setErrorStartDateStates,
  setErrorTimeRange,
  setShowErrorRangePicker,
} from "@feature/errorSlice";
import { DefinedRange, DateRange, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { getDateTimeStates } from "@src/actions/getDateTimeStates";
import { subDays, subMonths, differenceInMonths, isAfter } from "date-fns";
import { ErrorToast } from "../toasts/ErrorToast";

export const DateRangePickerContainer = (props: IssuesDateRangePickerProps) => {
  const component = props.component;
  const isForIssues = _.isEqual(component, "Issues");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateMonthOptions = () => {
      const monthOptions = document.querySelectorAll(" option");
      const today = new Date();
      const threeMonthsAgo = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );

      monthOptions.forEach((option) => {
        if (option instanceof HTMLOptionElement) {
          const monthValue = parseInt(option.value);
          const year = today.getFullYear();
          const monthDate = new Date(year, monthValue, 1);

          if (monthDate < threeMonthsAgo || monthDate > today) {
            option.disabled = true;
            option.style.color = "rgba(0, 0, 0, 0.5)"; // Fallback color with opacity
          } else {
            option.disabled = false;
            option.style.color = "";
          }
        }
      });
    };

    // Set up a MutationObserver to handle dynamically added elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          updateMonthOptions();
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function
    return () => observer.disconnect();
  }, []);

  const handleDateRangeChange = (item: RangeKeyDict, invokedBy: string) => {
    const { startDate, endDate } = item.selection;
    let startDateState = startDate;
    let endDateState = endDate;
    const today = new Date();
    if (differenceInMonths(today, startDate!) > 3 || isAfter(endDate!, today)) {
      const maxDate = new Date();
      const minDate = subMonths(maxDate, 3);
      startDateState = startDate! < minDate ? minDate : startDate;
      endDateState = endDate! > maxDate ? maxDate : endDate;
      if (invokedBy === "DateRange") {
        ErrorToast({
          Message: `Date range must be within the last 3 months and today. Updated start date: ${startDateState!.toDateString()}, updated end date: ${endDateState!.toDateString()}`,
        });
      }
    }

    if (isForIssues) {
      dispatch(setIssuesStartDateStates(getDateTimeStates(startDateState!)));
      dispatch(setIssuesEndDateStates(getDateTimeStates(endDateState!)));
      dispatch(setIssuesTimeRange());
    } else {
      dispatch(setErrorStartDateStates(getDateTimeStates(startDateState!)));
      dispatch(setErrorEndDateStates(getDateTimeStates(endDateState!)));
      dispatch(setErrorTimeRange());
    }
    setState([
      {
        startDate: startDateState || new Date(),
        endDate: endDateState || new Date(),
        key: "selection",
      },
    ]);
  };

  const rangePickerStates: DateRangePickerStates = useSelector(
    (state: RootState) =>
      isForIssues
        ? state.issuesSlice.dateRangeStates
        : state.errorSlice.dateRangeStates
  );

  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setState([
      {
        startDate: rangePickerStates.startDateStates.normalDate,
        endDate: rangePickerStates.endDateStates.normalDate,
        key: "selection",
      },
    ]);
  }, [rangePickerStates]);

  const dateRangePickerRef = useRef<HTMLDivElement>(null);

  return (
    !_.isUndefined(rangePickerStates) && (
      <div
        className="flex items-center gap-2 w-[35%]"
        onMouseLeave={() =>
          dispatch(
            isForIssues
              ? setShowIssuesRangePicker(false)
              : setShowErrorRangePicker(false)
          )
        }
      >
        <div className="text-[#4F5A69] mr-2 font-medium text-sm leading-[18px] whitespace-nowrap">
          Date :{" "}
        </div>

        <div
          ref={dateRangePickerRef}
          className="relative w-[100%] h-[35px] bg-white rounded-[8px]  px-3 border border-[#DDE0EF] "
        >
          <div
            data-testid="DateRangePickerContainer"
            className="flex justify-between items-center cursor-pointer w-full h-full"
            onClick={() =>
              dispatch(
                isForIssues
                  ? setShowIssuesRangePicker()
                  : setShowErrorRangePicker()
              )
            }
          >
            <div className="flex md:w-[90%] h-full lg:min-w-max items-center text-solid_gray">
              <img
                src="/icons/Calendar.svg"
                alt="Calendar Icon"
                className="w-[16px] mr-2"
              />
              <div className="truncate mr-4 text-[14px] font-medium text-[#292C31] overflow-hidden">
                {rangePickerStates.combinedDateRange == ""
                  ? "All Time"
                  : rangePickerStates.combinedDateRange}
              </div>
            </div>

            <div className="flex justify-center items-center bg-[#E6E8F3] rounded-full w-[18px] h-[18px]">
              <img
                className="w-[15px]"
                src={`/icons/${rangePickerStates.rangePickerViewIcon}.svg`}
                alt="View Datepicker"
              />
            </div>
          </div>
          <div className="h-1"></div>
          {rangePickerStates.showDateRangePicker && (
            <div
              className={
                "absolute flex shadow-md rounded-[8px] z-10 w-full md:w-auto  right-0 text-black"
              }
            >
              <DateRange
                rangeColors={["#325EBB"]}
                minDate={subMonths(new Date(), 3)}
                maxDate={new Date()}
                retainEndDateOnFirstSelection={true}
                months={1}
                ranges={state}
                direction="horizontal"
                dateDisplayFormat="yyyy MMM dd hh:mm a"
                editableDateInputs={true}
                onChange={(item) => {
                  handleDateRangeChange(item, "DateRange");
                }}
              />

              <DefinedRange
                onChange={(item) => {
                  handleDateRangeChange(item, "DefinedRange");
                }}
                ranges={state}
                inputRanges={[]}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default DateRangePickerContainer;

import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RepositoryContext, customLogger } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const IssueStatusPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const projectId = params.id;
  const statusId = params.statusId;
  const repository = useContext(RepositoryContext);
  const codeFixRepo = repository.issues;
  const { showBoundary } = useErrorBoundary();
  const [codeFixData, setCodeFixData] = useState<CodeFixData>();
  const [expandedRows, setExpandedRows] = useState<number[]>([5]);

  useEffect(() => {
    const fetchCodeFix = async () => {
      dispatch(setIsLoading(true));
      try {
        const codeFixList = await codeFixRepo.getCodeFix<CodeFixData>(
          `${projectId}`
        );
        if (!_.isUndefined(codeFixList)) {
          if ("errors" in codeFixList) {
            await customLogger.reportErrorResponse(showBoundary, codeFixList);
          } else {
            setCodeFixData(codeFixList);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    };
    fetchCodeFix();
  }, [codeFixRepo, dispatch, projectId, showBoundary]);

  const capitalizeFirstLetter = (
    string: string | undefined
  ): string | undefined => {
    if (!string || string.length === 0) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const numericStatusId = Number(statusId);

  const matchedItem = _.find(codeFixData?.ai_fix_data, { id: numericStatusId });

  const getStatusBgColor = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case "failed":
        return "bg-[#ED4337] bg-opacity-[0.10] text-[#ED4337]";
      case "running":
        return "bg-[#0EB000] bg-opacity-[0.10] text-[#0EB000]";
      case "in queue":
        return "bg-[#2E00B0] bg-opacity-[0.10] text-[#2E00B0]";
      case "completed":
        return "bg-[#0051B0] bg-opacity-[0.10] text-[#0051B0]";
      default:
        return "bg-[#F7F7F7]";
    }
  };

  const handleRowClick = (index: number) => {
    // Toggle the expansion state of the clicked row
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        // If the index is already in the expanded rows, remove it
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        // If the index is not in the expanded rows, add it
        return [...prevExpandedRows, index];
      }
    });
  };

  return (
    <>
      <div className="flex flex-col ms-9 w-[95%]">
        <div className="flex justify-start items-center gap-4 my-8">
          <div className="font-medium">Task Status:</div>
          <div
            className={`${getStatusBgColor(
              matchedItem?.status
            )} px-11 py-2 rounded-full`}
          >
            {capitalizeFirstLetter(matchedItem?.status)}
          </div>
        </div>
        <div className="flex flex-col border border-[#D9D9D9] rounded mb-10">
          <div className="flex justify-between items-center p-7">
            <div className="text-lg">Progress Status</div>
            <div className="flex gap-3">
              <div className="flex gap-2">
                <img src="/icons/RunningIcon.svg" alt="RunningIcon" />
                Completed
              </div>
              <div className="flex gap-2">
                <img src="/icons/CrossErrorIcon.svg" alt="CrossErrorIcon" />
                Error
              </div>
            </div>
          </div>
          {_.map(matchedItem?.step_status, (step, index) => (
            <>
              <div
                key={index}
                className={`flex justify-between items-center mb-2 py-2 px-4 ${
                  step.success === true
                    ? "bg-[#d6fdcc]"
                    : step.success === false
                    ? "bg-[#FFF1F1]"
                    : "bg-[#F1F5FF]"
                }`}
                onClick={() => handleRowClick(index)}
              >
                <div className="flex gap-2">
                  <img
                    src={`${
                      step.success === true
                        ? "/icons/RunningIcon.svg"
                        : "/icons/CrossErrorIcon.svg"
                    } `}
                    alt="status"
                  />
                  <div className="flex">{step.title}</div>
                </div>
                <div>
                  <img
                    src={`/icons/DownPointer.svg`}
                    alt="DownPointer"
                    className={`transform transition-transform ${
                      expandedRows.includes(index) ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>
              {expandedRows.includes(index) && (
                <div className="flex justify-between ps-[50px] pe-[50px] py-4">
                  {index !== 5 && <>{step.detail}</>}
                  {index === 5 && (
                    <>
                      {step.detail.startsWith("http") ? (
                        <a
                          href={step.detail}
                          target="_blank"
                          className="text-[#001789]"
                        >
                          Link to Code Fix Pull Request
                        </a>
                      ) : (
                        <span>{step.detail}</span>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default IssueStatusPage;

import { useContext, useEffect, useRef, useState } from "react";
import InfoTooltip from "../../buttons/InfoTooltip";
import { useErrorBoundary } from "react-error-boundary";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { ErrorToast } from "../../toasts/ErrorToast";
import { SuccessToast } from "../../toasts/SuccessToast";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { RootState } from "@src/redux/store";
import { useSelector } from "react-redux";
import {
  setIsEditGitHubLabModalOpen,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { useDispatch } from "react-redux";

const EditGitHubLabModal = ({ source_id }: EditGitHubLabModalProps) => {
  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
    divRef2: useRef<HTMLDivElement>(null),
  };
  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const params = useParams();
  const project_id = params.id;

  const dispatch = useDispatch();

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const gitHubLabRepo = repository.gitHubLab;
  const [vcsId, setVcsId] = useState<string>("");
  const [integration, setIntegration] = useState<GitHubLabDataProps>();
  const [integrationType, setIntegrationType] = useState<string>();

  const closeEditGitHubLabModal = () => {
    dispatch(setIsEditGitHubLabModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  const { register, handleSubmit, setError, setValue, watch } =
    useForm<AddGitHubLabFieldData>();

  const onSubmit = async (data: AddGitHubLabFieldData) => {
    try {
      const response = await gitHubLabRepo.editGitHubLab<
        MemberData,
        AddGitHubLabFieldData
      >(`${project_id}`, source_id, vcsId, data);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "access_token" | "clone_url";
            setError(validField, { message: error.message });
            if (validField !== "access_token" && validField !== "clone_url") {
              ErrorToast({ Message: error.message });
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeEditGitHubLabModal();
          const info: InfoLog = {
            message: "details updated.",
          };
          await customLogger.log(info);
          SuccessToast({ Message: "Success! GitHub has been edited." });
        }
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  useEffect(() => {
    const source = _.find(
      sourceSlice.sourceData,
      (source) => source.id === source_id
    );
    if (source && source.integrations.github.is_connected) {
      setVcsId(source.integrations.github.id);
    } else if (source && source.integrations.gitlab.is_connected) {
      setVcsId(source.integrations.gitlab.id);
    }
  }, [sourceSlice.sourceData, source_id]);

  useEffect(() => {
    if (!vcsId) {
      return;
    }
    const fetchIntegration = async () => {
      try {
        const integrationData =
          await gitHubLabRepo.retrieve<GitHubLabDataProps>(
            project_id!,
            source_id,
            vcsId
          );
        if (!_.isUndefined(integrationData)) {
          if ("errors" in integrationData) {
            await customLogger.reportErrorResponse(
              showBoundary,
              integrationData
            );
          } else {
            setIntegration(integrationData);
            setValue("access_token", integrationData.access_token);
            setValue("clone_url", integrationData.clone_url);
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    };
    fetchIntegration();
  }, [
    customLogger,
    gitHubLabRepo,
    project_id,
    setValue,
    showBoundary,
    sourceSlice.sourceData,
    source_id,
    vcsId,
  ]);

  useEffect(() => {
    const checkRepoType = (url: string | undefined) => {
      const githubRegex = /github\.com/;
      const gitlabRegex = /gitlab\.com/;

      if (githubRegex.test(url!)) {
        return "GitHub";
      } else if (gitlabRegex.test(url!)) {
        return "GitLab";
      } else {
        return "Unknown";
      }
    };
    setIntegrationType(checkRepoType(integration?.clone_url));
  }, [integration?.clone_url]);

  const watchFields = watch(["access_token", "clone_url"]);
  const isFormDirty = watchFields.some(
    (field, index) =>
      field !==
      integration?.[
        ["access_token", "clone_url"][index] as keyof GitHubLabDataProps
      ]
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-between">
            <h2 className="text-[24px] font-[700] flex gap-[20px]">
              <img width={32} height={32} src="/icons/Github.svg" alt="cross" />
              Edit {integrationType} Integration
            </h2>
            <button type="button" onClick={closeEditGitHubLabModal}>
              <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
            </button>
          </div>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500] flex items-center"
              htmlFor="channel_id"
            >
              Access_token
              <span className="text-red text-sm ml-1">*</span>
              <InfoTooltip
                divRef={divRefs.divRef1}
                messages={[
                  "The access token is partially hidden for safety reasons.",
                ]}
                bgColor="bg-[grey]"
                textColor="text-[white]"
              />
            </label>
            <input
              {...register("access_token", {})}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
            />
          </div>
          <div className="mt-2">
            <label
              className="text-text_gray text-sm font-[500] flex items-center"
              htmlFor="channel_id"
            >
              Clone_url
              <span className="text-red text-sm ml-1">*</span>
              <InfoTooltip
                divRef={divRefs.divRef2}
                messages={["Url of the repository."]}
                bgColor="bg-[#777777]"
                textColor="text-[white]"
              />
            </label>
            <input
              {...register("clone_url", {})}
              className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
              type="text"
            />
          </div>
          <div className="flex justify-start gap-[20px] mt-[16px]">
            <button
              type="submit"
              className="bg-button_blue w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              disabled={!isFormDirty}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditGitHubLabModal;

import { createSlice } from "@reduxjs/toolkit";

const initialState: EnvironmentSliceProps = {
	currentEnvironment: null,
	currentEnvironmentKey: null,
	deleteEnvKey: false
};

export const envrionmentSlice = createSlice({
	name: "Envrionment Slice",
	initialState,
	reducers: {
		setCurrentEnvironment: (state, action) => {
			state.currentEnvironment = action.payload;
		},
		setCurrentEnvironmentKey: (state, action) => {
			state.currentEnvironmentKey = action.payload;
		},
		setDeleteEnvKey:(state) =>{
			state.deleteEnvKey = !state.deleteEnvKey
		}
	}
});

export const { setCurrentEnvironment, setCurrentEnvironmentKey, setDeleteEnvKey } =
	envrionmentSlice.actions;
export default envrionmentSlice.reducer;

import { useNavigate } from "react-router-dom";

interface NotFoundProps {
  resetErrorBoundary?: () => void;
}

const NotFound = ({ resetErrorBoundary }: NotFoundProps) => {
  const navigate = useNavigate();
  const orgId = localStorage.getItem("orgId");
  return (
    <div
      data-testid="NotFound"
      className="flex justify-center items-center font-bold text-2xl w-screen h-screen flex-col"
    >
      Page Not Found
      <button
        data-testid="HomeButton"
        className="bg-solid_gray text-white py-1 px-3 rounded-md mt-5 text-xl cursor-pointer"
        onClick={() => {
          if (resetErrorBoundary) {
            resetErrorBoundary();
          }
          navigate(`/organization/${orgId}`);
        }}
      >
        Dashboard
      </button>
    </div>
  );
};

export default NotFound;

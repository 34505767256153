import { ErrorBoundary } from "react-error-boundary";
import { FallbackComponent } from "./FallbackComponent";
import { useContext } from "react";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import { LoggerContext } from "@shared/contexts";

export const CustomErrorBoundary = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const customLogger = useContext<CustomLogger>(LoggerContext);

  const handleError = async (error: Error) => {
    await customLogger.reportError(error);
  };
  return (
    <ErrorBoundary onError={handleError} FallbackComponent={FallbackComponent}>
      {children}
    </ErrorBoundary>
  );
};

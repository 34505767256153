import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import { useContext, useEffect } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import _ from "lodash";
import { useErrorBoundary } from "react-error-boundary";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { setIsLoading } from "@src/redux/feature/modalHandler";

const EditProjectModal = ({ closeEditProjectModal }: EditProjectModalProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<ProjectFieldData>();

  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const memberSlice = useSelector((state: RootState) => state.memberSlice);
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const role = memberSlice.current_member?.role;

  const dispatch = useDispatch<AppDispatch>();
  const repository = useContext(RepositoryContext);
  const projectRepo = repository.project;

  useEffect(() => {
    const PID = Number(projectSlice.project_id);
    const currentProject = projectSlice.projectData.find(
      (project) => project.id === PID
    );
    clearErrors();
    setValue("title", currentProject?.title);
    setValue("description", currentProject?.description);
  }, [
    setValue,
    projectSlice.projectData,
    projectSlice.project_id,
    clearErrors,
  ]);

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const onSubmit = async (data: ProjectFieldData) => {
    dispatch(setIsLoading(true));
    try {
      const response = await projectRepo.editProject<
        ProjectData,
        ProjectFieldData
      >(projectSlice.project_id!.toString(), data);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as "title" | "description";
            if (validField === "title" || "description") {
              setError(validField, { message: error.message });
            }
            if (
              validField !== "title" &&
              validField !== "description"
            ) {
              ErrorToast({ Message: error.message });
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          closeEditProjectModal?.();
          SuccessToast({ Message: "Success! Your Project has been Edited." });
        }
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[2]">
      <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[850px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className="text-[28px] text-text_dark_blue font-[700]">
            Edit Project
          </h1>
          <div className="mt-[14px]">
            <label
              className="text-[#7C7C8D] text-[12px] font-[500]"
              htmlFor="title"
            >
              Project Name
              <span className="text-error text-[14px]">*</span>
            </label>
            <input
              disabled={role === "Basic"}
              {...register("title", {
                required: "Title Required",
              })}
              className="border border-[#E9EAEC] rounded-xl w-full h-[48px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-[10px]"
              type="text"
            />
            {errors.title != null && (
              <small className="block text-error mt-2">
                {errors.title.message}
              </small>
            )}
          </div>
          <div className="mt-[16px]">
            <label
              className="text-[#7C7C8D] text-[12px] font-[500]"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              disabled={role === "Basic"}
              {...register("description")}
              className="border border-[#E9EAEC] rounded-xl w-full h-[137px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-[10px] resize-none"
            />
           {errors.description != null && (
              <small className="block text-red mt-2">
                {errors.description.message}
              </small>
            )}
          </div>
          <div className="flex justify-start gap-[20px] mt-[16px]">
            {isLoading ? (
              <button
                disabled
                className="flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
              >
                <img
                  width={22}
                  height={22}
                  src="/icons/Loading.svg"
                  alt="loading"
                />
                Saving...
              </button>
            ) : (
              <button
                disabled={role === "Basic"}
                type="submit"
                className={`bg-[#001789] w-[150px] h-[38px] text-white text-[14px] rounded-md ${
                  role === "Basic" ? "opacity-30 cursor-not-allowed" : ""
                }`}
              >
                Save and Proceed
              </button>
            )}

            <button
              onClick={closeEditProjectModal}
              type="button"
              className={`border border-[#001789] w-[125px] h-[38px] text-[#001789] text-[14px] rounded-md`}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProjectModal;

import { setDemoIssues } from "@actions/setDemoIssues";
import { setIssuesPageNumber } from "@feature/issuesSlice";
import { RootState } from "@redux/store";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { BarDiagram } from "@sharedComponents/issuesExplorer/BarDiagram";
import { DemoSourceEnvironmentCheckbox } from "@src/components/sharedComponents/issuesExplorer/DemoSourceEnvironmentCheckbox";
import { DemoDateRangePicker } from "@sharedComponents/issuesExplorer/DemoDateRangePicker";
import { ViewAllErrorModal } from "@sharedComponents/modal/ViewAllErrorModal";
import Pagination from "@src/components/Pagination";
import { setIsViewAllErrorsOpen } from "@src/redux/feature/modalHandler";
import _ from "lodash";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useContext, useState } from "react";
import { DemoIssueDetails } from "@src/components/sharedComponents/issuesExplorer/DemoIssueDetails";
import CustomToolTip from "@src/components/sharedComponents/CustomToolTip";
import { DemoIssuesList } from "@src/components/sharedComponents/issuesExplorer/DemoIssuesList";

// Renders the overall Issues overview component
export const DemoIssuesExplorer = () => {
  const repository = useContext(RepositoryContext);
  const issuesRepo = repository.issues;

  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const issuesSlice = useSelector((state: RootState) => state.issuesSlice);

  const dispatch = useDispatch();
  dispatch(setIsViewAllErrorsOpen(false));

  const isViewAllErrorOpen = modalHandler.isViewAllErrorsOpen;

  const { total_pages, current_page, has_next_page, has_previous_page } =
    issuesSlice.issues_response.meta_data;

  const [isIssuesLoading, setIssuesLoading] = useState<boolean>(false);

  const customLogger = useContext(LoggerContext);
  const { showBoundary } = useErrorBoundary();

  const setPageNumber = (page: number) => {
    dispatch(setIssuesPageNumber(page));
  };

  const handleNextPage = () => {
    if (has_next_page) {
      dispatch(setIssuesPageNumber(current_page + 1));
    }
  };

  const handlePreviousPage = () => {
    if (has_previous_page) {
      dispatch(setIssuesPageNumber(current_page - 1));
    }
  };

  // Based on currently set request parameters, fetch issues list and store it
  useEffect(() => {
    if (!_.isNull(projectSlice.project_id)) {
      setIssuesLoading(true);
      setDemoIssues(
        issuesRepo,
        issuesSlice.request_params,
        customLogger,
        showBoundary,
        dispatch
      )
        .catch(async (error) => {
          showBoundary(error);
          await customLogger.reportError(error);
        })
        .finally(() => setIssuesLoading(false));
    }
  }, [
    projectSlice.project_id,
    customLogger,
    issuesRepo,
    issuesSlice.request_params,
    dispatch,
    showBoundary,
  ]);

  const barGraphProps: BarGraphProps = {
    frequency_data: issuesSlice.issues_response.errors_frequencies,
    max_bar_height: 100,
    containerHeight: 120,
    x_axis_label_count: 13,
    y_axis_label_count: 4,
    bars_container_width: 1550,
  };

  return (
    <>
      {!isViewAllErrorOpen && (
        <>
          <div className="p-[25px] md:p-[36px] flex flex-col h-[90vh] w-full">
            <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4">
              Issues
            </h1>

            {/* Checkboxes and Radio selection components */}
            <div className="flex items-center w-[100%] gap-8 mb-2">
              <div className="flex w-[30%] h-[34px] bg-white border border-border_gray rounded-[8px] px-3">
                <img
                  src="/icons/Search.svg"
                  alt="SearchIcon"
                  className="w-[14px]"
                />
                <input
                  type="text"
                  className="w-full h-[30px] outline-none focus:outline-none rounded-[8px] ml-2"
                  placeholder="Search"
                />
              </div>
              {/* Passing properties to the Checkboxes type component for Source and Environment filter */}
              <CustomToolTip width="w-[35%]">
                <DemoSourceEnvironmentCheckbox />
              </CustomToolTip>
              <CustomToolTip width="w-[35%]">
                <DemoDateRangePicker />
              </CustomToolTip>
              {/* Passing properties to the Toggler type component for sorting Issues list */}
            </div>
            {isIssuesLoading ? (
              <div className="flex w-full h-[50vh] items-center justify-center">
                <img
                  className="w-[50px]"
                  src="/icons/Loading.svg"
                  alt="LoadingIcon"
                />
              </div>
            ) : (
              <>
                {/* Bar diagram component */}
                {!_.isEmpty(issuesSlice.issues_response.issue_data) && (
                  <>
                    <div className="max-h-[120px] mb-7  w-full">
                      <BarDiagram {...barGraphProps} />
                    </div>
                    <div className="border border-[#D9DCED] flex justify-start items-center gap-3 p-[10px] rounded-[5px] ">
                      <img
                        className="h-[15px]"
                        src="/icons/Clock.svg"
                        alt="Clock"
                      />{" "}
                      <div className="leading-[18px] text-[14px] font-normal">
                        {" "}
                        All times are in Coordinated Universal Time (UTC){" "}
                      </div>
                    </div>
                  </>
                )}
                {/* Issues List components */}
                <div className="py-[11px]">
                  <div className="border-[2px] border-[#D9DCED] rounded-t-[8px] overflow-hidden">
                    <table className="w-full text-[#292C31] bg-[#F3F4F8] overflow-y-auto">
                      <thead className="text-[14px] text-left ">
                        <tr>
                          <th className="font-[700] py-3 px-6">Title</th>
                          <th className="font-[700] py-3 px-6 text-center cursor-pointer">
                            <div className="flex justify-center items-center gap-2">
                              {" "}
                              Last Seen Date{" "}
                              <img
                                src="/icons/SortByInactive.svg"
                                alt="SortByActive"
                                className="w-[14px]"
                              />
                            </div>
                          </th>
                          <th className="font-[700] py-3 px-6 text-center cursor-pointer">
                            <div className="flex justify-center items-center gap-2">
                              First Occurred Date
                              <img
                                src="/icons/SortByInactive.svg"
                                alt="SortByActive"
                                className="w-[14px]"
                              />
                            </div>
                          </th>
                          <th className="font-[700] py-3 px-6 text-center cursor-pointer">
                            <div className="flex justify-center items-center gap-2">
                              Issue Count
                              <img
                                src="/icons/SortByInactive.svg"
                                alt="SortByActive"
                                className="w-[14px]"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <DemoIssuesList />
                    </table>
                  </div>
                </div>
                <div className="w-[100%] flex justify-end mt-[1em]">
                  <Pagination
                    totalPages={total_pages}
                    currentPage={current_page}
                    onPageChange={setPageNumber}
                    hasNextPage={has_next_page}
                    hasPreviousPage={has_previous_page}
                    onNextPage={handleNextPage}
                    onPreviousPage={handlePreviousPage}
                  />
                </div>
                <DemoIssueDetails />
              </>
            )}
          </div>
        </>
      )}
      {isViewAllErrorOpen && <ViewAllErrorModal />}
    </>
  );
};

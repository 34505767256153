import {
  setIsOverlayVisible,
  setIsSubscriptionSuccessModalOpen,
} from "@src/redux/feature/modalHandler";
import { RepositoryContext } from "@src/shared/contexts";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const RedirectSubs = () => {
  const repository = useContext(RepositoryContext);
  const subsRepo = repository.subscription;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [localOrgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });
  const dispatch = useDispatch();

  const handleOpenSubscriptionSuccessModal = () => {
    dispatch(setIsSubscriptionSuccessModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const requestURL = window.location.href;
        const urlObj = new URL(requestURL);
        const sessionId = urlObj.searchParams.get("session_id");
        const response = await subsRepo.verifySession(`${localOrgId}`, {
          session_id: `${sessionId!}`,
        });
        if (response && typeof response === "object") {
          if ("errors" in response) {
            setIsLoading(false);
          } else {
            navigate(`/organization/${localOrgId}/subscription/plan`);
            handleOpenSubscriptionSuccessModal();
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [subsRepo]);

  return (
    <div>
      {isLoading && (
        <div className="h-screen w-screen flex items-center justify-center">
          <img src="/icons/Loading.svg" alt="loading" />
        </div>
      )}
    </div>
  );
};
export default RedirectSubs;

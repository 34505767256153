import { parseApiResponse } from "@actions/parseApiResponse";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { IIntegrationRepository } from "./types";
import { RepositoryURL } from "./constants.enum";

export class IntegrationRepository implements IIntegrationRepository {
  private _api: IJsonAPI;

  private ORGANIZATION_PROJECT_URL  = RepositoryURL.ORGANIZATION_PROJECT_URL

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async getIntegrationStatus<IntegrationData>(projectId: string) {
    const integration_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/integrations/status`;
    const response = await this._api.get<IntegrationData>(integration_url);
    return parseApiResponse(response);
  }

  async integrate<IntegrationData, IntegrationFieldData>(
    projectId: string,
    name: string | null,
    data: IntegrationFieldData
  ): Promise<IntegrationData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const integration_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/${name}`;
    const response = await this._api.post<
      IntegrationData,
      IntegrationFieldData
    >(integration_url, data, headers);
    return parseApiResponse<IntegrationData>(response);
  }

  async editIntegration<IntegrationData, IntegrationFieldData>(
    projectId: string,
    name: string | null,
    data: IntegrationFieldData
  ): Promise<IntegrationData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const integration_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/${name}`;
    const response = await this._api.put<
      IntegrationData,
      IntegrationFieldData
    >(integration_url, data, headers);
    return parseApiResponse<IntegrationData>(response);
  }

  async getIntegratedData<IntegratedData>(
    projectId: string,
    name: string | null
  ) {
    const integration_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/${name}`;
    const response = await this._api.get<IntegratedData>(integration_url);
    return parseApiResponse(response);
  }

  async removeIntegration(projectId: string, name: string | null) {
    const sources_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/${name}`;
    return await this._api.delete(sources_url);
  }
}

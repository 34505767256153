import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useNavigate } from "react-router-dom";
import DemoSidebarMenu from "./DemoSidebarMenu";
import DemoSidebarProjectMenu from "./DemoSidebarProjectMenu";
import SidebarOrganizationMenu from "./SidebarOrganizationMenu";

const DemoSidebar = () => {
  const loadingState = useSelector((state: RootState) => state.appSlice);
  const orgId = useSelector(
    (state: RootState) => state.organizationSlice.currentOrganization
  );

  const navigate = useNavigate();
  return (
    <section className="bg-nav_blue h-screen flex flex-col">
      <div
        onClick={() => navigate(`/organization/${orgId}`)}
        className="flex items-center w-full h-[9vh] cursor-pointer"
      >
        <img
          width={125}
          height={125}
          src="/icons/RetackLogo.svg"
          alt="LogoIcon"
          className="mx-10"
        />
      </div>
      <div className="flex flex-col justify-between h-[91dvh]">
        <div className="flex flex-col justify-center">
          {!loadingState.loading && <DemoSidebarProjectMenu />}
          <DemoSidebarMenu />
        </div>

        <div className="mb-4">
          <SidebarOrganizationMenu />
        </div>
      </div>
    </section>
  );
};

export default DemoSidebar;

import { ErrorTitles } from "@repositories/constants.enum";
import {
  BackendError,
  AuthenticationError,
  ValidationError,
} from "@repositories/CustomErrors";

// Logs a single error
export const getErrorLog = (error: unknown): ErrorLog => {
  let errorLog: ErrorLog;

  if (error instanceof Error) {
    const errorStack = error.stack;

    if (
      error instanceof BackendError ||
      error instanceof AuthenticationError ||
      error instanceof ValidationError
    ) {
      errorLog = {
        title: error.title,
        status: error.status,
        stackTrace: errorStack,
      };
    } else {
      errorLog = {
        title: ErrorTitles.UnknownError,
        stackTrace: errorStack,
      };
    }
    return errorLog;
  } else {
    errorLog = {
      title: ErrorTitles.UnknownError,
    };
    return errorLog;
  }
};

import { useDispatch, useSelector } from "react-redux";
import { useMatch, useParams } from "react-router-dom";
import _ from "lodash";
import { RootState } from "@redux/store";
import MobileMenu from "./MobileMenu";
import {
  setIsProfileDropdownOpen,
  setAllPopUp,
  setIsViewOrganizationModalOpen,
  setIsOverlayVisible,
  setIsAddOrganizationModalOpen,
} from "@src/redux/feature/modalHandler";
import ProfileModal from "@src/components/sharedComponents/modal/ProfileModal";
import { useContext, useEffect, useRef, useState } from "react";
import { resetIssueParams } from "@src/redux/feature/issuesSlice";
import { setOrganizationList } from "@src/redux/feature/organizationSlice";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { fetchCurrentMember } from "@src/actions/fetchCurrentMember";
import ViewOrganizationModal from "@src/components/sharedComponents/modal/ViewOrganizationModal";
import AddOrganizationModal from "@src/components/sharedComponents/modal/AddOrganinzationModal";


const Navbar = () => {
  const params = useParams();
  const projectId = params.id;
  const issueId = params.issueid;

  const dispatch = useDispatch();
  const mobileNav = useSelector((state: RootState) => state.appSlice);
  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const issueDetailsSlice = useSelector(
    (state: RootState) => state.issueDetailsSlice
  );
  const organizationSlice = useSelector(
    (state: RootState) => state.organizationSlice
  );
  const OrganizationModal = useSelector(
    (state: RootState) => state.modalHandler
  );
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const organizationRepo = repository.organization;
  const memberRepo = repository.member;

  const profileModalRef = useRef<HTMLDivElement>(null);

  const [orgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });

  const breadCrumbMaker = () => {
    const isErrorPage = !!useMatch("/projects/:id/issues/:issueId/errors");
    const isUsage = !!useMatch("projects/:id/usage");
    const isAccountSettings = !!useMatch(
      "organization/:orgId/account-settings"
    );
    const isIssueResolutionPage = !!useMatch(
      "/projects/:id/sources/:sourceId/issues/:issueId/fix"
    );
    switch (true) {
      case isUsage:
        return ["Settings", "Usage"];
      case isAccountSettings:
        return ["Organization", "Account-Settings"];
      case isErrorPage:
        return ["Issue", issueDetailsSlice.response.issue_data?.title];
      case isIssueResolutionPage:
        return ["Task", "Fix Issue #" + issueId];
      default:
        return [];
    }
  };

  const breadCrumb = breadCrumbMaker();

  useEffect(() => {
    const handleHistoryChange = () => {
      dispatch(setAllPopUp(false));
    };
    handleHistoryChange();
  }, [window.location.href]);

  useEffect(() => {
    dispatch(resetIssueParams());
  }, [projectId]);

  const ToggleProfileModal = () => {
    dispatch(setIsProfileDropdownOpen(!modalHandler.isProfileDropdownOpen));
  };

  const closeViewOrganizationModal = () => {
    dispatch(setIsViewOrganizationModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };
  const closeAddOrganizationModal = () => {
    dispatch(setIsAddOrganizationModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  useEffect(() => {
    const getOrganization = async () => {
      try {
        const OrgResponse = await organizationRepo.getOrganization<
          Organization[]
        >();
        if (!_.isUndefined(OrgResponse)) {
          if ("errors" in OrgResponse) {
            await customLogger.reportErrorResponse(showBoundary, OrgResponse);
          } else {
            dispatch(setOrganizationList(OrgResponse));
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      }
    };
    getOrganization();
  }, [modalHandler.isEditOrganizationModalOpen]);

  useEffect(() => {
    if (orgId !== 0) {
      try {
        fetchCurrentMember(
          memberRepo,
          orgId.toString(),
          showBoundary,
          dispatch,
          customLogger
        );
      } catch (error) {
        showBoundary(error);
        customLogger.reportError(error);
      }
    }
  }, [
    customLogger,
    dispatch,
    memberRepo,
    projectId,
    organizationSlice.currentOrganization,
    showBoundary,
  ]);

  return (
    <div>
      <section
        className={`h-[9vh] flex items-center justify-between px-[25px] md:px-[36px]`}
      >
        <div className="flex w-full justify-between items-center">
          {breadCrumb.length !== 0 && (
            <>
              <div className="flex items-center gap-2">
                <div className="text-[#112692] font-medium text-[14px]">
                  {breadCrumb[0]}
                </div>
                <img
                  src="/icons/Ep_arrow.svg"
                  alt="Ep_arrowIcon"
                  width={14}
                  height={14}
                />
                <div className="font-normal text-[#172B4D] text-[14px]">
                  {breadCrumb[1]}
                </div>
              </div>
            </>
          )}
          <div className="grow" />
        </div>
      </section>
      <div ref={profileModalRef}>
        {modalHandler.isProfileDropdownOpen && (
          <ProfileModal ToggleProfileModal={ToggleProfileModal} />
        )}
      </div>
      {mobileNav.mobileNav && <MobileMenu />}
      {OrganizationModal.isViewOrganizationModalOpen && (
        <ViewOrganizationModal
          closeViewOrganizationModal={closeViewOrganizationModal}
        />
      )}
      {OrganizationModal.isAddOrganizationModalOpen && (
        <AddOrganizationModal
          closeAddOrganizationModal={closeAddOrganizationModal}
        />
      )}
    </div>
  );
};
export default Navbar;

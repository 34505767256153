import { useState, useEffect } from "react";
import { SuccessToast } from "@sharedComponents/toasts/SuccessToast";
import FixIssueStep from "@src/utils/FixIssueSteps.json";
import PRList from "@src/utils/PRList.json";
import _ from "lodash";
import { useParams } from "react-router-dom";

export const DemoIssueResolutionPage = () => {
  type PRLinks = Record<string, string>;
  const prLinks: PRLinks = PRList;
  const params = useParams();
  const issueId: string = params.issueid!;
  const [processSteps, setProcessSteps] = useState(() =>
    _.cloneDeep(FixIssueStep)
  );
  const [successResponse, setSuccessResponse] = useState<string>();
  const [taskStatus, setTaskStatus] = useState<string>("In Queue");
  const [successToastShown, setSuccessToastShown] = useState(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [showPRLink, setShowPRLink] = useState<boolean>(false);
  const [prLink, setPRLink] = useState<string>("");

  // Handle showing toasts
  useEffect(() => {
    if (successResponse && !successToastShown) {
      SuccessToast({ Message: successResponse });
      setSuccessToastShown(true);
    }
  }, [successResponse, successToastShown]);

  // Simulate step progression with delay
  useEffect(() => {
    setPRLink(prLinks[issueId]);
    const simulateProgression = async () => {
      for (let i = 0; i < processSteps.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const updatedSteps = [...processSteps];
        updatedSteps[i][2] = "true";
        updatedSteps[i][0] = "RunningIcon";
        updatedSteps[i][3] =
          i === processSteps.length - 1 && showPRLink
            ? "Step completed - Link to Code Fix Pull Request"
            : "Step completed";

        setProcessSteps(updatedSteps);
        setTaskStatus("Running");

        // Automatically expand the last step when it's completed
        if (i === processSteps.length - 1) {
          setSuccessResponse(
            "Your issue is resolved. Please check the repo for the new PR."
          );
          setTaskStatus("Fixed");
          setShowPRLink(true);
          setExpandedRows([...expandedRows, i]);
        }
      }
    };

    simulateProgression();
  }, []);

  // Handle clicks on row divs to toggle expansion
  const handleRowClick = (index: number) => {
    setExpandedRows((prevExpandedRows) => {
      if (prevExpandedRows.includes(index)) {
        return prevExpandedRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevExpandedRows, index];
      }
    });
  };

  return (
    <div className="flex flex-col ms-9 w-[95%]">
      <div className="flex justify-start items-center gap-4 my-8">
        <div className="font-medium">Task Status:</div>
        <div className="border border-[#D9D9D9] px-11 py-2 rounded">
          {taskStatus}
        </div>
      </div>
      <div className="flex flex-col border border-[#D9D9D9] rounded mb-10">
        <div className="flex justify-between items-center p-7">
          <div className="text-lg">Progress Status</div>
          <div className="flex gap-3">
            <div className="flex gap-2">
              <img src="/icons/RunningIcon.svg" alt="RunningIcon" />
              Running
            </div>
            <div className="flex gap-2">
              <img src="/icons/CrossErrorIcon.svg" alt="CrossErrorIcon" />
              Error
            </div>
            <div className="flex gap-2">
              <img src="/icons/InQueueIcon.svg" alt="InQueueIcon" />
              In Queue
            </div>
          </div>
        </div>
        {processSteps.map((step, index) => (
          <div key={index}>
            <div
              className={`flex justify-between items-center mb-2 py-2 px-4 ${
                step[0] === "RunningIcon"
                  ? "bg-[#d6fdcc]"
                  : step[0] === "CrossErrorIcon"
                  ? "bg-[#FFF1F1]"
                  : "bg-[#F1F5FF]"
              }`}
              onClick={() => handleRowClick(index)}
            >
              <div className="flex gap-2">
                <img src={`/icons/${step[0]}.svg`} alt="status" />
                <div className="flex">{step[1]}</div>
              </div>
              <div>
                <img
                  src={`/icons/DownPointer.svg`}
                  alt="DownPointer"
                  className={`transform transition-transform ${
                    expandedRows.includes(index) ? "rotate-180" : ""
                  }`}
                />
              </div>
            </div>

            {expandedRows.includes(index) && (
              <div className="flex justify-between ps-[50px] pe-[50px] py-4">
                {index !== processSteps.length - 1 && step[3]}
                {index === processSteps.length - 1 && showPRLink && (
                  <a
                    href={`${prLink}`}
                    target="_blank"
                    className="text-[#001789]"
                  >
                    Link to Code Fix Pull Request
                  </a>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex items-center gap-3 border border-[#D9D9D9] rounded mb-10 p-3 text-sm">
        <img
          src={`/icons/SuggestionInfo.svg`}
          alt="SuggestionInfo"
          width={17}
          height={17}
        />
        Suggested fixing are generated by machine learning and algorithms and
        may require human validations.
      </div>
    </div>
  );
};

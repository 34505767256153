import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { store } from "@redux/store";
import "react-datepicker/dist/react-datepicker.css";
import "react-tooltip/dist/react-tooltip.css";
import "./index.css";
import { App } from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CustomErrorBoundary } from "@sharedComponents/errorHandling/CustomErrorBoundary";
import { LoggerProvider } from "@providers/LoggerProvider";
import { customLogger } from "@shared/contexts";
import { CustomLogger } from "retack-sdk-app-observer";


 // Initialize Retack SDK
 const env_key = import.meta.env.VITE_ENV_KEY; // Fetch from env file
 const appVersion = "1.0.4"; // Define your app version or fetch dynamically if needed
 CustomLogger.init(env_key, appVersion); // Initialize Retack SDK

const clientId = import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <LoggerProvider customLogger={customLogger}>
        <CustomErrorBoundary>
          <GoogleOAuthProvider clientId={clientId}>
            <App/>
          </GoogleOAuthProvider>
        </CustomErrorBoundary>
      </LoggerProvider>
    </Provider>

    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      closeButton={false}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </React.StrictMode>
);
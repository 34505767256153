import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { SuccessToast } from "@src/components/sharedComponents/toasts/SuccessToast";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";

const ResetEmailForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignUpData>();

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const signUpRepo = repository.login;

  const onSubmit = async (data: SignUpData) => {
    try {
      const response = await signUpRepo.resetPassword<SignUpData, SignUpData>(
        data
      );
      if (response && typeof response === "object") {
        if ("errors" in response) {
          _.forEach(response.errors, (error) => {
            const validField = error.title as "email";
            setError(validField, { message: error.message });
            if (validField !== "email") {
              ErrorToast({ Message: error.message });
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        }
      } else {
        if (typeof response === "string") {
          SuccessToast({
            Message: "We have sent a link for a password reset to your email.",
          });
        }
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col justify-center items-center w-full"
    >
      <div className="mt-5 flex flex-col w-full md:w-[400px]">
        <label className="text-[#42454A] font-[500]">Email Address</label>
        <input
          className={`${
            errors.email ? "border-[#CC0202]" : "border-[#162C5B]"
          } border  rounded-[6px] md:w-[400px] h-[40px] py-2 px-3 text-gray-700 focus:outline-none mt-[6px]`}
          type="email"
          {...register("email", {
            required: "Email is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Invalid email address",
            },
          })}
          placeholder="example@example.com"
        />
        {errors.email != null && (
          <small className="flex text-[#CC0202] mt-2 gap-2 w-[350px] items-start">
            <img src="/icons/ErrorAlert.svg" alt="error" />
            {errors.email.message}
          </small>
        )}
      </div>
      <button className="border border-[#325EBB] bg-[#325EBB] w-full md:w-[400px] rounded-[6px] font-medium text-[18px] text-[#FFFFFF] py-[6px] mt-[46px]">
        Reset Password
      </button>
    </form>
  );
};

export default ResetEmailForm;

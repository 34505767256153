import { Dispatch } from "react";
import { AnyAction } from "@reduxjs/toolkit";
import { CustomLogger } from "@repositories/CustomLoggerRepository";
import { SourceRepository } from "@repositories/SourceRepository";
import { setSourceData } from "@feature/sourceSlice";
import _ from "lodash";
import { parseRepositoryResponse } from "./parseRepositoryResponse";

export const setSources = async (
  sourceRepo: SourceRepository,
  project_id: string,
  customLogger: CustomLogger,
  showBoundary: (error: unknown) => void,
  dispatch: Dispatch<AnyAction>
): Promise<SourceData[] | undefined> => {
  const repoResponse = await sourceRepo.getSources<SourceData>(project_id);
  const sources = await parseRepositoryResponse(
    repoResponse,
    customLogger,
    showBoundary
  );

  if (!_.isUndefined(sources)) {
    dispatch(setSourceData(sources));
  }
  return sources;
};

import { createSlice } from "@reduxjs/toolkit";

const initialState: ProjectSliceProps = {
  project_id: null,
  projectData: [],
};

export const projectSlice = createSlice({
    name: "projectSlice",
    initialState,
    reducers:{
        setProjectId: (state, action) => {
            state.project_id = action.payload;
          },
        setProjectData: (state, action) => {
            state.projectData = action.payload;
          },
    }
});

export const {
    setProjectId,
    setProjectData,
} = projectSlice.actions;
export default projectSlice.reducer;
import { setOrganizationList } from "@src/redux/feature/organizationSlice";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import InitialPage from "./InitialPage";

const Redirect = () => {
  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const organizationRepo = repository.organization;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const isOrganizationMember = async () => {
      try {
        const OrgResponse = await organizationRepo.isOrganizationMember<OrgResponseData>();
        if (!_.isUndefined(OrgResponse)) {
          if ("errors" in OrgResponse) {
            await customLogger.reportErrorResponse(showBoundary, OrgResponse);
          } else {
            if (OrgResponse.is_organization_member) {
              const getOrganization = async () => {
                try {
                  const OrgResponse = await organizationRepo.getOrganization<Organization[]>();
                  if (!_.isUndefined(OrgResponse)) {
                    if ("errors" in OrgResponse) {
                      await customLogger.reportErrorResponse(showBoundary, OrgResponse);
                    } else {
                      dispatch(setOrganizationList(OrgResponse));
                      navigate(`/organization/${OrgResponse[0].id}`);
                    }
                  }
                } catch (error) {
                  showBoundary(error);
                  await customLogger.reportError(error);
                }
              };
              await getOrganization();
            }
          }
        }
      } catch (error) {
        showBoundary(error);
        await customLogger.reportError(error);
      } finally {
        setIsLoading(false);
      }
    };

    isOrganizationMember();
  }, [customLogger, dispatch, navigate, organizationRepo, showBoundary]);

  return (
    <>
      {isLoading ? (
        <div className="h-screen w-screen flex items-center justify-center">
          <img src="/icons/Loading.svg" alt="loading" />
        </div>
      ) : (
        <InitialPage />
      )}
    </>
  );
};

export default Redirect;

import { useContext } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { parseRepositoryResponse } from "@src/actions/parseRepositoryResponse";

const ArchiveEnvironmentModal = ({
	id,
	is_active,
	environment_name,
	toggleArchive,
	toggleArchiveEnvironmentOpen,
	toggleDropDown,
}: ArchiveEnvironmentModalProps) => {
	const { showBoundary } = useErrorBoundary();
	const customLogger = useContext(LoggerContext);

	const repository = useContext(RepositoryContext);
	const sourceRepo = repository.source;

	const archiveEnvironment = async (id: number, isActive: boolean) => {
		try {
			const environmentArchiveStatus: EnvironmentArchiveStatusProps = {
				is_active: isActive,
			};
			const response = await sourceRepo.archiveEnvironment(
				id,
				environmentArchiveStatus
			);
			await parseRepositoryResponse(
				response,
				customLogger,
				showBoundary
			);
			toggleArchive();
			toggleDropDown();
			toggleArchiveEnvironmentOpen();
		} catch (error) {
			showBoundary(error);
			await customLogger.reportError(error);
		}
	};

	const closeArchiveEnvironmentModal = async () => {
		toggleArchiveEnvironmentOpen();
	};

	return (
		<>
			<div className="fixed inset-0 flex items-center justify-center z-[2] w-[100%] h-[100%] bg-[#040E4140]">
				<div className="w-[349px] md:w-[593px] h-[349px] md:h-[308px] modal-container bg-white rounded-[12px] z-50">
					<div className="modal-content p-[20px] md:p-[32px] flex flex-col items-center gap-[27px] justify-center">
						<div className="w-[54px] h-[54px]">
							<img
								className="w-[100%] h-[100%] object-contain"
								src="/icons/Exclamation.png"
								alt="add"
							/>
						</div>
						<h1 className=" flex text-[20px] font-[700] text-[#242731] text-center">
							Are you sure you want to archive "{environment_name}”?
						</h1>
						<p className="text-[16px] text-[#242731]">
							This environment will be archived immediately.
						</p>
						<div className="flex justify-start gap-[20px] mt-[16px]">
							<button
								onClick={async () => {
									await archiveEnvironment(id, !is_active);
								}}
								className="bg-[#FF6370] w-[102px] h-[38px] text-white text-[14px] rounded-md font-bold"
							>
								Yes, Archive
							</button>

							<button
								onClick={closeArchiveEnvironmentModal}
								className="border border-[#001789] w-[105px] h-[38px] text-[#001789] text-[14px] rounded-md font-medium"
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ArchiveEnvironmentModal;

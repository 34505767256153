import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { LoggerContext, RepositoryContext } from "@shared/contexts";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import _ from "lodash";
import { useErrorBoundary } from "react-error-boundary";
import {
  setIsDeleteSourceModalOpen,
  setIsLoading,
  setIsOverlayVisible,
} from "@src/redux/feature/modalHandler";
import { SuccessToast } from "../../toasts/SuccessToast";
import { ErrorToast } from "../../toasts/ErrorToast";

const DeleteProjectModal = ({
  closeDeleteProjectModal,
}: DeleteProjectModalProps) => {
  const id = useParams();
  const projectId = id.id;
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const ProjectRepo = repository.project;
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const orgId = useSelector(
    (state: RootState) => state.organizationSlice.currentOrganization
  );
  const onSubmit = async () => {
    dispatch(setIsLoading(true));
    try {
      const response = await ProjectRepo.deleteProject(`${projectId}`);
      if (_.isUndefined(response)) {
        closeDeleteProjectModal?.();
        const info: InfoLog = {
          message: "Project deleted.",
        };
        await customLogger.log(info);
        dispatch(setIsDeleteSourceModalOpen(false));
        dispatch(setIsOverlayVisible(false));
        SuccessToast({ Message: "Successfully Deleted!" });
        navigate(`/organization/${orgId}`);
      } else if ("errors" in response) {
        ErrorToast({ Message: "Error occurred while deleting Project!" });
        await customLogger.reportErrorResponse(showBoundary, response);
        closeDeleteProjectModal?.();
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-[2] bg-[#040e4104]">
        <div className="min-w-[349px] md:w-[593px] min-h-[349px] md:h-[308px] modal-container bg-white rounded-[12px] z-50 pt-[38px] px-[50px] pb-[50px]">
          <div className="modal-content flex flex-col items-center gap-[22px]">
            <div className="w-[70px] h-[70px] bg-[#FFE8E8] rounded-full flex justify-center items-center">
              <img
                className="w-[46px] h-[46px] object-contain"
                src="/icons/Exclamation.png"
                alt="add"
              />
            </div>
            <h1 className="text-[20px] font-[700] text-[#242731] w-[80%] text-center">
              Are you sure you want to delete this Project?
            </h1>
            <p className="text-[16px] text-[#242731]">
              This project will be deleted immediately. You cannot undo later.
            </p>
            <div className="flex justify-start gap-[20px] mt-[16px]">
              {isLoading ? (
                <button
                  disabled
                  className="flex items-center gap-2 px-5 bg-[#FF6370] bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
                >
                  <img
                    width={22}
                    height={22}
                    src="/icons/Loading.svg"
                    alt="loading"
                  />
                  Deleting...
                </button>
              ) : (
                <button
                  onClick={onSubmit}
                  className="bg-[#FF6370] w-[124px] h-[40px] text-white text-[14px] rounded-[6px] px-[20px] py-[8px] flex justify-center items-center"
                >
                  <span>Yes, Remove</span>
                </button>
              )}

              <button
                onClick={closeDeleteProjectModal}
                className="text-[#001789] w-[87px] h-[40px] text-[14px] border border-[#001789] rounded-[6px]"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteProjectModal;

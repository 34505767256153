interface PaginationProps {
    totalPages: number | undefined;
    currentPage: number;
    onPageChange: (pageNumber: number) => void;
    hasNextPage: boolean | undefined;
    hasPreviousPage: boolean |undefined;
    onNextPage: () => void;
    onPreviousPage: () => void;
  }
  
  const Pagination = ({
    totalPages,
    currentPage,
    onPageChange,
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
  }: PaginationProps) => {
    const renderPageNumbers = (): JSX.Element[] => {
      const pageNumbers: JSX.Element[] = [];
  
      if (totalPages !== undefined && totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`w-8 md:flex  justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-[4px] ${
                currentPage === i
                  ? "text-[#162C5B] border-2 "
                  : "underline-none text-[#000000]"
              }`}
              onClick={() => onPageChange(i)}
            >
              {i}
            </div>
          );
        }
      } else {
        pageNumbers.push(
          <div
            key={1}
            className={`w-8 md:flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-[4px] ${
              currentPage === 1
                ? "text-[#162C5B] border-2 "
                : "underline-none text-[#000000]"
            }`}
            onClick={() => onPageChange(1)}
          >
            1
          </div>
        );
  
        if (currentPage > 3) {
          pageNumbers.push(
            <div
              key="ellipsisLeft"
              className="w-8 md:flex justify-center items-center cursor-default leading-5 transition duration-150 ease-in rounded-[4px] text-[#000000]"
            >
              . . .
            </div>
          );
        }
  
        let start = Math.max(2, currentPage - 1);
        let end = Math.min(currentPage + 1,totalPages !== undefined ?totalPages - 1 :0);
  
        while (end - start < 2 && (start > 2 || end <  (totalPages !== undefined ? totalPages - 1 : 0))) {
          if (start > 2) {
            start--;
          } else {
            end++;
          }
        }
  
        for (let i = start; i <= end; i++) {
          pageNumbers.push(
            <div
              key={i}
              className={`w-8 md:flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-[4px] ${
                currentPage === i
                  ? "text-[#162C5B] border-2 "
                  : "underline-none text-[#000000]"
              }`}
              onClick={() => onPageChange(i)}
            >
              {i}
            </div>
          );
        }
  
        if (currentPage < (totalPages !== undefined ? totalPages - 2 : 0)) {
          pageNumbers.push(
            <div
              key="ellipsisRight"
              className="w-8 md:flex justify-center items-center cursor-default leading-5 transition duration-150 ease-in rounded-[4px] text-[#000000]"
            >
              . . .
            </div>
          );
        }
  
        pageNumbers.push(
          <div
            key={totalPages}
            className={`w-8 md:flex justify-center items-center cursor-pointer leading-5 transition duration-150 ease-in rounded-[4px] ${
              currentPage === totalPages
                ? "text-[#162C5B] border-2"
                : "underline-none text-[#000000]"
            }`}
            onClick={() => totalPages !== undefined && onPageChange(totalPages)}          >
            {totalPages}
          </div>
        );
      }
  
      return pageNumbers;
    };
  
    return (
      <div className="flex flex-col items-center">
        <div className="flex text-gray-700">
          <div
            className={`h-8 w-8 mr-1 flex justify-center items-center rounded-[4px] cursor-pointer ${
              !hasPreviousPage && "opacity-50 pointer-events-none"
            }`}
            onClick={onPreviousPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#162C5B"
              strokeWidth={3}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left w-4 h-4"
            >
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </div>
          <div className="flex h-8 font-medium gap-[8px]">
            {renderPageNumbers()}
          </div>
          <div
            className={`h-8 w-8 ml-1 flex justify-center items-center rounded-[4px] cursor-pointer ${
              !hasNextPage && "opacity-50 pointer-events-none"
            }`}
            onClick={onNextPage}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#162C5B"
              strokeWidth={3}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right w-4 h-4"
            >
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  
  export default Pagination;

import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "@feature/appSlice";

export const ProjectDemoCard = () => {
  const dispatch = useDispatch();

  const openDemoProject = () => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 1000);
  };

  return (
    <NavLink
      className="w-full md:w-[240px] h-[160px] border border-border_gray bg-white rounded-lg hover:shadow-light z-[1]"
      to={`/project/1/demo`}
      onClick={openDemoProject}
    >
      <div className="flex justify-between p-3">
        <div>
          <h1 className="text-sm text-text_blue font-[500]">Demo Project</h1>
          <p className="text-text_dark_gray text-xs font-[400]">demo-project</p>
        </div>
        <div>
          <button className="w-[65px] h-[25px] flex items-center justify-center rounded-full bg-bg_demo">
            <p className="text-text_blue text-xs font-[700]">Demo</p>
          </button>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <img
          className=" w-[200px]"
          src="/icons/DemoGraph.svg"
          alt="DemoGraphIcon"
        />
      </div>
    </NavLink>
  );
};

import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

/**
 * CheckboxesOrToggler component renders either a Checkboxes component or a Toggler component
 * based on the "isCheckbox" property of CheckboxesOrTogglerProps.
 *
 * Steps in this component:
 * 1. Define component props including title, defaultSelection, options, isCheckbox, and onSelect.
 * 2. Initialize state variables for selectedItems and showOptions.
 * 3. Use a mutable ref object to track clicks outside the component to hide it.
 *    a. Add event listener for mousedown to check if the click is outside the component.
 *    b. If outside click is detected, hide the options.
 * 4. Define handleSelect function to handle selection logic and call onSelect action.
 * 5. Determine arrow direction style based on showOptions state.
 * 6. Render the component structure with title, selected items, and arrow icon.
 * 7. Add click event to toggle the display of options on clicking the component.
 * 8. Render the selected items and arrow icon within the clickable container.
 * 9. Create an overlay for options, which is visible based on the showOptions state.
 * 10. Map over options and render each option with appropriate styles and click handling.
 */

export const SourceEnvironmentCheckbox = (
  props: SourceEnvironmentCheckboxProps
) => {
  const params = useParams();
  const projectId = params.id;

  // Step 1
  const { defaultSelection, options, isCheckbox, onSelect } = props;

  // Step 2
  const [selectedItems, setSelectedItems] = useState<Origin[]>([]);

  const [showOptions, setShowOptions] = useState(false);

  // Step 3
  const selectorRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Step 3a
    const handleOutsideClick = (event: MouseEvent) => {
      const clickedInside =
        selectorRef && selectorRef.current!.contains(event.target as Node);

      // Step 3b
      if (clickedInside) {
        return;
      } else {
        setShowOptions(false);
      }
    };
    window.addEventListener("mousedown", handleOutsideClick);
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setSelectedItems([]);
  }, [projectId]);

  // Step 4
  const handleSelect = (option: Origin) => {
    const isOptionSelected = _.some(
      selectedItems,
      (item) =>
        item.source_id === option.source_id &&
        item.environment_id === option.environment_id
    );

    if (!isOptionSelected) {
      setSelectedItems([...selectedItems, option]);
    } else {
      const differenceItems = _.filter(
        selectedItems,
        (item) =>
          item.source_id !== option.source_id ||
          item.environment_id !== option.environment_id
      );
      setSelectedItems(differenceItems);
    }

    // Step 4b
    onSelect([option.source_id, option.environment_id]);
  };

  // Step 5
  const arrowDirectionStyle = !showOptions ? "" : "rotate-180";

  return (
    // Step 6
    <div ref={selectorRef} className="flex justify-center items-center w-[35%]">
      <div className="text-[#4F5A69] mr-2 font-medium text-sm  whitespace-nowrap">
        {/* Step 6a */}
        Sources :
      </div>
      <div className="relative w-full ">
        <div
          className="flex w-[100%] justify-between h-[35px] items-center cursor-pointer bg-white px-3  rounded-[8px] border border-[#DDE0EF]"
          // Step 7
          onClick={() => setShowOptions(!showOptions)}
        >
          <div className="text-[#172B4D] text-[14px] leading-[18px] max-w-96 truncate ">
            {/* Step 8 */}
            {selectedItems.length === 0
              ? defaultSelection
              : _.join(
                  _.map(
                    selectedItems,
                    (item) => `${item.source} / ${item.environment}`
                  ),
                  ", "
                )}
          </div>

          <div className="flex justify-center items-center bg-gray rounded-full w-[20px] h-[20px]">
            <img
              className={`w-[18px] ${arrowDirectionStyle}`}
              src={`/icons/CustomSelectionArrow.svg`}
              alt="View Environment"
            />
          </div>
        </div>
        <div className="h-1"></div>

        {/* Overlay Selection */}
        {/* Step 9 */}
        <div
          className={`${
            showOptions ? "block" : "hidden"
          } absolute z-10 flex-col w-full  max-h-56 left-0 shadow-xl bg-white rounded-md  overflow-y-auto`}
        >
          {_.map(options, (option, index) => {
            const isIncluded = _.some(
              selectedItems,
              (item) =>
                item.source_id === option.source_id &&
                item.environment_id === option.environment_id
            );
            const checkboxStyle = isIncluded
              ? "flex justify-center items-center border-nav_blue"
              : "border-[#D0D5DD]";

            const selectedItemStyle =
              !isCheckbox && isIncluded && "bg-[#E3E9F480]";

            // Step 10
            return (
              <div
                key={index}
                onClick={() => handleSelect(option)}
                className={`flex items-center h-[50px] text-[14px] rounded-md px-3 ${selectedItemStyle}`}
              >
                {isCheckbox && (
                  <div
                    className={`w-[16px] h-[16px] mr-[10px] cursor-pointer rounded-[4px] border ${checkboxStyle}`}
                  >
                    {isIncluded && (
                      <img
                        className="w-[10px] h-[10px]"
                        src="/icons/BlueTick.svg"
                      />
                    )}
                  </div>
                )}
                <div className=" text-[#333333] text-[14px] cursor-pointer">
                  {option.source} / {option.environment}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

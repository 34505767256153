import { RouterProvider } from "react-router-dom";
import Router from "@routes/Router";
import { AppObserverProvider } from "@providers/AppObserverProvider";
import {
  RepositoryProvider,
  RepositoryProviderProps,
} from "@providers/RepositoryProvider";
import {
  loginRepository,
  organizationRepository,
  memberInvitationRepository,
  profileRepository,
  projectRepository,
  app_observer,
  sourceRepository,
  issuesRepository,
  memberRepository,
  integrationRepository,
  gitHubLabRepository,
  subscriptionRepository,
} from "@shared/contexts";
import ReactGA from "react-ga4";

export const App = () => {
  ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  
  const providerProps: RepositoryProviderProps = {
    login: loginRepository,
    organization: organizationRepository,
    memberInvitation: memberInvitationRepository,
    profile: profileRepository,
    project: projectRepository,
    source: sourceRepository,
    issues: issuesRepository,
    member: memberRepository,
    integration: integrationRepository,
    gitHubLab: gitHubLabRepository,
    subscription: subscriptionRepository,
  };
  return (
    <RepositoryProvider {...providerProps}>
      <AppObserverProvider app_observer={app_observer}>
        <RouterProvider router={Router} />
      </AppObserverProvider>
    </RepositoryProvider>
  );
};

import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import { ILoginRepository } from "./types";
import { parseApiResponse } from "@actions/parseApiResponse";

export class LoginRepository implements ILoginRepository {
  private _api: IJsonAPI;

  private LOGIN_URL = RepositoryURL.LOGIN_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async requestLogin<TokenResponse, TokenRequest>(token_request: TokenRequest) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const response = await this._api.post<TokenResponse, TokenRequest>(
      `${this.LOGIN_URL}login/google/`,
      token_request,
      headers,
      false
    );

    return parseApiResponse<TokenResponse>(response);
  }

  async emailLogin<emailLoginResponse, SignInData>(data: SignInData) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<emailLoginResponse, SignInData>(
      `${this.LOGIN_URL}login/email/`,
      data,
      headers,
      false
    );

    return parseApiResponse<emailLoginResponse>(response);
  }

  async signUp<SignUpResponse, SignUpData>(
    data: SignUpData
  ): Promise<SignUpResponse | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<SignUpResponse, SignUpData>(
      `${this.LOGIN_URL}signup/email/`,
      data,
      headers
    );
    return parseApiResponse<SignUpResponse>(response);
  }

  async resetPassword<SignUpResponse, SignUpData>(data: SignUpData) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<SignUpResponse, SignUpData>(
      `${this.LOGIN_URL}reset/`,
      data,
      headers
    );
    return parseApiResponse<SignUpResponse>(response);
  }


  async resendEmail<Response, VerificationData>(
    data: VerificationData
  ) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.put<Response, VerificationData>(
      `${this.LOGIN_URL}reset/`,
      data,
      headers
    );
    return parseApiResponse(response);
  }

  async resendVerificationEmail<Response>(uid: string) {
    const response = await this._api.get<Response>(`${this.LOGIN_URL}reset/?uid=${uid}`);
    return parseApiResponse(response);
  }

  async resetForgotPassword<Response, VerificationData>(
    data: VerificationData
  ) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.put<Response, VerificationData>(
      `${this.LOGIN_URL}verify/user/`,
      data,
      headers
    );

    return parseApiResponse<Response>(response);
  }

  async Verify<Response, VerificationData>(data: VerificationData) {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const response = await this._api.post<Response, VerificationData>(
      `${this.LOGIN_URL}verify/user/`,
      data,
      headers
    );

    return parseApiResponse<Response>(response);
  }
}

export const DemoDateRangePicker = () => {
  return (
    <div className="flex items-center gap-2 w-full">
      <div className="text-[#4F5A69] mr-2 font-medium text-sm leading-[18px] whitespace-nowrap">
        Date :{" "}
      </div>
      <div className="relative w-full  h-[35px] bg-white rounded-[8px]  px-3 border border-[#DDE0EF] cursor-not-allowed">
        <div
          data-testid="DateRangePickerContainer"
          className="flex justify-between items-center w-full h-full"
        >
          <div className="flex md:w-[90%] h-full lg:min-w-max items-center text-solid_gray">
            <img
              src="/icons/Calendar.svg"
              alt="Calendar Icon"
              className="w-[16px] mr-2"
            />
            <div className="truncate mr-4 text-[14px] font-medium text-[#292C31]">
              21 May 12:36 PM to 28 May 12:36 PM
            </div>
          </div>

          <div className="flex justify-center items-center bg-[#E6E8F3] rounded-full w-[18px] h-[18px]">
            <img
              className="w-[15px]"
              src={`/icons/DownArrow.svg`}
              alt="View Datepicker"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoDateRangePicker;

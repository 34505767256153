import { createSlice } from "@reduxjs/toolkit";

const initialState: ProfileResponse = {
  user: undefined,
};
export const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUserData } = profileSlice.actions;
export default profileSlice.reducer;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import datepickerSlice from "@datepicker/datepickerSlice";
import storage from "redux-persist/lib/storage";
import authSlice from "@feature/authSlice";
import appSlice from "@feature/appSlice";
import modalHandlerReducer from "@feature/modalHandler";
import profileSlice from "@feature/profileSlice";
import issuesSlice from "@feature/issuesSlice";
import issueDetailsSlice from "@feature/issueDetailsSlice";
import projectSlice from "./feature/projectSlice";
import sourceSlice from "./feature/sourceSlice";
import errorSlice from "./feature/errorSlice";
import {
	persistStore,
	persistReducer,
	REHYDRATE,
	PERSIST,
	REGISTER
} from "redux-persist";
import memberSlice from "./feature/memberSlice";
import environmentSlice from "./feature/environmentSlice";
import issueFixSlice from "./feature/fixIssueSlice";
import organizationSlice from "./feature/organizationSlice";

const persistConfig = {
	key: "root",
	storage
};
const persistedReducer = persistReducer(
	persistConfig,
	combineReducers({
		auth: authSlice
	})
);
export const store = configureStore({
	reducer: {
		persistedReducer,
		appSlice: appSlice,
		organizationSlice:organizationSlice,
		modalHandler: modalHandlerReducer,
		datepicker: datepickerSlice,
		profileSlice: profileSlice,
		issuesSlice: issuesSlice,
		issueDetailsSlice: issueDetailsSlice,
		projectSlice: projectSlice,
		sourceSlice: sourceSlice,
		memberSlice: memberSlice,
		errorSlice: errorSlice,
		environmentSlice: environmentSlice,
		issueFixSlice :issueFixSlice,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [REHYDRATE, PERSIST, REGISTER]
			}
		}),
	devTools: process.env.NODE_ENV !== "production"
});
export const persister = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { parseApiResponse } from "@actions/parseApiResponse";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { ISourceRepository } from "./types";
import { RepositoryURL } from "./constants.enum";

export class SourceRepository implements ISourceRepository {
  private _api: IJsonAPI;

  private ORGANIZATION_PROJECT_URL  = RepositoryURL.ORGANIZATION_PROJECT_URL
  private ORGANIZATION_URL  = RepositoryURL.ORGANIZATION_URL

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async getSources<SourceData>(
    projectId: string
  ): Promise<SourceData[] | JsonAPIErrorResp | undefined> {
    const sources_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/sources/`;
    const sources = await this._api.get<SourceData[]>(sources_url);

    return parseApiResponse<SourceData[]>(sources);
  }
  async createSource<SourceData, CreateSourceData>(
    data: CreateSourceData,
    projectId: string
  ): Promise<SourceData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const sources_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/sources/`;

    const response = await this._api.post<SourceData, CreateSourceData>(
      sources_url,
      data,
      headers
    );
    return parseApiResponse<SourceData>(response);
  }
  async editSource<EditSourceData>(
    projectId: string | undefined,
    sourceId: number | undefined,
    data: EditSourceData
  ) {
    const sources_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/sources/${sourceId}`;

    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const response = await this._api.put<EditSourceData, EditSourceData>(
      sources_url,
      data,
      headers
    );
    return parseApiResponse<EditSourceData>(response);
  }
  async deleteSource(projectId: string, sourceId: number) {
    const sources_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/sources/${sourceId}`;
    return await this._api.delete(sources_url);
  }

  async getEnvironments<SourceData>(
    projectId: string
  ): Promise<SourceData[] | JsonAPIErrorResp | undefined> {
    const sources_url = `${this.ORGANIZATION_URL}${projectId}/sources/`;
    const sources = await this._api.get<SourceData[]>(sources_url);
    return parseApiResponse<SourceData[]>(sources);
  }

  async postEnvironment<EnvironmentData>(
    environmentName: string,
    sourceId: string
  ): Promise<EnvironmentData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const data = { name: environmentName };
    const environment_url = `${this.ORGANIZATION_URL}sources/${sourceId}/environments/`;
    const response = await this._api.post<EnvironmentData, { name: string }>(
      environment_url,
      data,
      headers
    );
    return parseApiResponse<EnvironmentData>(response);
  }

  async editEnvironment<EnvironmentData>(
    id: string,
    environmentName: string
  ): Promise<EnvironmentData | JsonAPIErrorResp | undefined> {
    const environment_url = `${this.ORGANIZATION_URL}environments/${id}`;
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");
    const data = { name: environmentName };
    const environments = await this._api.put<EnvironmentData, { name: string }>(
      environment_url,
      data,
      headers
    );
    return parseApiResponse<EnvironmentData>(environments);
  }

  async archiveEnvironment<EnvironmentData>(
    id: number,
    data: EnvironmentArchiveStatusProps
  ): Promise<EnvironmentData | JsonAPIErrorResp | undefined> {
    const environment_url = `${this.ORGANIZATION_URL}environments/${id}`;
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const environments = await this._api.put<
      EnvironmentData,
      EnvironmentArchiveStatusProps
    >(environment_url, data, headers);
    return parseApiResponse<EnvironmentData>(environments);
  }

  async generateEnvironmentKey<EnvironmentKeyData>(
    environmentId: number
  ): Promise<EnvironmentKeyData | JsonAPIErrorResp | undefined> {
    const environment_url = `${this.ORGANIZATION_URL}environments/${environmentId}/environment_keys/`;
    const environments = await this._api.post<EnvironmentKeyData, undefined>(
      environment_url
    );
    return parseApiResponse<EnvironmentKeyData>(environments);
  }

  async toggleEnvironmentKey<EnvironmentKeyData>(
    keyid: number,
    data: EnvironmentKeyArchiveStatusProps
  ): Promise<EnvironmentKeyData | JsonAPIErrorResp | undefined> {
    const environment_url = `${this.ORGANIZATION_URL}environment_keys/${keyid}`;
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const environment_key = await this._api.put<
      EnvironmentKeyData,
      EnvironmentKeyArchiveStatusProps
    >(environment_url, data, headers);
    return parseApiResponse<EnvironmentKeyData>(environment_key);
  }

  async deleteEnvironmentKey(keyid: number) {
    const environment_url = `${this.ORGANIZATION_URL}environment_keys/${keyid}`;
    return await this._api.delete(environment_url);
  }

  async getDemoSources<SourceData>(): Promise<
    SourceData[] | JsonAPIErrorResp | undefined
  > {
    const sources_url = `demo/sources/`;
    const sources = await this._api.get<SourceData[]>(sources_url);
    return parseApiResponse<SourceData[]>(sources);
  }
}

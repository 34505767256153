import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@redux/store";
import { setSourceId } from "@feature/sourceSlice";
import {
  setIsDeleteSourceModalOpen,
  setIsEditSourceModalOpen,
  setIsEnvironmentDotsButtonOpen,
  setIsOverlayVisible,
  setIsSourceDotsButtonOpen,
} from "@src/redux/feature/modalHandler";
import _ from "lodash";

const DotsButton = ({ sourceId }: DotsButtonProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const modalHandler = useSelector((state: RootState) => state.modalHandler);
  const sourceSlice = useSelector((state: RootState) => state.sourceSlice);

  const findSource = () => {
    let foundSource = _.find(
      sourceSlice.sourceData,
      (source) => source.id === sourceId
    );

    if (!foundSource) {
      foundSource = _.find(
        sourceSlice.archivedSourceData,
        (source) => source.id === sourceId
      );
    }

    return foundSource;
  };

  const source = findSource();

  const toggleDropdown = () => {
    dispatch(setSourceId(sourceId));
    dispatch(
      setIsSourceDotsButtonOpen(
        sourceSlice.source_id == sourceId
          ? !modalHandler.isSourceDotsButtonOpen
          : true
      )
    );
    dispatch(setIsEnvironmentDotsButtonOpen(false));
  };

  const openEditSourceModal = () => {
    dispatch(setIsEditSourceModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setIsSourceDotsButtonOpen(!modalHandler.isSourceDotsButtonOpen));
  };

  const openDeleteApiModal = () => {
    dispatch(setIsDeleteSourceModalOpen(true));
    dispatch(setIsOverlayVisible(true));
    dispatch(setIsSourceDotsButtonOpen(!modalHandler.isSourceDotsButtonOpen));
  };

  useEffect(() => {
    document.body.style.overflow = modalHandler.isEditSourceModalOpen
      ? "hidden"
      : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalHandler.isEditSourceModalOpen]);

  return (
    <div className="relative z-10">
      <div>
        <button
          className="w-[20px] h-[5px]"
          id="menu-button"
          onClick={(event) => {
            toggleDropdown();
            event.stopPropagation();
          }}
        >
          <img
            className="w-[3.75px] h-[17px] object-contain"
            src="/icons/Dots.svg"
            alt="dots"
          />
        </button>
        {modalHandler.isSourceDotsButtonOpen &&
          sourceSlice.source_id == sourceId && (
            <>
              <div
                className={`absolute right-6 top-[-1.5rem] px-[16px] py-[14px] w-[152px]  rounded-md bg-white shadow-light`}
              >
                {source?.is_archived ? (
                  ""
                ) : (
                  <>
                    <button
                      onClick={openEditSourceModal}
                      className="text-[#454545] w-[100%] text-[14px] flex gap-[5px] cursor-pointer"
                    >
                      <div className="w-[18px] h-[18px]">
                        <img
                          className="w-[100%] h-[100%] object-contain"
                          src="/icons/Edit.svg"
                          alt="dots"
                        />
                      </div>
                      Edit
                    </button>
                    <hr className="text-[#E5E7F2] my-[15px]" />
                  </>
                )}

                <button
                  onClick={openDeleteApiModal}
                  className="text-[#454545] w-[100%] text-[14px] flex gap-[5px] cursor-pointer"
                >
                  <div className="w-[18px] h-[18px]">
                    <img
                      className="w-[100%] h-[100%] object-contain"
                      src="/icons/Archive.svg"
                      alt="dots"
                    />
                  </div>
                  {source?.is_archived ? "Unarchive" : "Archive"}
                </button>
              </div>
            </>
          )}
      </div>
    </div>
  );
};
export default DotsButton;

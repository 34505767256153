import _ from "lodash";
import { JsonAPIErrorResp, JsonAPIError } from "@clients/api/types";
import { getErrorLog } from "./getErrorLog";
import {
  BackendError,
  AuthenticationError,
  ValidationError,
} from "@repositories/CustomErrors";

// Logs errors received from the backend
export const getErrorLogsFromResponse = (
  showBoundary: (error: Error) => void,
  response: JsonAPIErrorResp
): ErrorLog[] => {
  return _.map(response.errors, (error: JsonAPIError) => {
    let customError: Error;
    switch (error.status) {
      case "400":
      case "404":
      case "500": {
        customError = new BackendError(error);
        if (error.status === "500" || error.status === "404") {
          showBoundary(customError);
          return getErrorLog(customError);
        }
        break;
      }
      case "401":
      case "403": {
        customError = new AuthenticationError(error);
        break;
      }
      case "422": {
        customError = new ValidationError(error);
        break;
      }
      default: {
        customError = new Error(error.message);
      }
    }
    return getErrorLog(customError);
  });
};

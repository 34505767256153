import { useContext, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useForm } from "react-hook-form";
import { SuccessToast } from "../../toasts/SuccessToast";
import _ from "lodash";
import InfoTooltip from "../../buttons/InfoTooltip";
import { LoggerContext, RepositoryContext } from "@src/shared/contexts";
import { useParams } from "react-router-dom";
import { ErrorToast } from "../../toasts/ErrorToast";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@src/redux/store";
import { setIsLoading } from "@src/redux/feature/modalHandler";

const SlackIntegrationModal = ({
  closeIntegrationModal,
  integrationName,
}: IntegrationModalProps) => {
  const id = useParams();
  const projectid = id.id;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<AddSlackIntegrationFieldData>({});

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);
  const repository = useContext(RepositoryContext);
  const integrationRepo = repository.integration;
  const [url, setUrl] = useState("");
  const [loadingText, setLoadingText] = useState("");

  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );
  const dispatch = useDispatch();

  const divRefs = {
    divRef1: useRef<HTMLDivElement>(null),
    divRef2: useRef<HTMLDivElement>(null),
    divRef3: useRef<HTMLDivElement>(null),
  };
  const integrationNameLowerCase = integrationName
    ? integrationName.toLowerCase()
    : null;

  const handleTest = () => {
    const saveUrl = `${integrationNameLowerCase}/credential/test/`;
    setUrl(saveUrl);
    setLoadingText("Sending...");
    handleSubmit((data) => onSubmit(data, saveUrl))();
  };

  const handleSave = () => {
    const saveUrl = `${integrationNameLowerCase}/credential/`;
    setUrl(saveUrl);
    setLoadingText("Saving...");
    handleSubmit((data) => onSubmit(data, saveUrl))();
  };

  const onSubmit = async (data: AddSlackIntegrationFieldData, url: string) => {
    dispatch(setIsLoading(true));
    try {
      if (!data.icon_url) {
        delete data.icon_url;
      }
      const response = await integrationRepo.integrate<
        AddSlackIntegrationFieldData,
        AddSlackIntegrationFieldData
      >(`${projectid}`, url, data);
      if (!_.isUndefined(response)) {
        if ("errors" in response) {
          _.map(response.errors, (error) => {
            const validField = error.title as
              | "channel_id"
              | "oauth_token"
              | "icon_url";
            setError(validField, { message: error.message });
            if (
              validField !== "channel_id" &&
              validField !== "oauth_token" &&
              validField !== "icon_url"
            ) {
              ErrorToast({ Message: error.message });
              closeIntegrationModal?.();
            }
          });
          await customLogger.reportErrorResponse(showBoundary, response);
        } else {
          if (url.includes("test")) {
            SuccessToast({
              Message: `Success message sent`,
            });
          } else {
            SuccessToast({
              Message: `Success ${integrationName} has been Integrated`,
            });
            closeIntegrationModal?.();
          }
          const info: InfoLog = {
            message: `${integrationName} has been Integrated`,
          };
          await customLogger.log(info);
        }
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      showBoundary(error);
      await customLogger.reportError(error);
    }
  };

  return (
    <div className="bg-white rounded-md z-[2] p-[20px] md:px-[32px] w-[354px] md:w-[650px] 2xl:w-[850px]">
      <form onSubmit={handleSubmit((data) => onSubmit(data, url))}>
        <div className="flex justify-between">
          <h2 className="text-[28px] font-[700] text-text_black flex gap-[20px]">
            <img width={22} height={22} src="/icons/Slack.svg" alt="cross" />
            Slack
          </h2>
          <button type="button" onClick={closeIntegrationModal}>
            <img width={22} height={22} src="/icons/Cross2.svg" alt="cross" />
          </button>
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="channel_id"
          >
            Channel ID
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef1}
              messages={["Slack channel to post message"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("channel_id", {
              required: "Channel ID Required",
            })}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.channel_id && (
            <small className="block text-red mt-2">
              {errors.channel_id.message}
            </small>
          )}
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="Oauth_token"
          >
            Oauth Token
            <span className="text-red text-sm ml-1">*</span>
            <InfoTooltip
              divRef={divRefs.divRef2}
              messages={[
                "Slack access token link to view slack documentation to generate oauth token",
              ]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("oauth_token", {
              required: "Oauth_token Required",
            })}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.oauth_token && (
            <small className="block text-red mt-2">
              {errors.oauth_token.message}
            </small>
          )}
        </div>
        <div className="mt-2">
          <label
            className="text-text_gray text-sm font-[500] flex items-center"
            htmlFor="Icon Url"
          >
            Icon Url
            <InfoTooltip
              divRef={divRefs.divRef3}
              messages={["Url for icon image different messages"]}
              bgColor="bg-[grey]"
              textColor="text-[white]"
            />
          </label>
          <input
            {...register("icon_url", {})}
            className="border border-border_light_gray rounded-[10px] w-full h-[48px]  py-2 px-3 text-gray-700 focus:outline-none mt-[10px]"
            type="text"
          />
          {errors.icon_url && (
            <small className="block text-red mt-2">
              {errors.icon_url.message}
            </small>
          )}
        </div>

        {isLoading ? (
          <button
            disabled
            className="mt-[16px] flex items-center gap-2 px-5 bg-button_blue bg-opacity-[0.70] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md"
          >
            <img
              width={22}
              height={22}
              src="/icons/Loading.svg"
              alt="loading"
            />
            {loadingText}
          </button>
        ) : (
          <div className="flex gap-[20px]">
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={handleSave}
                type="button"
                className={`bg-[#162C5B] w-[160px] h-[40px] text-white text-[14px] font-[700] rounded-md ${
                  isValid
                    ? "hover:bg-blue-600"
                    : "cursor-not-allowed opacity-50"
                }`}
                disabled={!isValid}
              >
                Save
              </button>
            </div>
            <div className="flex justify-start gap-[20px] mt-[16px]">
              <button
                onClick={handleTest}
                type="button"
                className={`bg-[white] w-[160px] h-[40px] text-[#162C5B] text-[14px] font-[700] rounded-md ${
                  isValid ? "bg-blue-600" : "cursor-not-allowed opacity-50"
                }`}
                disabled={!isValid}
              >
                Send Test Event
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default SlackIntegrationModal;

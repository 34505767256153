import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { AppCard } from "@sharedComponents/card/AppCard";
import _ from "lodash";
import { RepositoryContext } from "@shared/contexts";
import { LoggerContext } from "@shared/contexts";
import { useErrorBoundary } from "react-error-boundary";
import { CreateNewProjectCard } from "@sharedComponents/card/CreateNewProjectCard";
import SkeletonCardLoader from "@sharedComponents/loader/SkeletonCardLoader";
import { setProjectData } from "@feature/projectSlice";
import {
  setIsOverlayVisible,
  setIsCreateProjectModalOpen,
} from "@src/redux/feature/modalHandler";
import CreateProjectModal from "@src/components/sharedComponents/modal/projectModal/CreateProjectModal";
import { ProjectDemoCard } from "@src/components/sharedComponents/card/ProjectDemoCard";
import { useParams } from "react-router-dom";

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const ParamsOrgId = params.orgId;
  const [localOrgId, setOrgId] = useState(() => {
    const storedOrgId = localStorage.getItem("orgId");
    return storedOrgId ? parseInt(storedOrgId) : 0;
  });
  const dispatch = useDispatch();

  const projectSlice = useSelector((state: RootState) => state.projectSlice);
  const SliceOrgId = useSelector(
    (state: RootState) => state.organizationSlice.currentOrganization
  );

  useEffect(() => {
    if (SliceOrgId !== 0 && localOrgId !== parseInt(ParamsOrgId!)) {
      localStorage.setItem("orgId", SliceOrgId.toString());
      setOrgId(SliceOrgId);
    }
  }, [SliceOrgId, localOrgId]);

  const isCreateProjectModalOpen = useSelector(
    (state: RootState) => state.modalHandler.isCreateProjectModalOpen
  );

  const openCreateProjectModal = () => {
    dispatch(setIsCreateProjectModalOpen(true));
    dispatch(setIsOverlayVisible(true));
  };

  const closeCreateProjectModal = () => {
    dispatch(setIsCreateProjectModalOpen(false));
    dispatch(setIsOverlayVisible(false));
  };

  useEffect(() => {
    if (isCreateProjectModalOpen) {
      document.body.style.overflow = "hidden";
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isCreateProjectModalOpen]);

  const { showBoundary } = useErrorBoundary();
  const customLogger = useContext(LoggerContext);

  const repository = useContext(RepositoryContext);
  const projectRepo = repository.project;

  useEffect(() => {
    if (localOrgId !== 0) {
      const fetchData = async () => {
        setLoading(true);
        const response = await projectRepo.getProject<ProjectData>(
          parseInt(ParamsOrgId!)
        );
        if (response) {
          if ("errors" in response) {
            await customLogger.reportErrorResponse(showBoundary, response);
          } else {
            dispatch(setProjectData(response));
          }
        }
      };
      fetchData()
        .catch(async (error) => {
          showBoundary(error);
          await customLogger.reportError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    customLogger,
    dispatch,
    projectRepo,
    showBoundary,
    localOrgId,
    SliceOrgId,
    ParamsOrgId,
  ]);

  const renderSkeletons = () => {
    return _.map(projectSlice.projectData, (project) => {
      return <SkeletonCardLoader key={project.id} />;
    });
  };

  const renderProjectCards = () => {
    return _.map(projectSlice.projectData, (project) => {
      const createdAtDate = new Date(project.updated_at);
      return (
        <AppCard
          key={project.id}
          projectId={project.id}
          appName={project.title}
          appInformation={project.description}
          editedTime={createdAtDate.toDateString()}
        />
      );
    });
  };

  return (
    <div className="p-[25px] md:p-[36px] flex flex-col h-[91vh] w-full bg-[#FFFFFF]">
      <h1 className="text-[30px] font-bold tracking-[0.5px] text-text_dark_blue mb-4 z-[1]">
        All Projects
      </h1>
      <div className="flex flex-wrap justify-start gap-7 md:gap-10">
        <CreateNewProjectCard openCreateProjectModal={openCreateProjectModal} />
        <ProjectDemoCard />
        {isCreateProjectModalOpen && (
          <CreateProjectModal
            closeCreateProjectModal={closeCreateProjectModal}
            onProjectCreated={(newProject: ProjectData) => {
              if (
                !_.isUndefined(projectSlice.projectData) &&
                projectSlice.projectData.length > 0
              ) {
                dispatch(
                  setProjectData([...projectSlice.projectData, newProject])
                );
              } else {
                dispatch(setProjectData([newProject]));
              }
            }}
          />
        )}
        {loading ? renderSkeletons() : renderProjectCards()}
      </div>
    </div>
  );
};

export default Projects;

import { useGoogleLogin } from "@react-oauth/google";
import { IStorageClient } from "@src/clients/storage/types";
import { ErrorToast } from "@src/components/sharedComponents/toasts/ErrorToast";
import { setAuthTokens } from "@src/redux/feature/authSlice";
import { setIsLoading } from "@src/redux/feature/modalHandler";
import { RootState } from "@src/redux/store";
import { ErrorTitles } from "@src/repositories/constants.enum";
import {
  RepositoryContext,
  LoggerContext,
  getLocalStorageClient,
} from "@src/shared/contexts";
import _ from "lodash";
import { useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const GoogleLoginButton = () => {
  const repository = useContext(RepositoryContext);
  const loginRepo = repository.login;
  const customLogger = useContext(LoggerContext);
  const isLoading = useSelector(
    (state: RootState) => state.modalHandler.isLoading
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { showBoundary } = useErrorBoundary();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      dispatch(setIsLoading(true));
      const googleToken = tokenResponse.access_token;
      const localStorageClient: IStorageClient = getLocalStorageClient();

      const attemptLogin = async () => {
        const token_request: TokenRequest = {
          access_token: googleToken,
        };
        const googleResponse = await loginRepo.requestLogin<
          TokenResponse,
          TokenRequest
        >(token_request);
        if (!_.isUndefined(googleResponse)) {
          if ("errors" in googleResponse) {
            _.map(googleResponse.errors, (error) => {
              ErrorToast({ Message: error.message });
            });
          } else {
            localStorageClient.setAccessToken(googleResponse.access_token);
            dispatch(setAuthTokens(googleResponse.access_token));
            localStorageClient.setRefreshToken(googleResponse.refresh_token);
            navigate("/")
          }
          dispatch(setIsLoading(false));
        }
      };
      attemptLogin()
        .catch(async (error) => {
          showBoundary(error);
          await customLogger.reportError(error);
        })
        .finally(() => {
          dispatch(setIsLoading(false));
        });
    },
    onError: async (errorResponse) => {
      const error: ErrorLog = {
        title: ErrorTitles.UnknownError,
        status: errorResponse.error,
        stackTrace: errorResponse.error_uri,
      };
      await customLogger.reportError(error);

      ErrorToast({ Message: "Google authentication failed!" });
    },
  });

  return (
    <div className="flex justify-center mt-[20px]">
      <button
        disabled={isLoading}
        onClick={() => googleLogin()}
        className="w-[292px] md:w-[400px] h-[54px] md:h-[41px] flex  justify-center items-center gap-2  border border-[#162C5B] rounded-[6px]"
      >
        {isLoading ? (
          <img src="/icons/Loading.svg" alt="loading" />
        ) : (
          <img src="/icons/Google.svg" alt="Google Logo" />
        )}
        <h3 className="font-[500] text-[14px] md:text-[16px] text-[#162C5B]">
          Continue with Google
        </h3>
      </button>
    </div>
  );
};

export default GoogleLoginButton;

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { format } from "date-fns";
import _ from "lodash";

const emptyDateTimeStates: DateTimeStates = {
  isAM: true,
  hour: "",
  minute: "",
  selectedDate: "",
  formattedDate: "",
  normalDate: new Date(),
};

const initialDateRange: DateRangePickerStates = {
  startDateStates: emptyDateTimeStates,
  endDateStates: emptyDateTimeStates,
  combinedDateRange: "",
  showDateRangePicker: false,
  rangePickerViewIcon: "DownArrow",
};

const initialState: ErrorSliceProps = {
  issue_id: 0,
  dateRangeStates: initialDateRange,
  sources: [],
  environments: [],
  error_list: [],
  request_params: {
    q: "",
    s: [],
    e: [],
    sort_by: "",
    timeRange: {
      from: "",
      to: "",
    },
    page: 1,
  },
  meta_data: {
    total_pages: 0,
    total_items: 0,
    current_page: 0,
    has_next_page: false,
    has_previous_page: false,
  },
  isResponseLoading: false,
};

export const errorSlice = createSlice({
  name: "errorSlice",
  initialState,
  reducers: {
    setErrorIssuesId: (state, action: PayloadAction<number>) => {
      state.issue_id = action.payload;
    },
    setErrorQuery: (state, action: PayloadAction<string>) => {
      state.request_params.q = action.payload;
    },
    setErrorPageNumber: (state, action: PayloadAction<number>) => {
      state.request_params.page = action.payload;
    },
    setErrors: (state, action: PayloadAction<ErrorDataResponse>) => {
      state.error_list = action.payload.error_list;
      state.meta_data = { ...action.payload.meta_data };
    },
    setErrorSources: (state, action: PayloadAction<string[]>) => {
      state.sources = action.payload;
    },
    resetErrorParams: (state) => {
      state.request_params.e = [];
      state.request_params.s = [];
    },
    setErrorSourceEnvironment: (
      state,
      action: PayloadAction<[number, number]>
    ) => {
      const [s, e] = action.payload;

      // Check if the payload environment is not already in the s array
      if (!state.request_params.s.includes(s)) {
        // Add the second number (s) to the s array
        state.request_params.s = [...state.request_params.s, s];
      } else {
        // Remove the second number (s) from the s array
        state.request_params.s = state.request_params.s.filter(
          (item) => item !== s
        );
      }

      // Check if the payload environment is not already in the e array
      if (!state.request_params.e.includes(e)) {
        // Add the first number (e) to the e array
        state.request_params.e = [...state.request_params.e, e];
      } else {
        // Remove the first number (e) from the e array
        state.request_params.e = state.request_params.e.filter(
          (item) => item !== e
        );
      }
    },
    setErrorSourcesParam: (state, action: PayloadAction<number>) => {
      // Check if the payload source is not already in the s array
      if (!_.includes(state.request_params.s, action.payload)) {
        // Add the payload source to the s array using spread syntax
        state.request_params.s = [...state.request_params.s, action.payload];
      } else {
        // Remove the payload source from the s array
        const differenceList = _.difference(state.request_params.s, [
          action.payload,
        ]);
        state.request_params.s = differenceList;
      }
    },
    setErrorEnvironments: (state, action: PayloadAction<string[]>) => {
      state.environments = action.payload;
    },
    setErrorEnvironmentsParams: (state, action: PayloadAction<number>) => {
      // Check if the payload environment is not already in the e array
      if (!_.includes(state.request_params.e, action.payload)) {
        // Add the payload environment to the e array using spread syntax
        state.request_params.e = [...state.request_params.e, action.payload];
      } else {
        // Remove the payload environment from the e array
        const differenceList = _.difference(state.request_params.e, [
          action.payload,
        ]);
        state.request_params.e = differenceList;
      }
    },
    setErrorSortBy: (state, action: PayloadAction<string>) => {
      state.request_params.sort_by = action.payload;
    },
    addErrorSource: (state, action: PayloadAction<number>) => {
      // Check if the payload source is not already in the s array
      if (!_.includes(state.request_params.s, action.payload)) {
        // Add the payload source to the s array using spread syntax
        state.request_params.s = [...state.request_params.s, action.payload];
      } else {
        // Remove the payload source from the s array
        const differenceList = _.difference(state.request_params.s, [
          action.payload,
        ]);
        state.request_params.s = differenceList;
      }
    },
    addErrorEnvironment: (state, action: PayloadAction<number>) => {
      // Check if the payload environment is not already in the e array
      if (!_.includes(state.request_params.e, action.payload)) {
        // Add the payload environment to the e array using spread syntax
        state.request_params.e = [...state.request_params.e, action.payload];
      } else {
        // Remove the payload environment from the e array
        const differenceList = _.difference(state.request_params.e, [
          action.payload,
        ]);
        state.request_params.e = differenceList;
      }
    },
    setErrorTimeRange: (state) => {
      // Extract selected dates in ISO string format
      const startDateISOString =
        state.dateRangeStates.startDateStates.selectedDate;

      const endDateISOString = state.dateRangeStates.endDateStates.selectedDate;

      // Set TimeRange of current state with start and end date from selected dates
      state.request_params.timeRange = {
        from: startDateISOString,
        to: endDateISOString,
      };

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
      state.dateRangeStates.rangePickerViewIcon = "DownArrow";
      state.isResponseLoading = true;
    },
    setIssueDetailsTimeRange: (state, action: PayloadAction<TimeRange>) => {
      // Set TimeRange of current state with start and end date from selected dates
      state.request_params.timeRange = action.payload;
      state.dateRangeStates.startDateStates.selectedDate = action.payload.from;
      state.dateRangeStates.endDateStates.selectedDate = action.payload.to;

      // Set formatted start and end dates
      const formattedStartDate = format(
        new Date(action.payload.from),
        "dd MMM hh:mm a"
      );
      const formattedEndDate = format(
        new Date(action.payload.to),
        "dd MMM hh:mm a"
      );

      state.dateRangeStates.startDateStates.formattedDate = formattedStartDate;
      state.dateRangeStates.endDateStates.formattedDate = formattedEndDate;
      state.dateRangeStates.combinedDateRange = `${formattedStartDate} to ${formattedEndDate}`;
    },
    setErrorStartDateStates: (state, action: PayloadAction<DateTimeStates>) => {
      // Update the start date states for the specified component
      state.dateRangeStates.startDateStates = action.payload;

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
    },
    setErrorEndDateStates: (state, action: PayloadAction<DateTimeStates>) => {
      // Update the end date states for the specified component
      state.dateRangeStates.endDateStates = action.payload;

      // Extract formatted start and end dates
      const startDate = state.dateRangeStates.startDateStates.formattedDate;
      const endDate = state.dateRangeStates.endDateStates.formattedDate;

      // Update the combinedDateRange property in dateStates
      state.dateRangeStates.combinedDateRange = `${startDate} to ${endDate}`;
    },
    setShowErrorRangePicker: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      // Check if the payload is undefined
      if (typeof action.payload === "undefined") {
        // Toggle the visibility of the date range picker
        state.dateRangeStates.showDateRangePicker =
          !state.dateRangeStates.showDateRangePicker;
      } else {
        // Set the visibility based on the payload
        state.dateRangeStates.showDateRangePicker = action.payload;
      }

      // Update the rangePickerViewIcon based on the visibility
      state.dateRangeStates.rangePickerViewIcon = state.dateRangeStates
        .showDateRangePicker
        ? "UpArrow"
        : "DownArrow";
    },
  },
});

export const {
  setErrorIssuesId,
  setErrors,
  setErrorSources,
  setErrorPageNumber,
  setErrorSourcesParam,
  setErrorEnvironments,
  setErrorEnvironmentsParams,
  setErrorSortBy,
  setErrorQuery,
  addErrorSource,
  addErrorEnvironment,
  setErrorTimeRange,
  setShowErrorRangePicker,
  setErrorEndDateStates,
  setErrorStartDateStates,
  resetErrorParams,
  setErrorSourceEnvironment,
} = errorSlice.actions;
export default errorSlice.reducer;

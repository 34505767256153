import { parseApiResponse } from "@actions/parseApiResponse";
import { IJsonAPI, JsonAPIErrorResp } from "@clients/api/types";
import { RepositoryURL } from "./constants.enum";
import { IProjectRepository } from "./types";

export class ProjectRepository implements IProjectRepository {
  private _api: IJsonAPI;

  private PROJECT_URL = RepositoryURL.PROJECT_URL;
  private ORGANIZATION_PROJECT_URL = RepositoryURL.ORGANIZATION_PROJECT_URL;
  private ORGANIZATION_URL = RepositoryURL.ORGANIZATION_URL;

  constructor(API: IJsonAPI) {
    this._api = API;
  }

  async getProject<ProjectData>(OrgId: number) {
    const response = await this._api.get<ProjectData[]>(
      `${this.ORGANIZATION_URL}${OrgId}/${this.PROJECT_URL}`
    );
    return parseApiResponse(response);
  }

  async editProject<ProjectData, ProjectFieldData>(
    projectId: string,
    data: ProjectFieldData
  ) {
    const project_url = `${this.ORGANIZATION_PROJECT_URL}${projectId}/`;

    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const response = await this._api.put<ProjectData, ProjectFieldData>(
      project_url,
      data,
      headers
    );
    return parseApiResponse<ProjectData>(response);
  }

  async createProject<ProjectData, ProjectFieldData>(
    OrgId: number,
    data: ProjectFieldData
  ): Promise<ProjectData | JsonAPIErrorResp | undefined> {
    const headers = new Map<string, string>();
    headers.set("Content-Type", "application/json");

    const response = await this._api.post<ProjectData, ProjectFieldData>(
      `${this.ORGANIZATION_URL}${OrgId}/${this.PROJECT_URL}`,
      data,
      headers
    );
    return parseApiResponse<ProjectData>(response);
  }

  async deleteProject(projectId: string) {
    return await this._api.delete(
      `${this.ORGANIZATION_URL}projects/${projectId}/`
    );
  }
}

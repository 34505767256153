import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: DatePickerProps = {
  showMonths: false,
  monthSelectorIcon: "DownArrow",
  amIndicatorIcon: "ConcentricCircle",
  pmIndicatorIcon: "Circle",
};

const datepickerSlice = createSlice({
  name: "datepicker",
  initialState,
  reducers: {
    setShowMonths: (state, action: PayloadAction<boolean>) => {
      state.showMonths = action.payload;
      state.monthSelectorIcon = state.showMonths ? "UpArrow" : "DownArrow";
    },
    setTimeIndicatorIcons: (state, action: PayloadAction<boolean>) => {
      const isAM = action.payload;
      state.amIndicatorIcon = isAM ? "ConcentricCircle" : "Circle";
      state.pmIndicatorIcon = !isAM ? "ConcentricCircle" : "Circle";
    },
  },
});

export const { setShowMonths, setTimeIndicatorIcons } = datepickerSlice.actions;

export default datepickerSlice.reducer;
